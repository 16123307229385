import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Moment } from 'moment'
import * as moment from 'moment';

import { dashboardVarialbles } from 'src/environments/environment';

@Component({
  selector: 'app-receipt-history',
  templateUrl: './receipt-history.component.html',
  styleUrls: ['./receipt-history.component.css']
})
export class ReceiptHistoryComponent implements OnInit {
  today: any;
  startDate: any;
  data: any[];
  basicDataLabels = [];
  pendingData = [];
  stagingData = [];
  completedData = [];
  selectedDateRange: any;
  apiPostData: any;
  todaysDate: any;
  firstDateOfThisMonth: any;
  lastDateOfThisMonth: any;
  firstDateOfLastMonth: any;
  lastDateOfLastMonth: any;
  firstDateOfSecondLastMonth: any;
  lastDateOfSecondLastMonth: any;
  firstDateOfThirdLastMonth: any;
  lastDateOfThirdLastMonth: any;
  daysInMonth: any;
  lastfifteenDays: any;
  lastFourtyFiveDays: any;
  secondLstMonth: any;
  ThirdLstMonth: any;
  totalDaysInLastMonth: any;
  totalDaysInLastSecondMonth: any;
  totalDaysInLastThirdMonth: any;
  categories: any;
  dropdownArray: any;
  newArray: any[];
  constructor(private datePipe: DatePipe, private apiCall: ApiService) { }
  title: any;
  basicData: any;
  StackedOptions
  ngOnInit() {
    this.basicDataLabels = [];
    this.pendingData = [];
    this.stagingData = [];
    this.completedData = [];
    this.dropdownArray = [];
    const now = moment();
    let todaysDates = new Date();

    // 1st
    this.todaysDate = moment(todaysDates).format('YYYY-MM-DD')
    this.today = moment(todaysDates).format('DD')
    this.firstDateOfThisMonth = moment(this.todaysDate).startOf('month').format('YYYY-MM-DD')
    // 1st end

    this.lastDateOfThisMonth = moment(this.todaysDate).endOf('month').format('YYYY-MM-DD')
    // 2nd 
    this.firstDateOfLastMonth = moment(this.todaysDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfLastMonth = moment(this.todaysDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    // 2nd end



    // 3rd
    this.firstDateOfSecondLastMonth = moment(this.todaysDate).subtract(2, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfSecondLastMonth = moment(this.todaysDate).subtract(2, 'months').endOf('month').format('YYYY-MM-DD')

    // 4th
    this.firstDateOfThirdLastMonth = moment(this.todaysDate).subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfThirdLastMonth = moment(this.todaysDate).subtract(3, 'months').endOf('month').format('YYYY-MM-DD')
    // 3rd end



    // Last 30 Days
    // this.lastfifteenDays = moment(todaysDates).format('YYYY-MM-DD').subtract(14, 'd');
    this.daysInMonth = 15
    this.lastfifteenDays = moment(this.todaysDate).subtract(15, 'days').format('YYYY-MM-DD')


    this.lastFourtyFiveDays = moment(this.lastfifteenDays).subtract(15, 'days').format('YYYY-MM-DD')



    console.log("days are", this.lastfifteenDays, this.lastFourtyFiveDays);


    // Last !5 Days end

    this.firstDateOfThisMonth = moment(this.todaysDate).startOf('month').format('YYYY-MM-DD')
    // 1st end


    let thisMonth = moment(this.firstDateOfThisMonth).format('MMM')
    let lastMonth = moment(this.firstDateOfLastMonth).format('MMM')
    this.secondLstMonth = moment(this.firstDateOfSecondLastMonth).format('MMM')
    this.ThirdLstMonth = moment(this.firstDateOfThirdLastMonth).format('MMM')
    let lastFourMonthArr = ['Last 15 Days']
    let daysObj = {
      'Last 15 Days': {
        startDate: this.lastfifteenDays,
        endDate: this.todaysDate

      }
    }
    if (this.today > 15) {

      lastFourMonthArr.push('01 ' + thisMonth + "-" + 15 + " " + thisMonth)
      daysObj['01 ' + thisMonth + "-" + 15 + " " + thisMonth] = {
        startDate: moment(this.firstDateOfThisMonth).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(this.firstDateOfThisMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
      }
    }

    console.log("==============================================>", lastMonth, this.secondLstMonth, this.ThirdLstMonth)

    this.totalDaysInLastMonth = moment(this.firstDateOfLastMonth, "YYYY-MM-DD").daysInMonth()

    lastFourMonthArr.push(16 + " " + lastMonth + "-" + this.totalDaysInLastMonth + " " + lastMonth)
    daysObj[16 + " " + lastMonth + "-" + this.totalDaysInLastMonth + " " + lastMonth] = {
      startDate: moment(this.firstDateOfLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfLastMonth).endOf('month').format('YYYY-MM-DD')
    }

    lastFourMonthArr.push('01 ' + lastMonth + "-" + 15 + " " + lastMonth)
    daysObj['01 ' + lastMonth + "-" + 15 + " " + lastMonth] = {
      startDate: moment(this.firstDateOfLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    this.totalDaysInLastSecondMonth = moment(this.firstDateOfSecondLastMonth, "YYYY-MM-DD").daysInMonth()
    lastFourMonthArr.push(16 + " " + this.secondLstMonth + "-" + this.totalDaysInLastSecondMonth + " " + this.secondLstMonth)
    daysObj[16 + " " + this.secondLstMonth + "-" + this.totalDaysInLastSecondMonth + " " + this.secondLstMonth] = {
      startDate: moment(this.firstDateOfSecondLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfSecondLastMonth).endOf('month').format('YYYY-MM-DD')
    }
    lastFourMonthArr.push('01 ' + this.secondLstMonth + "-" + 15 + " " + this.secondLstMonth)
    daysObj['01 ' + this.secondLstMonth + "-" + 15 + " " + this.secondLstMonth] = {
      startDate: moment(this.firstDateOfSecondLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfSecondLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    this.totalDaysInLastThirdMonth = moment(this.firstDateOfThirdLastMonth, "YYYY-MM-DD").daysInMonth()
    lastFourMonthArr.push(16 + " " + this.ThirdLstMonth + "-" + this.totalDaysInLastThirdMonth + " " + this.ThirdLstMonth)
    daysObj[16 + " " + this.ThirdLstMonth + "-" + this.totalDaysInLastThirdMonth + " " + this.ThirdLstMonth] = {
      startDate: moment(this.firstDateOfThirdLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfThirdLastMonth).endOf('month').format('YYYY-MM-DD')
    }
    lastFourMonthArr.push('01 ' + this.ThirdLstMonth + "-" + 15 + " " + this.ThirdLstMonth)
    daysObj['01 ' + this.ThirdLstMonth + "-" + 15 + " " + this.ThirdLstMonth] = {
      startDate: moment(this.firstDateOfThirdLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfThirdLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }



    // this.categories[2].value = this.secondLstMonth



    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", lastFourMonthArr,
      daysObj)
    // push into dropdown
    lastFourMonthArr.forEach(element => {
      this.dropdownArray.push({ "id": lastFourMonthArr.indexOf(element), "value": element })
    })

    console.log("array dropdown issssssssssssssss    ", this.dropdownArray);

    // console.log(dashboardVarialbles.receiptHistoryDateRange);

    if (dashboardVarialbles.receiptHistoryDateRange == 'last_15_Days') {

      this.selectedDateRange = 'last_15_Days'
      this.apiPostData = {
        "startDate": daysObj['last_15_Days']['startDate'],
        "endDate": daysObj['last_15_Days'].endDate,
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
    }
    else {
      this.selectedDateRange = dashboardVarialbles.receiptHistoryDateRange
      this.apiPostData = {
        "startDate": daysObj[dashboardVarialbles.receiptHistoryDateRange].startDate,
        "endDate": daysObj[dashboardVarialbles.receiptHistoryDateRange].endDate,
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
    }
    // if (dashboardVarialbles.receiptHistoryDateRange == 'this_month') {
    //   this.selectedDateRange = 'this_month'
    //   this.apiPostData = {
    //     "startDate": thisMonthDate.startdate,
    //     "endDate": thisMonthDate.enddate,
    //     "warehouseId": 11
    //   }
    // }
    // if (dashboardVarialbles.receiptHistoryDateRange == 'last_month') {
    //   this.selectedDateRange = 'last_month'
    //   this.apiPostData = {
    //     "startDate": lastMonthDate.startdate,
    //     "endDate": lastMonthDate.endDate,
    //     "warehouseId": 11
    //   }
    // }
    // if (dashboardVarialbles.receiptHistoryDateRange == 'second_last_month') {
    //   this.selectedDateRange = 'second_last_month'
    //   this.apiPostData = {
    //     "startDate": secondLastMonthdate.startdate,
    //     "endDate": secondLastMonthdate.endDate,
    //     "warehouseId": 11
    //   }
    // }

    // this.dropdownArray = []
    // this.newArray = [];
    // this.pendingData = [];
    // this.stagingData = [];
    // this.completedData = [];
    // this.title = "GFG";

    // api call










    this.apiCall.receiptHistory(this.apiPostData).subscribe(res => {
      this.data = res['result']
      // console.log(this.data)
      for (let i = 0; i < this.data.length; i++) {

        this.basicDataLabels.push(this.datePipe.transform(this.data[i].date, 'MMM dd'))
        this.pendingData.push(this.data[i].Pending)
        this.stagingData.push(this.data[i].Staging)
        this.completedData.push(this.data[i].Confirmed)

        // console.log((this.basicDataLabels));
        // const myArray = Object.entries(this.basicDataLabels);
        // console.log(myArray);


      }
      this.title = "GFG";

      // this.StackedOptions.global.elements.rectangle.borderWidth=2

      this.basicData = {
        labels: this.basicDataLabels,
        datasets: [
          {
            label: "Pending",
            backgroundColor: "#6CA6C1",
            // barPercentage: 0.5,
            barThickness: 9,
            maxBarThickness: 9,
            data: this.pendingData
          },
          {
            label: "Staging",
            backgroundColor: "#F9DF62",
            // barPercentage: 0.5,
            barThickness: 9,
            maxBarThickness: 9,
            data: this.stagingData
          },
          {
            label: "Completed",
            backgroundColor: "#72C238",
            barThickness: 9,
            maxBarThickness: 9,

            data: this.completedData
          }
        ]
      };

      // for (let i = 0; i < this.basicDataLabels.length; i++) {
      //   this.basicData.labels.push(this.basicDataLabels[i])
      // }
      // console.log(this.basicData.labels)





      this.StackedOptions = {
        legend: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          },
          barWidth: {
            ratio: 0.5 // Adjust the bar width
          }
        },
        layout: {
          padding: {
            left: 10,
            right: 10
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false,

            },
            ticks: {
              color: "#black",
              fontSize: 7


            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
              color: '#F6F6F6',
              borderDash: [12, 5]

            },
            ticks: {
              color: "#black"
            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }]
        },

      };
      // this.data.forEach(element => {
      //   this.basicDataLabels.push(element)
      // });

    })

  }
  lastthirtyDays(lastthirtyDays: any) {
    throw new Error('Method not implemented.');
  }
  dateRangeChange(event) {
    // console.log(event.target.value)
    dashboardVarialbles.receiptHistoryDateRange = event.target.value
    this.ngOnInit();
  }
  ondataclick(data) {
    // console.log(data);

  }

}


// [
//   "Sep 06",
//   "Sep 07",
//   "Sep 08",
//   "Sep 09",
//   "Sep 10",
//   "Sep 11",
//   "Sep 12",
//   "Sep 13",
//   "Sep 14",
//   "Sep 15",
//   "Sep 16",
//   "Sep 17",
//   "Sep 18",
//   "Sep 19",
//   "Sep 20",
//   "Sep 21"
// ]



// api call




