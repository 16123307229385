import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { LoaderService } from './loader.service';
import { Observable, BehaviorSubject, pipe, throwError } from 'rxjs';
import { tap, catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  decodedToken: any;
  helper = new JwtHelperService();

  constructor(private injector: Injector, private loginService: ApiService, private auth: AuthService, private router: Router, public loaderService: LoaderService) { }

  private isTokenRefreshing: boolean = false;

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true)
    // Check if the user is logging in for the first time

    return next.handle(this.attachTokenToRequest(request)).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log("Success");
          this.loaderService.isLoading.next(false)
        }
      }),

      catchError((err): Observable<any> => {
        if (err instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>err).status) {
            case 401:
              console.log("Token expired. Attempting refresh ...");
              return this.handleHttpResponseError(request, next);
            case 400:
              return <any>this.loginService.logout();
            case 404:
              console.log('Not Found',err);
              this.loaderService.isLoading.next(false);
              return;
            case 504:
              console.log('Retry again',err);
              this.loaderService.isLoading.next(false);
              return ;
          }
        } else {
          return throwError(this.handleError);
        }
      })

    );
  }

  // Global error handler method 
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMsg: string;

    if (errorResponse.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = "An error occured : " + errorResponse.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMsg = `Backend returned code ${errorResponse.status}, body was: ${errorResponse.error}`;
    }

    return throwError(errorMsg);
  }



  // Method to handle http error response
  private handleHttpResponseError(request: HttpRequest<any>, next: HttpHandler) {
    // First thing to check if the token is in process of refreshing
    if (!this.isTokenRefreshing)  // If the Token Refresheing is not true
    {
      this.isTokenRefreshing = true;

      // Any existing value is set to null
      // Reset here so that the following requests wait until the token comes back from the refresh token API call
      this.tokenSubject.next(null);

      /// call the API to refresh the token
      return this.loginService.getNewRefreshToken().pipe(
        switchMap((tokenresponse: any) => {
          if (tokenresponse) {
            this.tokenSubject.next(tokenresponse.token);
            localStorage.setItem('loginStatus', '1');
            localStorage.setItem('session_access_token', tokenresponse.token);
            this.decodedToken = this.helper.decodeToken(localStorage.getItem('session_access_token'));
            localStorage.setItem('CusID', this.decodedToken.customerId)
            localStorage.setItem('ID', this.decodedToken.id)
            localStorage.setItem('U_name', this.decodedToken.name)
            localStorage.setItem('U_email', this.decodedToken.email)
            console.log("Token refreshed...");
            return next.handle(this.attachTokenToRequest(request));

          }
          return <any>this.loginService.logout();
        }),
        catchError(err => {
          this.loginService.logout();
          return this.handleError(err);
        }),
        finalize(() => {
          this.isTokenRefreshing = false;
           this.loaderService.isLoading.next(false)
        })
      );

    }
    else {
      this.isTokenRefreshing = false;
      return this.tokenSubject.pipe(filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.attachTokenToRequest(request));
        }));
    }
  }


  private attachTokenToRequest(request: HttpRequest<any>) {
    var token = localStorage.getItem('session_access_token');

    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });

  }
}
