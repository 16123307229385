import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Table } from 'primeng/table';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-completed-receipt',
  templateUrl: './view-completed-receipt.component.html',
  styleUrls: ['./view-completed-receipt.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class ViewCompletedReceiptComponent implements OnInit {
  @ViewChild('myTable') private _table: Table;
  completedreceiptData: any;
  receiptNumber: any;
  cus_name: any;
  bookingId: any;
  InfoPickupInvoice: any;
  Date: any;
  ExpectedDate: any;
  ExpectedTime: any;
  SupplierName: any;
  SupplierMobile: any;
  Add_material: any;
  notFound = false;
  fileName: any;
  documentView: any;
  InvoiceDetails: any;
  EmptyInvoice = false;
  GrnNo: any;
  materialList: { field: string; header: string; }[];
  status: any;
  BusinessName: any;
  Sub_Table: any;
  statusColor: string[];
  LoopForLocation = [];
  OrginalLoopLocation = [];
  grnDocument: any;
  showDetails: boolean ;
  showReceivedSkus: boolean;
  downloadTab: boolean;

  constructor(private router: Router, private apiCall: ApiService) { }

  ngOnInit() {
    this.showdetailstab();
    this.ViewCompletedReceipt();
  }
  showdetailstab(){
    this.showDetails=true
    this.showReceivedSkus=false
    this.downloadTab=false

  }
  showreceivedskustab(){
    this.showDetails=false
    this.showReceivedSkus=true
    this.downloadTab=false
  }
  showDownloadTab(){
    this.showDetails=false
    this.showReceivedSkus=false
    this.downloadTab=true
  }
  ViewCompletedReceipt() {
    this.apiCall.getViewCompletedReceipt()
      .subscribe(res => {
        this.completedreceiptData = res.result
        console.log("COMPLETED RECEIPT", this.completedreceiptData)
        this.status = res.result[0].status
        this.grnDocument =  res.result[0].grnURL
        for (var i = 0; i < this.completedreceiptData[0].materials.length; i++) {
          this.Add_material = this.completedreceiptData[0].materials;
          // if (Object.keys(this.completedreceiptData[i].materials[i].materialLocations).length === 0) {
          //   this.Add_material[i]['location'] = ""

          // }
          // else {
          //   this.LoopForLocation = this.completedreceiptData[i].materials[i].materialLocations
          //   // this.Add_material[i]['location'] = this.completedreceiptData[i].materials[i].materialLocations.map(({ location }) => location)
          // }
          if (!this.completedreceiptData[0].documents.length) {
            this.notFound = true
            // console.log('',this.file)
          }
          else {
            this.fileName = this.completedreceiptData[0].documents
            this.documentView = this.fileName[0].fileUrl
          }
          
        }
        for(let i=0;i<this.Add_material.length;i++){
          if(this.Add_material[i].excessQuantity!=0){
            this.Add_material[i]['discrepancy']=this.Add_material[i].excessQuantity
          }
          if(this.Add_material[i].shortageQuantity!=0){
            this.Add_material[i]['discrepancy']=-(this.Add_material[i].shortageQuantity)
          }
          if(this.Add_material[i].shortageQuantity==0 &&this.Add_material[i].excessQuantity==0){
            this.Add_material[i]['discrepancy']=0
          }
        }
        this.materialList = [
          { field: 'materialCode', header: 'Product Code' },
          { field: 'materialName', header: 'Product Name' },
          { field: 'location', header: 'Location' },
          { field: 'goodQuantity', header: 'Good' },
          { field: 'damagedQuantity', header: 'Damaged' },
          { field: 'quantity', header: 'Total' },
          { field: 'invoiceQuantity', header:'Invoice Quantity'},
          { field: 'discrepancy', header:'Descrepancy'}
          // { field: 'unitOfMaterials', header: 'Unit Of Material' }
          // { field: 'weight', header: 'Weight' },
          // { field: 'remark', header: 'Remarks' },
        ];

        this.receiptNumber = res.result[0].id
        this.BusinessName = res.result[0].businessName
        this.cus_name = res.result[0].customerName
        this.bookingId = res.result[0].bookingId
        // this.InfoPickupInvoice = res.result[0].pickupInvoice
        this.Date = res.result[0].createdAt
        this.ExpectedDate = res.result[0].pickupDate
        this.ExpectedTime = res.result[0].pickupTime
        this.SupplierName = res.result[0].supplier.name
        this.SupplierMobile = res.result[0].supplier.mobileNo
        this.GrnNo = res.result[0].grnNumber
        this.InvoiceDetails = res.result[0]
        if (!this.InvoiceDetails.pickupInvoice.length) {
          this.EmptyInvoice = true;
        }
        else {
          this.InfoPickupInvoice = res.result[0].pickupInvoice
          console.log("CHECKING INVOICE", this.InfoPickupInvoice)
        }
      })
  }

  applyFilter(filter: String) {
    this.Add_material = this.Add_material.filter(item => {

      return item.description.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    }
    );
    if (filter === '') {
      this.ViewCompletedReceipt()
    }else{
      this.notFound = true
    }
  }

  documentViewPage() {
    window.open(this.documentView, '_blank')
  }
}
