import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { WareHouseModule } from '../app/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, DateAdapter } from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginformComponent } from './loginform/loginform.component';
import { ViewCustomersComponent } from './view-customers/view-customers.component';
import { InplaceModule } from 'primeng/inplace';

import { DatePipe } from '@angular/common';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from "@mat-datetimepicker/core";

import { AgmCoreModule } from '@agm/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';





import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PaginatorModule } from 'primeng/paginator';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LightboxModule } from 'primeng/lightbox';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { GalleriaModule } from 'primeng/galleria';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { ChartModule } from 'primeng/chart';



import { NgCircleProgressModule } from 'ng-circle-progress';

import { ViewSelectedCustomerComponent } from './view-selected-customer/view-selected-customer.component';
import { StockstatementComponent } from './stockstatement/stockstatement.component';
import { StockledgerComponent } from './stockledger/stockledger.component';
import { PendingreceiptComponent } from './pendingreceipt/pendingreceipt.component';
import { PendingdeliveriesComponent } from './pendingdeliveries/pendingdeliveries.component';
import { CompletedreceiptComponent } from './completedreceipt/completedreceipt.component';
import { CompleteddeliveriesComponent } from './completeddeliveries/completeddeliveries.component';
import { CreateReceiptComponent } from './create-receipt/create-receipt.component';
import { CompleteDeliveryComponent } from './complete-delivery/complete-delivery.component';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewSelectedDeliveryComponent } from './view-selected-delivery/view-selected-delivery.component';
import { ViewSelectedReceiptComponent } from './view-selected-receipt/view-selected-receipt.component';
import { AddMaterialComponent } from './add-material/add-material.component';
import { ViewCompletedReceiptComponent } from './view-completed-receipt/view-completed-receipt.component';
import { ViewCompletedDeliveryComponent } from './view-completed-delivery/view-completed-delivery.component';
import { MaterialMasterComponent } from './material-master/material-master.component';
import { NewAddMaterialComponent } from './new-add-material/new-add-material.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { StockTransactionComponent } from './stock-transaction/stock-transaction.component';
import { InterceptorService } from './loader/interceptor.service';
import { SearchByProductComponent } from './search-by-product/search-by-product.component';
import { SearchByLocationComponent } from './search-by-location/search-by-location.component';
import { LocationTransferComponent } from './location-transfer/location-transfer.component';
import { ReturnGrnComponent } from './return-grn/return-grn.component';

import { InputRestrictionDirective } from './pipe';
import { LocationPutawayComponent } from './location-putaway/location-putaway.component';
import { AddMatLocationComponent } from './add-mat-location/add-mat-location.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LocationTypeComponent } from './location-type/location-type.component';
import { AddRowDirective } from './location-type/add-row.directive';
import { LocationMasterComponent } from './location-master/location-master.component';
import { AddRowLocMastDirective } from './location-master/add-row-loc-mast.directive';
import { ShippingOrdersComponent } from './shipping-orders/shipping-orders.component';
import { CheckCourierServiceComponent } from './check-courier-service/check-courier-service.component';
import { TractOrderComponent } from './tract-order/tract-order.component';
import { CreateShipmentComponent } from './create-shipment/create-shipment.component';
import { GrnDetailsComponent } from './grn-details/grn-details.component';
import { ViewGrnDetailsComponent } from './view-grn-details/view-grn-details.component';
import { ViewReturnGrnDetailsComponent } from './view-return-grn-details/view-return-grn-details.component';
import { DnDetailsComponent } from './dn-details/dn-details.component';
import { ViewDnDetailsComponent } from './view-dn-details/view-dn-details.component';
import { FooterComponent } from './footer/footer.component';
import { WareHouseDetailsComponent } from './dashboard/ware-house-details/ware-house-details.component';
import { ReceiptHistoryComponent } from './dashboard/receipt-history/receipt-history.component';
import { DeliveryHistoryComponent } from './dashboard/delivery-history/delivery-history.component';
import { ReceiptPieComponent } from './dashboard/receipt-pie/receipt-pie.component';
import { DeliveriesPieComponent } from './dashboard/deliveries-pie/deliveries-pie.component';
import { LocationsPieComponent } from './dashboard/locations-pie/locations-pie.component';
import * as moment from 'moment';
import { GoodAndDamageskusComponent } from './dashboard/good-and-damageskus/good-and-damageskus.component';
import { WarehouseUtillizationComponent } from './dashboard/warehouse-utillization/warehouse-utillization.component';
import { MostOrderPlacedComponent } from './dashboard/most-order-placed/most-order-placed.component';
import { OrderHistoryComponent } from './dashboard/order-history/order-history.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginformComponent,
    ViewCustomersComponent,
    ViewSelectedCustomerComponent,
    StockstatementComponent,
    StockledgerComponent,
    PendingreceiptComponent,
    PendingdeliveriesComponent,
    CompletedreceiptComponent,
    CompleteddeliveriesComponent,
    CreateReceiptComponent,
    CompleteDeliveryComponent,
    NavHeaderComponent,
    DashboardComponent,
    ViewSelectedDeliveryComponent,
    ViewSelectedReceiptComponent,
    AddMaterialComponent,
    ViewCompletedReceiptComponent,
    ViewCompletedDeliveryComponent,
    MaterialMasterComponent,
    NewAddMaterialComponent,
    ChangePasswordComponent,
    StockTransactionComponent,
    SearchByProductComponent,
    SearchByLocationComponent,
    LocationTransferComponent,
    ReturnGrnComponent,
    InputRestrictionDirective,
    LocationPutawayComponent,
    AddMatLocationComponent,
    LocationTypeComponent,
    AddRowDirective,
    LocationMasterComponent,
    AddRowLocMastDirective,
    ShippingOrdersComponent,
    CheckCourierServiceComponent,
    TractOrderComponent,
    CreateShipmentComponent,
    GrnDetailsComponent,
    ViewGrnDetailsComponent,
    ViewReturnGrnDetailsComponent,
    DnDetailsComponent,
    ViewDnDetailsComponent,
    FooterComponent,
    WareHouseDetailsComponent,
    ReceiptHistoryComponent,
    DeliveryHistoryComponent,
    ReceiptPieComponent,
    DeliveriesPieComponent,
    LocationsPieComponent,
    GoodAndDamageskusComponent,
    WarehouseUtillizationComponent,
    MostOrderPlacedComponent,
    OrderHistoryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    WareHouseModule,
    HttpClientModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    InplaceModule,
    FormsModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    CheckboxModule,
    CalendarModule,
    TooltipModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    ButtonModule,
    PanelMenuModule,
    ToastModule,
    ToggleButtonModule,
    PaginatorModule,
    SplitButtonModule,
    InputSwitchModule,
    LightboxModule,
    SidebarModule,
    GalleriaModule,
    RadioButtonModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatSlideToggleModule,
    ChartModule,

    NgxMaterialTimepickerModule.forRoot(),
    NgCircleProgressModule.forRoot({
      lazy: false
    }),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyDGSiGHskPprBbDS2xNcoGEDGd3sKpoP3g',
      // apiKey: 'AIzaSyCuc_qvc4eqTFp2iu04STZOPUAHcTsMVsw',
      // apiKey: 'AIzaSyDGSiGHskPprBbDS2xNcoGEDGd3sKpoP3g',
      // apiKey: 'AIzaSyAI2lydBABMfzmS3v1JXt0oWJpp8qg9wHo', 
      apiKey: 'AIzaSyCuc_qvc4eqTFp2iu04STZOPUAHcTsMVsw',



      //Last Key created by Vignesh on 25th Mar20
      //last Key created by Kiran on 5th Mar21
      //Current key updated by utkarsh shared by vignesh on 15th May23
      libraries: ['places']
    }),
  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true
    }
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
