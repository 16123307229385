import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../api.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { myVariables } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  warehouseParams = { warehouseId: localStorage.getItem('WarehouseID') }
  customersCount: any;
  customers: any;
  pendingCounts: any;
  pendingReceiptCount: any;
  pendingDeliveriesCount: any;
  deliveredCounts: any;
  deliverdReceiptCount: any;
  deliverdDeliveriesCount: any;
  AreaSqFt: any;
  AreaSquareFeet: any;
  WarehouseName = [];
  warehouseTypes: FormGroup;
  Warehousename: any;
  warehouseNameID: any;
  warehouseLocation: any;
  completeCounts: any;
  completedReceiptCount: any;
  completedDeliveryCount: any;


  openRecieptsCircleOptions: any;
  openDeliveryCircleOptions: any;
  CompRecieptsCircleOptions: any;
  CompDeliveryCircleOptions: any;
  AlertsCircleOptions: any;
  progressbarOpenReceipt: any;
  progressbarOpenDelivery: any;
  progressbarCompReceipt: any;
  progressbarCompDelivery: any;
  progressbarAlerts: any;
  warehouseArray: any;

  constructor(private apiCall: ApiService, private formBuilder: FormBuilder, private router: Router) { }
  ngOnInit() {
    myVariables.completedReceiptRowsPerPage = 1,
      myVariables.completedDeliveryRowsPerPage = 1,
      myVariables.stagingReceiptRowsPerPage = 1,
      myVariables.stagingReceiptPageNumber = 1,
      myVariables.completedReceiptPageNumber = 1,
      myVariables.completedDeliveryPageNumber = 1,
      myVariables.pendingDeliveriespageNumber = 1,
      myVariables.pendingDeliveriesRowsPerPage = 1,
      myVariables.pendingReceiptRowsPerPage = 1,
      myVariables.pendingReceiptPageNumber = 1,
      myVariables.grnPageNumber = 1,
      myVariables.grnRowsPerPage = 1,
      myVariables.returnGrnPageNumber = 1,
      myVariables.returnGrnRowsPerPage = 1,
      myVariables.dnRowsPerPage = 1,
      myVariables.dnPageNumber = 1,
      myVariables.shippingOrdersRowsPerPage = 1,
      myVariables.shippingOrdersPageNumber = 1,
      myVariables.StockStatementPageNumber = 1,
      myVariables.StaockStatementRowsPerPage = 1,
      myVariables.directChannelOrder = null,
      sessionStorage.clear();

    this.warehouseDetails();
    // if(!localStorage.getItem('ConfigDetails')){
    //   window.location.reload();
    // }
    this.warehouseTypes = this.formBuilder.group({
      warehouseTypes: [null, Validators.required]
    });

    this.openRecieptsCircleOptions = {
      subtitleFormat: (percent: number): string => {
        if (0 <= percent && percent <= 25) {
          this.progressbarOpenReceipt = "red";
        } else if (26 <= percent && percent <= 50) {
          this.progressbarOpenReceipt = "#6065E9";
        } else if (51 <= percent && percent <= 100) {
          this.progressbarOpenReceipt = "#591471";
        } else if (100 < percent) {
          this.progressbarOpenReceipt = "#60E9AE";
        }
        return '';
      }
    }

    this.openDeliveryCircleOptions = {
      subtitleFormat: (percent: number): string => {
        if (0 <= percent && percent <= 25) {
          this.progressbarOpenDelivery = "red";
        } else if (26 <= percent && percent <= 50) {
          this.progressbarOpenDelivery = "#6065E9";
        } else if (51 <= percent && percent <= 100) {
          this.progressbarOpenDelivery = "#591471";
        } else if (100 < percent) {
          this.progressbarOpenDelivery = "#60E9AE";
        }
        return '';
      }
    }

    this.CompRecieptsCircleOptions = {
      subtitleFormat: (percent: number): string => {
        if (0 <= percent && percent <= 25) {
          this.progressbarCompReceipt = "red";
        } else if (26 <= percent && percent <= 50) {
          this.progressbarCompReceipt = "#6065E9";
        } else if (51 <= percent && percent <= 100) {
          this.progressbarCompReceipt = "#591471";
        } else if (100 < percent) {
          this.progressbarCompReceipt = "#60E9AE";
        }
        return '';
      }
    }

    this.CompDeliveryCircleOptions = {
      subtitleFormat: (percent: number): string => {
        if (0 <= percent && percent <= 25) {
          this.progressbarCompDelivery = "red";
        } else if (26 <= percent && percent <= 50) {
          this.progressbarCompDelivery = "#6065E9";
        } else if (51 <= percent && percent <= 100) {
          this.progressbarCompDelivery = "#591471";
        } else if (100 < percent) {
          this.progressbarCompDelivery = "#60E9AE";
        }
        return '';
      }
    }
  }

  dashboardDetails() {
    this.apiCall.getDashboardApi(this.warehouseParams)
      .subscribe(res => {
        console.log("Result", res.result)
        this.customersCount = res.result['Served']
        this.customers = this.customersCount['Total number of Customers']
        this.AreaSquareFeet = res.result['Served']
        this.AreaSqFt = this.AreaSquareFeet['Total Area Occupied']
        this.pendingCounts = res.result['Pending Tasks']
        this.pendingReceiptCount = this.pendingCounts['Receipts Count']
        this.pendingDeliveriesCount = this.pendingCounts['Deliveries Count']
        this.completeCounts = res.result['Completed Tasks']
        this.completedReceiptCount = this.completeCounts['Receipts Count']
        this.completedDeliveryCount = this.completeCounts['Deliveries Count']
        // this.deliveredCounts = res.result['Served']
        // this.deliverdReceiptCount = this.deliveredCounts['Receipts Count']
        // this.deliverdDeliveriesCount = this.deliveredCounts['Deliveries Count']
        // this.warehouseLocation = res.result['warehouse Address']
        this.warehouseLocation = JSON.parse(localStorage.getItem('WarehouseArray'))

        // this.warehouseLocation["div"] = <div>hello</div>
        console.log("WAREHOUSE LOCATION", this.warehouseLocation)
        this.WarehouseName = this.warehouseLocation
        this.WarehouseName.forEach(element => {
          this.warehouseNameID = element.name
        });
        const toSelect = this.WarehouseName.find(c => c.name == this.warehouseNameID);
        this.warehouseTypes.get('warehouseTypes').setValue(toSelect);
      })
    console.log(this.WarehouseName)
    this.ConfigPhaseDetails()
  }
  warehouseDetails() {
    // this.apiCall.getWarehouseDetails()
    //   .subscribe(res => {
    //     console.log("WarehouseDetails", res)
    if (this.warehouseParams.warehouseId == null) {
      this.warehouseArray = JSON.parse(localStorage.getItem('WarehouseArray'))
      this.warehouseParams.warehouseId = this.warehouseArray[0].id
      console.log(this.warehouseParams)
      localStorage.setItem('WarehouseID', this.warehouseParams.warehouseId)
    }
    else {
      this.warehouseParams.warehouseId = localStorage.getItem('WarehouseID')
    }

    console.log(this.warehouseParams)
    // localStorage.setItem("WarehouseID", res.result.id)

    this.dashboardDetails()

    // })
  }

  ConfigPhaseDetails() {
    console.log(localStorage.getItem('WarehouseID'))
    this.apiCall.getConfigDetails()
      .subscribe(res => {
        console.log(res)
        res.result.map(ass => {
          ass.hasOwnProperty('description')
          delete ass['description']

          ass.hasOwnProperty('module')
          delete ass['module']

          ass.hasOwnProperty('subModule')
          delete ass['subModule']

          ass.hasOwnProperty('title')
          delete ass['title']

          ass.hasOwnProperty('userRole')
          delete ass['userRole']
        })
        localStorage.setItem('ConfigDetails', JSON.stringify(res.result))
      })
  }
  Customer() {
    this.router.navigate(['viewcustomers'])
  }
  PendingReceipt() {
    this.router.navigate(['/pendingreceipt'])
  }
  PendingDeliveries() {
    this.router.navigate(['/pendingdeliveries'])
  }
  CompletedReceipt() {
    this.router.navigate(['/completedreceipt'])
  }
  CompletedDelivery() {
    this.router.navigate(['/completeddeliveries'])
  }
}