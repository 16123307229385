import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-view-grn-details',
  templateUrl: './view-grn-details.component.html',
  styleUrls: ['./view-grn-details.component.css']
})
export class ViewGrnDetailsComponent implements OnInit {
  grnno: any;
  grndate: any;
  suppliername: any;
  pono: any;
  result: any;
  supplierInvoiceNumber: any;
  cols: { field: string; header: string; }[];
  skuinformation:any
  grnUrl: any;
  constructor(private ApiCall:ApiService) { }

  ngOnInit() {
    this.getgrnByid()
    this.cols = [
      { field: 'productCode', header: 'Product Code' },
      { field: 'productName', header: 'Product Name' },
      { field: 'invoiceQty', header: 'Invoice Qty' },
      { field: 'goodQty', header: 'Good' },
      { field: 'damagedQty', header: 'Damaged' },
      { field: 'totalQty', header: 'Total' },
      { field: 'desperency', header: 'Discrepancy' },
  
    ];

 
  }
getgrnByid(){
  this.ApiCall.grnNumberInformation().subscribe(data=>{
    console.log(data)
    this.result=data.result.grn
    this.grnno=this.result.grnNo
    console.log(this.grnno)
  this.grndate=this.result.grnDate
  this.suppliername=this.result.supplierName
this. pono=this.result.poNumber
this.supplierInvoiceNumber=this.result.supplierInvoiceNumber
this.grnUrl=this.result.grnUrl
console.log(this.grnUrl)
this.skuinformation=data.result.skus
 


 
})

}
}