import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, NgZone, Input, ChangeDetectorRef } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
import * as _moment from 'moment';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarVerticalPosition, } from '@angular/material';
import { DatePipe } from '@angular/common';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-complete-delivery',
  templateUrl: './complete-delivery.component.html',
  styleUrls: ['./complete-delivery.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class CompleteDeliveryComponent implements OnInit {
  public formGroup: FormGroup;
  RaiseGcnForm: FormGroup;
  submitted = false;
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  addExtraClass: boolean = true;
  RequiredDetails = {
    "customerId": localStorage.getItem('Dly_CusId'),
    "deliveryRequestId": localStorage.getItem('pending_delivery_id'),
    "status": "Transit Out",
    "ewayBillNo": "",
    "invoiceValue": "",
    "invoiceDate": "",
    "invoiceNo": "",
    "items": JSON.parse(localStorage.getItem('selected_product')),
    "vehicleNo": "",
    "warehouseId": localStorage.getItem('WarehouseID')
  }
  Delivery_invoices: any;
  EmptyInvoice = false;
  InvoiceForm: FormGroup;
  gcnDate: any;
  InvoiceValue: any;
  InvoiceNumber: any;
  InvoiceDate: any;
  Currencies: any;
  CurrenciesID: any;

  constructor(private messageService: MessageService, private formBuilder: FormBuilder, private apiCall: ApiService, private snackBar: MatSnackBar, private cdRef: ChangeDetectorRef, private router: Router, private datePipe: DatePipe) { }

  ngOnInit() {

    this.InvoiceFormValidation()
    this.CurrenciesApi()
    this.Delivery_invoices = JSON.parse(localStorage.getItem('Delivery_invoice'))

    // this.InvoiceNumber = this.Delivery_invoices[0].invoiceNumber
    // this.InvoiceDate = this.Delivery_invoices[0].invoiceDate

    if (!this.Delivery_invoices.length) {
      this.EmptyInvoice = true;
    }
    else {
      for (var i = 0; i < this.Delivery_invoices.length; i++) {
        this.InvoiceValue = this.Delivery_invoices[i].invoiceValue
        this.formGroup = this.formBuilder.group({
          Rows: this.formBuilder.array([this.formBuilder.group({
            invoiceNumber: this.Delivery_invoices[i].invoiceNumber,
            invoiceDate: this.datePipe.transform(this.Delivery_invoices[i].invoiceDate, 'd MMM y')
          })
          ])
        })
        this.RequiredDetails.invoiceNo = this.formGroup.value.Rows[i].invoiceNumber
        this.RequiredDetails.invoiceDate = this.formGroup.value.Rows[i].invoiceDate
      }
    }
    this.RaiseGcnFormValidate()
  }

  InvoiceFormValidation() {
    this.InvoiceForm = this.formBuilder.group({
      InvoiceNo: [''],
      InvoiceDate: [''],
    })
  }

  get I() {
    return this.InvoiceForm.controls;
  }

  get formArr() {
    return this.formGroup.get("Rows") as FormArray;
  }

  initRows() {
    return this.formBuilder.group({
      invoiceNumber: [""],
      invoiceDate: [""]
    });
  }
  addInvoiceTable(NewRow: boolean) {
    this.formArr.push(this.initRows())
  }

  RaiseGcnFormValidate() {
    this.RaiseGcnForm = this.formBuilder.group({
      invoiceValue: [''],
      Currencies: [94],
      vehicleNo: [''],
      billNo: ['']
    })
  }
  get f() { return this.RaiseGcnForm.controls; }

  onMatRowEditDeleteInvoice(index: number) {
    this.formArr.removeAt(index);
    // this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Do you want to delete' })

  }

  onConfirmInvoice(index: number) {
    this.formArr.removeAt(index);
    this.messageService.clear('c');
  }

  onMatReject() {
    this.messageService.clear('c');
  }



  onSubmitToast() {
    this.submitted = true;
    this.RequiredDetails.invoiceDate = this.datePipe.transform(this.RequiredDetails.invoiceDate, "yyyy-MM-dd")
    var lengthOfInvoice = JSON.parse(localStorage.getItem('Delivery_invoice'))
    if (lengthOfInvoice.length !== 0) {
      this.RequiredDetails.invoiceNo = this.formGroup.value.Rows[0].invoiceNumber
      this.RequiredDetails.invoiceDate = this.formGroup.value.Rows[0].invoiceDate
    }
    let invoiceValueCount = this.RaiseGcnForm.get('invoiceValue').value;
    var count = parseInt(invoiceValueCount)
    if (count >= 50000) {
      this.RaiseGcnForm.get('billNo').setValidators([Validators.required]);
      this.RaiseGcnForm.get('billNo').updateValueAndValidity();
    } else {
      this.RaiseGcnForm.get('billNo').clearValidators();
      this.RaiseGcnForm.get('billNo').updateValueAndValidity();
    }

    // if (this.RaiseGcnForm.invalid) {
    //   console.log("Error")
    //   return
    // }

    // if (this.EmptyInvoice === true && this.InvoiceForm.invalid) {
    //   return
    // }
    this.RequiredDetails.invoiceDate = this.datePipe.transform(this.RequiredDetails.invoiceDate, "yyyy-MM-dd")
    this.messageService.add({ key: 's', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Handed Over to Transporter' })
    // if (count <= 50000) {
    //   console.log("FORM CHECK", this.RaiseGcnForm)
    //   this.RequiredDetails.invoiceDate = this.datePipe.transform(this.RequiredDetails.invoiceDate, "yyyy-MM-dd")
    //   console.log("CHECK BEFORE CALLING API", this.RequiredDetails)
    //   this.messageService.add({ key: 's', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Handed Over to Transporter' })
    // }
    // else if (count > 50000) {
    //   this.RequiredDetails.invoiceDate = this.datePipe.transform(this.RequiredDetails.invoiceDate, "yyyy-MM-dd")
    //   this.messageService.add({ key: 's', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Handed Over to Transporter' })
    // }
  }

  onConfirmSubmit() {
    if (this.RequiredDetails.customerId == '15') {
      delete this.RequiredDetails.invoiceValue
    }
    if (this.RequiredDetails.invoiceValue == '') {
      delete this.RequiredDetails.invoiceValue
    }
    // this.RequiredDetails.invoiceValue = this.RaiseGcnForm.value.Currencies.shortName + " " + this.RaiseGcnForm.value.invoiceValue
    console.log("CHECK BEFORE CALLING API", this.RequiredDetails)
    this.apiCall.getGcnNumber(this.RequiredDetails)
      .subscribe(res => {
        if (res.message === "operation failed") {
          this.messageService.clear('s');
          Swal.fire({
            toast: true,
            timer: 4000,
            type: 'error',
            text: 'Product Code Not Scanned!',
            showConfirmButton: false
          })
        } else {
          console.log("RaiseGcnNumber", res)
          this.gcnDate = res.result.createdAt
          this.gcnDate = this.datePipe.transform(this.gcnDate, "d MMM y")
          this.messageService.add({ key: 'S', sticky: true, severity: 'success', summary: 'DN Raised', detail: res.result.gcnNumber })
          localStorage.removeItem("selected_product")
        }
      })
  }

  gcnCloseBtn() {
    this.router.navigate(['pendingdeliveries'])
    this.messageService.clear('S');
  }

  onRejectSubmit() {
    this.messageService.clear('s');
  }

  numberOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;

  }

  CurrenciesApi() {
    this.apiCall.getCurrencies()
      .subscribe(
        res => {
          this.Currencies = res.result
          console.log(this.Currencies)
          // this.Currencies.forEach(ID => {
          //   this.CurrenciesID = ID.id
          // });
          // const toSelect = this.Currencies.find(c => c.id == this.CurrenciesID);
          // this.RaiseGcnForm.get('Currencies').setValue(toSelect);

          // console.log("Currencies", this.RaiseGcnForm)
        }
      )
  }

}