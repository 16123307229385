import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-create-shipment',
  templateUrl: './create-shipment.component.html',
  styleUrls: ['./create-shipment.component.css']
})
export class CreateShipmentComponent implements OnInit {
  
  createShipmentData={
      "deliveryRequestId": localStorage.getItem('completed_delivery_id'),
     
  "shipmentLength": "",
  "shipmentWidth": "",
  "shipmentHeight": "",
  "shipmentWeight": "",
  "logistics": "",
  "shippingCharges" : "" ,
  "customerInfo": {
      "id":'',
      "name": "",
      "company_name": "",
      "addressLine1": "",
      "addressLine2": "",
      "addressLine3": "",
      "pincode": "",
      "city": "",
      "state": "",
      "country": "",
      "phone": "",
      "alt_phone": "",
      "email": ""
  }
}
      
  hideTable:boolean;
  product: any;
  data: any;
cols:any
  selectedValue: any;
  shipcustDetails: any;
  constructor(private apiCall:ApiService, private router:Router) { }

  ngOnInit() {
    
    this.hideTable=true
    this.cols=[
      {value:'logistic_name',header:'Name'},
      {value:'rate',header:'id'},
      
    ]
    this.shipcustDetails=JSON.parse(localStorage.getItem('shipCustDetails'))
    console.log(this.shipcustDetails)
    this.createShipmentData.customerInfo.id=this.shipcustDetails.id
    this.createShipmentData.customerInfo.name=this.shipcustDetails.name
    this.createShipmentData.customerInfo.addressLine1=this.shipcustDetails.addressLine1
    this.createShipmentData.customerInfo.addressLine2=this.shipcustDetails.addressLine2
    this.createShipmentData.customerInfo.pincode=this.shipcustDetails.pincode
    this.createShipmentData.customerInfo.city=this.shipcustDetails.city
    this.createShipmentData.customerInfo.state=this.shipcustDetails.state
    this.createShipmentData.customerInfo.country=this.shipcustDetails.country
    this.createShipmentData.customerInfo.phone=this.shipcustDetails.phone
    this.createShipmentData.customerInfo.email=this.shipcustDetails.email
    

   

  }
  getEstimate(){
    const obj={
      "deliveryRequestId": localStorage.getItem('completed_delivery_id'),   // mandatory
      "customerPincode": this.createShipmentData.customerInfo.pincode,     // mandatory
      // "customerPincode": '560066',
      "shippingLength": this.createShipmentData.shipmentLength,
      "shippingWidth": this.createShipmentData.shipmentWidth,
      "shippingHeight": this.createShipmentData.shipmentHeight,
      "shippingWeight": this.createShipmentData.shipmentWeight       // mandatory
      // "shippingWeight": 5
      // "deliveryRequestId": '41287',   // mandatory
      // "customerPincode": '689695',     // mandatory 689695
      // // "customerPincode": '560066',
      // "shippingLength": 46,
      // "shippingWidth": 25,
      // "shippingHeight": 18,
      // "shippingWeight": 6.736  
    }
    console.log(obj,'get estimate button is clicked')
    if(obj.customerPincode!=''&& obj.shippingWeight!=undefined){
      this.apiCall.getRate(obj).subscribe(res=>{
        if(res['status']==true){
          console.log(res['status'])
          console.log('get rate api response',res)
          this.hideTable=false
          this.data=res['shipmentRate']
          console.log(this.data.data)
        }
        else{
          Swal.fire({
            title: '<h3>Get Estimate Error</h3>',
            showConfirmButton: false,
            timer: 3300,
            type: 'error',
            text:res['error'],
          })
        }
        
    
      })
    }
    else{
      Swal.fire({
        title: '<h3>Please enter pincode and shipment weight</h3>',
        showConfirmButton: false,
        timer: 3300,
        type: 'error'
      })
    }
 
  }
  maximise(rowData){

  }
  submit(){
    
    this.createShipmentData.logistics=this.selectedValue.logistic_name
    this.createShipmentData.shippingCharges=this.selectedValue.rate
    // console.log(this.selectedValue.rate)
    console.log(this.createShipmentData)
    this.apiCall.createShipment(this.createShipmentData).subscribe(res=>{
      console.log(res)
      if(this.createShipmentData.customerInfo.name!=''&&
      this.createShipmentData.customerInfo.addressLine1!=''&&
      this.createShipmentData.customerInfo.pincode!=''&&
      this.createShipmentData.customerInfo.phone!=''){
        if(res['status']==true){
          Swal.fire({
            title: '<h3>Shipment is created successfully</h3>',
            showConfirmButton: false,
            timer: 3300,
            type: 'success'
          })
          localStorage.removeItem('shipCustDetails')
          this.router.navigate(['Viewcompleteddelivery'])
          
        }
        else{
          Swal.fire({
            title: '<h3>Shipment could not be created</h3>',
            showConfirmButton: true,
            text:res['error'],
            // timer: 3300,
            type: 'error'
          })
        }
      }
      else{
        Swal.fire({
          title: '<h3>Please enter valid customer information</h3>',
          showConfirmButton: true,
          // text:res['error'],
          timer: 3300,
          type: 'error'
        })
      }
      
    })
  }

}
