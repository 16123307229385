import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { ApiService } from 'src/app/api.service';
import { dashboardVarialbles } from 'src/environments/environment';

@Component({
  selector: 'app-receipt-pie',
  templateUrl: './receipt-pie.component.html',
  styleUrls: ['./receipt-pie.component.css']
})
export class ReceiptPieComponent implements OnInit {

  data: any;
  report: any;
  options: { legend: { display: boolean; }; rotation: number; circumference: number; };
  chart: any;
  sum: any;
  text: any;
  completed: any;
  pending: any;
  staging: any;

  newArray: any = [];
  warehouseArray: any;
  warehouseParams = { warehouseId: localStorage.getItem('WarehouseID') };
  firstDate: Date;
  startDate: string;
  constructor(private ApiCall: ApiService, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('WarehouseID') == null || localStorage.getItem('WarehouseID') == undefined) {
      this.warehouseArray = JSON.parse(localStorage.getItem('WarehouseArray'))
      this.warehouseParams.warehouseId = this.warehouseArray[0].id
      console.log(this.warehouseParams)
      localStorage.setItem('WarehouseID', this.warehouseParams.warehouseId)
    }
    else {
      this.warehouseParams.warehouseId = localStorage.getItem('WarehouseID')
    }

    this.firstDate = new Date();
    // this.firstDate.setMonth(this.today.getMonth());
    this.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
    const obj = {
      "date": this.startDate,
      "warehouseId": localStorage.getItem('WarehouseID'),
      "customerId": null
    }
    this.ApiCall.getInfoReceptChart(obj).subscribe(res => {
      // console.log('donught', res);
      this.completed = res['result']['completedReciepts']
      this.pending = res['result']['pendingReciepts']

      this.staging = res['result']['stagingReciepts']

      this.newArray.push(this.pending)
      this.newArray.push(this.staging)
      this.newArray.push(this.completed)


      // console.log(this.newArray);
      this.report = this.newArray

      this.sum = this.report[0] + this.report[1] + this.report[2]
      this.text = JSON.stringify(this.sum);
      // console.log(this.text);

      this.chart = new Chart('canvas', {
        type: 'doughnut',
        data: {
          labels: ['Pending', 'Staging', 'Completed'],
          datasets: [
            {
              data: this.newArray,
              borderWidth: 0,

              backgroundColor: [
                "#87D0FE",
                "#FFE469",
                "#9CDB6A"
              ],
              hoverBackgroundColor: [
                "#27a4f2",
                "#f5c902",
                "#71f20a"
              ]
            }
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false,
          },
          // tooltips: {
          //   enabled: false,
          // },
          rotation: -1 * Math.PI,
          circumference: 1 * Math.PI
        },


      });

    })

  }
  getReceiptInformation() {

  }
  onclickPending() {
    dashboardVarialbles.isPendingRoute = true
    this.router.navigate(['/pendingreceipt'])
  }
  onclickStaging() {
    dashboardVarialbles.isStagingRoute = true
    this.router.navigate(['/locationputaway'])
  }
  onclickCompleted() {
    dashboardVarialbles.IsCompletedRoute = true
    this.router.navigate(['/completedreceipt'])
  }


}