import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { myVariables } from 'src/environments/environment';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-search-by-product',
  templateUrl: './search-by-product.component.html',
  styleUrls: ['./search-by-product.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchByProductComponent implements OnInit {

  searchProcols = [];
  check = true;
  searchByProdctData = [];

  RequiredMaterial = {
    code: '',
    warehouseId: localStorage.getItem('WarehouseID')
  }
  ProductName: any;
  options: any;

  // RequiredGetAllMaterial = {}

  constructor(private ApiCall: ApiService) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber=1;
    myVariables.grnRowsPerPage=1;
    myVariables.returnGrnPageNumber=1;
    myVariables.returnGrnRowsPerPage=1;
    myVariables.dnRowsPerPage=1;
    myVariables.dnPageNumber=1;
    myVariables.shippingOrdersRowsPerPage=1;
    myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  sessionStorage.removeItem('shippingOrdersDate');
  sessionStorage.removeItem('grnReportsDates');
  sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('dnReportsDate');
  sessionStorage.removeItem('active')

    // this.GetAllLocation()
    this.searchProcols = [
      
      { field: 'location', header: 'Location' },
      { field: 'goodqty', header: 'Good Quantity' },
      { field: 'damagedqty', header: 'Damaged Quantity' },
      { field: 'totalqty', header: 'Total Quantity' },
    ];
  }
 
  // GetAllLocation() {
  //   const promise = this.ApiCall.GetSearchByLocation(this.RequiredGetAllMaterial).toPromise();

  //   promise.then((data) => {
  //     this.searchByProdctData = data.result
  //     if (this.searchByProdctData.length === 0) {
  //       this.check = true
  //     } else {
  //       this.check = false
  //     }
  //   }, (error)=>{
  //     console.log("Rejected", + JSON.stringify(error))
  //   }
  //   )
  // }
  searchProduct(data){
    const obj={
      'searchval':data
    }
    if(data.length>2){
      this.ApiCall.getProductName(obj).subscribe(res=>{
        console.log(res)
        this.options=res['result']
      })
    }
    if(data.length==0){
      this.options=[]
    }
  }
  GetProduct() {
  
    const promise = this.ApiCall.getSearchByMaterial(this.RequiredMaterial).toPromise();
    promise.then((data) => {
      console.log("Promise Success Msg: " + JSON.stringify(data));
      this.searchByProdctData = data.result
      

      if (this.searchByProdctData.length === 0) {
        this.check = true
      } else {
        this.check = false
        const obj={
          'searchval':this.RequiredMaterial.code
        }
        this.ApiCall.getProductName(obj).subscribe(res=>{
          console.log(res);
          this.ProductName = res['result'][0].productName
        })
        
      }
    }, (error) => {
      console.log("Promise Rejection Msg " + JSON.stringify(error));
    })
  }

}
