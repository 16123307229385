import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-view-dn-details',
  templateUrl: './view-dn-details.component.html',
  styleUrls: ['./view-dn-details.component.css']
})
export class ViewDnDetailsComponent implements OnInit {
  grnno: any;
  grndate: any;
  suppliername: any;
  pono: any;
  result: any;
  supplierInvoiceNumber: any;
  cols: { field: string; header: string; }[];
  skuinformation:any
  grnUrl: any;
  requestNo: any;
  requestDate: any;
  eWayBillNo: any;
  constructor(private ApiCall:ApiService) { }

  ngOnInit() {
    this.getgrnByid()
    this.cols = [
      { field: 'productCode', header: 'Product Code' },
      { field: 'productName', header: 'Product Name' },
       { field: 'good', header: 'Good' },
      { field: 'damaged', header: 'Damaged' },
      { field: 'total', header: 'Total' },
   
    ];

 
  }
getgrnByid(){
  this.ApiCall.DnNumberInformation().subscribe(data=>{
    console.log(data)
    this.result=data.result.dn
    this.grnno=this.result.deliveryNoteId
    console.log(this.grnno)
  this.grndate=this.result.deliveryNoteDate
  this.suppliername=this.result.channel
this. pono=this.result.orderNumber
this.supplierInvoiceNumber=this.result.invoiceNumber
this.grnUrl=this.result.consignorURL
this.skuinformation=data.result.skus
 

this.requestDate=this.result.deliveryRequestDate
 console.log(this.requestDate)
this.requestNo=this.result.deliveryRequestId
this.eWayBillNo=this.result.eWayBillNo




})

}
}