import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MustMatch } from '../change-password/passwordvalidator';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import lodash from 'lodash';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {
  hide: boolean = true;
  hideC: boolean = true;
  myFunction() {
    this.hide = !this.hide;
  }

  myFunctions(){
    this.hideC = !this.hideC;
  }
  registerForm: FormGroup;
  submitted = false;

  RequiredUpdate = {
    "updates": {
      "fullname": "",
      "mobileNo": "",
      "email" :"",
      "password": "",
    }
  }

  constructor(private formBuilder: FormBuilder, private apiAuth: ApiService, private router: Router) { }

  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  SubmitToChange() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const dataToBeSent = this.RequiredUpdate
    if(lodash.isEqual(dataToBeSent.updates.email,'')) delete dataToBeSent.updates.email
    if(lodash.isEqual(dataToBeSent.updates.mobileNo,'')) delete dataToBeSent.updates.mobileNo
    if(lodash.isEqual(dataToBeSent.updates.fullname,'')) delete dataToBeSent.updates.fullname
    this.apiAuth.getUpdateUser(this.RequiredUpdate)
    .subscribe(
      res => {
        Swal.fire({
          toast: true,
          title: 'Updated Successfully',
          type: "success",
          showConfirmButton: false,
          timer: 3000
        })
        // console.log("UPDATED", res)
        err => {
          Swal.fire({
            toast: true,
            title: err,
            type: "error",
            showConfirmButton: false,
            timer: 3000
          })
          console.log("FOUND ERROR", err)
        }
        this.router.navigate(['dashboard'])
      }
    )
    // console.log("Changes Happening")
  }
}