import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, NgZone, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { } from 'googlemaps';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
import * as _moment from 'moment';
import { Router } from '@angular/router';
import { HttpEventType, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { EMPTY, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import lodash from 'lodash';
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { isArray } from 'util';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-create-receipt',
  templateUrl: './create-receipt.component.html',
  styleUrls: ['./create-receipt.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class CreateReceiptComponent implements OnInit, AfterViewInit {
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild("fileUpload", {}) fileUpload: ElementRef; files = [];
  // @ViewChild('closeBtn') closeBtn: ElementRef;
  // @ViewChild('closeBtnTwo') closeBtnTwo: ElementRef;

  public apiEndPoint = 'http://52.170.134.229/storage/storage/files/upload/pickupinvoice';

  address: string;
  latitude: number;
  longitude: number;
  pickUpPoint: string;
  zoom: number;
  Uploading = false;
  private geoCoder;
  minDate = new Date();
  formGroup: FormGroup;
  selected = new FormControl(0);
  selectedRow = new FormControl(0);
  Add_Material_Form: FormGroup;

  WarehouseName = [];
  warehouseTypes: FormGroup;
  warehouseNameID: any;
  materialName: FormControl;

  RequiredPreAlert = {
    "pickupDate": "",
    "pickupTime": "",
    "invoices": [],
    "poMasterIds": [],
    "poNo": "",
    "bookingId": "",
    "userId": localStorage.getItem('Custom_User_Id'),
    "customerId": localStorage.getItem('CusID'),
    "warehouseId": localStorage.getItem('WarehouseID'),
    "materials": [
      {
        "materialName": "",
        "materialCode": "",
        "quantity": "",
        "productId": ""
      }
    ],
    "supplier": {
      "name": "",
      "address": "",
      "pincode": "",
      "mobileNo": ""
    }
  }
  RequiredAutoSuggest = {
    SkuUpcEan: "",
    customerId: "",
    warehouseId: localStorage.getItem('WarehouseID')
  }

  ProductSearch = {
    "searchval": "",
    "customerId": localStorage.getItem('CusID')
  }

  qtyModalPopup = false
  submitted = false;
  pickupReqID: any;
  img_name: any;
  strLength: number;
  row = [];
  invoiceRows = [];
  materialRows = [];
  AutoSuggest = [];
  materialForm: FormGroup;
  orderItems = new FormArray([]);
  filteredOptions: Observable<string[]>;
  SupplierfilteredOption: Observable<string[]>;
  name = new FormControl('', Validators.required);
  matCode: any;
  SummaryQuantityForm: FormGroup;
  mymodel: any;
  materialList = {
    productId: "",
    materialCode: "",
    materialName: "",
    quantity: "",
  };
  invoiceDetails = {
    invoiceNo: '',
    invoiceDate: '',
    poNo: ''
  }

  Autofill = [];

  quantity: any;
  rowMaterials: any;
  UpdateQty: any;
  pro_name: any;
  avilable_stock: any;
  actual_stock: any;
  selectedRowData: any;
  seletedExisitingCars: any;
  addMaterials = [];
  deleteIndex: any;
  indexVal: any;
  count = 0;
  customerList: any;
  BookingList: any;
  autoFill: any;
  searchText;
  countryCode = []
  Currencies: any;
  rowDataMaterial: any;
  // RowHideMaterials = [];
  rowPoNumber = [];
  selectedPoNo: any;
  code: any;
  readonly = false;
  CheckIcon = true;
  PencilIcon = false;
  cars: any[];
  cols: any[];

  InvoicesDetails: any[];
  Invoicecols: any[];
  deleteMatIndex: any;
  AutoFillSuppliers = [];
  AutoSuggestFill = [];

  isEnabled = false;
  InputDataValue: any;
  HideClearBtn: boolean;

  constructor(private ApiCall: ApiService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private formBuilder: FormBuilder, private router: Router, private http: HttpClient, private messageService: MessageService,
    private datePipe: DatePipe, private cdRef: ChangeDetectorRef, private el: ElementRef) {

    this.materialName = new FormControl();
    this.filteredOptions = this.materialName.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.customerDetails();
    this.SummeryQuantityForm();
    this.ReceiptForm();
    this.countryCodeApi();
    this.materialForm = new FormGroup({
      'orderItems': this.orderItems
    });

    this.row = [
      {
        productId: "",
        materialCode: "",
        materialName: "",
        quantity: ""
      }
    ];

    this.invoiceRows = [{
      invoiceNo: "",
      invoiceDate: "",
      poNo: ""
    }]

    this.cols = [
      { field: 'materialName', header: 'Material Name' },
      { field: 'materialCode', header: 'Material Code' },
      { field: 'quantity', header: 'Quantity' }
    ];

    this.Invoicecols = [
      { field: 'invoiceNo', header: 'Invoice Number' },
      { field: 'invoiceDate', header: 'Invoice Date' },
      { field: 'PoNo', header: 'PO Number' }
    ];
    // console.log(this.row)
    // this.AddMaterialForm();

    this.cars = [
      { materialName: null, materialCode: null, quantity: null, editable: true }
    ]
    this.mapsAPILoader.load().then(() => {
      this.setCurrentPosition();
      this.geoCoder = new google.maps.Geocoder;
      var bangaloreBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(12.864162, 77.438610),
        new google.maps.LatLng(13.139807, 77.711895));
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        bounds: bangaloreBounds,
        strictBounds: true,
        types: ['establishment'],
        componentRestrictions: { 'country': 'in' }
      });
      // autocomplete = new google.maps.places.Autocomplete(input, autocomplete);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddress(this.latitude, this.longitude);
          this.zoom = 12;
          this.pickUpPoint = this.latitude + ',' + this.longitude;
          // console.log("pickup", this.pickUpPoint)
        });
      });
    });
    this.addTable()
  }

  initForm(index?: number) {
    var arrayControl = this.materialForm.get('orderItems') as FormArray;
    this.filteredOptions = arrayControl.at(index).get('materialName').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value.toString()))
      );
  }

  PencilDisable(orderItem: any, i) {
    console.log(orderItem, i);
    console.log(this.materialForm)
    // orderItem.value=this.materialForm.value.orderItems[i]
    // orderItem=this.RequiredPreAlert.materials[i]
    orderItem.editable = !orderItem.editable;
  }

  CheckDisable(orderItem: any, i) {
    if (orderItem.materialName === null || orderItem.quantity === null) {
      Swal.fire({
        toast: true,
        title: 'Please fill required fields before submit!',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
    } else {
      orderItem.editable = !orderItem.editable;
    }
  }

  InvoicePencilDisable(Value: any, i) {
    Value.editable = !Value.editable;
  }

  InvoiceCheckDisable(Value: any, i) {

    if (Value.invoiceNo === '' || Value.invoiceDate === '') {
      Swal.fire({
        toast: true,
        title: 'Please fill required fields before submit !',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
    } else {
      Value.editable = !Value.editable;
    }
  }

  onDeleteIngredient(index: number, item: any) {
    this.deleteMatIndex = index

    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Do you want to delete' })
  }

  onConfirm() {
    this.rowPoNumber.splice(this.deleteMatIndex, 1);
    (<FormArray>this.materialForm.get('orderItems')).removeAt(this.deleteMatIndex);
    this.messageService.clear('c');
  }
  onMatReject() {
    this.messageService.clear('c');
  }

  onMatRowEditDeleteInvoice(index) {
    this.deleteIndex = index
    this.messageService.add({ key: 'I', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Do you want to delete' })
  }
  onConfirmInvoice(y) {
    this.invoicesGroup.removeAt(this.deleteIndex);
    this.invoiceRows.splice(y, 1);
    this.messageService.clear('I');
  }
  onRejectInvoice() {
    this.messageService.clear('I');
  }


  addTable() {

    var ContainerLength = this.materialForm.value.orderItems.length

    if (ContainerLength !== 0) {
      if (this.materialForm.value.orderItems[ContainerLength - 1].materialName === null || this.materialForm.value.orderItems[ContainerLength - 1].quantity === null) {
        Swal.fire({
          toast: true,
          title: 'Please fill existing fields before creating new one !',
          timer: 3000,
          type: "warning",
          showConfirmButton: false
        })
        return
      } else {

        if (this.materialForm.value.orderItems[ContainerLength - 1].editable !== true) {
          this.materialForm.value.orderItems[ContainerLength - 1].editable = !this.materialForm.value.orderItems[ContainerLength - 1].editable
        }
        console.log("CONTAINER LENGTH", this.materialForm.value.orderItems)
        this.materialForm.value.orderItems[ContainerLength - 1].editable = !this.materialForm.value.orderItems[ContainerLength - 1].editable
      }
    }
    this.HideClearBtn = true
    this.InputDataValue = '';
    (<FormArray>this.materialForm.get('orderItems')).push(
      new FormGroup({
        'materialName': new FormControl(null, Validators.required),
        'materialCode': new FormControl(null, Validators.required),
        'quantity': new FormControl(null, Validators.required),
        'editable': new FormControl(true)
      })
    );
    this.initForm((<FormArray>this.materialForm.get('orderItems')).length - 1);
    // this.materialForm.value.orderItems.map(function (entry) {
    //   entry.editable = false;
    //   return entry;
    // });
    console.log("CHECKING MATERIAL FORM", this.materialForm.value.orderItems)
    this.filteredOptions = EMPTY
  }


  isDisabled(value) {
    console.log(this.rowPoNumber.includes(value))
    return this.rowPoNumber.includes(value);
  }


  getControls() {
    return (<FormArray>this.materialForm.get('orderItems')).controls;
  }

  AutoFillingField() {
    this.ApiCall.getSkuMaterial(this.RequiredAutoSuggest)
      .subscribe(
        res => {
          this.AutoSuggest = res.result
          this.autoFill = res.result
          this.AutoSuggest.map(function (entry) {
            entry.isdisabled = false;
            return entry;
          });;
          console.log("AUTO SUGGEST DATA's ", this.AutoSuggest)
          this.addTable()
        }
      )
  }

  clearMatCode(event: any, matIndex) {
    console.log("VALUE DATA WANT TO SEE", event.target.value)
    // this.rowPoNumber = []
    this.orderItems.value[matIndex].materialCode = ''
  };

  clearfilter(index) {
    console.log("INDEX", index)
    this.isEnabled = false;
    this.InputDataValue = ''
  }

  // selectedMaterialName(data, index) {
  //   // console.log("Coming", data, index)
  //   for (var i = 0; i < this.AutoFillSuppliers.length; i++) {
  //     if (this.AutoFillSuppliers[i].productName === data) {
  //       this.matCode = this.AutoFillSuppliers[i]
  //       this.rowPoNumber.push(this.matCode.productName)
  //       // console.log("ROW CHECKING", this.rowPoNumber)
  //       // console.log("Printing value", this.matCode)
  //       this.orderItems.value[index].materialCode = this.AutoFillSuppliers[i].productCode
  //     }
  //   }
  //   this.HideClearBtn = false
  //   this.isEnabled = true;
  // }
  selectedMaterialName(data, index) {
    console.log("Coming", data, index)

    const orderItemGroup = this.orderItems.at(index) as FormGroup;
    orderItemGroup.get('materialName').setValue(data.productName);
    orderItemGroup.get('materialCode').setValue(data.productCode);

    for (var i = 0; i < this.AutoFillSuppliers.length; i++) {

      if (this.AutoFillSuppliers[i].productCode === data.productCode) {
        this.matCode = this.AutoFillSuppliers[i]
        this.rowPoNumber.push(this.matCode.productCode)
        console.log("ROW CHECKING", this.rowPoNumber)
        console.log("Printing value", this.matCode)
      }
    }
    this.HideClearBtn = false
    this.isEnabled = true;

  }

  // filter(val: string, key: string): string[] {
  //   const filterValue = val.toLowerCase();
  //   if (val === '' || val === null) {
  //     return []
  //   }
  //   else if (val.toLowerCase() && key === 'I') {
  //     return this.AutoSuggest.filter(option => option.productName.toLowerCase().includes(filterValue));
  //   }
  // }


  customerDetails() {
    this.ApiCall.getCustomerList()
      .subscribe(
        res => {
          console.log("Customer List", res.result)
          this.customerList = res.result;
        }
      )
  }

  BookingDetails() {
    this.ApiCall.getBookingList()
      .subscribe(
        res => {
          console.log("Booking List", res.result)
          this.BookingList = res.result;
        }
      )
  }

  selectedValue(id, index) {
    // this.initForm();
    // this.customerDetails()
    // console.log("SELECTED ID CUSTOMER",this.customerList[id].id)
    localStorage.setItem("CusID", this.customerList[id].id)
    this.ProductSearch.customerId = this.customerList[id].id
    localStorage.setItem("Custom_User_Id", this.customerList[id].userId)
    this.BookingDetails();
    this.RequiredAutoSuggest.customerId = this.customerList[id].id
    this.RequiredPreAlert.customerId = this.customerList[id].id
    this.RequiredPreAlert.userId = this.customerList[id].userId
    this.AutoFillingField();
    this.SupplierInto();
    (<FormArray>this.materialForm.get('orderItems')).removeAt(index);
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
      );
  }

  selectedID(id) {
    this.BookingDetails();
    this.RequiredPreAlert.bookingId = this.BookingList[id].id
  }

  onKeydownFunc(event: any) {
    this.RequiredAutoSuggest.SkuUpcEan = ''

  };

  setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 2;
        this.getAddress(this.latitude, this.longitude);
      })
    }
  }
  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          // console.log('came here 1');
          this.address = results[0].formatted_address;
          this.RequiredPreAlert.supplier.address = this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }
  locate() {
    this.closeModal();
  }
  private closeModal(): void {
  }

  _keyPress(event: any) {
    const pattern = /[0-9,+, ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  get invoicesGroup() {
    return this.formGroup.get('invoicesGroup') as FormArray;
  }

  addInvoiceTable(NewRow: boolean) {

    var InvoiceLength = this.formGroup.value.invoicesGroup.length

    if (InvoiceLength !== 0) {
      if (this.formGroup.value.invoicesGroup[InvoiceLength - 1].invoiceDate === "" || this.formGroup.value.invoicesGroup[InvoiceLength - 1].invoiceNo === "") {
        Swal.fire({
          toast: true,
          title: 'Please fill existing fields before creating new one !',
          timer: 3000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }
      else {

        if (this.formGroup.value.invoicesGroup[InvoiceLength - 1].editable !== true) {
          this.formGroup.value.invoicesGroup[InvoiceLength - 1].editable = !this.formGroup.value.invoicesGroup[InvoiceLength - 1].editable
        }
        console.log("CONTAINER LENGTH", this.formGroup.value.invoicesGroup)
        this.formGroup.value.invoicesGroup[InvoiceLength - 1].editable = !this.formGroup.value.invoicesGroup[InvoiceLength - 1].editable

        this.invoicesGroup.push(this.formBuilder.group({ invoiceNo: '', invoiceDate: '', poNo: '', editable: true }));
        this.count = this.count + 1
      }

    }
    this.indexVal = (<FormArray>this.formGroup.get('invoicesGroup'))

    if (NewRow) {
      this.selectedRow.setValue(this.invoiceRows.length - 1);
    }
    this.invoiceRows = this.formGroup.get('invoicesGroup').value
    this.RequiredPreAlert.invoices = this.invoiceRows
  }

  /*---------------------------------END_ADD_MATERIAL_TS-------------------------*/


  ReceiptForm() {
    this.formGroup = this.formBuilder.group({
      pickupDate: ['', Validators.required],
      pickupTime: ['', Validators.required],
      uploadDoc: [''],
      invoiceDate: [''],
      invoiceNo: [''],
      poNo: [''],
      name: ['', Validators.required],
      countryCode: [10146],
      mobileNo: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', Validators.required],
      customerName: ['', Validators.required],
      bookingId: ['', Validators.required],
      invoicesGroup: this.formBuilder.array([this.formBuilder.group({ invoiceNo: '', invoiceDate: '', poNo: '', editable: true })])

    })
    console.log("MATERIAL FORM", this.formGroup.value.invoicesGroup)
    this.InvoicesDetails = this.formGroup.value.invoicesGroup
  }
  public ReceiptcheckError = (controlName: any, errorName: any) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  // private scrollToFirstInvalidControl() {
  //   const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
  //     ".ng-invalid"
  //   );

  //   window.scroll({
  //     top: this.getTopOffset(firstInvalidControl),
  //     left: 0,
  //     behavior: "smooth"
  //   });

  //   fromEvent(window, "scroll")
  //     .pipe(
  //       debounceTime(100),
  //       take(1)
  //     )
  //     .subscribe(() => firstInvalidControl.focus());
  // }

  // private getTopOffset(controlEl: HTMLElement) {
  //   const labelOffset = 50;
  //   return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  // }


  SummeryQuantityForm() {
    this.SummaryQuantityForm = this.formBuilder.group({
      QuantityCount: ['', [Validators.required, Validators.min(1)]],

    })
  }
  get qty() { return this.SummaryQuantityForm.controls; }
  get f() { return this.formGroup.controls; }

  receiptAlert() {

    this.matCode = (<FormArray>this.materialForm.get('orderItems')).value;

    this.RequiredPreAlert.materials = this.matCode

    this.submitted = true;
    // this.scrollToFirstInvalidControl()

    this.RequiredPreAlert.materials.forEach(element => {
      if (lodash.isEqual(element["currencies"], element["currencies"] || '' || null)) delete element["currencies"]
    });

    if (lodash.isEqual(this.formGroup.value.countryCode, this.formGroup.value.countryCode || '' || null)) delete this.formGroup.value.countryCode


    console.log('CHECKING FORM', this.formGroup.value)
    this.invoiceRows = this.formGroup.get('invoicesGroup').value;
    if (this.invoiceRows[0].invoiceNo === "") {
      this.RequiredPreAlert.invoices = []
    }
    else {
      this.invoiceRows = this.formGroup.get('invoicesGroup').value;
      this.RequiredPreAlert.invoices = this.invoiceRows
    }

    this.RequiredPreAlert.pickupDate = this.datePipe.transform(this.RequiredPreAlert.pickupDate, "yyyy-MM-dd")
    if (this.formGroup.invalid) {
      return;
    }
    this.matCode = (<FormArray>this.materialForm.get('orderItems')).value;

    this.RequiredPreAlert.materials = this.matCode

    console.log('PARAMS', this.RequiredPreAlert)

    var ContainerLength = this.materialForm.value.orderItems.length

    var InvoiceLength = this.formGroup.value.invoicesGroup.length

    //   if(InvoiceLength !== 0){
    //   if (this.formGroup.value.invoicesGroup[InvoiceLength - 1].invoiceDate === "" || this.formGroup.value.invoicesGroup[InvoiceLength - 1].invoiceNo === "") {
    //     Swal.fire({
    //       toast: true,
    //       title: 'Please fill Invoice fields before Submit !',
    //       timer: 3000,
    //       type: "warning",
    //       showConfirmButton: false
    //     })
    //     return
    //   }
    // }

    if (ContainerLength !== 0) {
      if (this.materialForm.value.orderItems[ContainerLength - 1].materialName === null || this.materialForm.value.orderItems[ContainerLength - 1].quantity === null) {
        Swal.fire({
          toast: true,
          title: 'Please fill Material fields before Submit !',
          timer: 3000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }
    }

    if (this.RequiredPreAlert.materials[0].materialName == '' || this.RequiredPreAlert.materials[0].materialName == null) {
      Swal.fire({
        toast: true,
        title: 'Please choose at least one material while creating receipt alert',
        timer: 3000,
        type: "error",
        showConfirmButton: false
      })
    }

    else {
      console.log('PARAMS', this.RequiredPreAlert)
      this.ApiCall.getCreateReceipt(this.RequiredPreAlert)
        .subscribe(
          res => {
            if (res.status == false) {
              if (res.error == "notNull Violation: receiptMaterial.quantity cannot be null") {
                Swal.fire({
                  toast: true,
                  title: 'Please choose at least one material while creating receipt alert',
                  timer: 3000,
                  type: "error",
                  showConfirmButton: false
                })
              }
              if (res.error == "Out of range value for column 'pincode' at row 1") {
                Swal.fire({
                  toast: true,
                  title: 'Please Enter Valid Pin code',
                  timer: 2000,
                  type: "error",
                  showConfirmButton: false
                })
              }
              if (res.error == "Quantity of the item should be always > 0 in receipt alert") {
                Swal.fire({
                  toast: true,
                  title: 'Quantity of the item should be always more than 0 in receipt alert',
                  timer: 2000,
                  type: "error",
                  showConfirmButton: false
                })
              }
            }
            if (res.status == true) {
              Swal.fire({
                toast: true,
                title: 'Receipt Created Successfully',
                timer: 2000,
                type: "success",
                showConfirmButton: false
              })
              localStorage.setItem('pending_receipt_id', res['result']['id'])
              this.router.navigate(['/viewselectedreceipt'])
            }


          }

        )
    }
  }
  checkLength1(event: any) {
    const pattern = /[0-9, ,+]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  countryCodeApi() {
    this.ApiCall.countryCode()
      .subscribe(
        res => {
          this.countryCode = res.result
        }
      )
  }

  SupplierInto() {
    this.ApiCall.getSupplierInfo()
      .subscribe(
        res => {
          this.AutoSuggestFill = res.result
        }
      )
  }

  filters(val) {
    return this.AutoSuggestFill.filter(option =>
      option.name.toLowerCase().includes(val.toLowerCase()));
  }

  selectedSupplier(id) {
    this.SupplierInto()
    for (var i = 0; i < this.AutoSuggestFill.length; i++) {
      if (this.AutoSuggestFill[i].name == id) {
        this.RequiredPreAlert.supplier.mobileNo = this.AutoSuggestFill[i].mobileNo
        this.RequiredPreAlert.supplier.pincode = this.AutoSuggestFill[i].pincode
        this.RequiredPreAlert.supplier.address = this.AutoSuggestFill[i].address
        // //console.log("INDIVIDUAL DATA",this.autoFill[i])
      }
    }
  }

  RemoveSupplierDetails() {
    this.RequiredPreAlert.supplier.mobileNo = ''
    this.RequiredPreAlert.supplier.pincode = ''
    this.RequiredPreAlert.supplier.address = ''
  }

  setInputField(data) {

    this.InputDataValue = data
    this.ProductSearch.searchval = data
    this.ProductSearch.customerId = localStorage.getItem('CusID')
    if (data.length > 2) {
      this.ProductSearching()
    } else {
      this.ProductSearch.searchval = ''
      this.filteredOptions = EMPTY
      this.AutoFillSuppliers = []
    }
  }

  ProductSearching() {

    this.filteredOptions =

      this.ApiCall.getProductName(this.ProductSearch).pipe(
        map(x => x.result))

    this.ApiCall.getProductName(this.ProductSearch)
      .subscribe(
        res => {
          this.AutoFillSuppliers = res.result;
          this.filteredOptions = this.orderItems.valueChanges
            .pipe(
              startWith(''),
              map(val => this.filter(val))
            );
          console.log("AUTO FILL", this.AutoFillSuppliers)
          if (!this.AutoFillSuppliers) {
            this.AutoFillSuppliers.map(function (entry) {
              entry.isdisabled = false;
              return entry;
            });
          }

          if (res.result === 'No data Found' || res.status === false) {
            this.AutoFillSuppliers = []
            this.filteredOptions = EMPTY
          }

        }
      )
  }

  filter(val: string) {
    // const filterValue = val.toLowerCase();
    // if (val === '' || val === null) {
    //   return []
    // }
    // else if (val.toLowerCase()) {
    return this.AutoFillSuppliers.filter(option => option.productName.toLowerCase());
    // }
  }
}
