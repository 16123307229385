import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
declare var require: any
const FileSaver = require('file-saver');
import { saveAs } from 'file-saver'



@Component({
  selector: 'app-view-completed-delivery',
  templateUrl: './view-completed-delivery.component.html',
  styleUrls: ['./view-completed-delivery.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewCompletedDeliveryComponent implements OnInit {
  completedreceiptData: any;
  receiptNumber: any;
  cus_name: any;
  bookingId: any;
  InfoPickupInvoice: any;
  Date: any;
  ExpectedDate: any;
  ExpectedTime: any;
  SupplierName: any;
  SupplierMobile: any;
  Add_material = [];
  notFound = false;
  fileName: any;
  documentView: any;
  EmptyInvoice = false;
  InvoiceDetails: any;
  VehicleNo: any;
  EwayBillNo: any;
  gcnNo: any;
  materialList: { field: string; header: string; }[];
  status: any;
  BusinessName: any;

  car: any;
  newCar: boolean;
  displayDialog: boolean;
  cols = [];
  deliveryData: any;
  orderDocumentNumber: any;
  ProductCodeTitle: any;
  check: boolean;
  DNDocument: any;
  platformRequest: any;
  generateinvoice: boolean = false;
  generateinvoicerequest = [];
  menifeststatus: any;
  downloadlabelUrl: any;
  deliveryRequestId: any;
  failedmsg: any;
  shippingStatus: any;
  downloadManifesturl: any;
  shippingMode: any;
  wayBilNo: any;
  shipmentLabelUrl: any;
  manifestUrl: any;
  shippingCustomerdetails: any;
  downloadManifesturllength: any;
  trackingUrlLink: any;
  trackingNumber: any;
  shippingProvider: any;
  msg: any;
  invoiceCode: any;
  orderDocumentDate: any;
  showDetails: boolean;
  showReceivedSkus: boolean;
  downloadTab: boolean;
  trackingTab: boolean;
  partiallyTab: boolean;
  cus_mobile: any;
  cus_address: any;
  platformRequestvalue: string;
  paymentMode: any;
  endCustomerName: any;
  logistics: any;
  deliveryMode = 'null';
  constructor(private router: Router, private apiCall: ApiService) { }

  ngOnInit() {
    if (sessionStorage.getItem('downloadTab')) {
      this.showDetails = false
      this.showReceivedSkus = false
      this.downloadTab = true
      this.trackingTab = false
      this.partiallyTab = false
    }
    else {
      this.showdetailstab()
    }

    localStorage.setItem('OrderDocNumber', '')

    if (localStorage.getItem('OrderDocNumber') !== '') {
      this.orderNumberDelivery()
    } else {
      this.ViewCompletedDelivery();
    }

    this.cols = [
      { field: 'productCode', header: 'Product Code' },
      { field: 'productName', header: 'Product Name' },
      { field: 'location', header: 'Location' },
      { field: 'good', header: 'Required / Scanned' },
      { field: 'damage', header: 'Required / Scanned' },
      // { field: 'shortageGoodQuantity', header: 'Descrepancy' },
      { field: 'total', header: 'Required / Scanned' },
      // { field: 'descrepency', header: 'Descrepancy' },
      // { field: 'shortageDamagedQuantity', header: 'Descrepancy' },
    ];

  }
  showdetailstab() {
    this.showDetails = true
    this.showReceivedSkus = false
    this.downloadTab = false
    this.trackingTab = false
    this.partiallyTab = false
  }
  showreceivedskustab() {
    this.showDetails = false
    this.showReceivedSkus = true
    this.downloadTab = false
    this.trackingTab = false
    this.partiallyTab = false
  }
  showDownloadTab() {
    this.showDetails = false
    this.showReceivedSkus = false
    this.downloadTab = true
    this.trackingTab = false
    this.partiallyTab = false
  }
  showtrackingTab() {
    this.showDetails = false
    this.showReceivedSkus = false
    this.downloadTab = false
    this.trackingTab = true
    this.partiallyTab = false
  }
  showpartiallyTab() {
    this.showDetails = false
    this.showReceivedSkus = false
    this.downloadTab = false
    this.trackingTab = false
    this.partiallyTab = true
    if (localStorage.getItem('OrderDocNumber') == '') {
      this.ProductCodeTitle = "Not Found";
      this.check = true;
    } else {
      if (this.deliveryData.length === 0) {
        this.check = true;
        // console.log(this.check)
      } else {
        this.check = false
        this.deliveryData.forEach((x) => {
          if (x.availableGoodQuantity === 0) {
            x.status = "No Inventory";
          } else {
            x.status = "Partial";
          }
        });
      }

      if (this.deliveryData.length === 0) {
        this.check = true;
        // console.log(this.check)
      } else {
        this.check = false
      }
    }
  }
  orderNumberDelivery() {
    this.apiCall.getAlldeliverydetails()
      .subscribe(res => {

        this.completedreceiptData = res.result
        this.BusinessName = res.result.deliveryDetails.businessName
        this.status = res.result.deliveryDetails.status
        this.receiptNumber = res.result.deliveryDetails.id
        this.cus_name = res.result.customerDetails.name
        this.bookingId = res.result.deliveryDetails.bookingId
        this.Date = res.result.deliveryDetails.createdAt

        this.VehicleNo = res.result.deliveryDetails.vehicleNo
        this.EwayBillNo = res.result.deliveryDetails.ewayBillNo
        this.gcnNo = res.result.deliveryDetails.gcnNumber
        this.Add_material = res.result.materials;
        this.orderDocumentNumber = res.result.deliveryDetails.orderDocumentNumber
        localStorage.setItem('OrderDocNumber', this.orderDocumentNumber)
        this.CollectOrderDetails()
        console.log("CHECKING MATERIAL", this.Add_material)
        this.generateinvoicerequest = this.receiptNumber;


        this.materialList = [
          { field: 'productCode', header: 'Product Code' },
          { field: 'productName', header: 'Description' },
          { field: 'location', header: 'Location' },
          { field: 'goodQuantity', header: 'Good Quantity' },
          { field: 'damagedQuantity', header: 'Damaged Quantity' },
          { field: 'goodScannedQuantity', header: 'Scanned Good Quantity' },
          { field: 'damagedScannedQuantity', header: 'Scanned Damaged Quantity' },
        ];

        if (!this.completedreceiptData.documents.length) {
          this.notFound = true
        }
        else {
          this.fileName = this.completedreceiptData.documents
          this.documentView = this.completedreceiptData.documents[0].fileUrl
        }

        this.InvoiceDetails = res.result.deliveryDetails
        if (!this.InvoiceDetails.invoices.length) {
          this.EmptyInvoice = true;
        }
        else {
          this.InfoPickupInvoice = res.result.deliveryDetails.invoices
          console.log("CHECKING INVOICE", this.InfoPickupInvoice)
        }
      })
  }

  ViewCompletedDelivery() {
    this.apiCall.getViewCompletedDelivery()
      .subscribe(res => {
        this.completedreceiptData = res.result
        console.log('completed Delivery Data', this.completedreceiptData)
        this.DNDocument = res.result.deliveryDetails.vendorURL
        this.BusinessName = res.result.deliveryDetails.businessName
        this.status = res.result.deliveryDetails.status
        this.receiptNumber = res.result.deliveryDetails.id
        this.cus_name = res.result.customerDetails.name
        this.cus_mobile = res.result.customerDetails.mobileNo
        this.cus_address = res.result.customerDetails.address
        this.bookingId = res.result.deliveryDetails.bookingId
        this.Date = res.result.deliveryDetails.createdAt
        this.shippingMode = res.result.deliveryDetails.courierMethod
        this.platformRequest = res.result.deliveryDetails.platformRequest
        this.paymentMode = res.result.deliveryDetails.shippingMethod

        if (this.shippingMode == 2) {
          this.endCustomerName = res.result.shippingCustomer.name
          this.logistics = res.result.deliveryDetails.logistics
        }
        console.log('shippingMode', this.shippingMode)
        if (this.shippingMode == 0) {

          this.platformRequestvalue = 'None'
          console.log(this.platformRequestvalue)
        }
        if (this.shippingMode == 1) {
          this.platformRequestvalue = 'Platform'
          console.log(this.platformRequestvalue)

        }
        if (this.shippingMode == 2) {
          this.platformRequestvalue = 'Shipment'
          console.log(this.platformRequestvalue)

        }
        this.shippingCustomerdetails = res.result.shippingCustomer
        console.log(this.shippingCustomerdetails)
        this.VehicleNo = res.result.deliveryDetails.vehicleNo
        this.EwayBillNo = res.result.deliveryDetails.ewayBillNo
        this.wayBilNo = res.result.deliveryDetails.wayBill
        this.shipmentLabelUrl = res.result.deliveryDetails.shipmentLabelUrl
        this.manifestUrl = res.result.deliveryDetails.manifestUrl
        this.gcnNo = res.result.deliveryDetails.gcnNumber
        this.Add_material = this.completedreceiptData.materials;
        console.log('add_material', this.Add_material)
        this.deliveryRequestId = this.completedreceiptData.deliveryDetails.id

        this.orderDocumentNumber = res.result.deliveryDetails.orderDocumentNumber
        this.orderDocumentDate = res.result.deliveryDetails.orderDocumentDate

        localStorage.setItem('OrderDocNumber', this.orderDocumentNumber)
        console.warn(res)
        console.log(res.result.documents)
        this.downloadlabelUrl = res.result.deliveryDetails.shippingLabelLink
        console.warn(res.result.customerDetails.customerId)
        console.warn(res.result.deliveryDetails.id)

        this.CollectOrderDetails()

        if (!this.completedreceiptData.documents.length) {
          this.notFound = true
        }
        else {
          this.fileName = this.completedreceiptData.documents
          this.documentView = this.completedreceiptData.documents[0].fileUrl
        }

        this.InvoiceDetails = res.result.deliveryDetails
        if (!this.InvoiceDetails.invoices.length) {
          this.EmptyInvoice = true;
        }
        else {
          this.InfoPickupInvoice = res.result.deliveryDetails.invoices
          console.log("CHECKING INVOICE", this.InfoPickupInvoice)
        }
        this.trackingUrlLink = res.result.deliveryDetails.trackingLink
        console.log(this.trackingUrlLink);

        this.trackingNumber = res.result.deliveryDetails.trackingNumber
        this.shippingProvider = res.result.deliveryDetails.shippingProvider
        console.log("MATERIALS", this.completedreceiptData.materials)
        console.warn(res.result.deliveryDetails)
        console.warn(res.result.deliveryDetails.courierMethod)
        this.shippingMode = res.result.deliveryDetails.courierMethod
        //console.log(res.result.deliveryDetails.shippingLabelLink)
        this.downloadlabelUrl = res.result.deliveryDetails.shippingLabelLink
        this.shippingStatus = res.result.deliveryDetails.shippingStatus
        this.invoiceCode = res.result.deliveryDetails.invoiceCode
        if (res.result.deliveryDetails.manifestLink) {
          this.downloadManifesturl = res.result.deliveryDetails.manifestLink
          this.downloadManifesturllength = this.downloadManifesturl.length
          console.log('manifest url', this.downloadManifesturl)
        }





        // this.InvoiceDetails = res.result
        // if (!this.InvoiceDetails.deliveryDetails.invoices.length) {
        //   this.EmptyInvoice = true;
        // }
        // else {
        //   this.InfoPickupInvoice = res.result.invoices
        //   console.log("CHECKING INVOICE", this.InfoPickupInvoice)
        // }
        this.materialList = [
          { field: 'productCode', header: 'Product Code' },
          { field: 'productName', header: 'Description' },
          { field: 'location', header: 'Location' },
          { field: 'goodQuantity', header: 'Good Quantity' },
          { field: 'damagedQuantity', header: 'Damaged Quantity' },
          { field: 'goodScannedQuantity', header: 'Scanned Good Quantity' },
          { field: 'damagedScannedQuantity', header: 'Scanned Damaged Quantity' },
        ];
        console.log(this.materialList)
      })
  }

  applyFilter(filter: String) {
    this.Add_material = this.Add_material.filter(item => {

      return item.productName.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    }
    );
    if (filter === '') {
      this.ViewCompletedDelivery()
    } else {
      this.notFound = true
    }
  }

  CollectOrderDetails() {
    if (localStorage.getItem('OrderDocNumber') !== '') {
      this.apiCall.getorderNumberDetails()
        .subscribe(res => {
          this.deliveryData = res.result.materials;
          this.ProductCodeTitle = res.result.orderNumber;
          console.log("RESULT", this.deliveryData)
        })
    }
  }

  documentViewPage() {
    window.open(this.documentView, '_blank')
  }
  errormsg() {
    Swal.fire({
      title: '<h3>manifest label link is not available</h3>',
      text: 'Could not download manifest',
      showConfirmButton: false,
      timer: 3300,
      type: 'error'
    })
  }

  createShip() {
    sessionStorage.setItem('downloadTab', 'true')
    console.log('i am clicked')
    localStorage.setItem('shipCustDetails', JSON.stringify(this.shippingCustomerdetails))
    this.router.navigate(['create-shipment'])

  }
  allocateShiping() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "customerId": this.completedreceiptData.customerDetails.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.completedreceiptData.deliveryDetails.id,
      "courierPartner": "ITHINK"
    }
    this.apiCall.allocateShipping(obj).subscribe(res => {
      console.log(res)
      if (res['status'] == true) {
        Swal.fire({
          title: '<h3>Shipping provider Allocated successfully</h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        Swal.fire({
          title: '<h3>Shipping provider could not be allocated</h3>',
          text: res['error'],
          showConfirmButton: true,
          type: 'error'
        })
      }
    })

  }
  allocateShiping2() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "customerId": this.completedreceiptData.customerDetails.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.completedreceiptData.deliveryDetails.id,
      "courierPartner": "SELF"
    }
    this.apiCall.allocateShipping(obj).subscribe(res => {
      console.log(res)
      if (res['status'] == true) {
        Swal.fire({
          title: '<h3>Shipping provider Allocated successfully</h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        Swal.fire({
          title: '<h3>Shipping provider could not be allocated</h3>',
          text: res['error'],
          showConfirmButton: true,
          type: 'error'
        })
      }
    })

  }
  createLabel() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "customerId": this.completedreceiptData.customerDetails.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.deliveryRequestId
    }
    console.log('CreateLabel', obj)
    this.apiCall.createLabel(obj).subscribe(res => {
      console.log(res)
      if (res['status'] == true) {
        this.msg = res['message']
        Swal.fire({
          title: '<h3>Label generated successfully</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        this.msg = res['error']
        Swal.fire({
          title: '<h3>Generate Label failed</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'error'
        })
      }
    })
  }
  printShippingLabel() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "deliveryRequestId": this.deliveryRequestId
    }
    this.apiCall.shippingLabelLink(obj).subscribe(res => {
      console.log(res)
      if (res['status'] == true) {
        Swal.fire({
          title: '<h3>Shipment label is generated please click on download to download the label</h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        Swal.fire({
          title: '<h3>Shipment label could not be generated </h3>',
          text: res['error'],
          showConfirmButton: true,
          type: 'error'
        })
      }

    })
  }
  genInvoice() {

    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "customerId": this.completedreceiptData.customerDetails.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.completedreceiptData.deliveryDetails.id
    }
    console.log(obj)
    // const promise = this.apiCall.generateInvoiceAndLabel(obj).toPromise();
    // promise.then((data) => {

    //   console.log("utkarsh"+JSON.stringify(data))

    //   const var1 = JSON.stringify(data['status']);

    //   console.log(var1.toString())
    // }) 


    this.apiCall.generateInvoiceAndLabel(obj).subscribe(res => {
      console.warn(res)
      if (res['status']) {
        this.generateinvoice = true
        //this.downloadLabelUrl=res['result']['shippingLabelLink']
        this.ngOnInit();

      }
      else {
        this.msg = res['error']
        Swal.fire({
          title: '<h3>Generate Invoice and Label failed</h3>',
          text: this.msg,
          showConfirmButton: false,
          timer: 3300,
          type: 'error'
        })


      }

    })
  }
  // GetProduct() {
  //   const promise = this.ApiCall.GetSearchByMaterial(this.RequiredMaterial).toPromise();
  //   promise.then((data) => {
  //     console.log("Promise Success Msg: " + JSON.stringify(data));
  //     this.searchByProdctData = data.result


  //     if (this.searchByProdctData.length === 0) {
  //       this.check = true
  //     } else {
  //       this.check = false
  //       this.ProductName = data.result[0].productname
  //     }
  //   }, (error) => {
  //     console.log("Promise Rejection Msg " + JSON.stringify(error));
  //   })
  // }
  compManiPlatform() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "customerId": this.completedreceiptData.customerDetails.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.completedreceiptData.deliveryDetails.id,
      "courierPartner": "PLATFORM"
    }
    console.log(obj)
    this.apiCall.completeMinifest(obj).subscribe(res => {
      console.log(res)
      if (res['status']) {
        Swal.fire({
          title: '<h3>Manifest Completed</h3>',
          text: 'Your Manifest has been completed',
          showConfirmButton: true,

          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        this.failedmsg = res['error']
        Swal.fire({
          title: '<h3>Manifest failed</h3>',
          text: this.failedmsg,
          showConfirmButton: true,

          type: 'error'
        })
      }






    })

  }
  compMani() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "customerId": this.completedreceiptData.customerDetails.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.completedreceiptData.deliveryDetails.id,
      "courierPartner": "ITHINK"
    }
    console.log(obj)
    this.apiCall.completeMinifest(obj).subscribe(res => {
      console.log(res)
      if (res['status']) {
        Swal.fire({
          title: '<h3>Manifest Completed</h3>',
          text: 'Your Manifest has been completed',
          showConfirmButton: true,

          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        this.failedmsg = res['error']
        Swal.fire({
          title: '<h3>Manifest failed</h3>',
          text: this.failedmsg,
          showConfirmButton: true,

          type: 'error'
        })
      }






    })

  }
  compMani1() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "customerId": this.completedreceiptData.customerDetails.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.completedreceiptData.deliveryDetails.id,
      "courierPartner": "SELF"
    }
    console.log(obj)
    this.apiCall.completeMinifest(obj).subscribe(res => {
      console.log(res)
      if (res['status']) {
        Swal.fire({
          title: '<h3>Manifest Completed</h3>',
          text: 'Your Manifest has been completed',
          showConfirmButton: true,

          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        this.failedmsg = res['error']
        Swal.fire({
          title: '<h3>Manifest failed</h3>',
          text: this.failedmsg,
          showConfirmButton: true,

          type: 'error'
        })
      }






    })

  }
  compManiShip() {
    sessionStorage.setItem('downloadTab', 'true')
    const obj = {
      "deliveryRequestId": this.deliveryRequestId
    }
    this.apiCall.manifestLbelLink(obj).subscribe(res => {
      console.log('ship manifest api call', res)
      if (res['status'] == true) {
        Swal.fire({
          title: '<h3>Manifest label is generated please click on download to download the label</h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        Swal.fire({
          title: '<h3>Manifest label could not be generated </h3>',
          text: res['error'],
          showConfirmButton: true,
          type: 'error'
        })
      }
    })

  }
  cancelShip() {
    const obj = {
      "deliveryRequestId": this.deliveryRequestId
    }
    this.apiCall.cancelShipment(obj).subscribe(res => {
      console.log('cancel shipment api response', res)
      if (res['status'] == true) {
        Swal.fire({
          title: '<h3>Shipment has been cancelled successfully</h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        Swal.fire({
          title: '<h3>Shipment could not be cancelled</h3>',
          text: res['error'],
          showConfirmButton: true,
          type: 'error'
        })
      }
    })

  }
  downInvoice() {


  }
  downLabel() {
    saveAs(this.downloadlabelUrl, this.orderDocumentNumber + "_" + this.deliveryRequestId + "_Invoice.pdf", { type: 'application/pdf' })

  }

  onRowSelect(event) {
    // localStorage.setItem('OrderDocNumber', this.orderDocumentNumber)
    this.newCar = false;
    this.displayDialog = true;

    if (localStorage.getItem('OrderDocNumber') == '') {
      this.ProductCodeTitle = "Not Found";
      this.check = true;
    } else {
      if (this.deliveryData.length === 0) {
        this.check = true;
        // console.log(this.check)
      } else {
        this.check = false
        this.deliveryData.forEach((x) => {
          if (x.availableGoodQuantity === 0) {
            x.status = "No Inventory";
          } else {
            x.status = "Partial";
          }
        });
      }

      if (this.deliveryData.length === 0) {
        this.check = true;
        // console.log(this.check)
      } else {
        this.check = false
      }
    }
  }
}
