import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { myVariables } from 'src/environments/environment';
import Swal from 'sweetalert2';
export interface Product {
  [x: string]: any;
  id?: any;
  name?: string;
  description?: string;
  parentLocationId?: any;
  images?: string;
  isActive?: any;

}
@Component({
  selector: 'app-location-type',
  templateUrl: './location-type.component.html',
  styleUrls: ['./location-type.component.css']
})
export class LocationTypeComponent implements OnInit {
  cols = [];
  displayDialog: boolean = false;
  pendingData: any;
  totalRecords: number;
  RequiredPendingDelivery =
    {
      status: "pending",
      warehouseId: localStorage.getItem('WarehouseID'),
      pageNumber: 1
    }
  parentLocation: {}[];
  statuses: { label: string; value: string; }[];
  maximiseData: Product;
  product: {};
  editable: boolean = false;
  locationNames: {}[];
  locationNamesSelected: any;
  editingmode: boolean=true;
  dropDownValueChanged: boolean=false;

  constructor(private Apicall: ApiService) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber=1;
    myVariables.grnRowsPerPage=1;
    myVariables.returnGrnPageNumber=1;
    myVariables.returnGrnRowsPerPage=1;
    myVariables.dnRowsPerPage=1;
    myVariables.dnPageNumber=1;
    myVariables.shippingOrdersRowsPerPage=1;
    myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  sessionStorage.removeItem('shippingOrdersDate');
  sessionStorage.removeItem('grnReportsDates');
  sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('dnReportsDate');
    // this.cols = [
    //   { field: 'businessName', header: 'Business Name' },
    //   { field: 'id', header: 'Receipt Pre Alert Number' },
    //   { field: 'createdAt', header: 'Date' },
    //   { field: 'pickupDate', header: 'Expected Receipt Date' },
    //   // { field: 'pickupTime', header: 'Expected Receipt Time' },
    //   { field: 'poNo', header: 'PO Number' },
    //   { field: 'status', header: 'Status' },
    //   // { field: 'return', header: 'Returns' }
    // ];
    this.cols = [
      { field: 'id', header: 'S.No' },
      { field: 'name', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'parentLocationName', header: 'Parent Location' },
      { field: 'images', header: 'Images' },
      { field: 'isActive', header: 'status' }
    ]
    this.statuses = [
      { label: 'In Stock', value: 'INSTOCK' },
      { label: 'Low Stock', value: 'LOWSTOCK' },
      { label: 'Out of Stock', value: 'OUTOFSTOCK' }
    ]
    this.locationNames = new Array()

    this.GetallPendingStatus();
  }
  GetallPendingStatus() {
    this.locationNames = []
    this.Apicall.getLocationType().subscribe(
      result => {

        this.pendingData = result.locationDetails
        result.locationDetails.forEach(location => {
          // this.locationNames.push(location.name)
          let obj = {
            "id": location.id,
            "name": location.name
          }
          this.locationNames.push(...[obj])
        })


      }
    )
    // this.Apicall.getPendingReceipt(this.RequiredPendingDelivery)
    //   .subscribe(
    //     res => {
    //       this.pendingData = res.result;
    //       this.totalRecords = res.noOfPages
    //       console.log("PENDING STATUS", this.totalRecords)

    //       for (var i = 0; i < this.pendingData.length; i++) {
    //         if (this.pendingData[i].return) {
    //           this.pendingData[i]['return'] = 'Returns'
    //         } else {
    //           this.pendingData[i]['return'] = '';
    //         }
    //         if (!this.pendingData[i].pickupInvoice.length) {

    //           this.pendingData[i]['poNo'] = ''
    //         }
    //         else {
    //           for (var j = 0; j < this.pendingData[i].pickupInvoice.length; j++) {
    //             this.pendingData[i]['poNo'] = this.pendingData[i].pickupInvoice[j].purchaseOrderNumber
    //           }

    //         }
    //       }
    //     }
    //   )
  }
  getColor(parentLocationName) {
    switch (parentLocationName) {
      case 'Zone':
        return 'red';
      case 'Aisle':
        return '#4EAEE2'
      case 'Rack':
        return 'green';
      case 'Shelf':
        return 'blue';
      case 'Bin':
        return 'purple';
      case 'Pallet':
        return '#F0C023';
      case '':
        return 'white';
    }
  }
  dropdownvalue(event) {
this.dropDownValueChanged=true
    this.locationNamesSelected = this.pendingData.find(o => o.name == event)


  }
  getColorReturn(returns) {
    switch (returns) {
      case 'Returns':
        return 'orange';
      case '':
        return 'transparent';
    }
  }
  maximise(rowData: Product) {
    if(rowData.name!=''){
    this.Apicall.getLocaTypeById(rowData.id).subscribe(res => {
      console.log('get location type by id', res.locationDetailsById)
      this.maximiseData = res.locationDetailsById
    })
    console.log('i am maximised', rowData)

    this.displayDialog = true
    }
    else{
      console.log('cannot maximise as this is new location type',)
    }

  }

  newRow() {
    
    return {
      name: '',
      description: '',
      isActive: '',
      parentLocationId: ''
    };
  }
  onRowEditInit(rowData) {
this.editingmode=false
console.log( rowData)
if(rowData.parentLocationName!=''){
    this.locationNamesSelected = this.pendingData.find(o => o.name == rowData.parentLocationName)
    rowData.parentLocationName = this.locationNamesSelected.name
    rowData.parentLocationId = this.locationNamesSelected.id
  }
  else{
    console.log('parent location name is empty')
  }
  }
  onRowEditSave(rowData) {
    if(rowData.parentLocationName!=''){
    rowData.parentLocationName = this.locationNamesSelected.name
    rowData.parentLocationId = this.locationNamesSelected.id
    }
    else{
      console.log('parent location name is empty')
    }
    if (rowData.id == '' || rowData.id == undefined || rowData.id == null) {

      if (rowData.parentLocationName != '' && rowData.name != '' && rowData.description != '') {
        if (rowData.isActive == '') {
          //when row id is not present and isactive is not entered executing add method giving isactive value as false
          rowData.isActive = false
          this.Apicall.addLocaType(rowData)
          .subscribe(res=>{
            console.log('api response',res)
            this.editingmode=true
          })
        }
        else {
          //when row id is not present and isactive is entered executing add method
          this.Apicall.addLocaType(rowData)
          .subscribe(res=>{console.log('api response',res)})
          this.editingmode=true
        }
      }
      else {
        console.log('data cannot be empty')
      }
    }
    else {
      //when row id is not present executing update
      this.Apicall.updatelocaType(rowData)
      .subscribe(res=>{
        console.log('api response of put method',res)
        this.editingmode=true
      })
    }

    this.GetallPendingStatus();
  }
  addNewRow1(){
    
    this.editingmode=false
  }
  onRowEditCancel() {
    this.GetallPendingStatus();
    this.editingmode=true
  }
  deleteRow(rowData) {
    // console.log(rowData.id)
    this.Apicall.deleteLocationType(rowData.id).subscribe(res => {
      console.log('delete res', res)
    })
    this.GetallPendingStatus();
  }
  edit() {
    this.editable = true
  }
  save() {
    this.editable = false
 
    if(this.maximiseData.parentLocationName!=''){
      this.locationNamesSelected = this.pendingData.find(o => o.name == this.maximiseData.parentLocationName)
      this.maximiseData.parentLocationName = this.locationNamesSelected.name
    this.maximiseData.parentLocationId = this.locationNamesSelected.id
      }
      else{
        delete this.maximiseData.parentLocationId
      delete this.maximiseData.parentLocationName
      }
    
    if (this.maximiseData.id == '' || this.maximiseData.id == undefined || this.maximiseData.id == null) {

      if (this.maximiseData.name != '' && this.maximiseData.description != '') {
        if (this.maximiseData.isActive == '') {
          //when row id is not present and isactive is not entered executing add method giving isactive value as false
          this.maximiseData.isActive = false
          this.Apicall.addLocaType(this.maximiseData)
          .subscribe(res=>{
            console.log('api response',res)
            this.editingmode=true
          })
        }
        else {
          //when row id is not present and isactive is entered executing add method
          this.Apicall.addLocaType(this.maximiseData)
          .subscribe(res=>{
           
          this.editingmode=true
        })
          
        }
      }
      else {
        console.log('data cannot be empty')
        this.editingmode=true
      }
    }
    else {
      //when row id is not present executing update
      this.Apicall.updatelocaType(this.maximiseData)
      .subscribe(res=>{
        console.log('api response of put method',res)
        if(res['status']==true){
          Swal.fire({
              title: '<h3>your data has been saved successfully</h3>',
              showConfirmButton: false,
              timer: 3300,
              type: 'success'
            })
            this.displayDialog = false
            this.GetallPendingStatus();
        }
        else{
          Swal.fire({
            title: '<h3>your data was failed to save</h3>',
            showConfirmButton: false,
            timer: 3300,
            type: 'error'
          })
        }
        this.editingmode=true
      })
    }
    this.GetallPendingStatus();


    // Swal.fire({
    //   title: '<h3>your data has been saved successfully</h3>',
    //   showConfirmButton: false,
    //   timer: 3300,
    //   type: 'success'
    // })
    // this.displayDialog = false

  }
  cancel() {
    this.editable = false
    this.displayDialog=false
  }
  delete() {
    this.Apicall.deleteLocationType(this.maximiseData.id).subscribe(res => {
      console.log('delete res', res['status'])

    })
    Swal.fire({
      title: '<h3>your data has been deleted successfully</h3>',
      showConfirmButton: false,
      timer: 3300,
      type: 'success'
    })
    this.displayDialog = false
  }


}
