import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Chart } from 'chart.js';
import { dashboardVarialbles } from 'src/environments/environment'
import { Router } from '@angular/router';

@Component({
  selector: 'app-deliveries-pie',
  templateUrl: './deliveries-pie.component.html',
  styleUrls: ['./deliveries-pie.component.css']
})
export class DeliveriesPieComponent implements OnInit {
  data: any;
  report: any;
  options: any;
  sum: any;
  completed: any;
  pending: any;
  staging: any;
  newArray: any = [];
  unfullfilled: any;
  cols: { field: string; header: string; }[];
  unfilleddata: any;
  warehouseArray: any;
  warehouseParams = { warehouseId: localStorage.getItem('WarehouseID') };
  today: any;
  startDate: string;
  chart: any;



  constructor(private ApiCall: ApiService, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('WarehouseID') == null || localStorage.getItem('WarehouseID') == undefined) {
      this.warehouseArray = JSON.parse(localStorage.getItem('WarehouseArray'))
      this.warehouseParams.warehouseId = this.warehouseArray[0].id
      console.log(this.warehouseParams)
      localStorage.setItem('WarehouseID', this.warehouseParams.warehouseId)
    }
    else {
      this.warehouseParams.warehouseId = localStorage.getItem('WarehouseID')
    }
    this.cols = [
      { field: 'createdAt', header: 'Date' },
      { field: 'orderNumber', header: 'Order Number' },
      { field: 'customerName', header: 'Customer' },
      { field: 'productCode', header: 'Product Code' },
      { field: 'productName', header: 'Product Name' },
      { field: 'Required', header: 'Required' },
      { field: 'Actual', header: 'Actual' },
      { field: 'Descrepancy', header: 'Descrepancy' }
    ];
    const obj1 = {
      "customerId": null
    }
    this.ApiCall.UnfilledOrders(obj1).subscribe(res => {
      console.log(res);
      this.unfilleddata = res['result']

    })
    this.today = new Date();
    // this.firstDate.setMonth(this.today.getMonth());
    this.startDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
    const obj = {
      "date": this.startDate,
      "warehouseId": localStorage.getItem('WarehouseID'),
      "customerId": null
    }
    this.ApiCall.getInfoDeliveryChart(obj).subscribe(res => {
      console.log('donught', res);
      this.unfullfilled = res['result']['unfulfillableOrderCount']
      this.pending = res['result']['pendingDelivery']
      this.staging = res['result']['stagingDelivery']
      this.completed = res['result']['completedDelivery']
      this.newArray.push(this.unfullfilled)
      this.newArray.push(this.pending)
      this.newArray.push(this.staging)
      this.newArray.push(this.completed)



      // console.log(this.newArray);
      this.report = this.newArray

      this.sum = this.report[0] + this.report[1] + this.report[2] + this.report[3]

      this.chart = new Chart('canvas2', {
        type: 'doughnut',
        data: {
          labels: ['Unfulfillable', 'Pending', 'Staging', 'Completed'],
          datasets: [
            {
              data: this.newArray,
              borderWidth: 0,

              backgroundColor: [
                "#F65858",
                "#87D0FE",
                "#FFE469",
                "#9CDB6A",

              ],
              hoverBackgroundColor: [
                "#f00505",
                "#27a4f2",
                "#f5c902",
                "#71f20a",

              ]
            }
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false,
          },
          // tooltips: {
          //   enabled: false,
          // },
          rotation: -1 * Math.PI,
          circumference: 1 * Math.PI
        },


      });



    })



  }
  unfilledOrder() {

  }
  stagingRouting() {
    dashboardVarialbles.isStagingRoute = true
    this.router.navigate(['/pendingdeliveries'])
  }
  pendingRouting() {
    dashboardVarialbles.isPendingRoute = true
    this.router.navigate(['/pendingdeliveries'])
  }
  completedRouting() {
    dashboardVarialbles.IsCompletedRoute = true
    this.router.navigate(['/completeddeliveries'])
  }

}