import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-view-selected-customer',
  templateUrl: './view-selected-customer.component.html',
  styleUrls: ['./view-selected-customer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewSelectedCustomerComponent implements OnInit {

  syncDone = false;
  mobileNo: any;
  customer_Name: any;
  organization: any;
  EmailID: any;
  ecommEnabled: any;

  constructor(private Apicall: ApiService, private router: Router) { }

  ngOnInit() {
    this.individualBooking()
  }
  individualBooking() {
    console.log(window.location.href);
    if (this.Apicall.rootPath != this.Apicall.liveServer) {
      this.syncDone = true;
    }
    this.Apicall.getPendingDelivery()
      .subscribe(res => {
        console.log("individual booking", res)
        localStorage.setItem('Cus_ID', res.result.user.id)
        this.customer_Name = res.result.user.fullname
        this.organization = res.result.businessName
        this.mobileNo = res.result.user.mobileNo
        this.EmailID = res.result.user.email
        this.ecommEnabled = res.result.ecommCustomer
      })
  }
  StockStmtPage() {
    this.router.navigate(['/stockstatement'])
  }
  StockLedgerPage() {
    this.router.navigate(['/stockledger'])
  }
  StockTransaction() {
    this.router.navigate(['/stocktransaction'])
  }
  MaterialMaster() {
    this.router.navigate(['/materialmaster'])
  }
  syncInventory() {
    const obj = {
      "warehouseId": localStorage.getItem('WarehouseID'),
      "enabledCustomers": [
        localStorage.getItem('ViewCustomer_id')
      ]
    }
    this.Apicall.inventorySync(obj).subscribe(res => {
      console.log(res)
      if (res) {
        Swal.fire({
          text: 'Inventory synchronization completed Successfully',
          type: 'success',
          timer: 3000,
          toast: true

        })
        this.syncDone = true;
      } else {
        Swal.fire({
          text: 'Inventory synchronization failed',
          type: 'warning',
          timer: 3000,
          toast: true

        })
      }
    })
  }
  blockedInventoryReport() {
    const obj = {
      "customerid": localStorage.getItem('ViewCustomer_id'),
      "warehouseid": localStorage.getItem('WarehouseID'),
      "flag": "download"
    }
    this.Apicall.blockedInventoryReport(obj).subscribe({
      next: (response: any) => {
        let fileName = 'ConsolidateDN';
        const contentDisposition = response.headers.get('content-disposition');
        console.log('content', response.headers)
        console.log('content', response.headers.get('content-disposition'))
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        const fileContent = response.body;
        saveAs(fileContent, fileName);
      },
      error: (error) => {
        console.log({ error });
      }
    })
  }
}


