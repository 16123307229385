import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { MapsAPILoader } from '@agm/core';
import { } from 'googlemaps';
import { MessageService } from 'primeng/api';
import lodash from 'lodash';
import { MatSnackBar } from '@angular/material';
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { DatePipe } from '@angular/common';


interface UoM {
  id: string;
  Value: string;
}

interface Dimension {
  id: string;
  Value: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class AddMaterialComponent implements OnInit {
  @ViewChild('search')
  passStatusValue = []
  public searchElementRef: ElementRef;
  address: string;
  latitude: number;
  longitude: number;
  pickUpPoint: string;
  zoom: number;
  private geoCoder;
  submitted = false;
  AddMat_formGroup: FormGroup;
  palletContainerForm: FormGroup;
  // bookingId: localStorage.getItem('bookingID'),
  // invoiceDate: localStorage.getItem('invoiceDate'),
  // invoiceNumber: localStorage.getItem('InvoiceNo'),
  materialsInformation = {
    "SkuUpcEan": "",
    "brand": "",
    "damagedQuantity": "",
    "manufacturedDate": "",
    "batchNumber": "",
    "cartonCode": "",
    "description": "",
    "dimension": "",
    "dimensionUnit": "",
    "goodQuantity": "",
    "quantity": "",
    "deliveryPreferences": "",
    "media": [],
    // "materialId":"",
    "materialLocation": [],
    "productName": "",
    "remark": "",
    'stagingGoodQuantity': '',
    'stagingDamagedQuantity': '',
    "unitOfMaterials": "",
    "boxPcsQuantity": null,
    "unitOfBoxPices": "None",
    "weight": 0
  }

  UnitMaterial: UoM[] = [
    // { id: '1', Value: 'UoM' },
    { id: '1', Value: 'Box' },
    { id: '2', Value: 'Nos' },
    { id: '3', Value: 'Pcs' },
    { id: '4', Value: 'Bag' },
    // { id: '6', Value: 'Loose' }
  ];

  UnitSubMaterial: UoM[] = [
    { id: '1', Value: 'Nos' },
    { id: '2', Value: 'Pcs' },
  ];

  DimensionUnit: Dimension[] = [
    { id: '1', Value: 'MM' },
    { id: '2', Value: 'Inches' },
    { id: '3', Value: 'Feet' },
    { id: '4', Value: 'Centimeter' }
  ];

  GenerateProductCode =
    {
      "productName": ""
    }

  HideGenerateBtn = false;

  SkuUpcEan: any;
  Description: any;
  UnitOfMaterial: any;
  Quantity: any;
  brand: any;
  description: any;
  add_material = [];
  Remark: any;
  dimension: any;
  Weight: any;
  rowSelectedMaterial: any;
  goodQuantity: any;
  damagedQty: any;
  Dimension: any;
  formLoction: FormGroup
  PalletLocationRow = [
    {
      number: "",
      palletGoodQuantity: "",
      palletDamagedQuantity: ""
    }
  ];
  locationPage = false
  addMaterialPage = true

  Container = [];
  selected = new FormControl(0);
  PalletLocation: string;
  PalletNo: string;
  invoiceQty: any;
  InvoiceQuantity: any;
  keyValue: number;
  goodQuantityLength: any;
  ValueKey: number;
  DamageQuantityLength: any;
  count = 0;
  keyValues: number;
  keyValuess: number;
  CustomerQty: any;
  createNewLoaction = [];
  valueOfLocationQty: any;
  selectedMaterial: any;
  // Addition: any;
  SubUoMShow = true;
  UoMSwitchBox = true;
  BoxQtyInput = true;
  UoMEnable = true;
  selectedSubUoM: any;
  MainUoM = true;
  SubUoM: any;
  BoxQuantity: any;
  LocationPallet = false;
  butDisabled: boolean = true;
  TotalLocationGoodCount: number;
  TotalLocationDamagedCount: number;
  SelectedCustomerID: any;
  StatusName: any;
  manufacturedDate: any;
  cartonCode: any;
  batchNumber: any;
  manufactureDate: string;
  batchNo: string;
  cartoonCode: string;
  successmsg: any;
  success: boolean;
  failmsg: any;
  failure: boolean;
  deliveryPreferences: any;
  rowIndex: string;

  constructor(private fb: FormBuilder, private datePipe: DatePipe, private messageService: MessageService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private ApiCall: ApiService, private router: Router, private snackBar: MatSnackBar, private el: ElementRef) {
    {
      this.formLoction = fb.group({
        'createNewLoaction': fb.array([])
      });
    }
  }

  ngOnInit() {
    this.passStatusValue = JSON.parse(localStorage.getItem('ConfigDetails'))
    this.SelectedCustomerID = localStorage.getItem('ADD_CusID')
    this.rowIndex = localStorage.getItem('rowIndex')
    this.rowSelectedMaterial = JSON.parse(localStorage.getItem('selectedProduct'))
    this.add_material = JSON.parse(localStorage.getItem("materialInfo"))
    this.selectedMaterial = JSON.parse(localStorage.getItem('selectedProduct'))

    console.log("We need to see here", this.rowSelectedMaterial.boxPcsQuantity)
    console.log('rowSelectedMaterial', this.rowSelectedMaterial);
    console.log(this.rowSelectedMaterial.manufacturedDate != '')
    // if(this.rowSelectedMaterial.deliveryPreferences=="fefo"){
    //   this.rowSelectedMaterial.deliveryPreferences='fefo'
    // }
    // if(this.rowSelectedMaterial.deliveryPreferences=="fifo"){
    //   this.rowSelectedMaterial.deliveryPreferences='fifo'

    // }
    console.log('rowSelectedMaterial', this.rowSelectedMaterial);


    if (this.rowSelectedMaterial.boxPcsQuantity === "" || this.rowSelectedMaterial.boxPcsQuantity === null || this.rowSelectedMaterial.boxPcsQuantity === "null") {
      this.MainUoM = false
      this.UoMSwitchBox = false
      this.SubUoMShow = false;
      this.UoMSwitchBox = false;
      this.BoxQtyInput = false;
      this.MainUoM = true
    } else {

      this.MainUoM = true
      this.UoMSwitchBox = true
      this.SubUoMShow = true;
      this.UoMSwitchBox = true;
      this.BoxQtyInput = true;
      this.MainUoM = false;
    }

    if (this.rowSelectedMaterial.unitOfMaterials !== "") {
      this.butDisabled = true;
      this.MainUoM = true
    } else {
      this.butDisabled = false;
      this.MainUoM = false
    }

    if (this.rowSelectedMaterial.unitOfMaterials !== "Box") {
      this.UoMSwitchBox = false;
      this.SubUoMShow = false;
      this.BoxQtyInput = false;
    } else {
      this.UoMSwitchBox = true;
      this.SubUoMShow = true;
      this.BoxQtyInput = true;
    }

    console.log("MATERIAL LIST", this.add_material)
    console.log("SELECTED LIST", this.rowSelectedMaterial)
    if (this.add_material[0].materialLocation.length && localStorage.getItem('Redirecting') === "true") {
      this.locationPage = true
      this.addMaterialPage = false
    }
    this.goodQuantity = this.rowSelectedMaterial.goodQuantity || this.rowSelectedMaterial.stagingGoodQuantity
    this.damagedQty = this.rowSelectedMaterial.damagedQuantity || this.rowSelectedMaterial.stagingDamagedQuantity
    // this.invoiceQty = this.rowSelectedMaterial.invoiceQuantity
    console.log(this.damagedQty);

    this.brand = this.rowSelectedMaterial.brand
    this.Weight = this.rowSelectedMaterial.weight
    this.dimension = this.rowSelectedMaterial.dimension
    this.Dimension = this.rowSelectedMaterial.dimensionUnit
    this.Remark = this.rowSelectedMaterial.remark
    this.InvoiceQuantity = this.rowSelectedMaterial.invoiceQuantity
    this.SubUoM = this.rowSelectedMaterial.unitOfBoxPices
    this.BoxQuantity = this.rowSelectedMaterial.boxPcsQuantity
    if (this.rowSelectedMaterial.deliveryPreferences == 'fefo') {
      this.manufactureDate = this.rowSelectedMaterial.manufacturedDate
      this.manufacturedDate = this.rowSelectedMaterial.manufacturedDate
      this.batchNo = this.rowSelectedMaterial.batchNumber
      this.batchNumber = this.rowSelectedMaterial.batchNumber
      this.cartonCode = this.rowSelectedMaterial.cartonCode
      this.cartoonCode = this.rowSelectedMaterial.cartonCode
      this.deliveryPreferences = 'fefo'
    }



    if (this.selectedMaterial.materialLocation.length === 0) {
      this.addPalletContainer();
    } else {
      console.log("Material Location", this.selectedMaterial.materialLocation)
      if (this.selectedMaterial.materialLocation) {
        this.selectedMaterial.materialLocation.forEach(element => {
          this.addPalletContainer(element);
        });
      }
    }

    this.AddMatFormValidate()

    if (this.rowSelectedMaterial.SkuUpcEan === null || this.rowSelectedMaterial.SkuUpcEan === "") {
      this.HideGenerateBtn = true;
    } else {
      this.HideGenerateBtn = false;
    }

  }

  Received(event) {
    this.StatusName = event
  }

  changed() {
    if (this.UoMEnable === true) {
      this.SubUoMShow = true;
      this.BoxQtyInput = true
    }
    if (this.UoMEnable === false) {
      this.SubUoMShow = false;
      this.BoxQtyInput = false
      this.BoxQuantity = null
      this.SubUoM = "None"
    }
    if ((this.selectedSubUoM === "Nos" && this.UoMEnable === true) || (this.selectedSubUoM === "Pcs" && this.UoMEnable === true)) {
      // console.log("INGA THAN PAKANUM", this.selectedSubUoM)
      this.BoxQtyInput = true;
    }

  }

  selectedUoM(id) {
    if (id === "Box") {
      this.UoMSwitchBox = true;
      this.UoMEnable = false;
    }
    else if (id === "Nos" || id === "Pcs" || id === "Bag") {
      this.UoMSwitchBox = false;
      this.SubUoMShow = false;
      this.BoxQtyInput = false;
    }
  }

  SubUoMValue(id) {
    if (id === "Nos" || id === "Pcs") {
      this.selectedSubUoM = id
      console.log("Selected Sub UOM", this.selectedSubUoM)
      this.BoxQtyInput = true
    } else {
      this.BoxQtyInput = false;
    }

  }

  AddMatFormValidate() {
    if (this.rowSelectedMaterial.deliveryPreferences == 'fefo') {
      console.log(this.rowSelectedMaterial.deliveryPreferences, this.rowSelectedMaterial.deliveryPreferences == 'fefo')
      this.AddMat_formGroup = this.fb.group({
        SkuUpcEan: ['', Validators.required],
        description: ['', Validators.required],
        invoiceQty: ['', [Validators.required, Validators.min(1)]],
        unitOfMaterials: ['', Validators.required],
        quantity: ['', Validators.required],
        goodQuantity: ['', Validators.required],
        unitOfSubMaterials: [''],
        BoxQuantity: [''],
        damagedQuantity: [''],
        manufacturedDate: [''],
        batchNumber: [''],
        cartonCode: [''],
        brand: [''],
        switchBox: [''],
        dimension: [''],
        dimentionUnit: [''],
        weight: [''],
        remark: [''],
        palletNos: [''],
        palletLocation: ['']
      })
    }
    else {
      this.AddMat_formGroup = this.fb.group({
        SkuUpcEan: ['', Validators.required],
        description: ['', Validators.required],
        invoiceQty: ['', [Validators.required, Validators.min(1)]],
        unitOfMaterials: ['', Validators.required],
        quantity: ['', Validators.required],
        goodQuantity: ['', Validators.required],
        unitOfSubMaterials: [''],
        BoxQuantity: [''],
        damagedQuantity: [''],
        // manufacturedDate:['',Validators.required],
        // batchNumber:[''],
        // cartonCode:[''],
        brand: [''],
        switchBox: [''],
        dimension: [''],
        dimentionUnit: [''],
        weight: [''],
        remark: [''],
        palletNos: [''],
        palletLocation: ['']
      })
    }

  }
  get f() { return this.AddMat_formGroup.controls; }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(100),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  //---------------------------------ADD_MATERIAL_API---------------------------------------//
  cartoncode(event) {
    console.log(event.length);
    // let flag = false
    if (event.length > 0) {
      for (let i = 0; i < this.add_material.length; i++) {
        console.log(this.add_material[i].cartonCode == event)
        if (this.add_material[i].cartonCode == event) {
          this.failure = true
          this.success = false
          this.failmsg = event + ' is already selected'
          console.log(this.failmsg);

          break;
        } else {
          if (this.add_material.length - 1 == i) {
            const obj = {
              "warehouseId": localStorage.getItem('WarehouseID'),
              "cartonCode": event
            }
            this.ApiCall.CartonCodeValidate(obj).subscribe(res => {
              console.log(res)

              if (res['status'] == true) {
                this.success = true
                this.successmsg = res['result']
                this.failure = false
              }
              else {
                this.failure = true
                this.failmsg = res['result']
                this.success = false
              }
            })

          }
        }
      }

      // const obj={
      //     "warehouseId": localStorage.getItem('WarehouseID'),
      //     "cartonCode": event
      // }
      // this.ApiCall.CartonCodeValidate(obj).subscribe(res=>{
      //   console.log(res)

      //   if(res['status']==true){
      //     this.success=true
      //     this.successmsg= res['result']
      //     this.failure=false
      //   }
      //   else{
      //     this.failure=true
      //     this.failmsg=res['result']
      //     this.success=false
      //   }
      // })
      console.log(this.failure, this.failmsg, this.success, this.successmsg)
    }

  }

  AddMaterialsDetails() {
    this.submitted = true;

    if (this.AddMat_formGroup.invalid) {
      this.scrollToFirstInvalidControl()
      return;
    }
    if (this.failure == true) {
      const snack = this.snackBar.open('Please Enter Valid Carton Code', 'close', {
        duration: 2000,
      });
      return;
    }


    this.materialsInformation.SkuUpcEan = this.rowSelectedMaterial.SkuUpcEan
    // console.log("????????????????????????????/",this.materialsInformation.SkuUpcEan)
    this.materialsInformation.unitOfMaterials = this.rowSelectedMaterial.unitOfMaterials
    this.materialsInformation.quantity = this.rowSelectedMaterial.quantity
    this.materialsInformation.remark = this.Remark
    this.materialsInformation.brand = this.brand
    this.materialsInformation.dimension = this.dimension
    this.materialsInformation.dimensionUnit = this.Dimension
    this.materialsInformation.description = this.rowSelectedMaterial.description
    this.materialsInformation.productName = this.rowSelectedMaterial.description
    this.materialsInformation.boxPcsQuantity = this.BoxQuantity
    this.materialsInformation.unitOfBoxPices = this.SubUoM
    this.materialsInformation.stagingGoodQuantity = this.goodQuantity;
    this.materialsInformation.stagingDamagedQuantity = this.damagedQty;
    if (this.rowSelectedMaterial.materialId) {
      this.materialsInformation['materialId'] = this.rowSelectedMaterial.materialId
    }
    if (this.rowSelectedMaterial.deliveryPreferences == "fefo") {
      this.materialsInformation.deliveryPreferences = "fefo"
      console.log('fefo');

    }
    if (this.rowSelectedMaterial.deliveryPreferences == "fifo") {
      this.materialsInformation.deliveryPreferences = "fifo"
      console.log('fifo');

    }
    // this.materialsInformation.materialId= this.rowSelectedMaterial.materialId
    if (this.rowSelectedMaterial.deliveryPreferences == 'fefo') {
      this.materialsInformation.manufacturedDate = this.datePipe.transform(this.manufactureDate, "yyyy-MM-dd")
      this.materialsInformation.cartonCode = this.cartoonCode;
      this.materialsInformation.batchNumber = this.batchNo;
      this.materialsInformation.deliveryPreferences == 'fefo'
    }


    // if(res.customerId === this.SelectedCustomeID && res.id === 1002 && res.status === true){
    //   this.locationPage = true
    //   this.addMaterialPage = false
    // }

    if (this.materialsInformation.boxPcsQuantity === "" || this.UoMEnable === false || this.materialsInformation.boxPcsQuantity === null || this.materialsInformation.boxPcsQuantity === "null") {
      this.materialsInformation.boxPcsQuantity = null
      this.materialsInformation.unitOfBoxPices = "None"

    } else {
      this.materialsInformation.boxPcsQuantity = this.BoxQuantity
      this.materialsInformation.unitOfBoxPices = this.SubUoM
    }
    this.materialsInformation.weight = parseInt(this.Weight)
    this.materialsInformation.goodQuantity = this.goodQuantity
    console.log(this.damagedQty)
    this.materialsInformation.damagedQuantity = this.damagedQty

    // this.materialsInformation.pallets[0].location = this.PalletLocation
    // this.materialsInformation.pallets[0].number = this.PalletNo
    this.materialsInformation['invoiceQuantity'] = this.InvoiceQuantity
    // this.Addition = parseInt(this.materialsInformation.goodQuantity) + parseInt(this.materialsInformation.damagedQuantity)

    // if (this.selectedMaterial.unitOfBoxPices === "Box") {
    //   this.materialsInformation.boxPcsQuantity = this.selectedMaterial.boxPcsQuantity
    //   this.materialsInformation.unitOfBoxPices = this.selectedMaterial.unitOfBoxPices
    // } else {
    //   this.materialsInformation.unitOfBoxPices = "None"
    //   this.materialsInformation.boxPcsQuantity = null
    // }
    if (this.materialsInformation.goodQuantity === "0" && this.materialsInformation.damagedQuantity === "0") {
      const snack = this.snackBar.open('Please Enter Valid Quantity', 'close', {
        duration: 2000,
      });
    }
    // else if (this.LocationPallet === true) {
    //   this.locationPage = true
    //   this.addMaterialPage = false
    // } else {
    //   this.add_material.push(this.materialsInformation)
    //   if (lodash.isEqual(this.materialsInformation["dimensionUnit"], '')) delete this.materialsInformation["dimensionUnit"]

    //   if (lodash.isEqual(this.materialsInformation["dimension"], '')) delete this.materialsInformation["dimension"]

    //   localStorage.setItem("materialInfo", JSON.stringify(this.add_material))
    //   console.log("MATERIAL PUSHING", this.add_material)
    //   this.router.navigate(['/viewselectedreceipt'])
    // }
    else if (this.StatusName === 'Staging') {
      this.locationPage = true
      this.addMaterialPage = false
    }
    else {
      let BooleanCheck;
      this.passStatusValue.filter(res =>
        //  console.log("ID", res.id , "CusID", res.customerId, "Status", res.status)
        res.id === 1002
      ).map(
        res => {
          console.log("FOUND HERE", res.status, "CustomerID", res.customerId, "SELECTEDID", parseInt(this.SelectedCustomerID))
          if (res.customerId === parseInt(this.SelectedCustomerID))
            if (res.status === false) {
              BooleanCheck = false
              console.log("CAME", res)
              this.locationPage = true
              this.addMaterialPage = false
            } else {
              BooleanCheck = true
            }
        }
      )
      if (BooleanCheck === true) {
        if (this.materialsInformation.boxPcsQuantity === "" || this.UoMEnable === false || this.materialsInformation.boxPcsQuantity === null || this.materialsInformation.boxPcsQuantity === "null") {
          this.materialsInformation.boxPcsQuantity = null
          this.materialsInformation.unitOfBoxPices = "None"
        } else {
          this.materialsInformation.boxPcsQuantity = this.BoxQuantity
          this.materialsInformation.unitOfBoxPices = this.SubUoM
        }

        for (var i = 0; i < this.add_material.length; i++) {
          // if (this.add_material[i].description == this.materialsInformation.description && this.add_material[i].SkuUpcEan == this.materialsInformation.SkuUpcEan && this.add_material[i].manufacturedDate==this.materialsInformation.manufacturedDate) {
          if (i.toString() == this.rowIndex.toString()) {

            if (lodash.isEqual(this.materialsInformation["dimensionUnit"], '')) delete this.materialsInformation["dimensionUnit"]

            if (lodash.isEqual(this.materialsInformation["dimension"], '')) delete this.materialsInformation["dimension"]
            console.log(this.add_material[i], this.materialsInformation)
            console.log(this.add_material[i].manufacturedDate == this.materialsInformation.manufacturedDate)


            console.log('ALL PARAMS', this.add_material)
          }
          if (this.materialsInformation.deliveryPreferences == 'fefo') {
            if (this.add_material[i].description == this.materialsInformation.description
              && this.add_material[i].SkuUpcEan == this.materialsInformation.SkuUpcEan
              && this.add_material[i].manufacturedDate == this.materialsInformation.manufacturedDate
              && this.add_material[i].cartonCode == this.materialsInformation.cartonCode
              // && this.add_material[i].damagedQuantity==this.materialsInformation.stagingDamagedQuantity
              // && this.add_material[i].goodQuantity==this.materialsInformation.stagingGoodQuantity
              && this.materialsInformation.deliveryPreferences == 'fefo'
              && i.toString() != this.rowIndex.toString()) {
              const snack = this.snackBar.open('Cannot add duplicate material', 'close', {

              });
              console.log('everything is same')
              return;
            }
            else {
              if (i.toString() == this.rowIndex.toString()) {
                this.add_material[i] = this.materialsInformation
              }

            }
          }
          else {
            if (this.add_material[i].description == this.materialsInformation.description
              && this.add_material[i].SkuUpcEan == this.materialsInformation.SkuUpcEan
              && this.materialsInformation.deliveryPreferences == 'fifo'
              && i.toString() != this.rowIndex.toString()) {
              const snack = this.snackBar.open('Cannot add duplicate material', 'close', {

              });
              console.log('everything is same')
              return;
            }
            else {
              if (i.toString() == this.rowIndex.toString()) {
                this.add_material[i] = this.materialsInformation
              }

            }
          }
        }


        console.log(this.add_material, this.materialsInformation);

        // for (var i = 0; i < this.add_material.length; i++) {
        //   if (this.add_material[i].description == this.materialsInformation.description 
        //     && this.add_material[i].SkuUpcEan == this.materialsInformation.SkuUpcEan 
        //     && this.add_material[i].manufacturedDate==this.materialsInformation.manufacturedDate 
        //     && this.add_material[i].cartonCode==this.materialsInformation.cartonCode
        //     && this.add_material[i].stagingDamagedQuantity==this.materialsInformation.stagingDamagedQuantity
        //     && this.add_material[i].stagingGoodQuantity==this.materialsInformation.stagingGoodQuantity) {
        //     const snack = this.snackBar.open('Cannot add duplicate material', 'close', {
        //       duration: 2000,
        //     });
        //     console.log('everything is same')
        //     return;
        //   }
        // }
        localStorage.setItem("materialInfo", JSON.stringify(this.add_material))
        this.router.navigate(['/viewselectedreceipt'])

        console.log("How it is storing FORM", this.formLoction)
      }
    }
  }

  //-------------------------ADD PALLET LOCATION---------------------------------------//


  onMatRowDelete(createNewIndex, index) {
    console.log('userIndex', createNewIndex, '-------', 'index', index);
    (<FormArray>(<FormGroup>(<FormArray>this.formLoction.controls['createNewLoaction'])
      .controls[createNewIndex]).controls['pallets']).removeAt(index);
  }


  addPalletContainerss(createNewLoaction?: any) {
    var ContainerLength = this.formLoction.value.createNewLoaction.length

    let CountLocationQty = this.formLoction.value.createNewLoaction
    console.log("checking Total Qty", CountLocationQty)
    const CountAllLocationGoodQty = CountLocationQty.map(({ locationGoodQuantity, ...rest }) => ({ ...rest, locationGoodQuantity: +locationGoodQuantity }));

    const CountAllLocationDamagedQty = CountLocationQty.map(({ locationDamagedQuantity, ...rest }) => ({ ...rest, locationDamagedQuantity: +locationDamagedQuantity }));

    var result = [CountAllLocationGoodQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]

    var results = [CountAllLocationDamagedQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]

    this.TotalLocationGoodCount = result[0].locationGoodQuantity
    this.TotalLocationDamagedCount = results[0].locationDamagedQuantity

    console.log("COUNTING LA GUN", this.TotalLocationGoodCount, typeof (this.TotalLocationGoodCount), this.TotalLocationDamagedCount, typeof (this.TotalLocationDamagedCount))
    console.log("GOOD AND DAMAGED", this.goodQuantity, typeof (this.goodQuantity), this.damagedQty, typeof (this.damagedQty))

    for (var i = 0; i < this.formLoction.value.createNewLoaction.length; i++) {
      var locationLength = this.formLoction.value.createNewLoaction[i].location

      if (locationLength.length < 3) {
        Swal.fire({
          toast: true,
          title: 'Please enter atleast 3 characters',
          timer: 2000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }
    }

    if (this.formLoction.value.createNewLoaction[ContainerLength - 1].locationGoodQuantity === '' || this.formLoction.value.createNewLoaction[ContainerLength - 1].location === '' || this.formLoction.value.createNewLoaction[ContainerLength - 1].locationDamagedQuantity === '') {
      console.log('stoped')
      Swal.fire({
        toast: true,
        title: 'Please fill existing fields before creating new one !',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
    }
    else if (parseInt(this.goodQuantity) == this.TotalLocationGoodCount && this.damagedQty == this.TotalLocationDamagedCount) {
      console.log("COUNTING LA GUN", this.TotalLocationGoodCount, typeof (this.TotalLocationGoodCount), this.TotalLocationDamagedCount, typeof (this.TotalLocationDamagedCount))
      console.log("GOOD AND DAMAGED", this.goodQuantity, typeof (this.goodQuantity), this.damagedQty, typeof (this.damagedQty))
      Swal.fire({
        toast: true,
        title: 'Quantity is not enough!',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
      return
    }
    else {
      let fg = this.fb.group({
        'location': [createNewLoaction ? createNewLoaction.location : '', Validators.required],
        'locationGoodQuantity': [createNewLoaction ? createNewLoaction.locationGoodQuantity : '', Validators.required],
        'locationDamagedQuantity': [createNewLoaction ? createNewLoaction.locationDamagedQuantity : '', Validators.required],
        'pallets': this.fb.array([])

      });

      (<FormArray>this.formLoction.get('createNewLoaction')).push(fg);
      let userIndex = (<FormArray>this.formLoction.get('createNewLoaction')).length - 1;
      if (!createNewLoaction) {
        this.addPallets(userIndex);
      }
      else {
        createNewLoaction.number.forEach(phone => {
          this.addPallets(userIndex, phone);
        });
      }
    }
  }

  addPalletContainer(createNewLoaction?: any) {
    console.log("Create New Location", createNewLoaction)
    let fg = this.fb.group({
      'location': [createNewLoaction ? createNewLoaction.location : '', Validators.required],
      'locationGoodQuantity': [createNewLoaction ? createNewLoaction.locationGoodQuantity : '', Validators.required],
      'locationDamagedQuantity': [createNewLoaction ? createNewLoaction.locationDamagedQuantity : '', Validators.required],
      'pallets': this.fb.array([])
      // 'quantity':'',

    });
    (<FormArray>this.formLoction.get('createNewLoaction')).push(fg);

    let userIndex = (<FormArray>this.formLoction.get('createNewLoaction')).length - 1;
    if (!createNewLoaction) {
      console.log('COMING HERE')
      this.addPallets(userIndex);
    }
    else {
      console.log("Pallet details", createNewLoaction.pallets)
      if (createNewLoaction.number) {
        createNewLoaction.number.forEach(phone => {
          this.addPallets(userIndex, phone);
        });
      } else {
        createNewLoaction.pallets.forEach(phone => {
          this.addPallets(userIndex, phone);
        });
      }
    }
  }
  // ----------------------------FORM ARRAY FOR PALLET CONTAINER--------------------
  public palletsData = [
    {
      location: '',
      locationGoodQuantity: '',
      locationDamagedQuantity: '',
      number: [''],
      palletGoodQuantity: [''],
      palletDamagedQuantity: ['']
    },

  ];

  addPallets(userIndex: number, data?: any) {
    let fg = this.fb.group({
      'number': [data && data.number ? data.number : ''],
      'palletGoodQuantity': [data && data.palletGoodQuantity ? data.palletGoodQuantity : ''],
      'palletDamagedQuantity': [data && data.palletDamagedQuantity ? data.palletDamagedQuantity : ''],
    });
    (<FormArray>(<FormGroup>(<FormArray>this.formLoction.controls['createNewLoaction'])
      .controls[userIndex]).controls['pallets']).push(fg);
  }

  addPallet(userIndex: number, data?: any) {
    console.log("Look", userIndex)
    var LocationLength = this.formLoction.value.createNewLoaction.length
    var PalletLength = this.formLoction.value.createNewLoaction[userIndex].pallets.length;
    if (this.formLoction.value.createNewLoaction[userIndex].pallets[PalletLength - 1].number === '' || this.formLoction.value.createNewLoaction[userIndex].pallets[PalletLength - 1].palletGoodQuantity === '' || this.formLoction.value.createNewLoaction[userIndex].pallets[PalletLength - 1].palletDamagedQuantity === '') {
      Swal.fire({
        toast: true,
        title: 'Please fill existing fields before creating new one !',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
    } else {
      let fg = this.fb.group({
        'number': '',
        'palletGoodQuantity': '',
        'palletDamagedQuantity': ''
      });
      (<FormArray>(<FormGroup>(<FormArray>this.formLoction.controls['createNewLoaction'])
        .controls[userIndex]).controls['pallets']).push(fg);
      this.count = this.count + 1;
    }
  }

  DeleteContainer(index: number) {
    (<FormArray>this.formLoction.get('createNewLoaction')).removeAt(index);
  }

  ErrorValidation(index) {
    this.submitted = true;
    return (<FormArray>this.formLoction.get('createNewLoaction')).at(index);
  }
  goToReceipt() {

    if (this.materialsInformation.boxPcsQuantity === "" || this.UoMEnable === false || this.materialsInformation.boxPcsQuantity === null || this.materialsInformation.boxPcsQuantity === "null") {
      this.materialsInformation.boxPcsQuantity = null
      this.materialsInformation.unitOfBoxPices = "None"
    } else {
      this.materialsInformation.boxPcsQuantity = this.BoxQuantity
      this.materialsInformation.unitOfBoxPices = this.SubUoM
    }

    for (var i = 0; i < this.formLoction.value.createNewLoaction.length; i++) {
      let CountLocationQty = this.formLoction.value.createNewLoaction
      const CountAllLocationGoodQty = CountLocationQty.map(({ locationGoodQuantity, ...rest }) => ({ ...rest, locationGoodQuantity: +locationGoodQuantity }));

      const CountAllLocationDamagedQty = CountLocationQty.map(({ locationDamagedQuantity, ...rest }) => ({ ...rest, locationDamagedQuantity: +locationDamagedQuantity }));

      var result = [CountAllLocationGoodQty.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]

      var results = [CountAllLocationDamagedQty.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]

      this.TotalLocationGoodCount = parseInt(result[0].locationGoodQuantity)
      this.TotalLocationDamagedCount = results[0].locationDamagedQuantity

      // console.log("CHECKING TOTAL GOOD COUNT", this.TotalLocationGoodCount, typeof (this.TotalLocationGoodCount))
      // console.log("CHECKING TOTAL DAMAGED COUNT", this.TotalLocationDamagedCount, typeof (this.TotalLocationDamagedCount))

      // console.log("GOOD COUNT", this.goodQuantity, typeof (this.goodQuantity))
      // console.log("DAMAGED COUNT", this.damagedQty, typeof (this.damagedQty))

      let CountAllQty = this.formLoction.value.createNewLoaction[i].pallets

      const CountAllGoodQtyValue = CountAllQty.map(({ palletGoodQuantity, ...rest }) => ({ ...rest, palletGoodQuantity: +palletGoodQuantity }));

      const CountAllDamagedQtyValue = CountAllQty.map(({ palletDamagedQuantity, ...rest }) => ({ ...rest, palletDamagedQuantity: +palletDamagedQuantity }));

      var locationLength = this.formLoction.value.createNewLoaction[i].location

      if (locationLength.length < 3) {
        Swal.fire({
          toast: true,
          title: 'Please enter atleast 3 characters',
          timer: 2000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }

      if (this.formLoction.value.createNewLoaction[i].locationGoodQuantity === '' || this.formLoction.value.createNewLoaction[i].location === '' || this.formLoction.value.createNewLoaction[i].locationDamagedQuantity === '') {
        Swal.fire({
          toast: true,
          title: 'Please fill required  fields!',
          timer: 2000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }

      if (parseInt(this.goodQuantity) !== this.TotalLocationGoodCount || parseInt(this.damagedQty) !== this.TotalLocationDamagedCount) {
        Swal.fire({
          toast: true,
          title: 'Location Quantity is mismatching with Good and Damage Quantity!',
          timer: 3000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }

      var result = [CountAllGoodQtyValue.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]

      var results = [CountAllDamagedQtyValue.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]


      for (var k = 0; k < this.formLoction.value.createNewLoaction[i].pallets.length; k++) {
        if (this.formLoction.value.createNewLoaction[i].pallets[k].palletGoodQuantity !== "" || this.formLoction.value.createNewLoaction[i].pallets[k].palletDamagedQuantity !== "") {
          for (var a = 0; a < results.length; a++) {
            console.log("RESULT HERE CHECK", result)
            if (parseInt(this.formLoction.value.createNewLoaction[i].locationDamagedQuantity) !== results[a].palletDamagedQuantity) {
              Swal.fire({
                toast: true,
                title: 'Pallet Quantity is mismatching with Location Quantity!',
                timer: 3000,
                type: "warning",
                showConfirmButton: false
              })
              return
            }
          }
          for (var j = 0; j < result.length; j++) {
            console.log("RESULT HERE CHECK", result)
            if (parseInt(this.formLoction.value.createNewLoaction[i].locationGoodQuantity) !== result[j].palletGoodQuantity) {
              Swal.fire({
                toast: true,
                title: 'Pallet Quantity is mismatching with Location Quantity!',
                timer: 3000,
                type: "warning",
                showConfirmButton: false
              })
              return
            }
          }
        }
      }
      // console.log("3rd inga papom", result)
    }


    this.materialsInformation.materialLocation = this.formLoction.value.createNewLoaction
    console.log("PALLET LOCATION SENDING", this.materialsInformation.materialLocation)
    for (var i = 0; i < this.add_material.length; i++) {
      if (this.add_material[i].description == this.materialsInformation.description && this.add_material[i].SkuUpcEan == this.materialsInformation.SkuUpcEan && this.add_material[i].manufacturedDate == this.materialsInformation.manufacturedDate) {
        if (lodash.isEqual(this.materialsInformation["dimensionUnit"], '')) delete this.materialsInformation["dimensionUnit"]

        if (lodash.isEqual(this.materialsInformation["dimension"], '')) delete this.materialsInformation["dimension"]

        this.add_material[i] = this.materialsInformation
        console.log('ALL PARAMS', this.add_material)
      }


    }
    localStorage.setItem("materialInfo", JSON.stringify(this.add_material))
    this.router.navigate(['/viewselectedreceipt'])

    console.log("How it is storing FORM", this.formLoction)
  }


  GenerateCode() {
    this.GenerateProductCode.productName = this.rowSelectedMaterial.description
    // console.log("PARAMS FOR CODE", this.GenerateProductCode)
    this.ApiCall.getMaterialCode(this.GenerateProductCode)
      .subscribe(
        res => {
          this.rowSelectedMaterial.SkuUpcEan = res.result.productCode
          this.materialsInformation.SkuUpcEan = res.result.productCode
          localStorage.setItem('selectedProduct', JSON.stringify(this.rowSelectedMaterial));
          // console.log("GENERATE CODE", res)
          this.HideGenerateBtn = false;
        }
      )
    err => {
      console.log(err)
    }
  }

  BackToMaterial() {
    this.locationPage = false
    this.addMaterialPage = true
  }


  /*--------------------------CALCULATION-----------------------------------------*/


  _GoodQtykeyPress(event: any) {
    const pattern = /[0-9,+, ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
    this.damagedQty = this.InvoiceQuantity - this.goodQuantityLength
  }

  _goodQtykeyDown(event: any) {
    this.damagedQty = event
    var goodQty = parseInt(this.goodQuantityLength)
    this.damagedQty = parseInt(this.AddMat_formGroup.value.invoiceQty) - parseInt(this.AddMat_formGroup.value.goodQuantity)
    console.log("Total", this.AddMat_formGroup.value.invoiceQty)
  }

  /*------------------------------GOOD QUANTITY-----------------------*/

  checkLegthGoodQty(e, input) {
    this.keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValue) ? '' : this.keyValue.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.InvoiceQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

  /*------------------------------DAMAGED QUANTITY-----------------------*/
  checkLegthDamageQty(e, input) {
    this.keyValues = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValues) ? '' : this.keyValues.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.InvoiceQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

  /*------------------------------LOCATION GOOD QUANTITY-----------------------*/

  checkLegthLocationGoodQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.goodQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
    // this.valueOfLocationQty = this.goodQuantityLength
  }

  /*------------------------------LOCATION DAMAGED QUANTITY-----------------------*/

  checkLegthLocationDamagedQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.damagedQty || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
    // this.valueOfLocationQty = this.goodQuantityLength
  }

  /*------------------------------PALLET GOOD QUANTITY-----------------------*/

  checkLegthPalletGoodQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.goodQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

  /*------------------------------PALLET DAMAGED QUANTITY-----------------------*/

  checkLegthPalletDamagedQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.damagedQty || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

}
