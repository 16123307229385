import {
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatCardModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatTabsModule,
    MatRadioModule,
    MatGridListModule,
    MatExpansionModule,
    MatSliderModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatBadgeModule
  } from '@angular/material';
  import {NgModule} from '@angular/core';
  import {CdkTableModule} from '@angular/cdk/table';
  import {DragDropModule} from '@angular/cdk/drag-drop';
  @NgModule({
    imports: [
      MatAutocompleteModule,
      MatInputModule,
      MatSelectModule,
      MatMenuModule,
      MatSidenavModule,
      MatToolbarModule,
      MatListModule,
      MatButtonModule,
      MatIconModule,
      MatDialogModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatProgressBarModule,
      MatCheckboxModule,
      MatSlideToggleModule,
      MatCardModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatButtonToggleModule,
      MatChipsModule,
      MatTabsModule,
      MatRadioModule,
      MatGridListModule,
      MatExpansionModule,
      MatSliderModule,
      MatTableModule,
      CdkTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatStepperModule,
      MatProgressSpinnerModule,
      DragDropModule,
      MatBadgeModule
    ],
    exports: [
      MatAutocompleteModule,
      MatInputModule,
      MatSelectModule,
      MatMenuModule,
      MatSidenavModule,
      MatToolbarModule,
      MatListModule,
      MatButtonModule,
      MatIconModule,
      MatDialogModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatProgressBarModule,
      MatCheckboxModule,
      MatSlideToggleModule,
      MatCardModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatButtonToggleModule,
      MatChipsModule,
      MatTabsModule,
      MatRadioModule,
      MatGridListModule,
      MatExpansionModule,
      MatSliderModule,
      MatTableModule,
      CdkTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatStepperModule,
      MatProgressSpinnerModule,
      DragDropModule,
      MatBadgeModule
    ]
  })
  export class WareHouseModule {
  }