import { Component, OnInit, ViewEncapsulation, ViewChild, OnChanges, SimpleChanges, Input } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { Paginator } from 'primeng/paginator';
import { dashboardVarialbles, environment, myVariables } from 'src/environments/environment';
// import { PreviousRouteService } from '../previous-router.service'


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-completeddeliveries',
  templateUrl: './completeddeliveries.component.html',
  styleUrls: ['./completeddeliveries.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class CompleteddeliveriesComponent implements OnInit, OnChanges {
  @ViewChild('completeDel') private _table: Table;
  @ViewChild('calendar', undefined) private calendar: any;
  @ViewChild('pagin') paginator: Paginator;
  RequiredCompleteDelivery = {
    status: "Completed",
    warehouseId: localStorage.getItem('WarehouseID'),
    startDate: "",
    endDate: "",
    pageLimit: 10,
    pageNumber: 1,
    directChannelOrder: myVariables.directChannelOrder,
    customerId: null

  }
  bulkCompletemanifest = {
    warehouseId: localStorage.getItem('WarehouseID'),
    deliveryRequestIds: []
  };
  cols = [];
  deliveryData: any;
  complete_deliveries: any;
  documentView: any;
  completedDataSend: any;
  Loader = true;
  rangeDates: Date[];
  today: any;
  firstDate: any;
  control: FormControl;
  check = false
  pageNumber: any;
  totalRecords: number;

  OrderNumberDelivery: any;

  newCar: boolean;
  displayDialog: boolean;
  customerList = [];
  customerData: any;
  selectedCustomers: any;
  filter = "pi pi-filter";
  platformrequestvalue: { label: string; value: string; }[];
  rowcount: any;
  customers: any[];
  businessNameValue: {}[];
  totalrows: any;
  paginatorFirstValue: any = 1;
  row2: any;
  savedDates: any;
  sidebarVisible: boolean;
  sideBarDatas: any;
  sidebar: boolean;
  first: number;
  trackingDetails: { id: number; awbNumber: string; status: string; statusCode: string; statusDateTime: string; location: string; remarks: string; deliveryRequestId: number; createdAt: string; updatedAt: string; shippingOrderDetailId: any; }[];
  NDR: any;
  emptyTrackingDetails: boolean;
  shippingCustomerDetails: any;
  expectedDeliveryDate: any;
  constructor(private Apicall: ApiService, private router: Router, private datePipe: DatePipe) {

  }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage = 1,
      // myVariables.completedDeliveryRowsPerPage=1,
      myVariables.stagingReceiptRowsPerPage = 1,
      myVariables.StaockStatementRowsPerPage = 1
    myVariables.stagingReceiptPageNumber = 1,
      myVariables.completedReceiptPageNumber = 1,
      // myVariables.completedDeliveryPageNumber=1,
      myVariables.pendingDeliveriespageNumber = 1,
      myVariables.pendingDeliveriesRowsPerPage = 1,
      myVariables.pendingReceiptRowsPerPage = 1,
      myVariables.pendingReceiptPageNumber = 1,
      myVariables.grnPageNumber = 1,
      myVariables.grnRowsPerPage = 1,
      myVariables.returnGrnPageNumber = 1,
      myVariables.returnGrnRowsPerPage = 1,
      myVariables.dnRowsPerPage = 1,
      myVariables.dnPageNumber = 1,
      myVariables.shippingOrdersRowsPerPage = 1,
      myVariables.shippingOrdersPageNumber = 1,
      myVariables.StockStatementPageNumber = 1,

      sessionStorage.removeItem('compltdReceiptDate'),
      sessionStorage.removeItem('StagingReceiptDate'),
      sessionStorage.removeItem('PendingReceiptDate'),
      sessionStorage.removeItem('PendingDeliDate'),
      // sessionStorage.removeItem('compltdDeliDates'),
      sessionStorage.removeItem('shippingOrdersDate'),
      sessionStorage.removeItem('grnReportsDates'),
      sessionStorage.removeItem('ReturnGrnReportsDates'),
      sessionStorage.removeItem('dnReportsDate'),
      sessionStorage.removeItem('active')




    console.log(this.router.routerState.snapshot.url)

    console.log((this.paginatorFirstValue))

    // if(sessionStorage.getItem('paginatorFirstValue')){
    //   this.paginatorFirstValue=parseInt(sessionStorage.getItem('paginatorFirstValue'))
    //   this.RequiredCompleteDelivery.pageNumber=(parseInt(this.paginatorFirstValue)/this.RequiredCompleteDelivery.pageLimit)+1
    //   console.log(typeof(this.paginatorFirstValue))
    // }

    // console.log(typeof(this.paginatorFirstValue))

    localStorage.setItem('SelectedCustomerID', '')
    if (myVariables.completedDeliveryRowsPerPage != 1) {
      this.RequiredCompleteDelivery.pageLimit = myVariables.completedDeliveryRowsPerPage
    }
    if (myVariables.completedDeliveryPageNumber != 1) {
      this.RequiredCompleteDelivery.pageNumber = myVariables.completedDeliveryPageNumber
    }
    if (dashboardVarialbles.IsCompletedRoute) {
      this.today = new Date()
      this.rangeDates = [this.today, this.today]
    } else {
      if (sessionStorage.getItem('compltdDeliDates')) {
        this.savedDates = JSON.parse(sessionStorage.getItem('compltdDeliDates'))
        this.firstDate = new Date(this.savedDates.startDate)
        this.today = new Date(this.savedDates.endDate)
        this.rangeDates = [this.firstDate, this.today];
        this.RequiredCompleteDelivery.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
        this.RequiredCompleteDelivery.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')

      }
      else {
        this.today = new Date();
        this.firstDate = new Date();
        // this.firstDate.setMonth(this.today.getMonth());
        this.firstDate.setDate(this.firstDate.getDate() - 7);
        this.rangeDates = [this.firstDate, this.today];
        this.RequiredCompleteDelivery.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
        this.RequiredCompleteDelivery.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
      }

    }
    // this.today = new Date();
    // this.firstDate = new Date();
    this.businessNameValue = new Array()
    // this.firstDate.setMonth(this.today.getMonth());
    // this.rangeDates = [this.firstDate, this.today];
    // this.RequiredCompleteDelivery.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
    // this.RequiredCompleteDelivery.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')

    // localStorage.setItem('StartDateExcel', this.RequiredCompleteDelivery.startDate)
    // localStorage.setItem('EndDateExcel', this.RequiredCompleteDelivery.endDate)

    this.GetallPendingStatus();
    this.GetAllCustomer();
    this.getcustomers();
    // this.cols = [
    //   { field: 'id', header: 'Delivery Number' },
    //   { field: 'createdAt', header: 'Date' },
    //   { field: 'businessName', header: 'Customer' },
    //   { field: 'vehicleNo', header: 'Vehicle Number' },
    //   { field: 'gcnNumber', header: 'Delivery Note' },
    //   { field: 'ewayBillNo', header: 'E-Way Bill N0.' },
    //   { field: 'orderDocumentNumber', header: 'Order Number' },
    //   { field: 'courierMethodName', header: 'Shipping Mode' },
    //   { field: 'status', header: 'Status' }
    // ];
    this.cols = [
      { field: 'id', header: 'Delivery Number' },
      { field: 'orderDocumentNumber', header: 'Order Number' },
      { field: 'orderDocumentDate', header: 'Order Date' },
      { field: 'businessName', header: 'Customer' },
      // { field: 'gcnNumber', header: 'Delivery Note' },
      { field: 'wayBill', header: 'AWB Number' },
      { field: 'awbDate', header: 'AWB Date' },
      // { field: 'shippingMethod', header:'Payment Mode'},
      { field: 'courierMethodName', header: 'Shipping Mode' },
      { field: 'status', header: 'Status' }
    ]
    this.platformrequestvalue = [
      { label: 'None', value: '0' },
      { label: 'Platform', value: '1' },
      // { label: 'Shipment', value: '2' },
      { label: 'All', value: null }
    ];
    this.control = new FormControl();
    localStorage.setItem('OrderNumber', '')
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);

  }

  getColor(status) {
    switch (status) {
      case 'Completed':
        return '#58a846';
      case 'Cancelled':
        return 'red';
    }
  }

  sidebardata(rowData, i) {
    console.log(rowData)
    this.sideBarDatas = rowData
    const obj = {
      "orderNumber": rowData.orderDocumentNumber
    }
    localStorage.setItem('completed_delivery_id', rowData.id)

    this.Apicall.geTrackingDetails(obj).subscribe(res => {
      console.log(res);
      if (res['status']) {
        this.trackingDetails = res['trackOrder']
        this.NDR = res['NDR_count']
        this.Apicall.getViewCompletedDelivery().subscribe(res => {
          this.shippingCustomerDetails = res.result.shippingCustomer
          this.expectedDeliveryDate = res.result.deliveryDetails.expectedDeliveryDate
          console.log(res);
          this.sidebar = true
          this.sidebarVisible = true
        })
        if (this.trackingDetails.length == 0) {
          this.emptyTrackingDetails = true
        } else {
          this.emptyTrackingDetails = false
        }
      } else {

        this.sidebar = true
        this.sidebarVisible = true
        this.emptyTrackingDetails = true

      }

    })

  }
  cleartheObject() {
    console.log('i am cleared')
    this.trackingDetails = []
    this.shippingCustomerDetails = {}
  }

  GetallPendingStatus() {
    this.Loader = true;
    if (dashboardVarialbles.IsCompletedRoute) {
      this.RequiredCompleteDelivery.startDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
      this.RequiredCompleteDelivery.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
    }
    this.Apicall.getCOmpletedDeliveries(this.RequiredCompleteDelivery)
      .subscribe(
        res => {

          this.deliveryData = res.result;
          this.totalrows = this.deliveryData.length
          this.totalRecords = res.noOfPages * this.deliveryData.length
          console.log(this.totalRecords)
          console.log("Completed DELIVERY", this.deliveryData)
          this.totalrows = this.deliveryData.length

          console.log(this.totalrows);
          for (var i = 0; i < this.deliveryData.length; i++) {
            // this.completeData[i]['poNo'] = this.completeData[i].pickupInvoice[i].purchaseOrderNumber
            if (this.deliveryData[i]['courierMethod'] == 0) {
              this.deliveryData[i]['courierMethodName'] = 'None'
            }
            if (this.deliveryData[i]['courierMethod'] == 1) {
              this.deliveryData[i]['courierMethodName'] = 'Platform'
            }
            if (this.deliveryData[i]['courierMethod'] == 2) {
              this.deliveryData[i]['courierMethodName'] = 'Shipment'
            }
          }
          if (this.totalrows != 10 && this.totalrows != 25 && this.totalrows != 50 && this.totalrows != 75 && (this.row2)) {
            console.log(this.totalrows);

            console.log("not");
            this.totalrows = this.row2
            this.totalRecords = res.noOfPages * this.row2
          }
          else {
            console.log("yes");
            this.totalrows = myVariables.completedDeliveryRowsPerPage
            this.totalRecords = res.noOfPages * this.totalrows
            // console.log(this.totalRecords,this.totalrows);

          }
          this.first = (this.totalrows * this.RequiredCompleteDelivery.pageNumber) - 1
          console.log(this.first);

          if (this.deliveryData.length === 0) {
            this.check = true
          } else {
            this.check = false
          }
          // for (var i = 0; i < this.deliveryData.length; i++) {
          //   if(!this.deliveryData[i].deliveryInvoice.length){
          //     this.deliveryData[i]['documents'] = ''
          //   }
          //   else{
          //     this.deliveryData[i]['documents'] = this.deliveryData[i].deliveryInvoice[0].fileUrl;
          //     this.documentView = this.deliveryData[i]['documents']
          //   }
          // }
          this.Loader = false;
        }
      )
  }
  search(data) {
    if (data.length >= 3) {
      // this.RequiredCompleteDelivery.pageNumber=1
      // myVariables.completedDeliveryPageNumber=this.RequiredCompleteDelivery.pageNumber
      const obj = {

        "warehouseId": this.RequiredCompleteDelivery.warehouseId,      //(not mandatory)
        "status": this.RequiredCompleteDelivery.status,   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
        "searchval": data, 	//(mandatory and at least 3 characters)
        "customerId": this.RequiredCompleteDelivery.customerId 	//(not mandatory)

      }
      this.Apicall.searchDeliveryRequest(obj).subscribe(res => {
        this.deliveryData = res['result'];
        // this.totalrows=res['result'].length
        // this.totalRecords = 1*this.totalrows
        console.log(res)
        console.log("Pending Delivery ", this.deliveryData)
        for (var i = 0; i < this.deliveryData.length; i++) {
          // this.completeData[i]['poNo'] = this.completeData[i].pickupInvoice[i].purchaseOrderNumber
          if (this.deliveryData[i]['courierMethod'] == 0) {
            this.deliveryData[i]['courierMethodName'] = 'None'
          }
          if (this.deliveryData[i]['courierMethod'] == 1) {
            this.deliveryData[i]['courierMethodName'] = 'Platform'
          }
          if (this.deliveryData[i]['courierMethod'] == 2) {
            this.deliveryData[i]['courierMethodName'] = 'Shipment'
          }
        }
        if (this.totalrows != 10 && this.totalrows != 25 && this.totalrows != 50 && this.totalrows != 75 && (this.row2)) {
          console.log(this.totalrows);

          console.log("not");
          // this.totalrows=this.row2
          // this.totalRecords = 1 * this.row2
          this.totalRecords = data['totalPages'] * this.row2
        }
        else {
          console.log("yes");
          // this.totalrows=myVariables.completedDeliveryRowsPerPage
          // this.totalRecords = 1 * this.totalrows
          // this.totalrows=myVariables.completedDeliveryRowsPerPage
          this.totalRecords = data['totalPages'] * this.totalrows


        }
        this.first = (this.totalrows * this.RequiredCompleteDelivery.pageNumber) - 1
        if (this.deliveryData.length === 0) {
          this.check = true
          // console.log(this.check)
        } else {
          this.check = false
        }
      })
    }
    if (data.length == 0) {
      // this.RequiredCompleteDelivery.pageNumber=1
      // myVariables.completedDeliveryPageNumber=this.RequiredCompleteDelivery.pageNumber
      this.GetallPendingStatus();
    }

  }
  getcustomers() {
    this.Apicall.getDeliveryStatus().subscribe(res => {
      console.log(res.result)
      this.customers = res.result
      console.log("Customer data: ", this.customers)
      res.result.forEach(customer => {
        this.businessNameValue.push({ label: customer.businessName, value: customer.id })
      });
      console.log('Customers: ', this.businessNameValue)
    }
    )
  }
  bulkSelect(event) {

    if (event.checked == true) {
      console.log("everythink is selected", event)
      this.deliveryData.forEach(id => {

        if (id.courierMethod == 1) {
          this.bulkCompletemanifest.deliveryRequestIds.push(id.id)
        }

      })
      console.log(this.bulkCompletemanifest)


    }
    else {
      console.log("everythink is unselected", event)
      this.bulkCompletemanifest.deliveryRequestIds = []

    }
  }
  bulkCompleteManifest(rowData) {
    console.log('i am selected', rowData.data.id)
    this.bulkCompletemanifest.deliveryRequestIds.push(rowData.data.id)
    console.log(this.bulkCompletemanifest)
  }
  removeBulkGenerate(rowData) {

    console.log('i am unselected', rowData.data.id)
    console.log(this.bulkCompletemanifest.deliveryRequestIds)
    const i = this.bulkCompletemanifest.deliveryRequestIds.findIndex(object => { return object == rowData.data.id })
    this.bulkCompletemanifest.deliveryRequestIds.splice(i, 1)
    console.log(this.bulkCompletemanifest.deliveryRequestIds)
  }
  completeBulkManifest() {
    if (this.bulkCompletemanifest.deliveryRequestIds.length == 0) {
      Swal.fire({
        toast: true,
        title: "Please Select atleast one order",
        type: 'warning',
        showConfirmButton: true
      })
    }
    else {

      console.log('final payload', this.bulkCompletemanifest)
      this.Apicall.BulkcompleteManifest(this.bulkCompletemanifest).subscribe(res => {
        console.log('Bulk Gen Api Call', res)
        if (res['status'] == true) {
          Swal.fire({
            toast: true,
            title: "Bulk manifestation is successfull",
            type: 'success',
            showConfirmButton: true
          })
          this.ngOnInit();
        }
        else {
          Swal.fire({
            toast: true,
            title: res['error'],
            type: 'warning',
            showConfirmButton: true
          })
        }
      })
    }

  }

  customerFilter(event) {
    console.log(event['value'])
    this.RequiredCompleteDelivery.customerId = event['value']
    this.GetallPendingStatus();
  }

  platformrequestfilter(event) {
    console.log(event)
    if (event['value'] == '0') {
      this.RequiredCompleteDelivery.directChannelOrder = "0"
      this.RequiredCompleteDelivery.pageNumber = 1
      myVariables.completedDeliveryPageNumber = this.RequiredCompleteDelivery.pageNumber
    }
    else if (event['value'] == '1') {
      this.RequiredCompleteDelivery.directChannelOrder = "1"
      this.RequiredCompleteDelivery.pageNumber = 1
      myVariables.completedDeliveryPageNumber = this.RequiredCompleteDelivery.pageNumber
    }
    else if (event['value'] == '2') {
      this.RequiredCompleteDelivery.directChannelOrder = "2"
      this.RequiredCompleteDelivery.pageNumber = 1
      myVariables.completedDeliveryPageNumber = this.RequiredCompleteDelivery.pageNumber
    }
    else if (event['value'] == null) {
      this.RequiredCompleteDelivery.directChannelOrder = null
      this.RequiredCompleteDelivery.pageNumber = 1
      myVariables.completedDeliveryPageNumber = this.RequiredCompleteDelivery.pageNumber
    }
    myVariables.directChannelOrder = this.RequiredCompleteDelivery.directChannelOrder
    this.GetallPendingStatus();
  }

  paginate(event) {
    console.log(event)
    // if(this.RequiredCompleteDelivery.pageLimit!=event.rows){
    //   this.RequiredCompleteDelivery.pageLimit=event.rows
    //   this.RequiredCompleteDelivery.pageNumber=1
    //   this.paginator.changePage(0)
    //   console.log('i am running')
    // }

    // else{
    //   this.RequiredCompleteDelivery.pageNumber=event.page+1
    // this.paginatorFirstValue=event.first
    // console.log(this.paginatorFirstValue)
    // sessionStorage.setItem('paginatorFirstValue',this.paginatorFirstValue)

    // }
    // console.log(this.RequiredCompleteDelivery)
    // this.GetallPendingStatus();

    this.row2 = event.rows
    console.log(event.rows, this.totalrows);



    // this.RequiredDetails.pageLimit=event.rows
    // this.pageNumber = event.page + 1;
    // this.RequiredDetails.pageNumber = this.pageNumber
    console.log(event.rows, this.RequiredCompleteDelivery.pageLimit)

    if (this.RequiredCompleteDelivery.pageLimit == event.rows) {
      this.RequiredCompleteDelivery.pageNumber = event.page + 1
      myVariables.completedDeliveryPageNumber = this.RequiredCompleteDelivery.pageNumber


    } else if (event.rows == 10) {
      this.RequiredCompleteDelivery.pageLimit = 10
      this.RequiredCompleteDelivery.pageNumber = event.page
      myVariables.completedDeliveryRowsPerPage = this.RequiredCompleteDelivery.pageLimit


      this.paginator.changePage(0)

    }
    else if (event.rows == 25) {
      this.RequiredCompleteDelivery.pageLimit = 25
      this.RequiredCompleteDelivery.pageNumber = event.page
      myVariables.completedDeliveryRowsPerPage = this.RequiredCompleteDelivery.pageLimit



      this.paginator.changePage(0)

    } else if (event.rows == 50) {
      this.RequiredCompleteDelivery.pageLimit = 50
      this.RequiredCompleteDelivery.pageNumber = event.page
      myVariables.completedDeliveryRowsPerPage = this.RequiredCompleteDelivery.pageLimit

      this.paginator.changePage(0)

    }
    else if (event.rows == 75) {
      this.RequiredCompleteDelivery.pageLimit = 75
      this.RequiredCompleteDelivery.pageNumber = event.page
      myVariables.completedDeliveryRowsPerPage = this.RequiredCompleteDelivery.pageLimit

      this.paginator.changePage(0)

    }
    else {

      // this.RequiredCompleteDelivery.pageLimit=event.rows 
      this.RequiredCompleteDelivery.pageNumber = event.page + 1
      myVariables.completedDeliveryPageNumber = this.RequiredCompleteDelivery.pageNumber

      // this.first=event.page
      // console.log(event);
      // console.log(this.first);


      // this.paginator.changePage(0)

    }


    console.log(this.RequiredCompleteDelivery)
    this.GetallPendingStatus()



  }

  documentViewPage() {
    window.open(this.documentView, '_blank')
  }

  completedData(rowData) {
    this.completedDataSend = rowData;
    localStorage.setItem('completed_delivery_id', this.completedDataSend.id)
    this.router.navigate(['/Viewcompleteddelivery'])
  }

  onDateSelect() {
    var _self = this;
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;
      this.RequiredCompleteDelivery.startDate = this.formatDate(_self.rangeDates[0])
      this.RequiredCompleteDelivery.endDate = this.formatDate(_self.rangeDates[1])
      this.RequiredCompleteDelivery.pageNumber = 1
      myVariables.completedDeliveryPageNumber = this.RequiredCompleteDelivery.pageNumber
      this.paginator.changePage(0)
      const obj = {
        "startDate": _self.rangeDates[0],
        "endDate": _self.rangeDates[1]
      }
      sessionStorage.setItem('compltdDeliDates', JSON.stringify(obj))
      // localStorage.setItem('startDate', this.RequiredCompleteDelivery.startDate)
      // localStorage.setItem('endDate', this.RequiredCompleteDelivery.endDate)

      this.GetallPendingStatus();
    } else {
      e = s + 86400000;
    }
    if (this.rangeDates[1]) { // If second date is selected
      this.calendar.overlayVisible = false;
    }
  }
  defaultDate() {
    console.log('defaultdate');
    if (this.rangeDates[1] == null) {
      if (sessionStorage.getItem('compltdDeliDates')) {
        this.savedDates = (JSON.parse(sessionStorage.getItem('compltdDeliDates')))
        this.rangeDates = [new Date(this.savedDates.startDate), new Date(this.savedDates.endDate)]
      }
      else {
        this.rangeDates = [this.firstDate, this.today]
      }


    }

  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }

  downloadStmtDN() {
    this.Apicall.downloadReportDn(this.RequiredCompleteDelivery.startDate, this.RequiredCompleteDelivery.endDate)
      .subscribe({
        next: (response: any) => {
          let fileName = 'ConsolidateDN';
          const contentDisposition = response.headers.get('content-disposition');
          console.log('content', response.headers)
          console.log('content', response.headers.get('content-disposition'))
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          const fileContent = response.body;
          saveAs(fileContent, fileName);
        },
        error: (error) => {
          console.log({ error });
        }
      });
  }

  GetOrderDetails() {
    localStorage.setItem('OrderNumber', this.OrderNumberDelivery)

    if (this.OrderNumberDelivery === '' || this.OrderNumberDelivery === undefined) {
      Swal.fire({
        toast: true,
        title: 'Please Enter the Order Number !',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
    } else {
      this.router.navigate(['/Viewcompleteddelivery'])
    }
  }

  GetPartialFilter() {
    this.Apicall.getPartialDetails()
      .subscribe(
        res => {
          console.log("response", res)
        })
  }

  onRowSelect() {
    this.newCar = false;
    this.displayDialog = true;
  }

  GetAllCustomer() {
    this.Apicall.getDeliveryStatus()
      .subscribe(
        res => {
          console.log("Response", res.result)
          this.customerData = res.result;
          for (var i = 0; i < this.customerData.length; i++) {
            this.customerList.push(this.customerData[i])
          }
          // this.customerList = [{ fullname: "Sudhar", id: 1},
          // { fullname: "Kanna", id: 2},
          // { fullname: "Aswath", id: 3},
          // { fullname: "Anish", id: 4},
          // { fullname: "Selva", id: 5},
          // { fullname: "Gowtham", id: 6},]
          console.log("Result", this.customerList)
        }
      )
  }

  selectedCustomer() {
    localStorage.setItem('SelectedCustomerID', this.selectedCustomers.id)

    if (localStorage.getItem('SelectedCustomerID') !== '') {
      this.downloadStmtDN()
      this.displayDialog = false;
    } else {
      this.displayDialog = true;
      Swal.fire({
        toast: true,
        title: 'Please Select Customer !',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
    }
    console.log("SELECTED CUSTOMERS", this.selectedCustomers)
  }
}
