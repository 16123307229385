import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from  './api.service';
import { Router } from '@angular/router'
import { AuthService } from '../app/auth.service';
import Swal from 'sweetalert2';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })

export class LoginguardGuard implements CanActivate {

  refreshTokenParameter = {
    token: localStorage.getItem('Refresh_token')
  }

  helper = new JwtHelperService();
  decodedToken: any;

  constructor(
    private authguard: ApiService, private router: Router, private auth:AuthService,private loginService: ApiService ) { }
  
  canActivate(route:ActivatedRouteSnapshot,
    state:RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.authguard.getToken();
    const refreshToken = this.authguard.refreshTokenExpired();

//     if(refreshToken){
//       Swal.fire({
//         title:'<h3>Session Timeout !</h3>',
//         text:'Your session is expired due to inactivity. Please sign in again!',
//         showConfirmButton: false,
//         timer:3300
//       })
//       this.auth.logout()
//       this.router.navigate(['']);
//       return true;
//     }

//     if (token) {
//        this.loginService.getRefreshToken(this.refreshTokenParameter)
//                 .subscribe(res => {
//                   console.log("NEW TOKEN GENERATED", res)
//                   localStorage.setItem('session_access_token', res.token);
//                   this.decodedToken = this.helper.decodeToken(localStorage.getItem('session_access_token'));
//                   localStorage.setItem('CusID', this.decodedToken.customerId)
//                   localStorage.setItem('ID', this.decodedToken.id)
//                   localStorage.setItem('U_name', this.decodedToken.name)
//                   localStorage.setItem('U_email', this.decodedToken.email)
//                   // console.log(this.decodedToken)
//                 })
//       // return false;
//     }
//     return true;
//   }
// }


 if(refreshToken){
      Swal.fire({
        title:'<h3>Session Timeout !</h3>',
        text:'Your session is expired due to inactivity. Please sign in again!',
        showConfirmButton: false,
        timer:3300
      })
      this.auth.logout()
      this.router.navigate(['']);
      return false;
    }
    return true
  }
}