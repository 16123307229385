import { Component, OnInit } from '@angular/core';
import { dashboardVarialbles } from 'src/environments/environment';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {
  selectedDateRange: any;
  categories = [{ id: 0, value: "This Month" },
  { id: 1, value: "last month" },
  { id: 2, value: "" },
  ]
  dropdownArray = []
  newArray: any[];
  newArray1: any[];

  pendingArray: any[];
  stagingArray: any[];
  completedArray: any[];
  pendingArray1: any[];
  stagingArray1: any[];
  completedArray1: any[];
  title: string;
  todaysDate: string;
  today: any;
  firstDateOfThisMonth: string;
  lastDateOfThisMonth: string;
  firstDateOfLastMonth: string;
  lastDateOfLastMonth: string;
  firstDateOfSecondLastMonth: string;
  lastDateOfSecondLastMonth: string;
  firstDateOfThirdLastMonth: string;
  lastDateOfThirdLastMonth: string;
  daysInMonth: number;
  lastfifteenDays: string;
  lastthirtyDays: string;
  lastFourtyFiveDays: string;
  secondLstMonth: string;
  ThirdLstMonth: string;
  totalDaysInLastMonth: number;
  totalDaysInLastSecondMonth: number;
  totalDaysInLastThirdMonth: number;
  apiPostData: { startDate: any; endDate: any; warehouseId: string; customerId: string; };
  apiPostData1: { startDate: any; endDate: any; warehouseId: string; customerId: string; };

  obj: { warehouseId: string; customerId: string; startDate: string; endDate: string; };

  basicData: any;
  basicData1: any;

  StackedOptions: any;
  StackedOptions1: any;
  constructor(private ApiCall: ApiService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.dropdownArray = []
    this.newArray = [];
    this.newArray1 = [];

    this.pendingArray = [];
    this.stagingArray = [];
    this.completedArray = [];
    this.pendingArray1 = [];
    this.stagingArray1 = [];
    this.completedArray1 = [];
    this.title = "GFG";
    const now = moment();
    let todaysDates = new Date();

    // 1st
    this.todaysDate = moment(todaysDates).format('YYYY-MM-DD')
    this.today = moment(todaysDates).format('DD')
    this.firstDateOfThisMonth = moment(this.todaysDate).startOf('month').format('YYYY-MM-DD')
    // 1st end

    this.lastDateOfThisMonth = moment(this.todaysDate).endOf('month').format('YYYY-MM-DD')
    // 2nd 
    this.firstDateOfLastMonth = moment(this.todaysDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfLastMonth = moment(this.todaysDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    // 2nd end



    // 3rd
    this.firstDateOfSecondLastMonth = moment(this.todaysDate).subtract(2, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfSecondLastMonth = moment(this.todaysDate).subtract(2, 'months').endOf('month').format('YYYY-MM-DD')

    // 4th
    this.firstDateOfThirdLastMonth = moment(this.todaysDate).subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfThirdLastMonth = moment(this.todaysDate).subtract(3, 'months').endOf('month').format('YYYY-MM-DD')
    // 3rd end



    // Last 30 Days
    // this.lastfifteenDays = moment(todaysDates).format('YYYY-MM-DD').subtract(14, 'd');
    this.daysInMonth = 15
    this.lastfifteenDays = moment(this.todaysDate).subtract(15, 'days').format('YYYY-MM-DD')
    this.lastthirtyDays = moment(this.todaysDate).subtract(30, 'days').format('YYYY-MM-DD')


    this.lastFourtyFiveDays = moment(this.lastthirtyDays).subtract(15, 'days').format('YYYY-MM-DD')



    console.log("days are", this.lastfifteenDays, this.lastthirtyDays);


    // Last !5 Days end

    this.firstDateOfThisMonth = moment(this.todaysDate).startOf('month').format('YYYY-MM-DD')
    // 1st end


    let thisMonth = moment(this.firstDateOfThisMonth).format('MMM')
    let lastMonth = moment(this.firstDateOfLastMonth).format('MMM')
    this.secondLstMonth = moment(this.firstDateOfSecondLastMonth).format('MMM')
    this.ThirdLstMonth = moment(this.firstDateOfThirdLastMonth).format('MMM')
    let lastFourMonthArr = ['Last 15 Days']
    let daysObj = {
      'Last 15 Days': {
        startDate: this.lastfifteenDays,
        endDate: this.todaysDate

      }
    }
    if (this.today > 15) {

      lastFourMonthArr.push('01' + ' ' + thisMonth + "-" + 15 + ' ' + thisMonth)
      daysObj['01' + ' ' + thisMonth + "-" + 15 + ' ' + thisMonth] = {
        startDate: moment(this.firstDateOfThisMonth).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(this.firstDateOfThisMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
      }
    }

    console.log("==============================================>", lastMonth, this.secondLstMonth, this.ThirdLstMonth)

    this.totalDaysInLastMonth = moment(this.firstDateOfLastMonth, "YYYY-MM-DD").daysInMonth()

    lastFourMonthArr.push(16 + ' ' + lastMonth + "-" + this.totalDaysInLastMonth + ' ' + lastMonth)
    daysObj[16 + ' ' + lastMonth + "-" + this.totalDaysInLastMonth + ' ' + lastMonth] = {
      startDate: moment(this.firstDateOfLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfLastMonth).endOf('month').format('YYYY-MM-DD')
    }

    lastFourMonthArr.push('01' + ' ' + lastMonth + "-" + 15 + ' ' + lastMonth)
    daysObj['01' + ' ' + lastMonth + "-" + 15 + ' ' + lastMonth] = {
      startDate: moment(this.firstDateOfLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    this.totalDaysInLastSecondMonth = moment(this.firstDateOfSecondLastMonth, "YYYY-MM-DD").daysInMonth()
    lastFourMonthArr.push(16 + ' ' + this.secondLstMonth + "-" + this.totalDaysInLastSecondMonth + ' ' + this.secondLstMonth)
    daysObj[16 + ' ' + this.secondLstMonth + "-" + this.totalDaysInLastSecondMonth + ' ' + this.secondLstMonth] = {
      startDate: moment(this.firstDateOfSecondLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfSecondLastMonth).endOf('month').format('YYYY-MM-DD')
    }
    lastFourMonthArr.push('01' + ' ' + this.secondLstMonth + "-" + 15 + ' ' + this.secondLstMonth)
    daysObj['01' + ' ' + this.secondLstMonth + "-" + 15 + ' ' + this.secondLstMonth] = {
      startDate: moment(this.firstDateOfSecondLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfSecondLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    this.totalDaysInLastThirdMonth = moment(this.firstDateOfThirdLastMonth, "YYYY-MM-DD").daysInMonth()
    lastFourMonthArr.push(16 + ' ' + this.ThirdLstMonth + "-" + this.totalDaysInLastThirdMonth + ' ' + this.ThirdLstMonth)
    daysObj[16 + ' ' + this.ThirdLstMonth + "-" + this.totalDaysInLastThirdMonth + ' ' + this.ThirdLstMonth] = {
      startDate: moment(this.firstDateOfThirdLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfThirdLastMonth).endOf('month').format('YYYY-MM-DD')
    }
    lastFourMonthArr.push('01' + ' ' + this.ThirdLstMonth + "-" + 15 + ' ' + this.ThirdLstMonth)
    daysObj['01' + ' ' + this.ThirdLstMonth + "-" + 15 + ' ' + this.ThirdLstMonth] = {
      startDate: moment(this.firstDateOfThirdLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfThirdLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    this.categories[2].value = this.secondLstMonth



    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", lastFourMonthArr,
      daysObj)
    // push into dropdown
    lastFourMonthArr.forEach(element => {
      this.dropdownArray.push({ "id": lastFourMonthArr.indexOf(element), "value": element })
    })

    console.log("array dropdown issssssssssssssss    ", this.dropdownArray);

    // api call




    if (dashboardVarialbles.HistoryDateRange == 'Last 15 Days') {

      this.selectedDateRange = 'Last 15 Days'
      this.apiPostData = {
        "startDate": daysObj['Last 15 Days']['startDate'],
        "endDate": daysObj['Last 15 Days'].endDate,
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
    }
    else {
      this.selectedDateRange = dashboardVarialbles.HistoryDateRange
      console.log(this.selectedDateRange, daysObj);

      this.apiPostData = {
        "startDate": daysObj[dashboardVarialbles.HistoryDateRange].startDate,
        "endDate": daysObj[dashboardVarialbles.HistoryDateRange].endDate,
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
    }



    //  dropdown array data

    console.log(dashboardVarialbles.HistoryDateRange);


    this.ApiCall.receiptHistory(this.apiPostData).subscribe(res => {
      console.log(this.apiPostData);

      this.newArray = []

      console.log('receipthistory', res);

      res['result'].forEach(element => {


        // this.datePipe.transform(this.data[i].date, 'MMM dd')
        this.newArray.push(this.datePipe.transform(element.date, 'MMM dd'))

        this.pendingArray.push(element.Pending)
        this.completedArray.push(element.Confirmed)
        this.stagingArray.push(element.Staging)




      });
      console.log(this.newArray);


      // charts data start

      this.basicData = {
        labels: this.newArray,

        datasets: [
          {
            label: "Pending",
            backgroundColor: "#6CA6C1",
            // barPercentage: 0.5,
            barThickness: 12,
            maxBarThickness: 12,
            data: this.pendingArray
            // data: [66, 49, 81, 71, 26, 65, 60, 72, 66, 49, 81, 71, 26, 65, 60, 66, 49, 81, 71, 26, 65, 60, 72, 66, 49, 81, 71, 26, 65, 60, 67, 54, 72]
          },
          {
            label: "Staging",
            backgroundColor: "#F9DF62",
            barPercentage: 0.5,
            barThickness: 12,
            maxBarThickness: 12,
            data: this.stagingArray

            // data: [56, 69, 89, 61, 36, 75, 50, 56, 69, 89, 61, 36, 75, 50, 23, 6, 69, 89, 61, 36, 75, 50, 56, 69, 89, 61, 36, 75, 50, 23, 25, 56, 78]
          },
          {
            label: "Completed",
            backgroundColor: "#72C238",
            barThickness: 12,
            maxBarThickness: 12,
            data: this.completedArray

            // data: [52, 59, 99, 71, 46, 85, 30, 52, 59, 99, 71, 46, 85, 30, 46]
          }
        ]
      };

      this.StackedOptions = {
        legend: {
          display: false
        },


        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          },
          barWidth: {
            ratio: 0.5 // Adjust the bar width
          }
        },
        layout: {
          padding: {
            left: 10,
            right: 10
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false,

            },
            ticks: {
              color: "#black",
              fontSize: 7


            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: '#F6F6F6',
              borderDash: [12, 5]

            },
            stacked: true,

            ticks: {
              color: "#black"
            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }]
        },

      };

    })




    // 2nd graph means deliivery history chart

    if (dashboardVarialbles.HistoryDateRange == 'Last 15 Days') {

      this.selectedDateRange = 'Last 15 Days'
      this.apiPostData1 = {
        "startDate": daysObj['Last 15 Days']['startDate'],
        "endDate": daysObj['Last 15 Days'].endDate,
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
    }
    else {
      this.selectedDateRange = dashboardVarialbles.HistoryDateRange
      this.apiPostData1 = {
        "startDate": daysObj[dashboardVarialbles.HistoryDateRange]['startDate'],
        "endDate": daysObj[dashboardVarialbles.HistoryDateRange]['endDate'],
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
    }


    this.ApiCall.deliveryHistory(this.apiPostData1).subscribe(res1 => {

      console.log('Deliveryhistory', res1['result']);
      this.newArray1 = []

      res1['result'].forEach(element => {


        // this.datePipe.transform(this.data[i].date, 'MMM dd')
        this.newArray1.push(this.datePipe.transform(element.date, 'MMM dd'))

        this.pendingArray1.push(element.Pending)
        this.completedArray1.push(element.Completed)
        this.stagingArray1.push(element.Staging)




      });
      console.log(this.newArray1);

      // charts data start
      this.basicData1 = {
        labels: this.newArray1,
        datasets: [
          {
            label: "Pending",
            backgroundColor: "#6CA6C1",
            // barPercentage: 0.5,
            barThickness: 12,
            maxBarThickness: 12,
            borderRadius: 5,

            data: this.pendingArray1
          },
          {
            label: "Staging",
            backgroundColor: "#F9DF62",
            // barPercentage: 0.5,
            barThickness: 12,
            maxBarThickness: 12,

            data: this.stagingArray1
          },
          {
            label: "Completed",
            backgroundColor: "#72C238",
            barThickness: 12,
            maxBarThickness: 12,
            borderRadius: 45,

            data: this.completedArray1
          }
        ]
      };

      this.StackedOptions1 = {
        legend: {
          display: false
        },

        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          },
          barWidth: {
            ratio: 0.5 // Adjust the bar width
          }
        },
        layout: {
          padding: {
            left: 10,
            right: 10
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false,

            },
            ticks: {
              color: "#black",
              fontSize: 7


            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: '#F6F6F6',
              borderDash: [12, 5]

            },

            stacked: true,

            ticks: {
              color: "#black"
            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }]
        },

      };
    })

  }

  ondataclick(data) {
    console.log(data.target.value)
    dashboardVarialbles.HistoryDateRange = data.target.value;

    this.ngOnInit();
  }
}
