import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/api';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import lodash from 'lodash';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";

interface UoM {
  id: string;
  Value: string;
}

interface Dimension {
  id: string;
  Value: string;
}

@Component({
  selector: 'app-new-add-material',
  templateUrl: './new-add-material.component.html',
  styleUrls: ['./new-add-material.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class NewAddMaterialComponent implements OnInit {
  submitted = false;
  new_AddMat_formGroup: FormGroup;
  palletContainerForm: FormGroup;
  new_formLoction: FormGroup;

  PalletLocationRow = [
    {
      PalletNumber: "",
      palletQuantity: ""
    }
  ];
  locationPage = false
  addMaterialPage = true
  LocationPallet = false;
  UoMEnable = false;
  Container = [];
  selected = new FormControl(0);

  UnitMaterial: UoM[] = [
    // { id: '1', Value: 'UoM' },
    { id: '1', Value: 'Box' },
    { id: '2', Value: 'Nos' },
    { id: '3', Value: 'Pcs' },
    { id: '4', Value: 'Bag' },
    // { id: '6', Value: 'Loose' }
  ];

  UnitSubMaterial: UoM[] = [
    { id: '1', Value: 'Nos' },
    { id: '2', Value: 'Pcs' },
  ];

  DimensionUnit: Dimension[] = [
    { id: '1', Value: 'MM' },
    { id: '2', Value: 'Inches' },
    { id: '3', Value: 'Feet' },
    { id: '4', Value: 'Centimeter' }
  ];

  materialsInformation = {
    "SkuUpcEan": "",
    "brand": "",
    "damagedQuantity": "",
    "description": "",
    "dimension": "",
    "dimensionUnit": "",
    "goodQuantity": "",
    "quantity": "",
    "media": [],
    "deliveryPreferences":"",
    "materialLocation": [],
    "productName": "",
    "remark": "",
    "unitOfMaterials": "",
    "boxPcsQuantity": null,
    "unitOfBoxPices": "None",
    "weight": 0
  }

  GenerateProductCode =
    {
      "productName": ""
    }

  SkuUpcEan: any;
  UnitOfMaterial: any;
  quantity: any;
  brand: any;
  description: any;
  new_add_material = [];
  GoodQuantity: any;
  Remark: any;
  dimension: any;
  Weight: any;
  rowSelectedMaterial: any;
  goodQuantity: any;
  damagedQty: any;
  invoiceQty: any;

  orderItems = new FormArray([]);
  filteredOptions: Observable<string[]>;
  name: FormControl;


  RequiredAutoSuggest = {
    SkuUpcEan: "",
    customerId: localStorage.getItem('ADD_CusID'),
    warehouseId: localStorage.getItem('WarehouseID')
  }
  AutoSuggest = [];
  autoFill = [];
  Dimension: any;

  HideGenerateBtn = true;
  PalletLocation: any;
  PalletNo: any;
  keyValue: number;
  InvoiceQuantity: any;
  goodQuantityLength: any;
  SubUoMShow = false;
  UoMSwitchBox = false;
  BoxQtyInput = false;
  BoxCount: any;
  BoxQuantity: any;
  UnitOfSubMaterial: any;
  selectedSubUoM: any;
  count = 0;
  keyValues: number;
  keyValuess: number;
  TotalLocationGoodCount: number;
  TotalLocationDamagedCount: number;
  rowPoNumber = [];

  ProductSearch = {
    "searchval": "",
    "customerId": localStorage.getItem('ADD_CusID')
  }
  deliveryPreferences: any;

  constructor(private fb: FormBuilder, private messageService: MessageService, private router: Router, private ApiCall: ApiService, private snackBar: MatSnackBar,  private el: ElementRef) {
    this.name = new FormControl();
    this.filteredOptions = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );
    
      this.new_formLoction = fb.group({
        'createNewLoaction': fb.array([])
      });
    

  }

  ngOnInit() {
    this.new_add_material = JSON.parse(localStorage.getItem("materialInfo"))
    console.log("EXISTING MATERIAL", this.new_add_material)
    this.AddMatFormValidate()
    // this.AutoFillingField();
    // this.initForm();
    if (this.palletsData) {
      this.palletsData.forEach(createNewLoaction => {
        this.addPalletContainer(createNewLoaction);
      });
    } else {
      this.addPalletContainer();
    }
  }

  changed() {
    if (this.UoMEnable === true) {
      this.SubUoMShow = true;
    }
    if (this.UoMEnable === false) {
      this.SubUoMShow = false;
      this.BoxQtyInput = false;
      this.BoxQuantity = null
      this.UnitOfSubMaterial = "None"
    }
    if ((this.selectedSubUoM === "Nos" && this.UoMEnable === true) || (this.selectedSubUoM === "Pcs" && this.UoMEnable === true)) {
      // console.log("INGA THAN PAKANUM", this.selectedSubUoM)
      this.BoxQtyInput = true;
    }

  }

  selectedUoM(id) {
    if (id === "Box") {
      this.UoMSwitchBox = true;
      this.UoMEnable = false;
    }
    else if (id === "Nos" || id === "Pcs" || id === "Bag") {
      this.UoMSwitchBox = false;
      this.SubUoMShow = false;
      this.BoxQtyInput = false;
    }
  }

  SubUoMValue(id) {
    if (id === "Nos" || id === "Pcs") {
      this.selectedSubUoM = id
      console.log("Selected Sub UOM", this.selectedSubUoM)
      this.BoxQtyInput = true
    } else {
      this.BoxQtyInput = false;
    }

  }

  // initForm() {
  //   this.filteredOptions = this.name.valueChanges
  //     .pipe(
  //       startWith(''),
  //       map(value => this.filters(value.toString()))
  //     );

  // }


  // filter(val: string, key: string): string[] {
  //   const filterValue = val.toLowerCase();
  //   if (val === '' || val === null) {
  //     return []
  //   }
  //   else if (val.toLowerCase() && key === 'I') {
  //     return this.AutoSuggest.filter(option => option.productName.toLowerCase().includes(filterValue));
  //   }
  // }

  selectedValue(id) {
    // this.AutoFillingField()
    // console.log("SELECTED ID CUSTOMER",id)
    for (var i = 0; i < this.autoFill.length; i++) {
      if (this.autoFill[i].productName == id) {
        this.description = this.autoFill[i].SkuUpcEan
        this.HideGenerateBtn = false;
        // console.log("INDIVIDUAL DATA",this.autoFill[i])
      }
    }
  }

  onKeydownFunc(_event: any, matIndex:any) {
    this.rowPoNumber.splice(matIndex, 1);
    this.materialsInformation.description = ''
    this.HideGenerateBtn = true;
  };

  // AutoFillingField() {
  //   this.ApiCall.getSkuMaterial(this.RequiredAutoSuggest)
  //     .subscribe(
  //       res => {
  //         this.AutoSuggest = res.result
  //         this.autoFill = res.result
  //         // console.log("AUTO SUGGEST DATA's ", this.AutoSuggest)
  //       }
  //     )
  // }


  /*-----------------------AUTO SUGGEST NEW MATERIAL--------------------------*/

  filters(val: string) {
    if (this.AutoSuggest === undefined || this.AutoSuggest === []) {
      return
    }
    return this.AutoSuggest.filter(option =>
      option.productName.toLowerCase().includes(val.toLowerCase()));
  }

  setInputField(data) {
    this.ProductSearch.searchval = data
    this.filteredOptions = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoSuggest.slice()))
      );

    if (data.length > 2) {
      this.ProductSearching()

    } else {
      // console.log("please enter minimum 3 characters")
      this.AutoSuggest = []
    }
  }

  ProductSearching() {
    this.ApiCall.getProductName(this.ProductSearch)
      .subscribe(
        res => {
          this.AutoSuggest = res.result
          this.filteredOptions = this.name.valueChanges
            .pipe(
              startWith(''),
              map(val => this.filters(val))
              // map(val => (val ? this.filters(val) : this.AutoSuggest.slice()))
            );
          if (res.result === 'No data Found') {
            this.AutoSuggest = []
          }
        }
      )
  } 



  // selectedName(id) {
  //   this.AutoFillingField()
  //   // console.log("SELECTED ID CUSTOMER",id)
  //   for (var i = 0; i < this.autoFill.length; i++) {
  //     if (this.autoFill[i].name == id) {
  //       this.materialsInformation.customerDetails.mobileNo = this.autoFill[i].mobileNo
  //       this.materialsInformation.customerDetails.pincode = this.autoFill[i].pincode
  //       this.materialsInformation.customerDetails.address = this.autoFill[i].address
  //       // console.log("INDIVIDUAL DATA",this.autoFill[i])
  //     }
  //   }
  // }

  // onKeydownFunc(event: any) {
  //   this.materialsInformation.customerDetails.pincode = ''
  //   this.materialsInformation.customerDetails.address = ''
  //   this.materialsInformation.customerDetails.mobileNo = ''
  //   // console.log("KEY DOWN FUNC",event);
  // };

  AddMatFormValidate() {
    this.new_AddMat_formGroup = this.fb.group({
      materialCode: ['', Validators.required],
      materialName: ['', Validators.required],
      invoiceQty: [''],
      unitOfMaterials: ['', Validators.required],
      quantity: ['', [Validators.required, Validators.min(1)]],
      goodQuantity: ['', Validators.required],
      damagedQuantity: ['', Validators.required],
      unitOfSubMaterials: [''],
      BoxQuantity: [''],
      switchBox: [''],
      brand: [''],
      dimension: [''],
      dimentionUnit: [''],
      weight: [''],
      remark: [''],
      palletNos: [''],
      palletLocation: ['']
    })

    this.new_AddMat_formGroup.get('unitOfMaterials').valueChanges
      .subscribe(value => {
        if (value === "Box") {
          // console.log("DHOOO PARRAHHHH!!!", value)
          this.new_AddMat_formGroup.get('unitOfSubMaterials').setValidators(Validators.required)
          this.new_AddMat_formGroup.get('BoxQuantity').setValidators(Validators.required)
        } else {
          // console.log("DHOOO!!!", value)
          this.new_AddMat_formGroup.get('unitOfSubMaterials').clearValidators();
          this.new_AddMat_formGroup.get('BoxQuantity').clearValidators();
        }
      }
      );
  }


  conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!condition()) {
        return null;
      }
      return validator(control);
    }
  }
  get f() { return this.new_AddMat_formGroup.controls; }


  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".ng-invalid"
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

    fromEvent(window, "scroll")
      .pipe(
        debounceTime(200),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
  selectedSupplier(data){
    console.log(data)
    this.ProductSearch.searchval=data.productName
    this.SkuUpcEan=data.productCode
    this.deliveryPreferences=data.deliveryPreferences
  }
  AddMaterialsDetails() {
    this.submitted = true;
    if (this.new_AddMat_formGroup.invalid) {
      this.scrollToFirstInvalidControl()
      return;
    }
    
    this.materialsInformation.SkuUpcEan = this.SkuUpcEan
    this.materialsInformation.description =this.ProductSearch.searchval
    this.materialsInformation.productName =this.ProductSearch.searchval
    this.materialsInformation.unitOfMaterials = this.UnitOfMaterial
    this.materialsInformation.quantity = this.quantity
    this.materialsInformation.remark = this.Remark
    this.materialsInformation.brand = this.brand
    this.materialsInformation.dimension = this.dimension
    this.materialsInformation.dimensionUnit = this.Dimension
    this.materialsInformation.weight = parseInt(this.Weight)
    this.materialsInformation.goodQuantity = this.goodQuantity
    this.materialsInformation.damagedQuantity = this.damagedQty
    this.materialsInformation.unitOfBoxPices = this.UnitOfSubMaterial
    this.materialsInformation.boxPcsQuantity = this.BoxQuantity
    this.materialsInformation.deliveryPreferences=this.deliveryPreferences
    this.materialsInformation['invoiceQuantity'] = this.invoiceQty

      for(var i=0; i< this.new_add_material.length; i++){
        console.log("EXISTING FORM", typeof(this.new_add_material[i].SkuUpcEan), "FORM GROUP", typeof(this.description))
        if(this.new_add_material[i].SkuUpcEan !== this.description){
          
        }else{
          Swal.fire({
            toast: true,
            title: 'This Material already exist, Please select another Material',
            timer: 2000,
            type: "warning",
            showConfirmButton: false
          })
          return
        }
      }


    if (this.UoMEnable === true) {
      if (this.UnitOfSubMaterial === undefined || this.UnitOfSubMaterial === null || this.BoxQuantity === undefined) {
        const snack = this.snackBar.open('Please Fill Required Fields', 'close', {
          duration: 2000,
        });
        return
      }
    }

    if (this.materialsInformation.goodQuantity === "0" && this.materialsInformation.damagedQuantity === "0") {
      const snack = this.snackBar.open('Please Enter Valid Quantity', 'close', {
        duration: 2000,
      });
    } 
    // else if (invoiceQtty !== Addition) {
    //   const snack = this.snackBar.open('Quantity Mismatch ! Please Enter Valid Quantity', 'close', {
    //     duration: 2000,
    //   });
    // } 
    else if (this.LocationPallet === true) {
      this.locationPage = true
      this.addMaterialPage = false
    } else {
      console.log("MATERIAL PUSHING", this.materialsInformation)
      this.new_add_material.push(this.materialsInformation)
      if (lodash.isEqual(this.materialsInformation["dimensionUnit"], '')) delete this.materialsInformation["dimensionUnit"]

      if (lodash.isEqual(this.materialsInformation["dimension"], '')) delete this.materialsInformation["dimension"]

      localStorage.setItem("materialInfo", JSON.stringify(this.new_add_material))
      this.router.navigate(['/viewselectedreceipt'])
    }

  }

  goToReceipt() {
    for (var i = 0; i < this.new_formLoction.value.createNewLoaction.length; i++) {
      let CountLocationQty = this.new_formLoction.value.createNewLoaction
      console.log("checking Total Qty", CountLocationQty)
      const CountAllLocationGoodQty = CountLocationQty.map(({ locationGoodQuantity, ...rest }) => ({ ...rest, locationGoodQuantity: +locationGoodQuantity }));

      const CountAllLocationDamagedQty = CountLocationQty.map(({ locationDamagedQuantity, ...rest }) => ({ ...rest, locationDamagedQuantity: +locationDamagedQuantity }));

      var result = [CountAllLocationGoodQty.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]

      var results = [CountAllLocationDamagedQty.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]

      this.TotalLocationGoodCount = result[0].locationGoodQuantity
      this.TotalLocationDamagedCount = results[0].locationDamagedQuantity

      if (this.new_formLoction.value.createNewLoaction[i].locationGoodQuantity === '' || this.new_formLoction.value.createNewLoaction[i].location === '' || this.new_formLoction.value.createNewLoaction[i].locationDamagedQuantity === '') {
        Swal.fire({
          toast: true,
          title: 'Please fill required  fields!',
          timer: 2000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }

      console.log("TYPE CHCKING", typeof (this.invoiceQty))
      if (parseInt(this.goodQuantity) !== this.TotalLocationGoodCount || parseInt(this.damagedQty) !== this.TotalLocationDamagedCount) {
        Swal.fire({
          toast: true,
          title: 'Location Quantity is mismatching with Good and Damage Quantity!',
          timer: 3000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }

      let CountAllQty = this.new_formLoction.value.createNewLoaction[i].pallets

      const CountAllGoodQtyValue = CountAllQty.map(({ palletGoodQuantity, ...rest }) => ({ ...rest, palletGoodQuantity: +palletGoodQuantity }));

      const CountAllDamagedQtyValue = CountAllQty.map(({ palletDamagedQuantity, ...rest }) => ({ ...rest, palletDamagedQuantity: +palletDamagedQuantity }));

      var result = [CountAllGoodQtyValue.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]

      var results = [CountAllDamagedQtyValue.reduce((acc, n) => {
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})]

      for (var k = 0; k < this.new_formLoction.value.createNewLoaction[i].pallets.length; k++) {
        if (this.new_formLoction.value.createNewLoaction[i].pallets[k].palletGoodQuantity !== "" || this.new_formLoction.value.createNewLoaction[i].pallets[k].palletDamagedQuantity !== "") {
          for (var a = 0; a < results.length; a++) {
            console.log("RESULT HERE CHECK", result)
            if (parseInt(this.new_formLoction.value.createNewLoaction[i].locationDamagedQuantity) !== results[a].palletDamagedQuantity) {
              Swal.fire({
                toast: true,
                title: 'Pallet Quantity is mismatching with Location Quantity!',
                timer: 3000,
                type: "warning",
                showConfirmButton: false
              })
              return
            }
        }
          for (var j = 0; j < result.length; j++) {
              console.log("RESULT HERE CHECK", result)
              if (parseInt(this.new_formLoction.value.createNewLoaction[i].locationGoodQuantity) !== result[j].palletGoodQuantity) {
                Swal.fire({
                  toast: true,
                  title: 'Pallet Quantity is mismatching with Location Quantity!',
                  timer: 3000,
                  type: "warning",
                  showConfirmButton: false
                })
                return
              }
          }
        }
      }

    }

    this.materialsInformation.materialLocation = this.new_formLoction.value.createNewLoaction
    // console.log("MATERIAL PUSHING", this.materialsInformation)
    this.new_add_material.push(this.materialsInformation)
    if (lodash.isEqual(this.materialsInformation["dimensionUnit"], '')) delete this.materialsInformation["dimensionUnit"]

    if (lodash.isEqual(this.materialsInformation["dimension"], '')) delete this.materialsInformation["dimension"]

    localStorage.setItem("materialInfo", JSON.stringify(this.new_add_material))
    this.router.navigate(['/viewselectedreceipt'])
  }

  //-------------------------ADD PALLET LOCATION---------------------------------------//


  onMatRowDelete(createNewIndex, index) {
    (<FormArray>(<FormGroup>(<FormArray>this.new_formLoction.controls['createNewLoaction'])
      .controls[createNewIndex]).controls['pallets']).removeAt(index);
  }

  addPalletContainerss(createNewLoaction?: any) {
    var ContainerLength = this.new_formLoction.value.createNewLoaction.length

    let CountLocationQty = this.new_formLoction.value.createNewLoaction
    console.log("checking Total Qty", CountLocationQty)
    const CountAllLocationGoodQty = CountLocationQty.map(({ locationGoodQuantity, ...rest }) => ({ ...rest, locationGoodQuantity: +locationGoodQuantity }));

    const CountAllLocationDamagedQty = CountLocationQty.map(({ locationDamagedQuantity, ...rest }) => ({ ...rest, locationDamagedQuantity: +locationDamagedQuantity }));

    var result = [CountAllLocationGoodQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]

    var results = [CountAllLocationDamagedQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]

    this.TotalLocationGoodCount = parseInt(result[0].locationGoodQuantity)
    this.TotalLocationDamagedCount = results[0].locationDamagedQuantity

    if (this.new_formLoction.value.createNewLoaction[ContainerLength - 1].locationGoodQuantity === '' || this.new_formLoction.value.createNewLoaction[ContainerLength - 1].location === '' || this.new_formLoction.value.createNewLoaction[ContainerLength - 1].locationDamagedQuantity === '') {
      console.log('stoped')
      Swal.fire({
        toast: true,
        title: 'Please fill existing fields before creating new one !',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
    }
    else if (parseInt(this.goodQuantity) == this.TotalLocationGoodCount && this.damagedQty == this.TotalLocationDamagedCount ) {
      console.log("QUANITY CHECKING", this.goodQuantity, this.TotalLocationGoodCount, "ANOTHER", this.damagedQty, this.TotalLocationDamagedCount)
    //   console.log("COUNTING LA GUN", this.TotalLocationGoodCount, typeof(this.TotalLocationGoodCount), this.TotalLocationDamagedCount, typeof(this.TotalLocationDamagedCount))
    // console.log("GOOD AND DAMAGED", this.goodQuantity, typeof(this.goodQuantity), this.damagedQty, typeof(this.damagedQty))
      Swal.fire({
        toast: true,
        title: 'Quantity is not enough!',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
      return
    }
    else {
      let fg = this.fb.group({
        'location': [createNewLoaction ? createNewLoaction.location : '', Validators.required],
        'locationGoodQuantity': [createNewLoaction ? createNewLoaction.locationGoodQuantity : '', Validators.required],
        'locationDamagedQuantity': [createNewLoaction ? createNewLoaction.locationDamagedQuantity : '', Validators.required],
        'pallets': this.fb.array([])

      });

      (<FormArray>this.new_formLoction.get('createNewLoaction')).push(fg);
      let userIndex = (<FormArray>this.new_formLoction.get('createNewLoaction')).length - 1;
      if (!createNewLoaction) {
        this.addPallets(userIndex);
      }
      else {
        createNewLoaction.number.forEach(phone => {
          this.addPallets(userIndex, phone);
        });
      }
    }
  }

  addPalletContainer(createNewLoaction?: any) {

    console.log("Create New Location", createNewLoaction)
    let fg = this.fb.group({
      'location': [createNewLoaction ? createNewLoaction.location : '', Validators.required],
      'locationGoodQuantity': [createNewLoaction ? createNewLoaction.locationGoodQuantity : '', Validators.required],
      'locationDamagedQuantity': [createNewLoaction ? createNewLoaction.locationDamagedQuantity : '', Validators.required],
      'pallets': this.fb.array([])
      // 'quantity':'',

    });
    (<FormArray>this.new_formLoction.get('createNewLoaction')).push(fg);

    let userIndex = (<FormArray>this.new_formLoction.get('createNewLoaction')).length - 1;
    if (!createNewLoaction) {
      console.log('COMING HERE')
      this.addPallets(userIndex);
    }
    else {
      console.log("Pallet details", createNewLoaction.pallets)
      if (createNewLoaction.number) {
        createNewLoaction.number.forEach(phone => {
          this.addPallets(userIndex, phone);
        });
      } else {
        createNewLoaction.pallets.forEach(phone => {
          this.addPallets(userIndex, phone);
        });
      }
    }
    // this.Container.push(this.Container.length)
  }
  // ----------------------------FORM ARRAY FOR PALLET CONTAINER--------------------
  public palletsData = [
    {
      location: '',
      locationGoodQuantity: '',
      locationDamagedQuantity: '',
      number: [''],
      palletGoodQuantity: [''],
      palletDamagedQuantity: ['']
    },

  ];

  addPallets(userIndex: number, data?: any) {
    // console.log('userIndex', userIndex, '-------', 'data', data);
    let fg = this.fb.group({
      'number': [data ? data : ''],
      'palletGoodQuantity': [data ? data : ''],
      'palletDamagedQuantity': [data ? data : ''],
    });
    (<FormArray>(<FormGroup>(<FormArray>this.new_formLoction.controls['createNewLoaction'])
      .controls[userIndex]).controls['pallets']).push(fg);
  }

  addPallet(userIndex: number, data?: any) {
    console.log("Look", userIndex)
    var LocationLength = this.new_formLoction.value.createNewLoaction.length
    var PalletLength = this.new_formLoction.value.createNewLoaction[userIndex].pallets.length;
    if (this.new_formLoction.value.createNewLoaction[userIndex].pallets[PalletLength - 1].number === '' || this.new_formLoction.value.createNewLoaction[userIndex].pallets[PalletLength - 1].palletGoodQuantity === '' || this.new_formLoction.value.createNewLoaction[userIndex].pallets[PalletLength - 1].palletDamagedQuantity === '') {
      Swal.fire({
        toast: true,
        title: 'Please fill existing fields before creating new one !',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
    } else {
      let fg = this.fb.group({
        'number': '',
        'palletGoodQuantity': '',
        'palletDamagedQuantity': ''
      });
      (<FormArray>(<FormGroup>(<FormArray>this.new_formLoction.controls['createNewLoaction'])
        .controls[userIndex]).controls['pallets']).push(fg);
      console.log("Coming here", this.new_formLoction.value.createNewLoaction)
      console.log("Coming here", this.new_formLoction.value.createNewLoaction[0].pallets)
      this.count = this.count + 1;
    }
  }


  DeleteContainer(index: number) {
    (<FormArray>this.new_formLoction.get('createNewLoaction')).removeAt(index);
  }

  isDisabled(value) {
    return this.rowPoNumber.includes(value);
  }


  GenerateCode() {
    this.GenerateProductCode.productName = this.ProductSearch.searchval
    // console.log("PARAMS FOR CODE", this.GenerateProductCode)
    this.ApiCall.getMaterialCode(this.GenerateProductCode)
      .subscribe(
        res => {
          // this.materialsInformation.SkuUpcEan = res.result.productCode
          this.description = res.result.productCode
          // console.log("GENERATE CODE", res)
          this.HideGenerateBtn = false;
        }
      )
    err => {
      console.log(err)
    }
  }

  BackToMaterial(){
    this.locationPage = false
    this.addMaterialPage = true
  }

  /*--------------------------CALCULATION-----------------------------------------*/


  _GoodQtykeyPress(event: any) {
    const pattern = /[0-9,+, ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
    this.damagedQty = this.InvoiceQuantity - this.goodQuantityLength

    // console.log("BoxQty", this.BoxQuantity, "&&", "InvoiceQty", this.invoiceQty)
    this.BoxCount = Math.round(this.invoiceQty / this.BoxQuantity)
    if (this.BoxCount < 0.4) {
      this.BoxCount = 1;
    }
  }

  onSearchChange(searchValue: any): void {
    this.BoxCount = Math.round(this.invoiceQty / searchValue)
    if (this.BoxCount < 0.4) {
      this.BoxCount = 1;
    }
  }

  onSearchChangess(value: any): void {
    this.BoxCount = Math.round(value / this.BoxQuantity)
    if (this.BoxCount < 0.4) {
      this.BoxCount = 1;
    }
  }
  _goodQtykeyDown(event: any) {
    this.damagedQty = event
    // console.log("Before", this.new_AddMat_formGroup.value)
    var goodQty = parseInt(this.goodQuantityLength)
    this.damagedQty = parseInt(this.new_AddMat_formGroup.value.invoiceQty) - parseInt(this.new_AddMat_formGroup.value.goodQuantity)
    // console.log("Calculation", this.new_AddMat_formGroup.value)

    // console.log("Total", this.new_AddMat_formGroup.value.invoiceQty)
  }

  checkLegthGoodQty(e, input) {
    this.keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValue) ? '' : this.keyValue.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.invoiceQty || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

  /*------------------------------DAMAGED QUANTITY-----------------------*/
  checkLegthDamageQty(e, input) {
    this.keyValues = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValues) ? '' : this.keyValues.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.InvoiceQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }


  /*------------------------------LOCATION GOOD QUANTITY-----------------------*/

  checkLegthLocationGoodQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.goodQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

  /*------------------------------LOCATION DAMAGED QUANTITY-----------------------*/

  checkLegthLocationDamagedQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.damagedQty || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

  /*------------------------------PALLET GOOD QUANTITY-----------------------*/

  checkLegthPalletGoodQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.goodQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }

  /*------------------------------PALLET DAMAGED QUANTITY-----------------------*/

  checkLegthPalletDamagedQty(e, input) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > this.damagedQty || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
  }
}
