import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { myVariables } from 'src/environments/environment';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-search-by-location',
  templateUrl: './search-by-location.component.html',
  styleUrls: ['./search-by-location.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchByLocationComponent implements OnInit {

  searchLoccols = []; 
  check = true;
  searchByLocationData = [];

  RequiredLocation = {
    location : ''
  }
  options: any;

  // RequiredGetAllLoc = {}

  constructor(private ApiCall : ApiService) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber=1;
    myVariables.grnRowsPerPage=1;
    myVariables.returnGrnPageNumber=1;
    myVariables.returnGrnRowsPerPage=1;
    myVariables.dnRowsPerPage=1;
    myVariables.dnPageNumber=1;
    myVariables.shippingOrdersRowsPerPage=1;
    myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  sessionStorage.removeItem('shippingOrdersDate');
  sessionStorage.removeItem('grnReportsDates');
  sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('dnReportsDate');
  sessionStorage.removeItem('active')

    // this.GetAllLocation()
    this.searchLoccols = [
      { field: 'iricode', header: 'Product Code' },
      { field: 'productname', header: 'Product Name' },
      { field: 'goodqty', header: 'Good Quantity' },
      { field: 'damagedqty', header: 'Damaged Quantity' },
      { field: 'totalqty', header: 'Total Quantity' },
    ];
  }
  // GetAllLocation() {
  //   this.ApiCall.GetSearchByLocation('')
  //   .subscribe(
  //     res => {
  //       this.searchByLocationData = res.result

  //       if (this.searchByLocationData.length === 0) {
  //         this.check = true
  //       } else {
  //         this.check = false
  //       }
  //     }
  //   )
  // }
  searchlocation(data){
    // console.log(data.length)
    if(data.length>6){
      this.ApiCall.locationSearch(data).subscribe(res=>{
        // console.log(res)
        this.options=res['result']
      })
    }
  }
  GetLocation(){
    this.ApiCall.postSearchByLocation(this.RequiredLocation)
    .subscribe(
      res => {
        this.searchByLocationData = res.result

        if (this.searchByLocationData.length === 0) {
          this.check = true
        } else {
          this.check = false
        }
      }
    )
  }

}
