import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { Paginator } from 'primeng/paginator';
import { myVariables, dashboardVarialbles } from 'src/environments/environment';

@Component({
  selector: 'app-pendingdeliveries',
  templateUrl: './pendingdeliveries.component.html',
  styleUrls: ['./pendingdeliveries.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PendingdeliveriesComponent implements OnInit {
  @ViewChild('Opendel') private _table: Table;
  @ViewChild('calendar', undefined) private calendar: any;
  @ViewChild('pagin') paginator: Paginator;

  RequiredPendingDelivery = {
    status: "Pending",
    warehouseId: localStorage.getItem('WarehouseID'),
    startDate: "",
    endDate: "",
    pageLimit: 10,
    pageNumber: 1,
    directChannelOrder: null,
    customerId: null
  };
  bulkGenerateInvoicePayload = {
    warehouseId: localStorage.getItem('WarehouseID'),
    deliveryRequestIds: []
  };

  cols = [];
  completeData: any;
  pending_deliveries: any;
  documentView: any;
  rangeDates: Date[];
  today: any;
  firstDate: any;
  control: FormControl;
  check = false
  pageNumber: any;
  totalRecords: number;
  filter = "pi pi-filter";
  platformRequestValue: { label: string; value: string; }[];
  shippingMode: { label: string; value: string; }[];
  rowCount: any;
  customers: any[];
  businessNameValue: {}[];
  totalrows: any;
  row2: any;
  savedDates: any;
  first: number;
  newCar: boolean;
  displayDialog: boolean;
  selectedCustomers: any;
  customerData: any;
  customerList = [];

  constructor(private Apicall: ApiService, private router: Router, private datePipe: DatePipe) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage = 1;
    myVariables.completedDeliveryRowsPerPage = 1;
    myVariables.stagingReceiptRowsPerPage = 1;
    myVariables.StaockStatementRowsPerPage = 1;
    myVariables.stagingReceiptPageNumber = 1;
    myVariables.completedReceiptPageNumber = 1;
    myVariables.completedDeliveryPageNumber = 1;
    // myVariables.pendingDeliveriespageNumber=1;
    // myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage = 1;
    myVariables.pendingReceiptPageNumber = 1;
    myVariables.grnPageNumber = 1;
    myVariables.grnRowsPerPage = 1;
    myVariables.returnGrnPageNumber = 1;
    myVariables.returnGrnRowsPerPage = 1;
    myVariables.dnRowsPerPage = 1;
    myVariables.dnPageNumber = 1;
    myVariables.shippingOrdersRowsPerPage = 1;
    myVariables.shippingOrdersPageNumber = 1;
    myVariables.StockStatementPageNumber = 1;

    sessionStorage.removeItem('compltdReceiptDate');
    sessionStorage.removeItem('StagingReceiptDate');
    sessionStorage.removeItem('PendingReceiptDate');
    // sessionStorage.removeItem('PendingDeliDate');
    sessionStorage.removeItem('compltdDeliDates');
    sessionStorage.removeItem('shippingOrdersDate');
    sessionStorage.removeItem('grnReportsDates');
    sessionStorage.removeItem('ReturnGrnReportsDates');
    sessionStorage.removeItem('dnReportsDate');
    sessionStorage.removeItem('active')

    localStorage.removeItem("Materials")
    localStorage.removeItem('Dly_Invoice_No')
    localStorage.removeItem('Dly_Invoice_date')
    localStorage.removeItem('Dly_CusId')
    this.businessNameValue = new Array()
    this.businessNameValue.push({ label: 'All', value: null })
    if (myVariables.pendingDeliveriesRowsPerPage != 1) {
      this.RequiredPendingDelivery.pageLimit = myVariables.pendingDeliveriesRowsPerPage
    }
    if (myVariables.pendingDeliveriespageNumber != 1) {
      this.RequiredPendingDelivery.pageNumber = myVariables.pendingDeliveriespageNumber
    }
    if (dashboardVarialbles.isPendingRoute || dashboardVarialbles.isStagingRoute) {
      this.today = new Date();
      this.firstDate = new Date('01 July 2020');
      console.log(this.today, this.firstDate)

      this.rangeDates = [this.firstDate, this.today]
      const obj = {
        "startDate": this.firstDate,
        "endDate": this.today
      }
      sessionStorage.setItem('PendingDeliDate', JSON.stringify(obj))
    } else {
      if (sessionStorage.getItem('PendingDeliDate')) {
        this.savedDates = JSON.parse(sessionStorage.getItem('PendingDeliDate'))
        console.log(this.savedDates)
        this.firstDate = new Date(this.savedDates.startDate)
        this.today = new Date(this.savedDates.endDate)
        this.rangeDates = [this.firstDate, this.today];
        this.RequiredPendingDelivery.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
        this.RequiredPendingDelivery.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')

      }
      else {
        this.today = new Date();
        this.firstDate = new Date();
        // this.firstDate.setMonth(this.today.getMonth());
        this.firstDate.setDate(this.firstDate.getDate() - 7);
        this.rangeDates = [this.firstDate, this.today];
        this.RequiredPendingDelivery.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
        this.RequiredPendingDelivery.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
      }
    }


    this.GetallPendingStatus();
    this.getCustomers();
    this.GetAllCustomer();

    this.cols = [
      { field: 'id', header: 'Delivery Number' },
      { field: 'createdAt', header: 'Date' },
      { field: 'businessName', header: 'Customer' },
      { field: 'customerMobile', header: 'Mobile Number' },
      { field: 'orderDocumentNumber', header: 'Order Number' },
      { field: 'courierMethodName', header: 'Shipping Mode' },
      { field: 'status', header: 'Status' }
    ];
    this.shippingMode = [
      { label: 'none', value: '0' },
      { label: 'Platform', value: '1' },
      // { label: 'shipment', value: '2' },
      { label: 'All', value: null }
    ];

    this.platformRequestValue = [
      { label: 'None', value: '0' },
      { label: 'Platform', value: '1' },
      // { label: 'shipment', value: '2' },
      { label: 'All', value: null }
    ];

    this.control = new FormControl();

  }
  onRowSelect() {
    this.newCar = false;
    this.displayDialog = true;
  }
  selectedCustomer() {
    localStorage.setItem('SelectedCustomerID', this.selectedCustomers.id)

    if (localStorage.getItem('SelectedCustomerID') !== '') {
      this.downloadStmtDN()
      this.displayDialog = false;
    } else {
      this.displayDialog = true;
      Swal.fire({
        toast: true,
        title: 'Please Select Customer !',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
    }
    console.log("SELECTED CUSTOMERS", this.selectedCustomers)
  }
  downloadStmtDN() {
    const obj = {
      "customerid": localStorage.getItem('SelectedCustomerID'),
      "warehouseid": localStorage.getItem('WarehouseID'),
      "flag": "download"
    }
    this.Apicall.blockedInventoryReport(obj).subscribe({
      next: (response: any) => {
        let fileName = 'ConsolidateDN';
        const contentDisposition = response.headers.get('content-disposition');
        console.log('content', response.headers)
        console.log('content', response.headers.get('content-disposition'))
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        const fileContent = response.body;
        saveAs(fileContent, fileName);
      },
      error: (error) => {
        console.log({ error });
      }
    })
  }
  getColor(status) {
    switch (status) {
      case 'Staging':
        return 'orange';
      case 'Pending':
        return '#32ade2';
    }
  }
  getvalue(courierMethod) {
    switch (courierMethod) {
      case '0':
        return 'none';
      case '1':
        return 'Platform';
      case '2':
        return 'Shipment';
    }
  }
  search(data) {
    if (data.length >= 3) {
      const obj = {

        "warehouseId": this.RequiredPendingDelivery.warehouseId,      //(not mandatory)
        "status": this.RequiredPendingDelivery.status,   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
        "searchval": data, 	//(mandatory and at least 3 characters)
        "customerId": this.RequiredPendingDelivery.customerId 	//(not mandatory)

      }
      this.Apicall.searchDeliveryRequest(obj).subscribe(res => {
        this.completeData = res['result'];
        this.totalrows = res['result'].length
        this.totalRecords = 1 * this.totalrows
        console.log(res)
        console.log("Pending Delivery ", this.completeData)
        for (var i = 0; i < this.completeData.length; i++) {
          // this.completeData[i]['poNo'] = this.completeData[i].pickupInvoice[i].purchaseOrderNumber
          if (this.completeData[i]['courierMethod'] == 0) {
            this.completeData[i]['courierMethodName'] = 'None'
          }
          if (this.completeData[i]['courierMethod'] == 1) {
            this.completeData[i]['courierMethodName'] = 'Platform'
          }
          if (this.completeData[i]['courierMethod'] == 2) {
            this.completeData[i]['courierMethodName'] = 'Shipment'
          }
        }
        if (this.totalrows != 10 && this.totalrows != 25 && this.totalrows != 50 && this.totalrows != 75 && (this.row2)) {
          console.log(this.totalrows);

          console.log("not");
          this.totalrows = this.row2
          this.totalRecords = data['totalPages'] * this.row2
          // this.totalRecords=data['totalPages']*this.row2

        }
        else {
          console.log("yes");
          this.totalrows = myVariables.pendingDeliveriesRowsPerPage

          this.totalRecords = data['totalPages'] * this.totalrows
          // this.totalRecords=data['totalPages']*this.totalrows


        }
        this.first = (this.totalrows * this.RequiredPendingDelivery.pageNumber) - 1
        if (this.completeData.length === 0) {
          this.check = true
          // console.log(this.check)
        } else {
          this.check = false
        }
      })
    }
    if (data.length == 0) {
      this.GetallPendingStatus();
    }

  }
  getCustomers() {
    this.Apicall.getDeliveryStatus().subscribe(res => {
      console.log(res.result)
      this.customers = res.result
      console.log("Customer data: ", this.customers)
      var tempArray = new Array()
      res.result.forEach(customer => {

        this.businessNameValue.push({ label: customer.businessName, value: customer.id })
      });
      console.log('Customers: ', this.businessNameValue)

    }

    )
  }
  GetAllCustomer() {
    this.Apicall.getDeliveryStatus()
      .subscribe(
        res => {
          console.log("Response", res.result)
          this.customerData = res.result;
          for (var i = 0; i < this.customerData.length; i++) {
            this.customerList.push(this.customerData[i])
          }
          // this.customerList = [{ fullname: "Sudhar", id: 1},
          // { fullname: "Kanna", id: 2},
          // { fullname: "Aswath", id: 3},
          // { fullname: "Anish", id: 4},
          // { fullname: "Selva", id: 5},
          // { fullname: "Gowtham", id: 6},]
          console.log("Result", this.customerList)
        }
      )
  }
  bulkSelect(event) {

    if (event.checked == true) {
      console.log("everythink is selected", event)
      this.completeData.forEach(id => {
        // console.log(id.courierMethod);

        if (id.courierMethod == 1) {
          this.bulkGenerateInvoicePayload.deliveryRequestIds.push(id.id)
        }

      })
      console.log(this.bulkGenerateInvoicePayload)


    }
    else {
      console.log("everythink is unselected", event)
      this.bulkGenerateInvoicePayload.deliveryRequestIds = []

    }
  }
  bulkGenerateInvoice(rowData) {
    console.log('i am selected', rowData.data.id)
    this.bulkGenerateInvoicePayload.deliveryRequestIds.push(rowData.data.id)
    console.log(this.bulkGenerateInvoicePayload)
  }
  removeBulkGenerate(rowData) {

    console.log('i am unselected', rowData.data.id)
    console.log(this.bulkGenerateInvoicePayload.deliveryRequestIds)
    const i = this.bulkGenerateInvoicePayload.deliveryRequestIds.findIndex(object => { return object == rowData.data.id })
    this.bulkGenerateInvoicePayload.deliveryRequestIds.splice(i, 1)
    console.log(this.bulkGenerateInvoicePayload.deliveryRequestIds)
  }
  GenerateBulkInvoiceAndLabel() {
    if (this.bulkGenerateInvoicePayload.deliveryRequestIds.length == 0) {
      Swal.fire({
        toast: true,
        title: "Please Select atleast one order",
        type: 'warning',
        showConfirmButton: true
      })
    }
    else {

      console.log('final payload', this.bulkGenerateInvoicePayload)
      this.Apicall.BulkGenerateInvoiceAndLabel(this.bulkGenerateInvoicePayload).subscribe(res => {


        console.log('Bulk Gen Api Call', res)
        if (res['status'] == true) {
          Swal.fire({
            toast: true,
            title: "Bulk Generation of invoice and label is successfull",
            type: 'success',
            showConfirmButton: true
          })
        }
        else {
          Swal.fire({
            toast: true,
            title: res['error'],
            type: 'warning',
            showConfirmButton: true
          })
        }
      })
    }

  }


  GetallPendingStatus() {
    // console.log(dashboardVarialbles.isStagingRoute)
    if (dashboardVarialbles.isStagingRoute == true) {
      this.RequiredPendingDelivery.status = 'Staging'
      this.RequiredPendingDelivery.endDate = null
      this.RequiredPendingDelivery.startDate = null
    }
    if (dashboardVarialbles.isPendingRoute) {
      this.RequiredPendingDelivery.status = 'onlyPending'
      this.RequiredPendingDelivery.endDate = null
      this.RequiredPendingDelivery.startDate = null
    }
    this.Apicall.getCOmpletedDeliveries(this.RequiredPendingDelivery)
      .subscribe(
        res => {
          this.completeData = res.result;
          this.totalrows = res.result.length
          this.totalRecords = res.noOfPages * this.totalrows
          console.log(res)
          console.log("Pending Delivery ", this.completeData)
          this.totalrows = this.completeData.length

          console.log(this.totalrows);
          for (var i = 0; i < this.completeData.length; i++) {
            // this.completeData[i]['poNo'] = this.completeData[i].pickupInvoice[i].purchaseOrderNumber
            if (this.completeData[i]['courierMethod'] == 0) {
              this.completeData[i]['courierMethodName'] = 'None'
            }
            if (this.completeData[i]['courierMethod'] == 1) {
              this.completeData[i]['courierMethodName'] = 'Platform'
            }
            if (this.completeData[i]['courierMethod'] == 2) {
              this.completeData[i]['courierMethodName'] = 'Shipment'
            }
          }

          if (this.totalrows != 10 && this.totalrows != 25 && this.totalrows != 50 && this.totalrows != 75 && (this.row2)) {
            console.log(this.totalrows);

            console.log("not");
            this.totalrows = this.row2
            this.totalRecords = res.noOfPages * this.row2
          }
          else {
            console.log("yes");
            this.totalrows = myVariables.pendingDeliveriesRowsPerPage

            this.totalRecords = res.noOfPages * this.totalrows

          }
          this.first = (this.totalrows * this.RequiredPendingDelivery.pageNumber) - 1

          if (this.completeData.length === 0) {
            this.check = true
            // console.log(this.check)
          } else {
            this.check = false
          }
          //   for (var i = 0; i < this.completeData.length; i++) {
          //   if(!this.completeData[i].deliveryInvoice.length){
          //     this.completeData[i]['documents'] = ''
          //   }
          //   else{
          //     this.completeData[i]['documents'] = this.completeData[i].deliveryInvoice[0].fileUrl;
          //     this.documentView = this.completeData[i]['documents']
          //   }
          // }
        }
      )
  }

  platformRequestFilter(event) {
    console.log(event)
    if (event['value'] == '0') {
      this.RequiredPendingDelivery.directChannelOrder = 0
    }
    else if (event['value'] == '1') {
      this.RequiredPendingDelivery.directChannelOrder = 1
    }
    else {
      this.RequiredPendingDelivery.directChannelOrder = 2
    }

    this.paginator.changePage(0)
    this.RequiredPendingDelivery.pageNumber = 1
    myVariables.pendingDeliveriespageNumber = 1
    this.GetallPendingStatus();
  }
  customerFilter(event) {
    console.log(event['value'])
    this.RequiredPendingDelivery.customerId = event['value']
    this.paginator.changePage(0)
    this.RequiredPendingDelivery.pageNumber = 1
    myVariables.pendingDeliveriespageNumber = 1
    this.GetallPendingStatus();
  }

  paginate(event) {
    // console.log(event)
    // if(this.RequiredPendingDelivery.pageLimit!=event.rows){
    //   this.RequiredPendingDelivery.pageLimit=event.rows
    //   this.RequiredPendingDelivery.pageNumber=1
    //   this.paginator.changePage(0)
    // }

    // else{this.RequiredPendingDelivery.pageNumber=event.page+1}
    // console.log(this.RequiredPendingDelivery)
    // this.GetallPendingStatus();
    this.row2 = event.rows
    // console.log(event.rows ,this.totalrows);



    // this.RequiredDetails.pageLimit=event.rows
    // this.pageNumber = event.page + 1;
    // this.RequiredDetails.pageNumber = this.pageNumber
    //  console.log(event.rows,this.RequiredPendingDelivery.pageLimit)

    if (this.RequiredPendingDelivery.pageLimit == event.rows) {
      this.RequiredPendingDelivery.pageNumber = event.page + 1
      myVariables.pendingDeliveriespageNumber = this.RequiredPendingDelivery.pageNumber

    } else if (event.rows == 10) {
      this.RequiredPendingDelivery.pageLimit = 10
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingDeliveriesRowsPerPage = this.RequiredPendingDelivery.pageLimit


      this.paginator.changePage(0)

    }
    else if (event.rows == 25) {
      this.RequiredPendingDelivery.pageLimit = 25
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingDeliveriesRowsPerPage = this.RequiredPendingDelivery.pageLimit



      this.paginator.changePage(0)

    } else if (event.rows == 50) {
      this.RequiredPendingDelivery.pageLimit = 50
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingDeliveriesRowsPerPage = this.RequiredPendingDelivery.pageLimit

      this.paginator.changePage(0)

    }
    else if (event.rows == 75) {
      this.RequiredPendingDelivery.pageLimit = 75
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingDeliveriesRowsPerPage = this.RequiredPendingDelivery.pageLimit

      this.paginator.changePage(0)

    }
    else {

      // this.RequiredPendingDelivery.pageLimit=event.rows 
      this.RequiredPendingDelivery.pageNumber = event.page + 1
      myVariables.pendingDeliveriespageNumber = this.RequiredPendingDelivery.pageNumber

      // this.first=event.page
      // console.log(event);
      // console.log(this.first);


      // this.paginator.changePage(0)

    }


    console.log(this.RequiredPendingDelivery)
    this.GetallPendingStatus()

  }

  documentViewPage() {
    window.open(this.documentView, '_blank')
  }

  inPendingDeliveries(rowData) {
    this.pending_deliveries = rowData
    localStorage.setItem('pending_delivery_id', this.pending_deliveries.id)
    localStorage.setItem('Dly_CusId', this.pending_deliveries.customerId)
    // console.log("ROW DATA PICKING", this.pending_deliveries.customerId)
    this.router.navigate(['viewselecteddeliveries'])
  }

  onDateSelect(event) {
    console.log(event)
    var _self = this;
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;
      this.RequiredPendingDelivery.startDate = this.formatDate(_self.rangeDates[0])
      this.RequiredPendingDelivery.endDate = this.formatDate(_self.rangeDates[1])
      this.RequiredPendingDelivery.pageNumber = 1
      myVariables.pendingDeliveriespageNumber = 1
      this.paginator.changePage(0)
      const obj = {
        "startDate": _self.rangeDates[0],
        "endDate": _self.rangeDates[1]
      }
      sessionStorage.setItem('PendingDeliDate', JSON.stringify(obj))
      // sessionStorage.setItem('endDate',JSON.stringify(_self.rangeDates[1]))
      this.GetallPendingStatus();
    } else {
      e = s + 86400000;
    }
    console.log(this.rangeDates[1])
    if (this.rangeDates[1]) { // If second date is selected
      this.calendar.overlayVisible = false;
    }

  }
  defaultDate() {
    console.log('defaultdate');
    if (this.rangeDates[1] == null) {
      if (sessionStorage.getItem('PendingDeliDate')) {
        this.savedDates = (JSON.parse(sessionStorage.getItem('PendingDeliDate')))
        this.rangeDates = [new Date(this.savedDates.startDate), new Date(this.savedDates.endDate)]
      }
      else {
        this.rangeDates = [this.firstDate, this.today]
      }
    }

  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
}
