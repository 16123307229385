import { Component, OnInit } from '@angular/core';
// import * as Chart from 'chart.js'
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { ApiService } from 'src/app/api.service';
import { Chart, Filler } from 'chart.js';
@Component({
  selector: 'app-good-and-damageskus',
  templateUrl: './good-and-damageskus.component.html',
  styleUrls: ['./good-and-damageskus.component.css']
})
export class GoodAndDamageskusComponent implements OnInit {

  data: any;
  chartData: any;
  customerLabel: any[];
  totalGoodQty: any[];
  totalDamagedQty: any[];
  StackedOptions: any;
  damagedData: any;
  goodsData: any;


  constructor(private ApiService: ApiService) {

  }
  ngOnInit() {
    //     var canvas = document.getElementById('chart');
    // var ctx = canvas.getContext('2d');
    //     // var ctx = document.getElementById("chart").getContext("2d");
    //     var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    //     gradient.addColorStop(0, 'rgba(250,174,50,1)');
    //     gradient.addColorStop(1, 'rgba(250,174,50,0)');
    // Chart.register(Filler);
    this.customerLabel = []
    this.totalGoodQty = []
    this.totalDamagedQty = []
    this.ApiService.goodAndDamagedSku().subscribe(res => {
      // console.log(res);
      this.chartData = res['result'];
      // console.log("this is the result", this.chartData);
      for (let i = 0; i < this.chartData.length; i++) {
        this.customerLabel.push(this.chartData[i].customerName)
        this.totalGoodQty.push(this.chartData[i].data.totalGoodQty)
        this.totalDamagedQty.push(this.chartData[i].data.totalDamegedQty)
      }
      this.goodsData = {
        labels: this.customerLabel,
        datasets: [
          {
            label: 'Good',
            data: this.totalGoodQty,
            fill: -1,
            // fillColor: gradient,
            borderColor: '#B5E888',
            backgroundColor: '#B5E888',
            tension: 0.1,
            pointRadius: 5


          },
          // {
          //   label: 'Damaged',
          //   data: this.totalDamagedQty,
          //   fill: true,
          //   borderColor: '#FF7A77',
          //   backgroundColor: '#FF7A77',
          //   tension: 0.1

          // }
        ],
        options: {

          plugins: {
            Filler: {
              propagate: false
            }
          }
        }
      }
      this.damagedData = {
        labels: this.customerLabel,
        datasets: [
          // {
          //   label: 'Good',
          //   data: this.totalGoodQty,
          //   fill: '+1',
          //   // fillColor: gradient,
          //   borderColor: '#B5E888',
          //   backgroundColor: '#B5E888',
          //   tension: 0.1


          // },
          {
            label: 'Damaged',
            data: this.totalDamagedQty,
            fill: -1,
            borderColor: '#FF7A77',
            backgroundColor: '#FF7A77',
            tension: 0.1,
            pointRadius: 5

          }
        ],
        options: {

          plugins: {
            Filler: {
              propagate: false
            }
          }
        }
      }
      this.StackedOptions = {
        legend: {
          display: true
        },
        plugins: {
          Filler: {
            propagate: true
          }
        },
        scales: {
          xAxes: [{
            stacked: true,


            ticks: {
              color: "#black",
              fontSize: 10,
              display: false


            }
          }],
          yAxes: [{
            max: 100,
            gridLines: {
              display: false,

            },
          }]
        }

      }
    })

  }

}