import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import lodash from 'lodash';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-material-master',
  templateUrl: './material-master.component.html',
  styleUrls: ['./material-master.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialMasterComponent implements OnInit {
  skuList: any;
  skuListValue: any;
  width: any;
  ImgThumbnail: any[];
  pageData: any;
  pageNumber: any;
  check = false
  displayBasic2 = false;
  totalRecords: number;

  ProductSearch = {
    "searchval": "",
    "customerId": localStorage.getItem('ViewCustomer_id')
  }

  SupplierfilteredOption: Observable<string[]>;
  name: FormControl;
  AutoFillSuppliers = [];
  data: any;
  printingMaterialName: any;

  ProductMaterial = {}

  private SKUMasterUrl = 'http://52.170.134.229/storagewithpagination/material/sku/master';

  constructor(private apiCall: ApiService, private router: Router, private http: HttpClient) {
    this.name = new FormControl();
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );
  }
  ngOnInit() {
    this.skuList = [
      { field: 'SkuUpcEan', header: 'SKU Code / Barcode' },
      { field: 'productName', header: 'SKU Name' },
      { field: 'unitOfMaterials', header: 'UoM' },
      { field: 'length', header: 'Length', unit: 'lengthUnit' },
      { field: 'width', header: 'Breadth', unit: 'widthUnit' },
      { field: 'height', header: 'Height', unit: 'heightUnit' },
      { field: 'weight', header: 'Weight', unit: 'weightUnit' },
      { field: 'category', header: 'Category' },
      { field: 'subcategory', header: 'Subcategory' },
      { field: 'shelfLife', header: 'Shelf Life' },
      { field: 'deliveryPreferences', header: 'Delivery Preference' },
      { field: 'unitPerStack', header: 'Unit per Stack' },
      { field: 'boxPcsQuantity', header: 'Box Quantity' },
      { field: 'isFifo', header: 'Fifo' },
      // { field: 'productimage', header: 'Product Image' }
    ];
    this.skuListValue = [];

    this.SkuApiCall()
  }

  SkuApiCall() {
    this.apiCall.getSKUMaster()
      .subscribe(res => {
        console.log("SKU Master Datas", res)
        this.skuListValue = res.result
        this.pageData = this.skuListValue.length
        this.totalRecords = res.noOfPages
        if (this.skuListValue.length === 0) {
          this.check = true
        } else {
          this.check = false
        }

        for (var i = 0; i < this.skuListValue.length; i++) {
          if (this.skuListValue[i].barcode === 'NULL') {
            this.skuListValue[i].barcode = ''
          }
          if (this.skuListValue[i].isFifo == 'false') {
            this.skuListValue[i].isFifo = false
          } else {
            this.skuListValue[i].ifFifo = true
          }
        }
      })
  }

  ImgThumbnails(rowData, ri) {
    // console.log("Images",ri, rowData[ri].imageUrl)
    this.ImgThumbnail = rowData[ri].imageUrl
  }

  paginate(event) {
    this.pageNumber = event.page + 1;
    localStorage.setItem('PageNo', this.pageNumber)
    this.SkuApiCall();
  }

  filters(val: string) {
    // console.log("CHECKING HERE", this.AutoFillSuppliers)
    if (this.AutoFillSuppliers === undefined || this.AutoFillSuppliers === []) {
      return
    }
    return this.AutoFillSuppliers.filter(option =>
      option.productName.toLowerCase().includes(val.toLowerCase()));
  }

  setInputField(data) {
    this.ProductSearch.searchval = data
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );

    if (data.length > 2) {
      this.ProductSearching()

    } else {
      // console.log("please enter minimum 3 characters")
      this.AutoFillSuppliers = []
      this.SkuApiCall();
    }
  }
  searchProduct(data) {
    if (data.length > 2) {
      this.ProductSearch.searchval = data
      this.apiCall.getProductName(this.ProductSearch)
        .subscribe(
          res => {
            this.skuListValue = res.result
            for (let i = 0; i < this.skuListValue.length; i++) {
              this.skuListValue[i]['SkuUpcEan'] = this.skuListValue[i]['productCode']
            }
            this.totalRecords = 1
            for (var i = 0; i < this.skuListValue.length; i++) {
              if (this.skuListValue[i].barcode === 'NULL') {
                this.skuListValue[i].barcode = ''
              }
              if (this.skuListValue[i].deliveryPreferences == 'fifo') {
                this.skuListValue[i].isFifo = true
              } else {
                this.skuListValue[i].ifFifo = false
              }
            }
            if (this.skuListValue.length === 0) {
              this.check = true
            } else {
              this.check = false
            }
          })
    }
    if (data.length == 0) {
      this.SkuApiCall()
    }

  }
  ProductSearching() {
    this.apiCall.getProductName(this.ProductSearch)
      .subscribe(
        res => {
          this.AutoFillSuppliers = res.result
          this.SupplierfilteredOption = this.name.valueChanges
            .pipe(
              startWith(''),
              map(val => this.filters(val))
              // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
            );
          if (res.result === 'No data Found') {
            this.AutoFillSuppliers = []
          }
        }
      )
  }

  selectedSupplier(evt) {
    for (var i = 0; i < this.AutoFillSuppliers.length; i++) {
      if (this.AutoFillSuppliers[i].productCode == evt.source.value) {
        console.log(this.AutoFillSuppliers[i].productCode)
        localStorage.setItem('Selected_Product', this.AutoFillSuppliers[i].productCode)
        this.printingMaterialName = this.AutoFillSuppliers[i].productCode + ' - ' + this.AutoFillSuppliers[i].productName
        this.apiCall.getMaterialMasterProduct(this.ProductMaterial)
          .subscribe(
            res => {
              this.skuListValue = []
              // var newResult = res.result
              // console.log("step2",newResult)
              this.skuListValue = res.result
              this.totalRecords = res.noOfPages
              this.SupplierfilteredOption = this.name.valueChanges
                .pipe(
                  startWith(''),
                  map(val => this.filters(val))
                );

            }
          )
      }
    }
  }
}