import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from  './api.service';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private authguard: ApiService, private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.authguard.getToken();
    console.log(token)
    if (!token) {
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }
}
