import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Paginator } from 'primeng/paginator';
import { saveAs } from 'file-saver';
import { ApiService } from '../api.service';
import { myVariables } from 'src/environments/environment';

@Component({
  selector: 'app-dn-details',
  templateUrl: './dn-details.component.html',
  styleUrls: ['./dn-details.component.css']
})
export class DnDetailsComponent implements OnInit {
  @ViewChild('pagin') paginator: Paginator;

  @ViewChild('calendar', undefined) private calendar: any;

  today: any;
  firstDate: any;
  rangeDates: any[];
  cols: { field: string; header: string; }[];

  SelectedDate: any;
  public dateFilters = new Date();
  dndetails: any;
  totalnoofpages: any;
  totalrecords: any;
  rows: any;
  pageNumber: any;
  RequiredPending: any;
  rows2: any;
  totalrecords2: any;
  customerList: any;
  selectedCustomerId: any;
  deliverynote: any;
  deliveryrequestno: any;
  orderno: any;
  orderdate: any;
  channel: any;
  delnoterow: any;
  todayy: string;
  firstDatee: string;
  val: any;
  result: any;
  filter = "pi pi-filter";
  newArray:any= [];
  channelValue: { label: string; value: string; }[];
  ChannelValue: { label: { label: string; value: string; }[]; value: { label: string; value: string; }[]; }[];
  savedDates: any;
  row2: any;
  check: boolean;
  first: number;


  constructor(private Apicall: ApiService, private router: Router, private datePipe: DatePipe) { }
  sales: any[];

  RequiredDetails = {

    // customerId: localStorage.getItem('CusID'),
    // warehouseID:localStorage.getItem('WarehouseID'),
    startDate: "firstDate",
    endDate: "today",
    pageNumber: 1,
    pageLimit: 10,
    channel:''
  }
  selectedCustomer='Please select a customer'

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber=1;
    myVariables.grnRowsPerPage=1;
    myVariables.returnGrnPageNumber=1;
    myVariables.returnGrnRowsPerPage=1;
    // myVariables.dnRowsPerPage=1;
    // myVariables.dnPageNumber=1;
    myVariables.shippingOrdersRowsPerPage=1;
    myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  sessionStorage.removeItem('shippingOrdersDate');
  sessionStorage.removeItem('grnReportsDates');
  sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('active')
  // sessionStorage.removeItem('dnReportsDate');
    
   if(sessionStorage.getItem('selectedCust')){
    this.selectedCustomer=sessionStorage.getItem('selectedCust')
   }

if(myVariables.dnPageNumber!=1){
  this.RequiredDetails.pageNumber=myVariables.dnPageNumber
}
if(myVariables.dnRowsPerPage!=1){
this.RequiredDetails.pageLimit=myVariables.dnRowsPerPage
}
    if (sessionStorage.getItem('dnReportsDate')) {
      this.savedDates=JSON.parse(sessionStorage.getItem('dnReportsDate'))
      let date = new Date(this.savedDates.startDate)

      let todayDate = new Date(this.savedDates.endDate)

      this.rangeDates = [date, todayDate];

      // console.log(date)
      // console.log(typeof (date))

      this.RequiredDetails.startDate = this.datePipe.transform(date, 'yyyy-MM-dd')
      this.RequiredDetails.endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd')

    }

    else {
      var _self = this;
      this.today = new Date();
      this.firstDate = new Date();
      this.firstDate.setMonth(this.today.getMonth() - 1);
      // console.log('today date', this.today)
      // console.log('first date', this.firstDate)
      // console.log(typeof (this.today))
      this.rangeDates = [this.firstDate, this.today];

      this.RequiredDetails.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
      this.RequiredDetails.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')



    }
    this.getChannelValue();
    // this.getDeliveryNote()



    // this.getReturnDnDetail()

    this.cols = [
      { field: 'delivery_request_id', header: 'Delivery Request No' },
      { field: 'order_number', header: 'Order Number' },
      { field: 'delivery_note_id', header: 'DN Number' },
      { field: 'delivery_note_date', header: 'DN Date' },

      { field: 'channel', header: 'Channel' },
      { field: 'Status', header: 'Status' }

    ];


    this.Apicall.getDeliveryStatus().subscribe(res=>{
      // console.log(res.result)
      this.customerList=res.result
      })

    this.getDeliveryNote()


  }
  getDeliveryNote() {
    // console.log(this.RequiredDetails)
    this.Apicall.getDnDetails(this.RequiredDetails,this.selectedCustomer).subscribe(data => {
      console.log(data)
      this.deliverynote = data['result']

      this.deliverynote.forEach(element => {
        element.Status = "Completed"
      });
      this.deliveryrequestno = this.deliverynote.delivery_note_id
      this.orderno = this.deliverynote.order_number
      this.orderdate = this.deliverynote.order_date
      this.channel = this.deliverynote.channel

      this.rows = this.deliverynote.length
      this.totalrecords = data['totalPages'] * this.rows
      this.rows= this.deliverynote.length

          console.log(this.rows);
          
          if(this.rows!=10 && this.rows!=25 && this.rows!=50 && this.rows!=75 && (this.row2)){
            console.log(this.rows);
  
            console.log("not");
            this.rows=this.row2
            this.totalrecords = data['totalPages'] * this.row2
          }
          else{
            console.log("yes",data['totalPages']);
            this.rows=myVariables.dnRowsPerPage
            this.totalrecords = data['totalPages'] * this.rows
            // console.log(this.totalrecords,this.rows);
          }
          this.first=(this.rows*this.RequiredDetails.pageNumber)-1

          if(this.deliverynote.length == 0){
            this.check=true
          }
          else{
            this.check=false
          }
    })


  }
  searchDn(data){
if(data.length>=3){
  const obj={
        
    "warehouseId":localStorage.getItem('WarehouseID'),      //(not mandatory)
    "status":'Completed',   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
    "searchval":data, 	//(mandatory and at least 3 characters)
    "customerId" : this.selectedCustomerId 	//(not mandatory)
    
}
this.Apicall.searchDeliveryRequest(obj).subscribe(res=>{
  console.log(res);
  this.deliverynote = res['result']

  // this.deliverynote.forEach(element => {
  //   element.Status = "Completed"
  //   element['delivery_request_id']=
  // });
  for(let i=0;i<this.deliverynote.length;i++){
    this.deliverynote[i]['delivery_request_id']=this.deliverynote[i].id
    this.deliverynote[i]['order_number']=this.deliverynote[i].orderDocumentNumber
    this.deliverynote[i]['channel']=this.deliverynote[i].endCustomerName
    this.deliverynote[i]['delivery_note_id']=this.deliverynote[i].gcnNumber


  }
  if(this.rows!=10 && this.rows!=25 && this.rows!=50 && this.rows!=75 && (this.row2)){
    console.log(this.rows);

    console.log("not");
    this.rows=this.row2
    this.totalrecords = data['totalPages'] * this.row2
  }
  else{
    console.log("yes",data['totalPages']);
    this.rows=myVariables.dnRowsPerPage
    this.totalrecords = data['totalPages'] * this.rows
    // console.log(this.totalrecords,this.rows);
  }
  this.first=(this.rows*this.RequiredDetails.pageNumber)-1


  
})
}
if(data.length==0){
  this.getDeliveryNote();
}
  }
  channelFilter(event) {

    console.log(event)
    this.val=event.value.toLowerCase()
    this.RequiredDetails.channel=this.val
 
    this.getDeliveryNote()
    // this.RequiredCompleteDelivery.customerId = event['value']
    // this.GetallPendingStatus();
  }


getChannelValue() {
  console.log('selected customer',this.selectedCustomer)
    this.Apicall.getChannellist(this.selectedCustomer).subscribe(data => {
      // console.log(data)
      this.result = data['result']
      // console.log(data['result'])
      this.newArray.push({
        'label': 'ALL',
        'value': ''
      })
      this.result.forEach(element => {

        // console.log(element['channel'])
        this.channelValue = element['channel']
        let obj = {
          'label': element.channel.toUpperCase(),
          'value': element['channel']
        }
        this.newArray.push(obj)

        // console.log(this.newArray)
        this.ChannelValue = [

          { label: this.channelValue, value: this.channelValue }
        ]
      })
    
      // console.log(this.newArray);

      // console.log(this.channelValue)

      // console.log(data['result'])

    })
  }
  
  deliveryNoteInf(rowData) {
    console.log(rowData)
    this.delnoterow = rowData
    localStorage.setItem('del_id', this.delnoterow.delivery_note_id)
    // //console.log("ROW DATA PICKING", this.pending_deliveries)
    this.router.navigate(['/completeddelnote'])


  }


  onDateSelect(event) {
    console.log(event)
    var _self = this;
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;

      // console.log(_self.rangeDates[0], _self.rangeDates[1])
      // console.log('if part is hitting')
      // console.log(_self.rangeDates[0])
      // console.log(typeof (_self.rangeDates[0]));

      //  sessionStorage.setItem("firstDate", JSON.stringify(_self.rangeDates[0]));
      // sessionStorage.setItem("lastDate", JSON.stringify(_self.rangeDates[1]));
      const obj={
        "startDate": _self.rangeDates[0],
        "endDate": _self.rangeDates[1]
      }
     sessionStorage.setItem('dnReportsDate',JSON.stringify(obj))
      this.RequiredDetails.startDate = this.formatDate(_self.rangeDates[0])
       this.RequiredDetails.endDate = this.formatDate(_self.rangeDates[1])
       this.RequiredDetails.pageNumber=1
       //  this.paginator.changePage(0)
        this.paginator.changePage(0)
      //  this. RequiredDetails.startDate = this.formatDate(_self.rangeDates[0])
      // this. RequiredDetails.endDate = this.formatDate(_self.rangeDates[1])

      this.getDeliveryNote()
      // this.getReturnDnDetail()
    } else {
      // console.log('else part is hitting')
      e = s + 86400000;
    }
    if (this.rangeDates[1]) { // If second date is selected
      this.calendar.overlayVisible = false;
    }
  }
  
  formatDate(date) {


    // console.log(typeof (date))
    // console.log('hi')
    // console.log(date)
    let swap = new Date(date)
    // let aaa=JSON.parse(date)
    // console.log(typeof (swap))
    // console.log(swap)
    let month = swap.getMonth() + 1;
    // console.log(month)
    let day = swap.getDate();
    // console.log(day)
    // console.log('full year', swap.getFullYear)
    let newMonth: String
    let newDay: String
    if (month < 10) {
      newMonth = '0' + month;
    } else {
      newMonth = month.toString()
    }
    if (day < 10) {
      newDay = '0' + day;
    }
    else {
      newDay = day.toString();
    }
    return swap.getFullYear() + '-' + newMonth + '-' + newDay;
  }


  inrowInformationgrn(rowData) {
    console.log(rowData)
    this.dndetails = rowData
    localStorage.setItem('dn_id', this.dndetails.delivery_note_id)
    // //console.log("ROW DATA PICKING", this.pending_deliveries)
    this.router.navigate(['/view-dn-details'])


  }
  dropdown(event){
    sessionStorage.setItem('selectedCust',this.selectedCustomer)
    // console.log(this.selectedCustomer)
    this.getDeliveryNote()
    // this.getReturnDnDetail()
    }
  grndownload() {

    this.Apicall.DownloadReportDndet(this.RequiredDetails,this.selectedCustomer)
      .subscribe({
        next: (response: any) => {

          let fileName = 'ConsolidateDN';
          const contentDisposition = response.headers.get('content-disposition');
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }

          const fileContent = response.body;

          saveAs(fileContent, fileName);

        },
        error: (error) => {

          console.log({ error });

        }
      });
  }


  paginate(event) {
    // console.log(event)
    // // this.RequiredDetails.pageLimit=event.rows
    // // this.pageNumber = event.page + 1;
    // // this.RequiredDetails.pageNumber = this.pageNumber
    // if (this.RequiredDetails.pageLimit != event.rows) {
    //   this.RequiredDetails.pageLimit = event.rows
    //   this.RequiredDetails.pageNumber = 1
    //   this.paginator.changePage(0)

    // }

    // else { this.RequiredDetails.pageNumber = event.page + 1 }
    // console.log(this.RequiredDetails)
    // this.getDeliveryNote()

    this.row2=event.rows
  // console.log(event.rows ,this.totalrows);
  
   
    
 // this.RequiredDetails.pageLimit=event.rows
 // this.pageNumber = event.page + 1;
 // this.RequiredDetails.pageNumber = this.pageNumber
//  console.log(event.rows,this.RequiredDetails.pageLimit)

 if(this.RequiredDetails.pageLimit==event.rows){
  this.RequiredDetails.pageNumber=event.page+1
myVariables.dnPageNumber=this.RequiredDetails.pageNumber
   
}else if( event.rows==10){
  this.RequiredDetails.pageLimit = 10
  this.RequiredDetails.pageNumber=event.page
  myVariables.dnRowsPerPage=this.RequiredDetails.pageLimit


  this.paginator.changePage(0)

}
else if( event.rows==25){
  this.RequiredDetails.pageLimit = 25
  this.RequiredDetails.pageNumber=event.page
  myVariables.dnRowsPerPage=this.RequiredDetails.pageLimit



  this.paginator.changePage(0)

}else if( event.rows==50){
  this.RequiredDetails.pageLimit = 50
  this.RequiredDetails.pageNumber=event.page
  myVariables.dnRowsPerPage=this.RequiredDetails.pageLimit

  this.paginator.changePage(0)
  
}
else if( event.rows==75){
  this.RequiredDetails.pageLimit = 75
  this.RequiredDetails.pageNumber=event.page
  myVariables.dnRowsPerPage=this.RequiredDetails.pageLimit

  this.paginator.changePage(0)
  
}
 else {
    
  // this.RequiredDetails.pageLimit=event.rows 
  this.RequiredDetails.pageNumber=event.page + 1
  myVariables.dnPageNumber=this.RequiredDetails.pageNumber
  // this.first=event.page
  // console.log(event);
  // console.log(this.first);

  
// this.paginator.changePage(0)

 }

 
 console.log(this.RequiredDetails)
 this.getDeliveryNote()
 
  }


  getColor(status) {
    switch (status) {
      case 'Completed':
        return '#3CA724';
      case 'Cancelled':
        return 'red';
    }
  }


}