import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../api.service';
import lodash from 'lodash';
import Swal from 'sweetalert2';
import { MenuItem, MessageService } from 'primeng/api';
import { throwIfEmpty } from 'rxjs/operators';
import { isArray } from 'util';
import { restoreView } from '@angular/core/src/render3';
import { Router } from '@angular/router';
import { myVariables } from 'src/environments/environment';


@Component({
  selector: 'app-location-transfer',
  templateUrl: './location-transfer.component.html',
  styleUrls: ['./location-transfer.component.css'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class LocationTransferComponent implements OnInit {

  RowBgcolor: false
  LocTransfercols = [];
  check = true;
  LocationTransferData = [];

  RequiredLocation = {
    location: ''
  }

  RequiredGetAllLoc = {}
  CheckValue: any;
  selectedRows = [];
  filtered = [];
  selectedData = [];

  display: boolean = false;

  newArray = [];

  stepOne = false;
  stepTwo = false;
  stepThree = false;

  stepOneDiv = true;
  stepTwoDiv = false;
  stepThreeDiv = false;

  RequiredTransferProducts = {
    skudetails: [],
    "fromLocation": "",
    "toLocation": ""
  }
  FilteredTransferData = [];
  TransferredCols = [];
  newLocation = [];

  keyValuess: number;
  goodQuantityLength: any;
  ValueKey: number;
  DamageQuantityLength: any;
  deleteIndex: any;
  DeleteRow: any;
  FinalTransferCol = [];
  FinalTransferData = [];
  stepTwoDiv2: boolean;
  seletedProducts={
    "productArray":[],
    "fromLocation":""
  };
  existingLocations=[];
  finalpostData ={
    "fromLocation":"",
    "productDetails":[
      
    ],
    "summaryId":"",
    "userId":localStorage.getItem('UserID'),
"warehouseId":localStorage.getItem('WarehouseID')

  }
  showRemoveLocationDialog: boolean;
  sourceLocationId: any;
  options: any;
  summaryData: Object;

  constructor(private ApiCall: ApiService, private messageService: MessageService,private router:Router) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber=1;
    myVariables.grnRowsPerPage=1;
    myVariables.returnGrnPageNumber=1;
    myVariables.returnGrnRowsPerPage=1;
    myVariables.dnRowsPerPage=1;
    myVariables.dnPageNumber=1;
    myVariables.shippingOrdersRowsPerPage=1;
    myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  sessionStorage.removeItem('shippingOrdersDate');
  sessionStorage.removeItem('grnReportsDates');
  sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('dnReportsDate');
  sessionStorage.removeItem('active')

    // console.log('called');
    
    // this.FilteredTransferData = JSON.parse(localStorage.getItem('TransferredProducts'))
    // if(localStorage.getItem('TransferredProducts')){
    // this.FinalTransferData = JSON.parse(localStorage.getItem('TransferredProducts'))
    // }
    
    this.LocTransfercols = [
      { field: 'productName', header: 'Product Name' },
      { field: 'productCode', header: 'SKU Code' },
      { field: 'good_quantity', header: 'Good Quantity' },
      { field: 'damaged_quantity', header: 'Damaged Quantity' }
    ];

    this.TransferredCols = [
      { field: 'locationName', header: 'Location' },
      { field: 'productCode', header: 'IriCode' },
      { field: 'good_quantity', header: 'Good Qty' },
      { field: 'damaged_quantity', header: 'Damaged Qty' },
      { field: 'goodQuantity', header: 'Transfer Good' },
      { field: 'damagedQuantity', header: 'Transfer Damaged' }
    ];

    this.FinalTransferCol = [
      {field: 'fromLocationName', header: 'Source Location'},
      { field: 'toLocationName', header: 'Destination Location' },
      { field: 'productId', header: 'Product Id' },
      { field: 'goodQuantity', header: 'Transfered Good Qty' },
      { field: 'damagedQuantity', header: 'Transfered Damaged Qty' }
    ]
    // this.FilteredTransferData[0]['location'] = { location: '', goodqty: '1', damagedqty: '2', goodQuantity: '3', damagedQuantity: '4', iricode : 'DE10000000198'}

    // this.FilteredTransferData[1]['location'] = { location: '', goodqty: '1', damagedqty: '2', goodQuantity: '3', damagedQuantity: '4', iricode : 'FA10000000188'}
    if (this.FilteredTransferData) {
      for (var i = 0; i < this.FilteredTransferData.length; i++) {
        this.FilteredTransferData[i]['location'] = []

        this.FilteredTransferData[i]['location'].push({ location: '', goodqty: this.FilteredTransferData[i].goodqty, damagedqty: this.FilteredTransferData[i].damagedqty, goodQuantity: this.FilteredTransferData[i].goodQuantity, damagedQuantity: this.FilteredTransferData[i].damagedQuantity, productCode: this.FilteredTransferData[i].productCode,productId:this.FilteredTransferData[i].productId })
      }

    }
    // console.log("NEW IMPLEMENT", this.FilteredTransferData)
    if (this.FilteredTransferData.length === 0) {
      this.check = true
    } else {
      this.check = false
    }
    // this.GetAllLocation()
  }

  // GetAllLocation() {
  //   this.ApiCall.GetSearchByLocation(this.RequiredGetAllLoc)
  //     .subscribe(
  //       res => {
  //         this.LocationTransferData = res.result

  //         if (this.LocationTransferData.length === 0) {
  //           this.check = true
  //         } else {
  //           this.check = false
  //         }
  //       }
  //     )
  // }
  enter(event){
    console.log(event)
  }
  GetLocation() {
    this.RequiredLocation.location=this.RequiredTransferProducts.fromLocation
    this.ApiCall.sourceLocation(this.RequiredLocation.location)
      .subscribe(
        res => {
          console.log(res)
          this.LocationTransferData = res['data']
          this.sourceLocationId=res['OverAllData']['locationId']

          if (this.LocationTransferData.length === 0) {
            this.check = true
          } else {
            this.check = false
            this.stepOne=true
          }
        }
      )
  }


  selectRow(checkValue) {
    this.CheckValue = checkValue
    if (checkValue) {
      this.selectedRows = this.LocationTransferData.filter(value => value);
      // console.log(this.LocationTransferData.filter(value => value))
    } else {
      this.selectedRows = [];
    }
    // console.log("SELECTED ROWS", this.CheckValue)
  }
  @ViewChild('e') allcheck: any;


  showDialog() {
    this.display = true;
  }

  submitChangeLocation() {
    // this.selectedData.push.apply(this.selectedData, this.selectedRows)
    this.display = false;

    this.selectedRows.forEach(element => {
      if (lodash.isEqual(element["barcode"], element["barcode"])) delete element["barcode"]
      if (lodash.isEqual(element["category"], element["category"])) delete element["category"]
      if (lodash.isEqual(element["catid"], element["catid"])) delete element["catid"]
      if (lodash.isEqual(element["damagedqty"], element["damagedqty"])) delete element["damagedqty"]
      if (lodash.isEqual(element["goodqty"], element["goodqty"])) delete element["goodqty"]
      if (lodash.isEqual(element["id"], element["id"])) delete element["id"]
      if (lodash.isEqual(element["ldamagedqty"], element["ldamagedqty"])) delete element["ldamagedqty"]
      if (lodash.isEqual(element["lgoodqty"], element["lgoodqty"])) delete element["lgoodqty"]
      if (lodash.isEqual(element["ltotalqty"], element["ltotalqty"])) delete element["ltotalqty"]
      if (lodash.isEqual(element["scatid"], element["scatid"])) delete element["scatid"]
      if (lodash.isEqual(element["subcategory"], element["subcategory"])) delete element["subcategory"]
      if (lodash.isEqual(element["totalqty"], element["totalqty"])) delete element["totalqty"]
      if (lodash.isEqual(element["productname"], element["productname"])) delete element["productname"]
    });

    this.RequiredTransferProducts.skudetails = this.selectedRows
    console.log("All data pushing here", this.RequiredTransferProducts)

    this.ApiCall.transferLocation(this.RequiredTransferProducts)
      .subscribe(
        res => {
          if (res.status == true) {
            Swal.fire({
              toast: true,
              position: 'center',
              text: 'Transferred Successfully',
              showConfirmButton: false,
              timer: 3000,
              type: 'success',
            })
          } else {
            Swal.fire({
              toast: true,
              position: 'center',
              text: 'Please check the error',
              showConfirmButton: false,
              timer: 3000,
              type: 'error',
            })
          }

        }
      )

    // this.selectedData.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    // let ids = this.selectedData.map(o => o.id)
    // this.filtered = this.selectedData.filter(({ id }, index) => !ids.includes(id, index + 1))

    // console.log("find value", this.selectedData)
    // console.log("Filtered value", this.filtered)
  }

  CheckBoxEmpty(index) {
    this.LocationTransferData[index]['goodQuantity'] = ''
    this.LocationTransferData[index]['damagedQuantity'] = ''
    console.log(this.LocationTransferData[index]['productId'])
    this.seletedProducts.productArray.push(this.LocationTransferData[index]['productId'])
    console.log(this.seletedProducts)
  }

  TransferCheckBoxEmpty(index, rowData) {
    console.log(index, rowData)
    // rowData.goodQuantity = ''
    // rowData.damagedQuantity = ''
  }


  /*------------------------------LOCATION GOOD QUANTITY-----------------------*/

  AddGoodQty(index, value) {
    console.log(value)
    this.LocationTransferData[index]['goodQuantity'] = value.good_quantity
  }

  AddEditableGoodQty(index, value,goodQuantity) {
    console.log(goodQuantity);
    
    value.goodQuantity = goodQuantity
  }
  AddEditableGoodQty2(index, value,goodQuantity) {
    console.log(goodQuantity,value);
    
    if(index==0){
      value.goodQuantity = goodQuantity
    }
    else{
      value.goodQuantity = value.good_quantity
    }
  }

  checkLegthLocationGoodQty(e, input, rowData) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > rowData.good_quantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
    return true;
  }
  checkLegthLocationGoodQty2(e, input, rowData,goodQuantity) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > goodQuantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
    return true;
  }

  /*------------------------------LOCATION DAMAGED QUANTITY-----------------------*/

  AddDamagedQty(index, value) {
    this.LocationTransferData[index]['damagedQuantity'] = value.damaged_quantity
  }

  AddEditableDamagedQty(index, value,damagedQuantity) {
    console.log(damagedQuantity);
    
    value.damagedQuantity = damagedQuantity
  }
  AddEditableDamagedQty2(index, value,damagedQuantity) {
    console.log(damagedQuantity,value);
    if(index==0){
      value.damagedQuantity = damagedQuantity
    }
    else{
      value.damagedQuantity = value.damaged_quantity
    }
    
  }

  checkLegthLocationDamagedQty(e, input, rowData) {
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > rowData.damaged_quantity || !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
    return true;
  }
  checkLegthLocationDamagedQty2(e, input, rowData,damagedQuantity) {
    console.log(rowData,damagedQuantity)
    this.keyValuess = +e.key;
    const numberOnlyPattern = '[0-9]+';

    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    this.goodQuantityLength = input.value + (isNaN(this.keyValuess) ? '' : this.keyValuess.toString());
    const match = this.goodQuantityLength.match(numberOnlyPattern);

    if (+this.goodQuantityLength > damagedQuantity|| !match || this.goodQuantityLength === '') {
      e.preventDefault();
    }
    
    return true;
  }

  StepOne() {
    if (this.selectedRows.length === 0) {
      this.stepOne = false;
      this.stepOneDiv = true;
      this.stepTwoDiv = false;
      Swal.fire({
        toast: true,
        position: 'center',
        text: 'Please select a product!',
        showConfirmButton: false,
        timer: 3000,
        type: 'warning',
      })
    }
    else if (this.selectedRows.some(val => val.goodQuantity === '' || val.damagedQuantity === '')) {
      Swal.fire({
        toast: true,
        position: 'center',
        text: 'Please enter the quantity!',
        showConfirmButton: false,
        timer: 3000,
        type: 'warning',
      })
    }
    else {
      this.stepOne = true;
      this.stepOneDiv = false;
      
      this.seletedProducts.fromLocation=this.sourceLocationId
      this.ApiCall.existingLocation(this.seletedProducts).subscribe(res=>{
        console.log(res['data'])
        if(res['status']){
          this.existingLocations=res['data']
          console.log(this.existingLocations)
          this.stepTwoDiv = true;
          this.stepTwoDiv2=false;
          this.stepTwo=true
          console.log('selected Rows',this.selectedRows)
          localStorage.setItem('TransferredProducts', JSON.stringify(this.selectedRows))
          // this.FilteredTransferData = JSON.parse(localStorage.getItem('TransferredProducts'))
          this.FilteredTransferData = this.selectedRows
          // console.log(this.FilteredTransferData)
    
          if (this.FilteredTransferData) {
            for (var i = 0; i < this.existingLocations.length; i++) {
              this.FilteredTransferData[i]['location'] = []
              for(var j=0;j<this.existingLocations[i].length;j++){
                this.FilteredTransferData[i]['location'].push({ locationId: this.existingLocations[i][j].locationId,locationName: this.existingLocations[i][j].locationName, good_quantity: this.existingLocations[i][j].good_quantity, damaged_quantity: this.existingLocations[i][j].damaged_quantity, goodQuantity: '', damagedQuantity: '', productCode: this.existingLocations[i][j].sku,productId:this.existingLocations[i][j].productId })
                console.log('im being hit')
              }
              // this.FilteredTransferData[i]['location'].push(
              //   this.existingLocations[i].forEach(element=>{
              //     { locationName: element.locationName, good_quantity: element.good_quantity, damaged_quantity: element.damaged_quantity, goodQuantity: '', damagedQuantity: '', productCode: element.productCode }
              //   })
            }
            console.log('filtered data',this.FilteredTransferData)
    
          }
          // console.log("NEW IMPLEMENT", this.FilteredTransferData)
          if (this.FilteredTransferData) {
            if (this.FilteredTransferData.length === 0) {
              this.check = true
            } else {
              this.check = false
            }
          }
        }
       
      })
      
    }
  }

searchlocation(data){
  console.log(data.length)
  if(data.length>6){
    this.ApiCall.locationSearch(data).subscribe(res=>{
      console.log(res)
      this.options=res['result']
    })
  }
    
  
 
}
searchLocationarray(data){
  console.log(data)
  this.ApiCall.locationSearch(data).subscribe(res=>{
    console.log(res)
  })
}

addNewLocation2(index,rowData){
  console.log('addNewLocation2',rowData,index)
  console.log(this.FinalTransferData)
  
    console.log('location is there')
    this.FinalTransferData[index].location.push({ locationName: '', good_quantity: '', damaged_quantity: '', goodQuantity: '', damagedQuantity: '', iricode: rowData.productCode })
  
  // else{
  //   console.log('location is not there');
    
  //   this.FinalTransferData[index]['location']=[]
  //   // this.FinalTransferData[index]['location'].push({ locationName: '', good_quantity: '', damaged_quantity: '', goodQuantity: '', damagedQuantity: '', iricode: rowData.productCode })
  // }
}

  AddNewLocation(index, rowData) {
    console.log("LOCATION",rowData, index)

    const CountAllLocationGoodQty = rowData.location.map(({ goodQuantity, ...rest }) => 
    ({ ...rest, goodQuantity: +goodQuantity }));
console.log(CountAllLocationGoodQty);

    var result = [CountAllLocationGoodQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]

    const CountAllLocationDamagedQty = rowData.location.map(({ damagedQuantity, ...rest }) => ({ ...rest, damagedQuantity: +damagedQuantity }));

    var resultss = [CountAllLocationDamagedQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]

    const totalLength = rowData.location.length
    console.log(totalLength);
    if(rowData.location.length!=0){
      console.log(rowData.goodQuantity)
    let good_quantity = rowData.goodQuantity - rowData.location[totalLength - 1].goodQuantity
    let damaged_quantity = rowData.damagedQuantity - rowData.location[totalLength - 1].damagedQuantity
    
    if (rowData.location[0].good_quantity === result[0].goodQuantity && rowData.location[0].damaged_quantity === resultss[0].damagedQuantity) {
      Swal.fire({
        toast: true,
        position: 'center',
        text: 'Quantity not enough',
        showConfirmButton: false,
        timer: 3000,
        type: 'warning',
      })
      return
    }

    if (rowData.location[totalLength - 1].location === '' || rowData.location[totalLength - 1].goodQuantity === '' || rowData.location[totalLength - 1].damagedQuantity === '') {
      Swal.fire({
        toast: true,
        position: 'center',
        text: 'Please fill existing fields before creating new location !',
        showConfirmButton: false,
        timer: 3000,
        type: 'warning',
      })
    }
    if(good_quantity===0 && damaged_quantity===0){
      Swal.fire({
        toast: true,
        position: 'center',
        text: 'Not enough quantity!',
        showConfirmButton: false,
        timer: 3000,
        type: 'warning',
      })
    }
    else {
      this.FinalTransferData[index].location.push({ locationName: '', good_quantity: good_quantity, damaged_quantity: damaged_quantity, goodQuantity: '', damagedQuantity: '', productCode: rowData.productCode, productId: rowData.productId })
      console.log("FINISH", this.FinalTransferData)
    }
  }
    else {
      this.FinalTransferData[index].location.push({ locationName: '', good_quantity: rowData.goodQuantity , damaged_quantity: rowData.damagedQuantity, goodQuantity: '', damagedQuantity: '', productCode: rowData.productCode, productId: rowData.productId })
      console.log("FINISH", this.FinalTransferData)
    }

  }

  DeleteLocation(index, rowData) {
    console.log(index, rowData)
    this.deleteIndex = index
    this.DeleteRow = rowData
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Do you want to delete' })
  }

  onMatReject() {
    this.messageService.clear('c');
  }

  onConfirmDelete() {
    console.log(this.deleteIndex)
    this.FilteredTransferData[this.deleteIndex].location[this.deleteIndex].splice(this.deleteIndex, 1);
    localStorage.setItem('TransferredProducts', JSON.stringify(this.FilteredTransferData));
    this.messageService.clear('c');
  }

  TransferAllProduct() {
this.finalpostData.fromLocation=this.sourceLocationId
this.FinalTransferData=this.FilteredTransferData
console.log(this.FinalTransferData)
    for(var i=0; i<this.FinalTransferData.length; i++){
        console.log("CHECKING LOCATION", this.FilteredTransferData[i].location)
        this.FilteredTransferData[i].location.some(val=>{
          if (val.location === '') {
            console.log("CHECKING LOCATION", val.location)
            Swal.fire({
              toast: true,
              position: 'center',
              text: 'Please enter the destination location !',
              showConfirmButton: false,
              timer: 3000,
              type: 'warning',
            })
            return
          } else {
            // localStorage.setItem('TransferredProducts', JSON.stringify(this.FinalTransferData))
            this.stepThreeDiv = false;
            this.stepOneDiv = false;
            this.stepTwoDiv = false;
            
  
            this.stepOne = true;
            this.stepTwo = true;
            this.stepThree = true;
            this.stepTwoDiv2=true
            
            // this.FinalTransferData = JSON.parse(localStorage.getItem('TransferredProducts'))
            // this.FinalTransferData.forEach(element => {
            //   element.location.forEach(data=>{
            //   console.log('each location',data)
            //   data['toLocation']=data.locationName
            //   this.finalpostData.productDetails.push(data)
            //   })
            // });
          }
        })
        }
   
        for(let i=0;i<this.FinalTransferData.length;i++){

          for(let j = 0;j<this.FinalTransferData[i]['location'].length;j++){

            if(this.FinalTransferData[i]['location'][j]['goodQuantity']===''|| this.FinalTransferData[i]['location'][j]['damagedQuantity']===''){
              this.FinalTransferData[i]['location'].splice(j,1)
              j--
            }
          }
        }
        console.log(this.FinalTransferData);

            for(let i=0;i<this.FinalTransferData.length;i++){
              console.log(this.FinalTransferData.length);
              
              for(let j=0;j<this.FinalTransferData[i]['location'].length;j++){
                this.FinalTransferData[i]['location'][j]['toLocation']=this.FinalTransferData[i]['location'][j]['locationName']
                
                this.finalpostData.productDetails.push(this.FinalTransferData[i]['location'][j])
            //   this.finalpostData.productDetails.push(data)
              }
            }
            console.log(this.FinalTransferData)
            console.log('final data',this.finalpostData)
  }
  DeleteselectedLocations(){
    // localStorage.setItem('TransferredProducts', JSON.stringify(this.FilteredTransferData))
    // this.FinalTransferData = JSON.parse(localStorage.getItem('TransferredProducts'))
    this.FinalTransferData = this.FilteredTransferData
    // console.log(this.FinalTransferData)
    // for(let i=0;i<this.FinalTransferData.length;i++){
    //   for(let j=0;j<this.FinalTransferData[i]['location'].length;j++){
    //     if(this.FinalTransferData[i]['location'][j]['goodQuantity']===''||this.FinalTransferData[i]['location'][j]['damagedQuantity']===''){
    //       this.FinalTransferData[i]['location'].splice(j,1)
    //       j--
    //       console.log(this.FinalTransferData[i]['location'])
    //     }
    //   }
    // }
    for(let i=0;i<this.FinalTransferData.length;i++){
     if(this.FinalTransferData[i]['location'].length!=0){
      this.showRemoveLocationDialog = true
      break
     }
     else(
      this.showRemoveLocationDialog= false
     )
    }
    if(this.showRemoveLocationDialog==true){
      this.messageService.add({ key: 'l', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Do you want to remove all the location' })
    }
    else{
      this.stepThreeDiv = false;
            this.stepOneDiv = false;
            this.stepTwoDiv= false
            this.stepTwoDiv2 = true
    }
  }
  onMatReject1() {
    this.messageService.clear('l');
  }

  onConfirmDelete1() {
    // localStorage.setItem('TransferredProducts', JSON.stringify(this.FilteredTransferData))
    // this.FinalTransferData = JSON.parse(localStorage.getItem('TransferredProducts'))
    this.FinalTransferData=this.FilteredTransferData
    console.log(this.FinalTransferData)
    for(let i=0;i<this.FinalTransferData.length;i++){
      for(let j=0;j<this.FinalTransferData[i]['location'].length;j++){
        // if(this.FinalTransferData[i]['location'][j]['goodQuantity']==''||this.FinalTransferData[i]['location'][j]['damagedQuantity']==''){
          this.FinalTransferData[i]['location'].splice(j,1)
          j--
          console.log(this.FinalTransferData[i]['location'])
        // }
      }
    }
    this.messageService.clear('l');
    this.stepThreeDiv = false;
            this.stepOneDiv = false;
            this.stepTwoDiv= false
            this.stepTwoDiv2 = true
            this.stepThree=true
  }
  finaldata(){
    for(let i=0;i<this.FinalTransferData.length;i++){

      for(let j = 0;j<this.FinalTransferData[i]['location'].length;j++){

        if(this.FinalTransferData[i]['location'][j]['goodQuantity']===''|| this.FinalTransferData[i]['location'][j]['damagedQuantity']===''){
          this.FinalTransferData[i]['location'].splice(j,1)
          j--
        }
      }
    }



    for(let i=0;i<this.FinalTransferData.length;i++){
      console.log(this.FinalTransferData.length);
      
      for(let j=0;j<this.FinalTransferData[i]['location'].length;j++){
        console.log(this.FinalTransferData[i]['location'].length,i,j)
        this.FinalTransferData[i]['location'][j]['toLocation']=this.FinalTransferData[i]['location'][j]['locationName']
        if(this.finalpostData.productDetails.length!=0){
          // this.finalpostData.productDetails.forEach(element=>{
          //   if(element.productCode==this.FinalTransferData[i]['location'][j]['productCode'] &&
          //    element.toLocation==this.FinalTransferData[i]['location'][j]['toLocation'] &&
          //    element.goodQuantity==this.FinalTransferData[i]['location'][j]['goodQuantity'] &&
          //    element.damagedQuantity==this.FinalTransferData[i]['location'][j]['damagedQuantity']){
          //     console.log('this is pushed',this.FinalTransferData[i]['location'][j]);
              
          //     // this.finalpostData.productDetails.push(this.FinalTransferData[i]['location'][j])
          //   }else{
          //     this.finalpostData.productDetails.push(this.FinalTransferData[i]['location'][j])

          //   }
          // })
          for(let k =0 ; k <this.finalpostData.productDetails.length;k++){
            if(this.finalpostData.productDetails[k].productCode==this.FinalTransferData[i]['location'][j]['productCode'] &&
           this.finalpostData.productDetails[k].toLocation==this.FinalTransferData[i]['location'][j]['toLocation'] &&
           this.finalpostData.productDetails[k].goodQuantity==this.FinalTransferData[i]['location'][j]['goodQuantity'] &&
           this.finalpostData.productDetails[k].damagedQuantity==this.FinalTransferData[i]['location'][j]['damagedQuantity']){
            console.log('this is pushed',this.FinalTransferData[i]['location'][j]);
            break
            // this.finalpostData.productDetails.push(this.FinalTransferData[i]['location'][j])
          }else{
            if(k==this.finalpostData.productDetails.length-1){
              this.finalpostData.productDetails.push(this.FinalTransferData[i]['location'][j])
            }
          }
        }
        }
        else{
          this.finalpostData.productDetails.push(this.FinalTransferData[i]['location'][j])
        }
        
        // this.finalpostData.productDetails.push(this.FinalTransferData[i]['location'][j])
    //   this.finalpostData.productDetails.push(data)
      }
    }
    console.log('final post data',this.finalpostData)
    this.SubmitAllData()
    
    
  }
  skip(){
    // localStorage.setItem('TransferredProducts', JSON.stringify(this.FilteredTransferData))
    // this.FinalTransferData = JSON.parse(localStorage.getItem('TransferredProducts'))
    // this.FinalTransferData=this.FilteredTransferData
    // console.log(this.FinalTransferData)
    // for(let i=0;i<this.FinalTransferData.length;i++){
    //   for(let j=0;j<this.FinalTransferData[i]['location'].length;j++){
    //     if(this.FinalTransferData[i]['location'][j]['goodQuantity']===''||this.FinalTransferData[i]['location'][j]['damagedQuantity']===''){
    //       this.FinalTransferData[i]['location'].splice(j,1)
    //       j--
    //       console.log(this.FinalTransferData[i]['location'])
    //     }
    //   }
    // }
    // for(let i=0;i<this.FinalTransferData.length;i++){
    //  if(this.FinalTransferData[i]['location'].length!=0){
    //   this.showRemoveLocationDialog = true
    //   break
    //  }
    //  else(
    //   this.showRemoveLocationDialog= false
    //  )
    // }
    // if(this.showRemoveLocationDialog==true){
    //   this.DeleteselectedLocations()

    // }
    // console.log(this.showRemoveLocationDialog)
    // console.log(this.FinalTransferData)
    this.messageService.clear('l');
    // this.stepThreeDiv = false;
    //         this.stepOneDiv = false;
    //         this.stepTwoDiv= false
    //         this.stepTwoDiv2 = true
  }
  back(){
    this.stepThreeDiv = false;
            this.stepOneDiv = false;
            this.stepTwoDiv= false
            this.stepTwoDiv2 = true
  }
  backToExistingLoca(){

    // this.FinalTransferData=this.FilteredTransferData
    // console.log(this.FilteredTransferData);
    
    // this.stepThreeDiv = false;
    // this.stepOneDiv = false;
    // this.stepTwoDiv= true
    // this.stepTwoDiv2 = false
    this.StepOne()
  }
     SubmitAllData(){
    this.finalpostData.fromLocation=this.sourceLocationId
    console.log('final transfer data',this.FinalTransferData)
    console.log('filterd transfer data',this.FilteredTransferData)
    console.log('final post data',this.finalpostData)
    for(let i=0;i<this.finalpostData.productDetails.length;i++){
      if(this.finalpostData.productDetails[i]['goodQuantity']==='' || this.finalpostData.productDetails[i]['damagedQuantity']==='' ){
         this.finalpostData.productDetails.splice(i,1)
      }
    }
    for(let i=0;i<this.finalpostData.productDetails.length;i++){
         this.ApiCall.locationSearch(this.finalpostData.productDetails[i].locationName).subscribe(res=>{
        this.finalpostData.productDetails[i].toLocation=res['result'][0]['id']
      })
    }
  //   this.finalpostData.productDetails.forEach(async pDetails => {
  //     await this.ApiCall.locationSearch(pDetails.locationName).subscribe(res=>{
  //         pDetails.toLocation=res['result'][0]['id']
  //      })
  // });
//   let arr=[]
//   this.finalpostData.productDetails.forEach(pDetails => {
//     new Promise((resolve, reject) => resolve(this.ApiCall.locationSearch(pDetails.locationName).subscribe(res=>{
//         pDetails.toLocation=res['result'][0]['id']
//      })))
// });
// await Promise.all(this.finalpostData.productDetails)
// let arr = []
//  this.finalpostData.productDetails.forEach(pDetails => {
//      arr.push(new Promise((resolve, reject) => resolve(this.ApiCall.locationSearch(pDetails.locationName).subscribe(res=>{
//          pDetails.toLocation=res['result'][0]['id']
//       }))
//  ))});
//  await Promise.all(arr)
// for(let i=0;i<this.finalpostData.productDetails.length;i++){
//   await new Promise((resolve, reject) => resolve(this.ApiCall.locationSearch(this.finalpostData.productDetails[i].locationName).subscribe(res=>{
// this.finalpostData.productDetails[i].toLocation=res['result'][0]['id']
// })))
// }
setTimeout(() => {
  this.finalapiCall()
  
}, 2000);
// this.finalapiCall()
  }
  finalapiCall(){
    this.finalpostData.productDetails.forEach(element=>{
      delete element.damaged_quantity
      delete element.good_quantity
      delete element.locationName
      delete element.locationId
      delete element.productCode  
      
    })
    
    console.log('final post data',this.finalpostData)
    let arr1 =  new Set(this.finalpostData["productDetails"])
    this.finalpostData.productDetails = [...arr1]
    console.log('final post data',this.finalpostData)    
    
    this.ApiCall.transferLocation(this.finalpostData).subscribe(
      res => {
        console.log('final post data',this.finalpostData)    

        if(res['status']==true){
          Swal.fire({
              toast: false,
              position: 'center',
              text: res['message'],
              showConfirmButton: false,
              timer: 3000,
              type: 'success',
            })
            // this.router.navigate([this.router.url])
            this.stepThreeDiv = true;
            this.stepOneDiv = false;
            this.stepTwoDiv= false
            this.stepTwoDiv2 = false
            this.stepThree=true
          this.ApiCall.locationTransferSummary(res['summaryId']).subscribe(response=>{
            this.summaryData=response['result']
          })
        }
        else{
          Swal.fire({
            toast: false,
            position: 'center',
            text: res['error'],
            showConfirmButton: false,
            timer: 3000,
            type: 'warning',
          })
        }
      console.log(res)
    })

    // Swal.fire({
    //   toast: false,
    //   position: 'center',
    //   text: 'Not Implemented Yet!!!',
    //   showConfirmButton: false,
    //   timer: 3000,
    //   type: 'warning',
    // })
  }

}

