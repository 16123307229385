import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';
import { DatePipe } from '@angular/common';
import { dashboardVarialbles, myVariables } from 'src/environments/environment';

@Component({
  selector: 'app-pendingreceipt',
  templateUrl: './pendingreceipt.component.html',
  styleUrls: ['./pendingreceipt.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PendingreceiptComponent implements OnInit {
  @ViewChild('pagin') paginator: Paginator;
  @ViewChild('calendar', undefined) private calendar: any;
  @ViewChild('myTable') private _table: Table;
  RequiredPendingDelivery =
    {
      status: "pending",
      warehouseId: localStorage.getItem('WarehouseID'),
      startDate: 'firstDate',
      endDate: 'today',
      pageNumber: 1,
      pageLimit: 10,
      customerId: null
    }
  cols = [];
  pendingData: any;
  pending_receipt: any;
  pageNumber: any;
  totalRecords: number;
  today: Date;
  firstDate: any;
  dateTime = new Date();
  rangeDates: any[];
  first: any;
  pagelinks: any;
  rows: any;
  filter = "pi pi-filter";
  customers: any;
  businessNameValue: {}[];
  check: boolean;
  row2: any;
  savedDates: any;
  constructor(private Apicall: ApiService, private router: Router, private datePipe: DatePipe) { }
  ngOnInit() {
    myVariables.completedReceiptRowsPerPage = 1;
    myVariables.completedDeliveryRowsPerPage = 1,
      myVariables.stagingReceiptRowsPerPage = 1;
    myVariables.StaockStatementRowsPerPage = 1;
    myVariables.stagingReceiptPageNumber = 1;
    myVariables.completedReceiptPageNumber = 1;
    myVariables.completedDeliveryPageNumber = 1,
      myVariables.pendingDeliveriespageNumber = 1;
    myVariables.pendingDeliveriesRowsPerPage = 1;
    // myVariables.pendingReceiptRowsPerPage=1;
    // myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber = 1;
    myVariables.grnRowsPerPage = 1;
    myVariables.returnGrnPageNumber = 1;
    myVariables.returnGrnRowsPerPage = 1;
    myVariables.dnRowsPerPage = 1;
    myVariables.dnPageNumber = 1;
    myVariables.shippingOrdersRowsPerPage = 1;
    myVariables.shippingOrdersPageNumber = 1;
    myVariables.StockStatementPageNumber = 1;

    sessionStorage.removeItem('compltdReceiptDate');
    sessionStorage.removeItem('StagingReceiptDate');
    // sessionStorage.removeItem('PendingReceiptDate');
    sessionStorage.removeItem('PendingDeliDate');
    sessionStorage.removeItem('compltdDeliDates');
    sessionStorage.removeItem('shippingOrdersDate');
    sessionStorage.removeItem('grnReportsDates');
    sessionStorage.removeItem('ReturnGrnReportsDates');
    sessionStorage.removeItem('dnReportsDate');
    sessionStorage.removeItem('active')

    // this.dateTime.setDate(this.dateTime.getDate() + 0);
    if (myVariables.pendingReceiptRowsPerPage != 1) {
      this.RequiredPendingDelivery.pageLimit = myVariables.pendingReceiptRowsPerPage
    }
    if (myVariables.pendingReceiptPageNumber != 1) {
      this.RequiredPendingDelivery.pageNumber = myVariables.pendingReceiptPageNumber
    }
    if (dashboardVarialbles.isPendingRoute) {
      this.today = new Date();
      this.firstDate = new Date('01 July 2020');
      console.log(this.today, this.firstDate)

      this.rangeDates = [this.firstDate, this.today]
      const obj = {
        "startDate": this.firstDate,
        "endDate": this.today
      }
      sessionStorage.setItem('PendingReceiptDate', JSON.stringify(obj))
      this.GetallPendingStatus();
    } else {
      if (sessionStorage.getItem('PendingReceiptDate')) {
        this.savedDates = JSON.parse(sessionStorage.getItem('PendingReceiptDate'))
        let date = new Date(this.savedDates.startDate)
        console.log(date);
        let todayDate = new Date(this.savedDates.endDate)

        this.rangeDates = [date, todayDate];

        // console.log(date)
        // console.log(typeof (date))

        this.RequiredPendingDelivery.startDate = this.datePipe.transform(date, 'yyyy-MM-dd')
        console.log(this.RequiredPendingDelivery.startDate);

        this.RequiredPendingDelivery.endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd')
        console.log(this.RequiredPendingDelivery.endDate);
        this.GetallPendingStatus();

      }

      else {
        var _self = this;
        this.today = new Date();
        this.firstDate = new Date();
        // this.firstDate.setMonth(this.today.getMonth());
        this.firstDate.setDate(this.firstDate.getDate() - 7);

        // console.log('today date', this.today)
        // console.log('first date', this.firstDate)
        // console.log(typeof (this.today))
        this.rangeDates = [this.firstDate, this.today];

        this.RequiredPendingDelivery.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
        this.RequiredPendingDelivery.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
        this.GetallPendingStatus();



      }
    }
    localStorage.removeItem('materialInfo')
    localStorage.removeItem('selectedProduct')
    localStorage.removeItem('exisiting_material')
    localStorage.removeItem('ViewReceiptInvoiceDate')
    localStorage.removeItem('ViewReceiptInvoiceNo')
    localStorage.removeItem('ViewReceiptPoNo')
    this.businessNameValue = new Array()
    this.getCustomers();
    this.cols = [
      { field: 'businessName', header: 'Business Name' },
      { field: 'id', header: 'Receipt Pre Alert Number' },
      { field: 'createdAt', header: 'Date' },
      { field: 'pickupDate', header: 'Expected Receipt Date' },
      // { field: 'pickupTime', header: 'Expected Receipt Time' },
      { field: 'poNo', header: 'PO Number' },
      { field: 'grnNumber', header: 'GRN Number' },
      { field: 'status', header: 'Status' },
      { field: 'return', header: 'Returns' }
    ];
  }

  getColor(status) {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'Pending':
        return '#32ade2';
      case 'Staging':
        return 'Orange'

    }
  }
  getCustomers() {
    this.Apicall.getDeliveryStatus().subscribe(res => {
      console.log(res.result)
      this.customers = res.result
      console.log("Customer data: ", this.customers)
      var tempArray = new Array()
      res.result.forEach(customer => {

        this.businessNameValue.push({ label: customer.businessName, value: customer.id })
      });
      console.log('Customers: ', this.businessNameValue)

    }

    )
  }
  customerFilter(event) {
    console.log(event['value'])
    this.RequiredPendingDelivery.customerId = event['value'].toString()
    this.GetallPendingStatus();
  }
  search(data) {
    if (data.length > 2) {
      const obj = {

        "warehouseId": this.RequiredPendingDelivery.warehouseId,      //(not mandatory)
        "status": this.RequiredPendingDelivery.status,   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
        "searchval": data, 	//(mandatory and at least 3 characters)
        "customerId": this.RequiredPendingDelivery.customerId 	//(not mandatory)

      }
      this.Apicall.searchReceiptRequest(obj).subscribe(res => {
        this.pendingData = res['result'];
        this.rows = res['result'].length
        this.totalRecords = 1 * this.rows
        // console.log(res)
        console.log("Pending Receipt ", this.pendingData)
        if (this.rows != 10 && this.rows != 25 && this.rows != 50 && this.rows != 75 && (this.row2)) {
          console.log(this.rows);
          console.log(this.row2);

          console.log("not");

          this.row2 = this.pendingData.length

          this.rows = this.row2
          this.totalRecords = data['totalPages'] * this.row2
          // this.totalRecords=data['totalPages']*this.row2
        }
        else {
          console.log("yes");
          this.rows = myVariables.pendingReceiptRowsPerPage
          // this.totalRecords = 1 * this.rows
          this.totalRecords = data['totalPages'] * this.rows


        }
        this.first = (this.rows * this.RequiredPendingDelivery.pageNumber) - 1

        if (this.pendingData.length === 0) {
          this.check = true

          // console.log(this.check)
        } else {
          for (var i = 0; i < this.pendingData.length; i++) {
            // console.log('i am running');
            // console.log(this.pendingData[i].purchaseOrderNumber)
            this.pendingData[i]['poNo'] = this.pendingData[i].purchaseOrderNumber
            if (this.pendingData[i]['isReturnGrn'] == "true") {
              this.pendingData[i]['return'] = 'Returns'
            } else {
              this.pendingData[i]['return'] = '';
            }
          }
          // console.log(this.pendingData)
          this.check = false
        }
      })
    }
    if (data.length == 0) {
      console.log('i am running');

      this.GetallPendingStatus();
    }

  }
  getColorReturn(returns) {
    switch (returns) {
      case 'Returns':
        return 'orange';
      case '':
        return 'transparent';
    }
  }
  GetallPendingStatus() {
    if (dashboardVarialbles.isPendingRoute) {
      this.RequiredPendingDelivery.endDate = null
      this.RequiredPendingDelivery.startDate = null
    }
    this.Apicall.getPendingReceipt(this.RequiredPendingDelivery)
      .subscribe(
        res => {
          this.pendingData = res.result;

          this.rows = this.pendingData.length
          this.totalRecords = res['noOfPages'] * this.rows
          console.log("PENDING STATUS", this.totalRecords)
          this.rows = this.pendingData.length

          console.log(this.rows);

          if (this.rows != 10 && this.rows != 25 && this.rows != 50 && this.rows != 75 && (this.row2)) {
            console.log(this.rows);
            console.log(this.row2);

            console.log("not");
            // if(this.row2==undefined){
            //   this.row2=this.pendingData.length
            // }
            this.rows = this.row2
            this.totalRecords = res.noOfPages * this.row2
          }
          else {
            console.log("yes");
            console.log(this.rows);

            this.rows = myVariables.pendingReceiptRowsPerPage

            this.totalRecords = res.noOfPages * this.rows

          }
          this.first = (this.rows * this.RequiredPendingDelivery.pageNumber) - 1

          if (this.pendingData.length === 0) {
            this.check = true
            // console.log(this.check)
          } else {
            this.check = false
          }
          for (var i = 0; i < this.pendingData.length; i++) {
            if (this.pendingData[i].return) {
              this.pendingData[i]['return'] = 'Returns'
            } else {
              this.pendingData[i]['return'] = '';
            }
            if (!this.pendingData[i].pickupInvoice.length) {

              this.pendingData[i]['poNo'] = ''
            }
            else {
              for (var j = 0; j < this.pendingData[i].pickupInvoice.length; j++) {
                this.pendingData[i]['poNo'] = this.pendingData[i].pickupInvoice[j].purchaseOrderNumber
                if (this.pendingData[i]['courierMethod'] == 0) {
                  this.pendingData[i]['courierMethodName'] = 'None'
                }
                if (this.pendingData[i]['courierMethod'] == 1) {
                  this.pendingData[i]['courierMethodName'] = 'Platform'
                }
                if (this.pendingData[i]['courierMethod'] == 2) {
                  this.pendingData[i]['courierMethodName'] = 'Shipment'
                }
              }

            }
          }
          console.log(this.pendingData)
        }
      )
  }

  // paginate(event) {
  //   this.pageNumber = event.page + 1;
  //   this.RequiredPendingDelivery.pageNumber = this.pageNumber
  //   this.GetallPendingStatus();
  // }
  paginate(event) {

    console.log(event)
    //  // this.RequiredDetails.pageLimit=event.rows
    //  // this.pageNumber = event.page + 1;
    //  // this.RequiredDetails.pageNumber = this.pageNumber
    //  console.log(event.rows,this.RequiredPendingDelivery.pageLimit)

    //  if(this.RequiredPendingDelivery.pageLimit==event.rows){
    //   this.RequiredPendingDelivery.pageNumber=event.page+1


    // }else if( event.rows==10){
    //   this.RequiredPendingDelivery.pageLimit = 10
    //   this.RequiredPendingDelivery.pageNumber=event.page


    //   this.paginator.changePage(0)

    // }else if( event.rows==25){
    //   this.RequiredPendingDelivery.pageLimit = 25
    //   this.RequiredPendingDelivery.pageNumber=event.page



    //   this.paginator.changePage(0)

    // }else if( event.rows==50){
    //   this.RequiredPendingDelivery.pageLimit = 50
    //   this.RequiredPendingDelivery.pageNumber=event.page

    //   this.paginator.changePage(0)

    // }
    //  else {

    //   // this.RequiredPending.pageLimit=event.rows 
    //   this.RequiredPendingDelivery.pageNumber=event.page + 1
    // // this.paginator.changePage(0)
    //  }


    //  console.log(this.RequiredPendingDelivery)
    //  this.GetallPendingStatus()

    this.row2 = event.rows
    // console.log(event.rows ,this.totalrows);



    // this.RequiredDetails.pageLimit=event.rows
    // this.pageNumber = event.page + 1;
    // this.RequiredDetails.pageNumber = this.pageNumber
    //  console.log(event.rows,this.RequiredPendingDelivery.pageLimit)

    if (this.RequiredPendingDelivery.pageLimit == event.rows) {
      this.RequiredPendingDelivery.pageNumber = event.page + 1
      myVariables.pendingReceiptPageNumber = this.RequiredPendingDelivery.pageNumber
      //  this.GetallPendingStatus()

    } else if (event.rows == 10) {
      this.RequiredPendingDelivery.pageLimit = 10
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingReceiptRowsPerPage = this.RequiredPendingDelivery.pageLimit


      this.paginator.changePage(0)
      // this.GetallPendingStatus()

    }
    else if (event.rows == 25) {
      this.RequiredPendingDelivery.pageLimit = 25
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingReceiptRowsPerPage = this.RequiredPendingDelivery.pageLimit



      this.paginator.changePage(0)
      // this.GetallPendingStatus()

    } else if (event.rows == 50) {
      this.RequiredPendingDelivery.pageLimit = 50
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingReceiptRowsPerPage = this.RequiredPendingDelivery.pageLimit

      this.paginator.changePage(0)
      //  this.GetallPendingStatus()

    }
    else if (event.rows == 75) {
      this.RequiredPendingDelivery.pageLimit = 75
      this.RequiredPendingDelivery.pageNumber = event.page
      myVariables.pendingReceiptRowsPerPage = this.RequiredPendingDelivery.pageLimit

      this.paginator.changePage(0)
      //  this.GetallPendingStatus()


    }
    else {

      // this.RequiredPendingDelivery.pageLimit=event.rows 
      this.RequiredPendingDelivery.pageNumber = event.page + 1
      myVariables.pendingReceiptPageNumber = this.RequiredPendingDelivery.pageNumber

      // this.first=event.page
      // console.log(event);
      // console.log(this.first);


      // this.paginator.changePage(0)

    }


    console.log(this.RequiredPendingDelivery)
    this.GetallPendingStatus()

  }
  formatDate(date) {


    // console.log(typeof (date))
    // console.log('hi')
    // console.log(date)
    let swap = new Date(date)
    //  console.log(typeof (swap))
    // console.log(swap)
    let month = swap.getMonth() + 1;
    // console.log(month)
    let day = swap.getDate();
    // console.log(day)
    // console.log('full year', swap.getFullYear)
    let newMonth: String
    let newDay: String
    if (month < 10) {
      newMonth = '0' + month;
    } else {
      newMonth = month.toString()
    }
    if (day < 10) {
      newDay = '0' + day;
    }
    else {
      newDay = day.toString();
    }
    return swap.getFullYear() + '-' + newMonth + '-' + newDay;
  }
  onDateSelect(event) {
    console.log(event)
    var _self = this;
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;

      // console.log(_self.rangeDates[0], _self.rangeDates[1])
      // console.log('if part is hitting')
      // console.log(_self.rangeDates[0])
      // console.log(typeof (_self.rangeDates[0]));
      const obj = {
        "startDate": _self.rangeDates[0],
        "endDate": _self.rangeDates[1]
      }
      sessionStorage.setItem('PendingReceiptDate', JSON.stringify(obj))
      // sessionStorage.setItem("firstDate", JSON.stringify(_self.rangeDates[0]));
      // sessionStorage.setItem("lastDate", JSON.stringify(_self.rangeDates[1]));


      this.RequiredPendingDelivery.startDate = this.formatDate(_self.rangeDates[0])
      this.RequiredPendingDelivery.endDate = this.formatDate(_self.rangeDates[1])
      this.RequiredPendingDelivery.pageNumber = 1
      myVariables.pendingReceiptPageNumber = 1
      this.paginator.changePage(0)

      this.GetallPendingStatus();
    } else {
      // console.log('else part is hitting')
      e = s + 86400000;
    }
    if (this.rangeDates[1]) { // If second date is selected
      console.log(this.calendar);

      this.calendar.overlayVisible = false;
    }
  }
  defaultDate() {
    console.log('defaultdate');
    if (this.rangeDates[1] == null) {
      if (sessionStorage.getItem('PendingReceiptDate')) {
        this.savedDates = (JSON.parse(sessionStorage.getItem('PendingReceiptDate')))
        this.rangeDates = [new Date(this.savedDates.startDate), new Date(this.savedDates.endDate)]
      }
      else {
        this.rangeDates = [this.firstDate, this.today]
      }
    }

  }
  CreateReceipt() {
    this.router.navigate(['createreceipt'])
  }

  inReceipt(rowData) {
    this.pending_receipt = rowData
    localStorage.setItem('pending_receipt_id', this.pending_receipt.id)
    console.log("ROW DATA PICKING", this.pending_receipt)
    this.router.navigate(['/viewselectedreceipt'])
  }
}
