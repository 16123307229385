import { Component, ElementRef, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormGroup, FormBuilder, Validators, MinLengthValidator, FormArray, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatSnackBar, MatSnackBarVerticalPosition, } from '@angular/material';
import lodash from 'lodash';
declare var require: any
const FileSaver = require('file-saver');
import { saveAs } from 'file-saver'


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-view-selected-delivery',
  templateUrl: './view-selected-delivery.component.html',
  styleUrls: ['./view-selected-delivery.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class ViewSelectedDeliveryComponent implements OnInit {

  @ViewChildren("selectLang") langSelects: QueryList<ElementRef<HTMLSelectElement>>;
  productDimensions = {}
  materialList: any;
  materialListValue = [];
  RequestNo: any;
  CustomerName: any;
  InvoiceDate: any;
  InvoiceTime: any;
  enteredQty: any;
  public QuantityForm: FormGroup;
  DamageQuantityForm: FormGroup;
  submitted = false;
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  addExtraClass: boolean = true;

  RequiredMoveToStage = {
    customerId: "",
    deliveryRequestId: "",
    items: [],
    warehouseId: localStorage.getItem('WarehouseID')
  }
  display = false;
  selectedRowData: any;
  rowData_available: any;
  rowData_proCode: any;
  rowData_Stock: any;
  balance: any;
  addToCart = [];
  keyValue: number;
  ValueKey: number;
  moveTodelivery = false;
  moveToStaging = true;
  pendingStatus = true;
  stagingStatus = false;
  goodValue: any;
  damageValue: any;
  sendProItems = []
  products = [];
  selectedIndex: any;
  selected_dm_Index: any;
  LastindexValue: number;
  LastD_indexValue: number;
  InfoPickupInvoice: any;
  notFound = false;
  MobileNo: any;
  Status: any;
  BusinessName: any;
  DeliveryDate: any;
  damageEnteredQty: any;
  DmBalance: any;
  display_dm: boolean;
  rowData_damage_available: any;
  DocumentDetails: any;
  fileName: any;
  documentView: any;
  EmptyInvoice = false;
  InvoiceDetails: any;
  rowData_ProductName: any;
  PalletLocation = [];
  LocationID: any;
  EnableAdd = true;
  EnableTrash = false;
  somePlaceholder: string = "Select Location";
  rowData_GoodQty: any;
  rowData_DamagedQty: any;
  TotalLocationGoodCount: any;
  TotalLocationDamagedCount: any;
  result: any[];
  CountLocationQty: any;
  selectedLocIndex: any;
  toppings: any
  SelectedFieldList = [];
  selectedLangs = new Set<string>();
  FinalData: any;
  LocationDropdown = [];
  StoreFinalData = [];
  NewLocationArr = [];
  firstValue: any;
  selectedValues = [];
  revertValue: any;
  locationGoodQty: any;
  locationDamagedQty: any;
  arrayTwoSelectedLocation = [];
  selectedLocation = [];
  goodQuantity: any;
  damagedQuantity: any;

  car: any;
  newCar: boolean;
  displayDialog: boolean;
  cols = [];

  deliveryData: any;
  orderDocumentNumber: any;
  ProductCodeTitle: any;
  check: boolean;
  platformRequest: any;
  generateinvoice: boolean = false;
  customerId: any;
  deliveryRequestId: any;
  downloadlabelUrl: any;
  downloadShipLabelurl: any;
  msg: any;
  shippingStatus: any;
  ShippingStatus: any;
  shippingMode: any;
  productDimension: boolean = false;
  wayBillNo: any;
  completeshipmentdata: any;
  shippingCustomerdetails: any;
  invoiceCode: any;
  check1: boolean;
  orderTransferPopUp = false;
  warehouseArray: any;
  selectedWarehouse: any;
  toWarehouse: any = "Choose Warehouse";
  getwarehousebyidData: any;
  fromfacilityCode: any;
  tofacilityCode: any;
  allowOrderTransfer: any;

  constructor(private router: Router, private ApiCall: ApiService, private formBuilder: FormBuilder, private snackBar: MatSnackBar) {
    {
      this.QuantityForm = formBuilder.group({
        'Rows': formBuilder.array([])
      });
    }
  }
  ngOnInit() {
    this.addToCart = [];
    // localStorage.setItem('Selected_Location', "[]")
    this.warehouseArray = JSON.parse(localStorage.getItem('WarehouseArray'))
    for (let i = 0; i < this.warehouseArray.length; i++) {
      if (this.warehouseArray[i].id == localStorage.getItem('WarehouseID')) {
        this.selectedWarehouse = this.warehouseArray[i]
      }

    }
    // for (let i = 0; i < this.warehouseArray.length; i++) {
    //   if (this.warehouseArray[i] == this.selectedWarehouse) {
    //     this.warehouseArray = this.warehouseArray.filter(item => item != this.selectedWarehouse)
    //   }
    // }
    console.log(this.selectedWarehouse);
    console.log(this.warehouseArray)

    this.RedirectToCompleteDelivery();
    // this.initRows()
    this.QuantityFormValidation();
    this.materialList = [
      { field: 'productCode', header: 'Product Code' },
      { field: 'productName', header: 'Product Name' },
      { field: 'location', header: 'Location' },
      { field: 'goodQuantity', header: 'Good Quantity' },
      { field: 'damagedQuantity', header: 'Damaged Quantity' },
      // { field: 'palletNumber', header: 'Pallet Number' },
      // { field: 'location', header: 'Location' },
      { field: 'goodScannedQuantity', header: 'Scanned Good Quantity' },
      { field: 'damagedScannedQuantity', header: 'Scanned Damaged Quantity' },
    ];

    this.cols = [
      { field: 'productCode', header: 'Product Code' },
      { field: 'productName', header: 'Product Name' },
      { field: 'goodQuantity', header: 'Req.G Qty' },
      { field: 'damagedQuantity', header: 'Req.D Qty' },
      { field: 'availableGoodQuantity', header: 'Avail.G Qty' },
      { field: 'availableDamagedQuantity', header: 'Avail.D Qty' },
      { field: 'shortageGoodQuantity', header: 'Short.G Qty' },
      { field: 'shortageDamagedQuantity', header: 'Short.D Qty' },
      { field: 'status', header: 'Status' },
    ];

  }

  MoveToStaging() {
    this.submitted = true;
    console.log("Pushed Valuse", this.addToCart)
    console.log("MAterial list", this.materialListValue)
    // this.addToCart = [];

    this.addToCart.forEach(data => {
      data.materialLocations.forEach(element => {
        element["locationQuantity"] = parseInt(element.totalqty)
        element["locationGoodQuantity"] = parseInt(element["locationGoodQuantity"])
        element["locationDamagedQuantity"] = parseInt(element["locationDamagedQuantity"])

        element.id = element.locationId

        // delete element["locationId"]
        delete element["goodQuantity"]
        delete element["damagedQuantity"]
        delete element["goodqty"]
        delete element["damagedqty"]
        delete element["totalqty"]
        delete element["productId"]
        delete element["status"]
        delete element["rowid"]





      })
      console.log('data', data)

      // console.log("HEY THERE LOOK HERE", data)
      const formattedData = {}
      formattedData["SkuUpcEan"] = data.SkuUpcEan
      formattedData["id"] = data.id
      formattedData["location"] = data.location
      formattedData["palletNumber"] = data.palletNumber
      formattedData["productCode"] = data.productCode
      formattedData["productId"] = data.productId.toString();
      formattedData["productName"] = data.productName
      formattedData["quantity"] = data.quantity.toString();
      formattedData["goodQuantity"] = data.goodQuantity
      formattedData["damagedQuantity"] = data.damagedQuantity
      formattedData["materialLocations"] = data.materialLocations


      data.quantity.toString()
      // console.log("BEFOR DELETE KEYS", formattedData)
      if (lodash.isEqual(formattedData['id'], data.id)) delete formattedData['id']
      if (lodash.isEqual(formattedData['location'], data.location)) delete formattedData['location']
      if (lodash.isEqual(formattedData['palletNumber'], data.palletNumber)) delete formattedData['palletNumber']
      if (lodash.isEqual(formattedData['productCode'], data.productCode)) delete formattedData['productCode']
      if (lodash.isEqual(formattedData['productId'], data.productId)) delete formattedData['productId']
      delete formattedData["productName"]
      delete formattedData["quantity"]

      if (this.addToCart.length == this.materialListValue.length) {
        this.NewLocationArr.push(formattedData)
        // console.log("COLLECTED DATA", this.sendProItems)
        this.RequiredMoveToStage.items = this.NewLocationArr
        // localStorage.setItem('Materials', JSON.stringify(this.sendProItems))
        localStorage.setItem('selected_product', JSON.stringify(this.NewLocationArr));
      }
    })

    console.log("HEY THERE ADD CART CHECKING", this.addToCart, this.materialListValue)

    if (this.addToCart.length != this.materialListValue.length) {
      const snack = this.snackBar.open('Please scan all the skus', 'X', {
        duration: 1500,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
    }
    else {
      // if (this.addToCart.length == this.materialListValue.length) {
      const checkValue =
        this.addToCart.every(value =>
          value.goodQuantity == value.goodScannedQuantity && value.damagedQuantity == value.damagedScannedQuantity
        )
      console.log("CHECK_VALUE ALL PARAMETERS", this.RequiredMoveToStage)

      if (checkValue) {
        this.ApiCall.getMoveToStage(this.RequiredMoveToStage)
          .subscribe(
            res => {
              if (res.message === "operation failed") {
                Swal.fire({
                  toast: true,
                  timer: 3000,
                  type: "error",
                  text: 'Quantity specified here is more than product in the delivery Request',
                  showConfirmButton: false
                })
                this.moveToStaging = true;
                this.moveTodelivery = false;
              }
              else {
                console.log('Moved to Staging Successfully', res)
                Swal.fire({
                  toast: true,
                  timer: 3000,
                  type: "success",
                  text: 'Moved to Staging Successfully',
                  showConfirmButton: false
                })
                this.moveToStaging = false;
                this.moveTodelivery = true;
                this.stagingStatus = true;
                this.pendingStatus = false;
              }
            }
          )
      }
      else {
        const snack = this.snackBar.open('Please Enter the Quantity', 'X', {
          duration: 1500,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }

    }

  }
  MoveToDelivery() {
    //Delivery Api should call
    // localStorage.setItem('selected_product', JSON.stringify(this.NewLocationArr));
    this.router.navigate(['completdelivery'])
  }
  RedirectToCompleteDelivery() {
    this.ApiCall.getViewSelectedDelivery()
      .subscribe(res => {
        if (res.result.deliveryDetails.status === "Staging") {
          this.stagingStatus = true;
          this.pendingStatus = false;
          this.moveTodelivery = true;
          this.moveToStaging = false;
        }
        console.log('get View selected delivery=', res)
        console.log(res.result.deliveryDetails.courierMethod)
        this.shippingMode = res.result.deliveryDetails.courierMethod
        this.customerId = res.result.customerDetails.customerId,
          this.deliveryRequestId = res.result.deliveryDetails.id,
          this.downloadlabelUrl = res.result.deliveryDetails.shippingLabelLink
        this.downloadShipLabelurl = res.result.deliveryDetails.shipmentLabelUrl
        this.shippingStatus = res.result.deliveryDetails.shippingStatus
        this.wayBillNo = res.result.deliveryDetails.wayBill
        this.invoiceCode = res.result.deliveryDetails.invoiceCode
        console.log(this.invoiceCode)

        this.shippingCustomerdetails = res.result.shippingCustomer
        // console.log('shipping customer details',JSON.stringify(this.shippingCustomerdetails))


        localStorage.setItem('Delivery_invoice', JSON.stringify(res.result.deliveryDetails.invoices))
        // console.log("COMPLETE DELIVERY ", res)
        this.products = res.result.materials
        // localStorage.setItem('Dly_CusId', res.result.customerDetails.customerId)
        this.materialListValue = res.result.materials;
        this.LocationDropdown = res.result.materials

        console.log("MATERIAL LIST VALUE", this.materialListValue)
        this.RequestNo = res.result.deliveryDetails.id;
        this.CustomerName = res.result.customerDetails.name;
        this.MobileNo = res.result.customerDetails.mobileNo;
        this.Status = res.result.deliveryDetails.status;
        this.DeliveryDate = res.result.deliveryDetails.createdAt
        this.InvoiceDetails = res.result
        this.BusinessName = res.result.deliveryDetails.businessName
        this.orderDocumentNumber = res.result.deliveryDetails.orderDocumentNumber
        this.allowOrderTransfer = res.result.deliveryDetails.allowTransfer

        localStorage.setItem('OrderDocNumber', this.orderDocumentNumber)

        this.ApiCall.getWarehouseBycustId(this.customerId).subscribe(data => {
          console.log(data)
          this.getwarehousebyidData = data['data']


          this.warehouseArray = this.warehouseArray.filter(item => {
            const warehouseId = item.id;
            const warehouseIdsToRemove = data['data'].map(obj => obj.warehouseId);
            return warehouseIdsToRemove.includes(warehouseId);
          });
          this.warehouseArray = this.warehouseArray.filter(item => item != this.selectedWarehouse)
          console.log(this.warehouseArray);
          // this.warehouseArray = this.warehouseArray.filter(item => item != this.selectedWarehouse)
          // console.log(this.warehouseArray);



        })

        if (!this.InvoiceDetails.deliveryDetails.invoices.length) {
          this.EmptyInvoice = true;
        }
        else {
          this.InfoPickupInvoice = res.result.deliveryDetails.invoices
          // console.log("CHECKING INVOICE", this.InfoPickupInvoice)
          localStorage.setItem('Dly_Invoice_date', res.result.deliveryDetails.invoices[0].invoiceDate)
          localStorage.setItem('Dly_Invoice_No', res.result.deliveryDetails.invoices[0].invoiceNumber)
        }

        this.DocumentDetails = res.result
        if (!this.DocumentDetails.documents.length) {
          this.notFound = true
        }
        else {
          this.fileName = this.DocumentDetails.documents
          this.documentView = this.fileName[0].fileUrl
        }
        this.RequiredMoveToStage.customerId = res.result.customerDetails.customerId.toString()
        this.RequiredMoveToStage.deliveryRequestId = res.result.deliveryDetails.id.toString()

        for (var i = 0; i < this.materialListValue.length; i++) {
          this.materialListValue[i]['SkuUpcEan'] = this.materialListValue[i]['productCode']
          this.materialListValue[i]["status"] = res.result.deliveryDetails.status;
        }

        this.materialListValue.forEach(data => {
          // console.log("HEY THERE LOOK HERE", data)
          data.materialLocations.forEach(element => {
            element["locationQuantity"] = element.totalqty

          })
          console.log('data', data)
          const formattedData = {}
          formattedData["SkuUpcEan"] = data.SkuUpcEan
          formattedData["id"] = data.id
          formattedData["location"] = data.location
          formattedData["palletNumber"] = data.palletNumber
          formattedData["productCode"] = data.productCode
          formattedData["productId"] = data.productId.toString();
          formattedData["productName"] = data.productName
          formattedData["quantity"] = data.quantity.toString();
          formattedData["goodQuantity"] = data.goodQuantity
          formattedData["damagedQuantity"] = data.damagedQuantity
          formattedData["materialLocations"] = data.materialLocations

          data.quantity.toString()
          // console.log("BEFOR DELETE KEYS", formattedData)
          if (lodash.isEqual(formattedData['id'], data.id)) delete formattedData['id']
          if (lodash.isEqual(formattedData['location'], data.location)) delete formattedData['location']
          if (lodash.isEqual(formattedData['palletNumber'], data.palletNumber)) delete formattedData['palletNumber']
          if (lodash.isEqual(formattedData['productCode'], data.productCode)) delete formattedData['productCode']
          if (lodash.isEqual(formattedData['productId'], data.productId)) delete formattedData['productId']
          this.sendProItems.push(formattedData)
          // console.log("COLLECTED DATA", this.sendProItems)
          // this.RequiredMoveToStage.items = this.sendProItems
          localStorage.setItem('Materials', JSON.stringify(this.sendProItems))
          localStorage.setItem('selected_product', JSON.stringify(this.sendProItems));
        })

      })
  }
  orderTrasferDialog() {
    this.orderTransferPopUp = true;

  }
  cancelOrderTrasferPopUp() {
    this.orderTransferPopUp = false
    this.toWarehouse = 'Choose Warehouse'
  }
  transferOrder() {
    if (this.toWarehouse == "Choose Warehouse") {
      // this.toWarehouse = ''
      this.orderTransferPopUp = false
      Swal.fire({

        title: 'Please select To warehouse to transfer',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
      this.orderTransferPopUp = true

      return
    }
    this.fromfacilityCode = this.getwarehousebyidData.filter(item => item.warehouseId == this.selectedWarehouse.id)
    this.tofacilityCode = (this.getwarehousebyidData.filter(item => item.warehouseId == this.toWarehouse))[0].facilityCode
    // this.getwarehousebyidData = this.getwarehousebyidData.filter(item => item.warehouseId == this.toWarehouse)
    console.log(this.getwarehousebyidData);
    console.log(this.fromfacilityCode, this.tofacilityCode);

    const obj = {
      "orderNumber": this.orderDocumentNumber,
      "transferTo": {
        "fromFacilityCode": this.fromfacilityCode[0].facilityCode,
        "toFacilityCode": this.tofacilityCode
      }
    }
    console.log(obj);
    this.ApiCall.orderTransfer(obj).subscribe(res => {
      console.log("ORDER TRANSFER RESPONSE", res);
      if (res['status'] == true) {
        Swal.fire({
          toast: true,
          title: res['message'],
          timer: 2000,
          type: "success",
          showConfirmButton: false
        })
        this.orderTransferPopUp = false
        this.router.navigate(['pendingdeliveries'])
      }
      else {
        this.orderTransferPopUp = false
        Swal.fire({
          toast: true,
          title: res['message'],
          timer: 2000,
          type: "error",
          showConfirmButton: false
        })
        this.orderTransferPopUp = true
      }
    })

  }
  OrderNumberDetails() {
    if (localStorage.getItem('OrderDocNumber') !== '') {
      this.ApiCall.getorderNumberDetails()
        .subscribe(res => {
          this.deliveryData = res.result.materials;
          this.ProductCodeTitle = res.result.orderNumber;

          if (this.deliveryData.length == 0) {
            this.check1 = true
          } else {
            this.check1 = false
          }

        })
    }
  }
  QuantityFormValidation() {
    this.QuantityForm = this.formBuilder.group({
      Rows: this.formBuilder.array([])
    });
  }

  get PalletArr() {
    return this.QuantityForm.get("Rows") as FormArray;
  }

  initRows(Rows?: any) {
    console.log("ROWS DATA", Rows)
    console.log('id', this.selectedRowData.id)
    return this.formBuilder.group({
      'id': [this.selectedRowData && this.selectedRowData.id ? this.selectedRowData.id : ''],
      'goodQuantity': [Rows && Rows.goodqty ? Rows.goodqty : 0, [Validators.required]],
      'damagedQuantity': [Rows && Rows.damagedqty ? Rows.damagedqty : 0, [Validators.required]],
      'location': [Rows ? Rows.location : ""],
      'locationGoodQuantity': [Rows ? Rows.locationGoodQuantity : "", [Validators.required, Validators.min(1)]],
      'locationDamagedQuantity': [Rows ? Rows.locationDamagedQuantity : "", [Validators.required, Validators.min(1)]],

    });
    // (<FormArray>this.QuantityForm.get('Rows')).push(fg);
  }

  addNewRow(Rows?: any) {


    console.log("QUANTITY", typeof (this.selectedRowData.goodQuantity), "&&", typeof (this.selectedRowData.damagedQuantity))

    var ContainerLength = this.QuantityForm.value.Rows.length

    this.CountLocationQty = this.QuantityForm.value.Rows

    const CountAllLocationGoodQty = this.CountLocationQty.map(({ locationGoodQuantity, locationDamagedQuantity, ...rest }) => ({ ...rest, locationGoodQuantity: +locationGoodQuantity, locationDamagedQuantity: +locationDamagedQuantity }));

    this.result = [CountAllLocationGoodQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]

    this.TotalLocationGoodCount = this.result[0].locationGoodQuantity
    this.TotalLocationDamagedCount = this.result[0].locationDamagedQuantity

    for (var i = 0; i < this.QuantityForm.value.Rows.length; i++) {
      if (this.QuantityForm.value.Rows[i].location === undefined || this.QuantityForm.value.Rows[i].location === "undefined") {
        Swal.fire({
          toast: true,
          title: 'Please select location!',
          timer: 2000,
          type: "warning",
          showConfirmButton: false
        })
        return
      }
    }

    if (this.QuantityForm.value.Rows[ContainerLength - 1].locationGoodQuantity === '' || this.QuantityForm.value.Rows[ContainerLength - 1].locationDamagedQuantity === '' || this.QuantityForm.value.Rows[ContainerLength - 1].locationGoodQuantity === null || this.QuantityForm.value.Rows[ContainerLength - 1].locationDamagedQuantity === null) {
      Swal.fire({
        toast: true,
        title: 'Please fill existing fields before creating new one !',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
      return
    }


    if ((this.selectedRowData.goodQuantity === this.TotalLocationGoodCount || this.selectedRowData.goodQuantity <= this.TotalLocationGoodCount) && (this.selectedRowData.damagedQuantity === this.TotalLocationDamagedCount || this.selectedRowData.damagedQuantity <= this.TotalLocationDamagedCount)) {
      Swal.fire({
        toast: true,
        title: 'Quantity is not enough!',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
      return
    } else {
      this.PalletArr.push(this.initRows());
    }
  }

  isDisabled(value) {
    return this.selectedValues.includes(value);
  }

  valueBinding(value, index) {
    let Values = value.substring(3)
    this.selectedRowData.materialLocations.forEach(element => {
      if (element.location === Values) {
        this.PalletArr.removeAt(index);
        this.PalletArr.push(this.initRows(element));
      }
    });
    console.log("UPDATED VALUE FORM ", this.QuantityForm.value.Rows)
  }

  deleteRow(index: number) {
    var delBtn = confirm(" Do you want to delete ?");
    if (delBtn == true) {
      this.selectedValues.splice(index, 1)
      this.PalletArr.removeAt(index);
    }
  }

  async addingDelivery() {

    var CountLocationTotalQty = this.QuantityForm.value.Rows

    console.log("NEED TO CHECK HERE", this.QuantityForm.value.Rows)

    console.log("Pushed Valuse", this.addToCart)

    const CountAllLocationTotalQty = CountLocationTotalQty.map(({ locationGoodQuantity, locationDamagedQuantity, ...rest }) => ({ ...rest, locationGoodQuantity: +locationGoodQuantity, locationDamagedQuantity: +locationDamagedQuantity }));

    var Totalresult = [CountAllLocationTotalQty.reduce((acc, n) => {
      for (var prop in n) {
        if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
        else acc[prop] = n[prop];
      }
      return acc;
    }, {})]
    console.log('this is running');

    if (this.selectedRowData.goodQuantity !== Totalresult[0].locationGoodQuantity || this.selectedRowData.damagedQuantity !== Totalresult[0].locationDamagedQuantity) {
      console.log('this is running');
      this.display = false
      Swal.fire({
        toast: true,
        title: 'Total Count is not matching ! Please Check',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
      this.display = true
      console.log('this is running');
      console.log(Totalresult[0].locationGoodQuantity, this.selectedRowData.goodQuantity);


    } else {
      Swal.fire({
        toast: true,
        title: 'Quantity Added Successfully!',
        timer: 2000,
        type: "success",
        showConfirmButton: false

      })
      console.log('this is running');


      for (var i = 0; i < this.QuantityForm.value.Rows.length; i++) {
        if (this.QuantityForm.value.Rows[i].location === undefined || this.QuantityForm.value.Rows[i].location === "undefined") {
          Swal.fire({
            toast: true,
            title: 'Please select location!',
            timer: 2000,
            type: "warning",
            showConfirmButton: false
          })
          return
        }
      }
      console.log('this is running');

      this.selectedRowData.materialLocations.forEach(element => {
        if (lodash.isEqual(element["location"], element["location"])) delete element["location"]
      });

      var a = this.QuantityForm.value.Rows
      var b = this.selectedRowData.materialLocations

      // console.log("1 .HELLO THERE PLEASE LOOK INTO THIS", a)
      // console.log("2 .HELLO THERE PLEASE LOOK INTO THIS", b)
      console.log('this is running');

      this.FinalData = a.map((item, i) => Object.assign({}, item, b[i]));

      console.log("3 .HELLO THERE PLEASE LOOK INTO THIS", this.FinalData)

      // this.FinalData = this.QuantityForm.value.Rows.map((item, index) => ({ ...item, ...this.selectedRowData.materialLocations[index] }));

      for (var i = 0; i < this.FinalData.length; i++) {
        if (this.FinalData[i].location === undefined || this.FinalData[i].location === null || this.FinalData[i].locationGoodQuantity === "" || this.FinalData[i].locationGoodQuantity === null || this.FinalData[i].locationDamagedQuantity === "" || this.FinalData[i].locationDamagedQuantity === null) {
          Swal.fire({
            toast: true,
            title: 'Please fill required fields!',
            timer: 2000,
            type: "warning",
            showConfirmButton: false
          })
          return
        }
      }

      // console.log("CHECK KARO HERE", this.FinalData)
      // localStorage.setItem('Selected_Location', JSON.stringify(this.FinalData))
      console.log("before selected");

      this.FinalData.forEach(element => {
        element.rowid = this.selectedRowData.id
        if (lodash.isEqual(element["quantity"], element["quantity"])) delete element["quantity"]
        if (lodash.isEqual(element["isdisabled"], element["isdisabled"])) delete element["isdisabled"]
      });

      this.selectedRowData.goodScannedQuantity = Totalresult[0].locationGoodQuantity
      this.selectedRowData.damagedScannedQuantity = Totalresult[0].locationDamagedQuantity
      this.selectedRowData.materialLocations = this.FinalData
      console.log("after selected");

      this.display = false
      const index = this.addToCart.findIndex((product) => {
        return product.productId === this.selectedRowData.productId;
      });
      if (this.addToCart[index]) {
        this.addToCart[index].goodScannedQuantity = this.selectedRowData.goodScannedQuantity;
        this.addToCart[index].damagedScannedQuantity = this.selectedRowData.damagedScannedQuantity;
      } else {
        this.addToCart.push(this.selectedRowData);
      }
      // localStorage.setItem('Selected_Location_Here', JSON.stringify(this.addToCart))
      // this.QuantityForm.reset()
    }


  }

  addingQty(ri, rowData) {
    console.log('row data', ri, rowData)
    this.display = true
    // this.QuantityForm.setControl('Rows', this.formBuilder.array([this.QuantityFormValidation()]))

    this.selectedIndex = ri
    this.selectedRowData = rowData
    console.log(this.selectedRowData)

    // console.log("GETTING VALUE", this.selectedRowData.id)

    this.LocationDropdown = JSON.parse(localStorage.getItem('Materials'))

    this.PalletLocation = this.LocationDropdown[ri].materialLocations

    this.PalletLocation.map(function (entry) {
      entry.isdisabled = false;
      return entry;
    });;

    if (this.PalletLocation.length === 0) {
      this.somePlaceholder = "No Location Found";
    }
    this.rowData_ProductName = this.selectedRowData.productName
    this.rowData_available = this.selectedRowData.quantity
    this.rowData_proCode = this.selectedRowData.productCode
    this.rowData_Stock = this.selectedRowData.quantity;
    this.rowData_GoodQty = this.selectedRowData.goodQuantity;
    this.rowData_DamagedQty = this.selectedRowData.damagedQuantity

    this.QuantityForm.reset()
    this.QuantityFormValidation();
    this.selectedValues = []

    if (this.selectedRowData.goodScannedQuantity === "0" || this.selectedRowData.goodScannedQuantity === 0) {
      this.PalletArr.push(this.initRows());
      console.log(this.PalletArr)
    } else {
      this.selectedRowData.materialLocations.forEach(element => {
        if (element.rowid === this.selectedRowData.id) {
          this.selectedValues.push(element.location)
          this.PalletArr.push(this.initRows(element));
          console.log("ELEMENT", element)
        }
      });

    }
  }
  createLabel() {
    const obj = {
      "customerId": this.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.deliveryRequestId
    }
    console.log('CreateLabel', obj)
    this.ApiCall.createLabel(obj).subscribe(res => {
      console.log(res)
      if (res['status'] == true) {
        this.msg = res['message']
        Swal.fire({
          title: '<h3>Invoice generated successfully</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        this.msg = res['error']
        Swal.fire({
          title: '<h3>Generate invoice failed</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'error'
        })
      }
    })
  }
  generateInvoice() {
    const obj = {
      "customerId": this.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.deliveryRequestId
    }
    console.log(obj)
    this.ApiCall.generateInvoiceAndLabel(obj).subscribe(res => {
      console.warn(res)
      if (res['status']) {
        this.generateinvoice = true
        this.msg = res['message']
        //this.downloadlabelUrl=res['result']['shippingLabelLink']
        this.downloadlabelUrl = res['result']['0']['shippingLabelLink']
        Swal.fire({
          title: '<h3>Generated Invoice and Label successfully</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'success'
        })
      }
      else {
        this.msg = res['error']
        Swal.fire({
          title: '<h3>Generate Invoice and Label failed</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'error'
        })
      }
      //this.downloadlabelUrl="https://unicommerce-channel-shippinglabels.s3.amazonaws.com/0bb59d77b69467f1bc0f9520a628e76e.pdf"
      //this.generateinvoice=true
    })

  }
  createShip() {
    console.log('i am clicked')
    localStorage.setItem('shipCustDetails', JSON.stringify(this.shippingCustomerdetails))
    this.router.navigate(['create-shipment'])

  }
  createShipment() {
    console.log('product dimension', this.productDimensions)
    // if(this.productDimensions['shipmentWeight'] !== ''
    // && this.productDimensions['shipmentHeight'] !== '' 
    // && this.productDimensions['shipmentWidth'] !== ''
    // && this.productDimensions['shipmentLength'] !== ''){
    this.ApiCall.completeShipment(this.productDimensions).subscribe(res => {

      this.completeshipmentdata = res['createOrder']
      console.log(this.completeshipmentdata.status)
      if (this.completeshipmentdata.status != 'error') {
        this.productDimension = false
        Swal.fire({
          title: '<h3>Shipment created successfully</h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        this.productDimension = false
        Swal.fire({
          title: '<h3>Shipment could not be created </h3>',
          text: this.completeshipmentdata.data['1'].remark,
          showConfirmButton: true,
          type: 'error'
        })
      }
    })
    // }
    // else{
    //   console.log('invalid dimensions')
    //   this.productDimension=false
    //   Swal.fire({
    //     title: '<h3>Shipment could not be created </h3>',
    //         text: 'Please enter valid dimensions',
    //         showConfirmButton: true,
    //         type: 'error'
    //     // 
    //   }).then((result)=>{
    //     console.log('swal result',result.value)
    //     if(result.value==true){
    //       this.productDimension=true
    //     }
    //   })

    // }

  }
  printShippingLabel() {
    const obj = {
      "deliveryRequestId": this.deliveryRequestId
    }
    this.ApiCall.shippingLabelLink(obj).subscribe(res => {
      console.log(res)
      if (res['status'] == true) {
        Swal.fire({
          title: '<h3>Shipment label is generated please click on download to download the label</h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'success'
        })
        this.ngOnInit()
      }
      else {
        Swal.fire({
          title: '<h3>Shipment label could not be generated </h3>',
          text: res['message'],
          showConfirmButton: true,
          type: 'error'
        })
      }

    })
  }

  genInvoice() {
    const obj = {
      "customerId": this.customerId,
      "warehouseId": parseInt(localStorage.getItem('WarehouseID')),
      "deliveryRequestId": this.deliveryRequestId
    }
    console.log(obj)
    this.ApiCall.generateInvoiceAndLabel(obj).subscribe(res => {
      console.warn(res)
      if (res['status']) {
        this.generateinvoice = true
        this.msg = res['message']
        //this.downloadlabelUrl=res['result']['shippingLabelLink']
        this.downloadlabelUrl = res['result']['0']['shippingLabelLink']
        Swal.fire({
          title: '<h3>Generated Invoice and Label successfully</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'success'

        })
        this.ngOnInit()
      }
      else {
        this.msg = res['error']
        Swal.fire({
          title: '<h3>Generate Invoice and Label failed</h3>',
          text: this.msg,
          showConfirmButton: true,
          type: 'error'
        })


      }
      //this.downloadlabelUrl="https://unicommerce-channel-shippinglabels.s3.amazonaws.com/0bb59d77b69467f1bc0f9520a628e76e.pdf"
      //this.generateinvoice=true
    })

  }
  downLabel() {
    saveAs(this.downloadlabelUrl, this.orderDocumentNumber + "_" + this.deliveryRequestId + "_Invoice.pdf", { type: 'application/pdf' })
  }
  downInvoice() {

  }

  /*---------------------------CALCULATION-------------------------------*/

  checkLength1(e, input, index) {
    // console.log("checking E value", this.QuantityForm.value.Rows, "INDEX", index)
    this.keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';
    this.goodValue = input.value + (isNaN(this.keyValue) ? '' : this.keyValue.toString());
    const match = this.goodValue.match(numberOnlyPattern);

    if (+this.goodValue > this.QuantityForm.value.Rows[index].goodQuantity || !match || this.goodValue === '') {
      e.preventDefault();
    }
  }

  checkLength2(d, inputs, index) {
    // console.log("checking E value", e , "input", input)
    this.ValueKey = +d.key;
    const numberOnlyPattern = '[0-9]+';
    this.damageValue = inputs.value + (isNaN(this.ValueKey) ? '' : this.ValueKey.toString());
    const match = this.damageValue.match(numberOnlyPattern);

    if (+this.damageValue > this.QuantityForm.value.Rows[index].damagedQuantity || !match || this.damageValue === '') {
      d.preventDefault();
    }
  }
  documentViewPage() {
    window.open(this.documentView, '_blank')
  }


  onRowSelect(event) {
    // localStorage.setItem('OrderDocNumber', this.orderDocumentNumber)
    this.newCar = false;
    this.displayDialog = true;
    this.OrderNumberDetails();

    if (localStorage.getItem('OrderDocNumber') == '') {
      this.ProductCodeTitle = "Not Found";
      this.check = true;
    } else {
      if (this.deliveryData.length === 0) {
        this.check = true;
        // console.log(this.check)
      } else {
        this.check = false
        this.deliveryData.forEach((x) => {
          if (x.availableGoodQuantity === 0) {
            x.status = "No Inventory";
          } else {
            x.status = "Partial";
          }
        });
      }

      if (this.deliveryData.length === 0) {
        this.check = true;
        // console.log(this.check)
      } else {
        this.check = false
      }
    }
  }
}