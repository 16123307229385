import { Component, OnInit, ViewChild } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { myVariables } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { saveAs } from 'file-saver';


export interface Product {
  [x: string]: any;
  id?: any;
  code?: string;
  description?: string;
  locationName?: string;
  images?: string;
  isActive?: any;

}
@Component({
  selector: 'app-location-master',
  templateUrl: './location-master.component.html',
  styleUrls: ['./location-master.component.css']
})
export class LocationMasterComponent implements OnInit {
  @ViewChild('pagin') paginator: Paginator;

  cols = [];
  displayDialog: boolean = false;
  pendingData: any;
  totalRecords: number;
  RequiredPendingDelivery =
    {
      status: "pending",
      warehouseId: localStorage.getItem('WarehouseID'),
      pageNumber: 1
    }
  parentLocation: {}[];
  statuses: { label: string; value: string; }[];
  maximiseData: Product;
  product: {};
  editable: boolean = false;
  locationNames: {}[];
  locationNamesSelected: any;
  addButton: boolean;
  editingmode: boolean = true;
  postData = {
    pageLimit: 10,
    pageNumber: 1,
  }
  totalRows: number;
  rows: number;
  first: number;
  check: boolean;
  row2: any;
  newCar: boolean;
  displayDialog1: boolean;
  selectedCustomers: any;
  customerData: any;
  customerList = [];

  constructor(private Apicall: ApiService) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage = 1;
    myVariables.completedDeliveryRowsPerPage = 1;
    myVariables.stagingReceiptRowsPerPage = 1;
    myVariables.StaockStatementRowsPerPage = 1;
    myVariables.stagingReceiptPageNumber = 1;
    myVariables.completedReceiptPageNumber = 1;
    myVariables.completedDeliveryPageNumber = 1;
    myVariables.pendingDeliveriespageNumber = 1;
    myVariables.pendingDeliveriesRowsPerPage = 1;
    myVariables.pendingReceiptRowsPerPage = 1;
    myVariables.pendingReceiptPageNumber = 1;
    myVariables.grnPageNumber = 1;
    myVariables.grnRowsPerPage = 1;
    myVariables.returnGrnPageNumber = 1;
    myVariables.returnGrnRowsPerPage = 1;
    myVariables.dnRowsPerPage = 1;
    myVariables.dnPageNumber = 1;
    myVariables.shippingOrdersRowsPerPage = 1;
    myVariables.shippingOrdersPageNumber = 1;
    myVariables.StockStatementPageNumber = 1;

    sessionStorage.removeItem('compltdReceiptDate');
    sessionStorage.removeItem('StagingReceiptDate');
    sessionStorage.removeItem('PendingReceiptDate');
    sessionStorage.removeItem('PendingDeliDate');
    sessionStorage.removeItem('compltdDeliDates');
    sessionStorage.removeItem('shippingOrdersDate');
    sessionStorage.removeItem('grnReportsDates');
    sessionStorage.removeItem('ReturnGrnReportsDates');
    sessionStorage.removeItem('dnReportsDate');
    sessionStorage.removeItem('active')
    this.GetAllCustomer()
    this.cols = [
      { field: 'id', header: 'S.No' },
      { field: 'businessName', header: 'Location Name' },
      { field: 'createdAt', header: 'Code' },
      { field: 'businessName', header: 'Description' },
      { field: 'locationTypeName', header: 'Location Type' },
      { field: 'images', header: 'Images' },
      { field: 'isActive', header: 'status' }
    ]
    this.statuses = [
      { label: 'In Stock', value: 'INSTOCK' },
      { label: 'Low Stock', value: 'LOWSTOCK' },
      { label: 'Out of Stock', value: 'OUTOFSTOCK' }
    ]
    this.locationNames = new Array()
    this.GetallPendingStatus();
  }
  GetallPendingStatus() {

    this.locationNames = []
    // this.Apicall.getPendingReceipt(this.RequiredPendingDelivery)
    //   .subscribe(
    //     res => {
    //       this.pendingData = res.result;
    //       this.totalRecords = res.noOfPages
    //       console.log("PENDING STATUS", this.totalRecords)

    //       for (var i = 0; i < this.pendingData.length; i++) {
    //         if (this.pendingData[i].return) {
    //           this.pendingData[i]['return'] = 'Returns'
    //         } else {
    //           this.pendingData[i]['return'] = '';
    //         }
    //         if (!this.pendingData[i].pickupInvoice.length) {

    //           this.pendingData[i]['poNo'] = ''
    //         }
    //         else {
    //           for (var j = 0; j < this.pendingData[i].pickupInvoice.length; j++) {
    //             this.pendingData[i]['poNo'] = this.pendingData[i].pickupInvoice[j].purchaseOrderNumber
    //           }

    //         }
    //       }
    //     }
    //   )
    this.Apicall.getLocationMaster(this.postData).subscribe(res => {

      this.pendingData = res['locationDetailsByWarehouseId']
      if (this.pendingData.length == 0) {
        this.check = true
      }
      else {
        this.check = false
      }
      this.totalRows = this.pendingData.length
      this.totalRecords = res['noOfPages'] * this.pendingData.length
      console.log(this.totalRecords)
      console.log("Completed DELIVERY", this.pendingData)
      this.totalRows = this.pendingData.length

      console.log(this.totalRows);
      if (this.totalRows != 10 && this.totalRows != 25 && this.totalRows != 50 && this.totalRows != 75 && (this.row2)) {
        console.log(this.totalRows);

        console.log("not");
        this.totalRows = this.row2
        this.totalRecords = res['noOfPages'] * this.row2
      }
      else {
        console.log("yes");

        this.totalRecords = res['noOfPages'] * this.totalRows
        console.log(this.totalRecords);
        console.log(this.totalRows);


      }
      this.first = (this.totalRows * this.postData.pageNumber) - 1
      console.log(this.first, typeof (this.postData.pageNumber));

      this.Apicall.getLocationType().subscribe(
        result => {
          console.log(result)
          result.locationDetails.forEach(location => {
            // this.locationNames.push(location.name)
            let obj = {
              "id": location.id,
              "name": location.name
            }
            this.locationNames.push(...[obj])

          })
          console.log('location names', this.locationNames)

        })
    })
  }

  onRowSelect($event) {

    this.newCar = false;
    this.displayDialog1 = true;

  }

  selectedCustomer() {
    localStorage.setItem('SelectedCustomerID', this.selectedCustomers.id)

    if (localStorage.getItem('SelectedCustomerID') !== '') {
      this.downloadStmtDN()
      this.displayDialog = false;
    } else {
      this.displayDialog = true;
      Swal.fire({
        toast: true,
        title: 'Please Select Customer !',
        timer: 2000,
        type: "warning",
        showConfirmButton: false
      })
    }
    console.log("SELECTED CUSTOMERS", this.selectedCustomers)
  }
  GetAllCustomer() {
    this.Apicall.getDeliveryStatus()
      .subscribe(
        res => {
          console.log("Response", res.result)
          this.customerData = res.result;
          for (var i = 0; i < this.customerData.length; i++) {
            this.customerList.push(this.customerData[i])
          }
          // this.customerList = [{ fullname: "Sudhar", id: 1},
          // { fullname: "Kanna", id: 2},
          // { fullname: "Aswath", id: 3},
          // { fullname: "Anish", id: 4},
          // { fullname: "Selva", id: 5},
          // { fullname: "Gowtham", id: 6},]
          console.log("Result", this.customerList)
        }
      )
  }
  downloadStmtDN() {
    const obj = {
      "warehouseId": localStorage.getItem('WarehouseID'),
      "pageLimit": '',  // not mandatory
      "pageNumber": '', // not mandatory
      "customerId": this.selectedCustomers.id // not mandatory
    }
    this.Apicall.SOHBinData(obj)
      .subscribe({
        next: (response: any) => {
          let fileName = 'ConsolidateDN';
          const contentDisposition = response.headers.get('content-disposition');
          console.log('content', response.headers)
          console.log('content', response.headers.get('content-disposition'))
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          const fileContent = response.body;
          saveAs(fileContent, fileName);
        },
        error: (error) => {
          console.log({ error });
        }
      });
  }

  searchLocation(data) {
    console.log(data.length)
    if (data.length >= 3) {
      this.Apicall.locationSearch(data).subscribe(res => {

        this.pendingData = res['result']
        if (this.pendingData.length == 0) {
          this.check = true
        }
        else {
          this.check = false
          for (var i = 0; i < this.pendingData.length; i++) {
            // console.log('i am running');
            // console.log(this.pendingData[i].purchaseOrderNumber)
            this.pendingData[i]['locationTypeName'] = this.pendingData[i].locationType.name
          }
        }
        console.log(res)
      })
    }
    if (data.length == 0) {
      this.GetallPendingStatus()
    }

  }
  getColor(locationTypeName) {
    switch (locationTypeName) {
      case 'Zone':
        return 'red';
      case 'Aisle':
        return '#4EAEE2'
      case 'Rack':
        return 'green';
      case 'Shelf':
        return 'blue';
      case 'Bin':
        return 'purple';
      case 'Pallet':
        return '#F0C023';
      case '':
        return 'white';
    }
  }

  getColorReturn(returns) {
    switch (returns) {
      case 'Returns':
        return 'orange';
      case '':
        return 'transparent';
    }
  }
  maximise(rowData: Product) {
    console.log('i am maximised', rowData)
    this.maximiseData = { ...rowData }
    this.displayDialog = true
  }
  dropdownvalue(event) {

    this.locationNamesSelected = this.locationNames.find(o => o['name'] == event)
    console.log(this.locationNamesSelected)


  }
  onRowEditInit(rowData) {
    console.log('edit mode', rowData)
    this.locationNamesSelected = this.locationNames.find(o => o['name'] == rowData.locationTypeName)
    rowData.locationTypeName = this.locationNamesSelected.name
    rowData.locationTypeId = this.locationNamesSelected.id
    this.editingmode = false
  }
  onRowEditSave(rowData) {
    console.log(rowData)
    delete rowData.customerId
    delete rowData.warehouseId
    delete rowData.parentLocationId
    console.log("after rowdata: ", rowData)
    rowData.locationTypeName = this.locationNamesSelected.name
    rowData.locationTypeId = this.locationNamesSelected.id
    // rowData.warehouseId=localStorage.getItem('WarehouseID')

    if (rowData.id == '' || rowData.id == undefined || rowData.id == null) {

      if (rowData.locationTypeName != '' && rowData.code != '' && rowData.description != ''
        && rowData.locationName != '') {
        if (rowData.isActive == 0) {
          //when row id is not present and isactive is not entered executing add method giving isactive value as false
          rowData.isActive = false
          rowData.warehouseId = localStorage.getItem('WarehouseID')
          this.Apicall.addLocaMaster(rowData)
            .subscribe(res => {
              console.log('api response', res)
              this.editingmode = true

            })
          // console.log('location add master is execution without is active entered',rowData)
        }
        else {
          //when row id is not present and isactive is entered executing add method
          rowData.warehouseId = localStorage.getItem('WarehouseID')
          this.Apicall.addLocaMaster(rowData)
            .subscribe(res => {
              console.log('api response', res)
              this.editingmode = true
            })
          // console.log('row id not present but isactive is given',rowData)
        }
      }
      else {
        console.log('data cannot be empty', rowData)
      }
    }
    else {
      //when row id is not present executing update
      this.Apicall.updatelocaMaster(rowData)
        .subscribe(res => {

          console.log('api response of put method', res)
          this.editingmode = true
        })
      // console.log('location master is executed',rowData)
    }

    this.GetallPendingStatus();
  }
  addNewRow1() {

    this.editingmode = false
  }
  onRowEditCancel() {
    this.editingmode = true
    console.log('cancel edit mode')
    this.GetallPendingStatus();
  }
  newRow() {
    return {
      locationName: '',
      description: '',
      code: '',
      isActive: '',
      locationTypeId: ''

    };
  }
  deleteRow(rowData) {
    // console.log(rowData.id)
    this.Apicall.deleteLocationMaster(rowData.id).subscribe(res => {
      console.log('delete res', res)
    })
    this.GetallPendingStatus();
  }
  edit() {
    this.editable = true
  }
  save() {
    this.editable = false
    delete this.maximiseData.customerId
    delete this.maximiseData.warehouseId
    delete this.maximiseData.parentLocationId
    console.log(this.maximiseData, this.locationNamesSelected)
    if (this.locationNamesSelected != undefined) {
      this.maximiseData.locationTypeName = this.locationNamesSelected.name
      this.maximiseData.locationTypeId = this.locationNamesSelected.id
    }

    console.log('maximise data after changes', this.maximiseData)
    this.Apicall.updatelocaMaster(this.maximiseData)
      .subscribe(res => {
        console.log('api response of put method', res)
        if (res['status'] == true) {
          Swal.fire({
            title: '<h3>your data has been saved successfully</h3>',
            showConfirmButton: false,
            timer: 3300,
            type: 'success'
          })
          this.displayDialog = false
          this.GetallPendingStatus();
        }
        else {
          Swal.fire({
            title: '<h3>your data was failed to save</h3>',
            showConfirmButton: false,
            timer: 3300,
            type: 'error'
          })
        }
      })
    //
    // this.displayDialog = false

  }
  cancel() {
    this.editable = false
    this.displayDialog = false
  }
  delete() {
    console.log(this.maximiseData.id)
    this.Apicall.deleteLocationMaster(this.maximiseData.id).subscribe(res => {
      console.log('delete res', res)
      if (res['status'] == true) {
        Swal.fire({
          title: '<h3>your data has been deleted successfully</h3>',
          showConfirmButton: false,
          timer: 3300,
          type: 'success'
        })
        this.GetallPendingStatus();
        this.displayDialog = false
      }
      else {
        Swal.fire({
          title: '<h3>your data has not been deleted</h3>',
          showConfirmButton: false,
          timer: 3300,
          type: 'error'
        })
      }
    })

  }
  paginate(event) {
    // console.log(event)
    // console.log(event.rows)
    // console.log(this.postData.pageLimit)
    // if(this.postData.pageLimit!=event.rows){
    //   this.postData.pageLimit=event.rows
    //   this.postData.pageNumber=1
    //   this.paginator.changePage(0)
    // }

    // else{this.postData.pageNumber=event.page+1}
    // console.log(this.postData)
    // this.GetallPendingStatus();
    this.row2 = event.rows
    // console.log(event.rows ,this.totalrows);



    // this.postData.pageLimit=event.rows
    // this.pageNumber = event.page + 1;
    // this.postData.pageNumber = this.pageNumber
    //  console.log(event.rows,this.postData.pageLimit)

    if (this.postData.pageLimit == event.rows) {
      this.postData.pageNumber = event.page + 1


    } else if (event.rows == 10) {
      this.postData.pageLimit = 10
      this.postData.pageNumber = event.page


      this.paginator.changePage(0)

    }
    else if (event.rows == 25) {
      this.postData.pageLimit = 25
      this.postData.pageNumber = event.page



      this.paginator.changePage(0)

    } else if (event.rows == 50) {
      this.postData.pageLimit = 50
      this.postData.pageNumber = event.page

      this.paginator.changePage(0)

    }
    else if (event.rows == 75) {
      this.postData.pageLimit = 75
      this.postData.pageNumber = event.page

      this.paginator.changePage(0)

    }
    else {

      // this.postData.pageLimit=event.rows 
      this.postData.pageNumber = event.page + 1
      // this.first=event.page
      // console.log(event);
      // console.log(this.first);


      // this.paginator.changePage(0)

    }


    console.log(this.postData)
    this.GetallPendingStatus()


  }
  paginate1(event) {
    console.log(event)
  }
}
