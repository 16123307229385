import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-warehouse-utillization',
  templateUrl: './warehouse-utillization.component.html',
  styleUrls: ['./warehouse-utillization.component.css']
})
export class WarehouseUtillizationComponent implements OnInit {
  data: any;
  title: string;
  basicData: any;
  basicDataLabels: any;
  pendingData: any;
  stagingData: any;
  completedData: any;
  StackedOptions
  StackedOptions2



  constructor(private apiCall: ApiService) { }

  ngOnInit() {
    this.basicDataLabels = []
    this.pendingData = []
    const obj = {

      "warehouseId": localStorage.getItem('WarehouseID'),
      "customerId": null

    }
    this.apiCall.warehouseUtillization(obj).subscribe(res => {
      this.data = res['result']
      // console.log(this.data)
      for (let i = 0; i < this.data.length; i++) {

        this.basicDataLabels.push(this.data[i].customerName)
        this.pendingData.push(this.data[i].noOfBin)


        // console.log((this.basicDataLabels));
        // const myArray = Object.entries(this.basicDataLabels);
        // console.log(myArray);


      }
      this.title = "GFG";

      // this.StackedOptions.global.elements.rectangle.borderWidth=2

      this.basicData = {
        labels: this.basicDataLabels,
        datasets: [
          {
            label: "Bins ",
            backgroundColor: "#306D75",
            // barPercentage: 0.5,
            barThickness: 17,
            maxBarThickness: 17,
            data: this.pendingData,
            axis: 'y'
          },

        ]
      };

      // for (let i = 0; i < this.basicDataLabels.length; i++) {
      //   this.basicData.labels.push(this.basicDataLabels[i])
      // }
      // console.log(this.basicData.labels)





      this.StackedOptions = {
        maintainAspectRatio: false,
        responsive: false,
        legend: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          }
          // barWidth: {
          //   ratio: 0.9 // Adjust the bar width
          // }
        },
        layout: {
          padding: {
            left: 10,
            right: 10
          }
        },
        scales: {
          xAxes: [{
            stacked: false,
            position: 'top',


            ticks: {
              color: "#black",
              fontSize: 7


            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              color: "#black",
              // beginAtZero: true,
              // callback: function (label) {
              //   return label.substr(0, 5);
              // }
            },
            gridLines: {
              display: false,

            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }]
        },

      };
      this.StackedOptions2 = {
        maintainAspectRatio: false,
        responsive: false,
        legend: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          }
          // barWidth: {
          //   ratio: 0.9 // Adjust the bar width
          // }
        },
        layout: {
          padding: {
            left: 10,
            right: 10
          }
        },
        scales: {
          xAxes: [{
            stacked: false,
            position: 'top',


            ticks: {
              color: "#black",
              fontSize: 7


            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              color: "#black",
              beginAtZero: true,
              callback: function (label) {
                return label.substr(0, 10);
              }
            },
            gridLines: {
              display: false,

            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }]
        },

      };
      // this.data.forEach(element => {
      //   this.basicDataLabels.push(element)
      // });

    })
  }

}
