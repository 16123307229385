import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/api.service';
import { Moment } from 'moment'
import * as moment from 'moment';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-most-order-placed',
  templateUrl: './most-order-placed.component.html',
  styleUrls: ['./most-order-placed.component.css']
})
export class MostOrderPlacedComponent implements OnInit {
  @ViewChild('myTable') private _table: Table;
  @ViewChild('calendar') private calendar: any;


  cols = [];
  monthsArr: any[];
  categories = []
  mostOrderPlaced: any;
  selectedMonth: any;
  selectedCustomer: any = null;
  customerDetails: any;
  check: boolean;
  monthdata: {};
  monthnames: any[];
  savedDates: any;
  firstDate: any;
  today: any;
  rangeDates: any[];


  constructor(private apiCall: ApiService, private datePipe: DatePipe) { }
  postData = {
    "startDate": null,
    "endDate": null,
    "warehouseId": localStorage.getItem('WarehouseID'),
    "customerId": null
  }
  ngOnInit() {
    // this.categories = ['thismonth', 'lastmonth']
    // let montharray2 = ['mon', 'tue', 'wed', 'thu']

    // this.monthdata = this.getLast12MonthsDates().last12MonthsDates;
    // this.monthnames = this.getLast12MonthsDates().monthArr
    // console.log(this.monthdata, this.monthnames);
    this.selectedMonth = 'null'
    // console.log(this.monthsArr);
    // console.log(moment(firstMonth).startOf('month').format('yyy-MM-DD'))
    // this.postData.startDate = moment(todaysDate).startOf('month').format('yyy-MM-DD')
    // this.postData.endDate = moment(todaysDate).endOf('month').format('yyyy-MM-DD');
    if (sessionStorage.getItem('orderPlacedDates')) {
      this.savedDates = JSON.parse(sessionStorage.getItem('orderPlacedDates'))
      console.log(this.savedDates)
      this.firstDate = new Date(this.savedDates.startDate)
      this.today = new Date(this.savedDates.endDate)
      this.rangeDates = [this.firstDate, this.today];
      this.postData.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
      this.postData.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')

    }
    else {
      this.today = new Date();
      this.firstDate = new Date();
      // this.firstDate.setMonth(this.today.getMonth());
      this.firstDate.setDate(this.firstDate.getDate() - 30);
      this.rangeDates = [this.firstDate, this.today];
      this.postData.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
      this.postData.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
    }


    this.cols = [

      { field: 'pincode', header: 'Pincode' },
      // { field: 'location', header: 'Location' },
      { field: 'city', header: 'City' },
      { field: 'state', header: 'State' },
      { field: 'percentageCount', header: 'Orders' }
    ];
    this.apiCall.mostOrdePlaced(this.postData).subscribe(res => {
      // console.log("Most Order placed", res);

      this.mostOrderPlaced = res['result']
      if (this.mostOrderPlaced.length === 0) {
        this.check = true
        // console.log(this.check)
      } else {
        this.check = false
      }
    })
    this.apiCall.getCustomerList().subscribe(res => {
      // console.log(res);

      this.customerDetails = res.result

    })

  }
  getLast12MonthsDates() {
    const today = moment();

    const last12MonthsDates = {};
    const monthArr = []

    for (let i = 0; i < 12; i++) {
      const firstDate = moment(today).subtract(i, 'months').startOf('month');
      const lastDate = moment(today).subtract(i, 'months').endOf('month');
      monthArr.push(firstDate.format('MMM') + '-' + firstDate.format('YY'))
      last12MonthsDates[firstDate.format('MMM') + '-' + firstDate.format('YY')] = {
        startDate: firstDate.format('YYYY-MM-DD'),
        endDate: lastDate.format('YYYY-MM-DD')
      };
    }

    return { last12MonthsDates, monthArr };
  }
  defaultDate() {
    console.log('defaultdate');
    if (this.rangeDates[1] == null) {
      if (sessionStorage.getItem('orderPlacedDates')) {
        this.savedDates = (JSON.parse(sessionStorage.getItem('orderPlacedDates')))
        this.rangeDates = [new Date(this.savedDates.startDate), new Date(this.savedDates.endDate)]
      }
      else {
        this.rangeDates = [this.firstDate, this.today]
      }
    }

  }
  onDateSelect(event) {
    console.log(event)
    var _self = this;
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;
      this.postData.startDate = this.formatDate(_self.rangeDates[0])
      this.postData.endDate = this.formatDate(_self.rangeDates[1])
      const obj = {
        "startDate": _self.rangeDates[0],
        "endDate": _self.rangeDates[1]
      }
      sessionStorage.setItem('orderPlacedDates', JSON.stringify(obj))
      // sessionStorage.setItem('endDate',JSON.stringify(_self.rangeDates[1]))
      this.apiCall.mostOrdePlaced(this.postData).subscribe(res => {
        // console.log("Most Order placed", res);

        this.mostOrderPlaced = res['result']
        if (this.mostOrderPlaced.length === 0) {
          this.check = true
          // console.log(this.check)
        } else {
          this.check = false
        }
      })
      this.apiCall.getCustomerList().subscribe(res => {
        // console.log(res);

        this.customerDetails = res.result

      })
    } else {
      e = s + 86400000;
    }
    console.log(this.rangeDates[1])
    if (this.rangeDates[1]) { // If second date is selected
      this.calendar.overlayVisible = false;
    }

  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
  dateMonthChange(event) {
    console.log(event.target.value)
    if (event.target.value == 'null') {
      this.postData.startDate = null
      this.postData.endDate = null
    } else {
      // const now = moment();
      // console.log(JSON.stringify(event.target.value));
      // const currentYear = new Date().getFullYear();
      // const selectedStartDate = moment(`${currentYear}-${event.target.value}-01`, 'YYYY-MMM-DD')
      // const firstDateOfselectedMonth = selectedStartDate.startOf('month').format('YYYY-MM-DD');
      // console.log(firstDateOfselectedMonth);

      // console.log(moment(event.target.value).startOf('month').format('YYYY-MM-DD'));


      // Get the current year
      // const currentYear = new Date().getFullYear();

      // // Create a date for January 1st of the current year
      // const startdate = moment(`${currentYear}-${event.target.value}-01`, 'YYYY-MMM-DD');

      // // Get the first day of January
      // const firstDateOfselectedMonth = startdate.startOf('month').format('YYYY-MM-DD');
      // const lastDateofSelectedMonth = startdate.endOf('month').format('YYYY-MM-DD');

      // // console.log(firstDateOfselectedMonth, lastDateofSelectedMonth);

      this.postData.startDate = this.monthdata[event.target.value].startDate;
      this.postData.endDate = this.monthdata[event.target.value].endDate;

    }
    console.log(this.postData)
    this.apiCall.mostOrdePlaced(this.postData).subscribe(res => {
      // console.log("Most Order placed", res);
      this.mostOrderPlaced = res['result']
      if (this.mostOrderPlaced.length === 0) {
        this.check = true
        // console.log(this.check)
      } else {
        this.check = false
      }
    })


  }
  customerChange(data) {
    // console.log(data.target.value)
    if (data.target.value == 'null') {
      this.postData.customerId = null
    }
    else {
      this.postData.customerId = data.target.value

    }
    this.apiCall.mostOrdePlaced(this.postData).subscribe(res => {
      // console.log("Most Order placed", res);
      this.mostOrderPlaced = res['result']
      if (this.mostOrderPlaced.length === 0) {
        this.check = true
        // console.log(this.check)
      } else {
        this.check = false
      }
    })
  }
}
