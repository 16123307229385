import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import lodash from 'lodash';
import { MustMatch } from '../loginform/passwordvalidators';
import { JwtHelperService } from '@auth0/angular-jwt';
@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginformComponent implements OnInit {
  helper = new JwtHelperService();
  decodedToken: any;
  hide = true;
  LoginForm: FormGroup;
  ForgotForm: FormGroup;
  OtpForm: FormGroup;
  SetPwdForm: FormGroup;
  submitted = false;
  siginpage = true;
  forgotPswrd = false;
  otpVerify = false;
  setpwd = false;
  // loginParameter = { mobileNo: '', password: '', userTypeId: "3" }
  loginParameter = { mobileNo: '', password: '', userGroup: 'warehouse', client: 'web' }
  RequiredOtpGenerate = { mobileNo: "" }
  RequiredOtpVerify = { mobileNo: this.RequiredOtpGenerate.mobileNo, otp: "" }
  RequiredNewPassword = {
    "updates": { email: "", fullname: "", password: "", mobileNo: "" }
  }
  constructor(private formBuilder: FormBuilder, private router: Router, private apiCall: ApiService) { }
  ngOnInit() {
    this.loginValidate();
    this.ForgotPwdValidate();
    this.OtpVerifyValidate();
    this.SetPwdValidate();
  }
  /*----------LOGIN_VALIDATE-------------*/
  loginValidate() {
    this.LoginForm = new FormGroup({
      mobileNo: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }
  public checkSignInError = (controlName: string, errorName: string) => {
    return this.LoginForm.controls[controlName].hasError(errorName);
  }
  /*-----------FORGOT_PASSWORD_VALIDATE------------*/
  ForgotPwdValidate() {
    this.ForgotForm = new FormGroup({
      mobileNo: new FormControl('', [Validators.required])
    })
  }
  public checkForgotError = (controlName: string, errorName: string) => {
    return this.ForgotForm.controls[controlName].hasError(errorName);
  }
  /*----------------OTP_VERIFICATION_VALIDATE-------------*/
  OtpVerifyValidate() {
    this.OtpForm = new FormGroup({
      otp: new FormControl('', [Validators.required])
    })
  }
  public checkOtpError = (controlName: string, errorName: string) => {
    return this.OtpForm.controls[controlName].hasError(errorName);
  }
  /*-----------------SET_PASSWORD_VALIDATE----------------*/
  SetPwdValidate() {
    this.SetPwdForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmpassword')
    });
  }
  get S() {
    return this.SetPwdForm.controls;
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  get f() {
    return this.LoginForm.controls;
  }
  /*------------------------------LOGIN_API_CALLING--------------------------*/
  LoginAPI() {
    this.apiCall.getLoginApi(this.loginParameter)
      .subscribe(res => {
        if (res.status == true) {
          localStorage.setItem("token", res.token)
          localStorage.setItem("Refresh_token", res.refreshToken)
          this.decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
          localStorage.setItem('UserID', this.decodedToken.id)
          localStorage.setItem('U_name', this.decodedToken.name)
          localStorage.setItem('U_number', this.decodedToken.mobileNo)
          localStorage.setItem('U_email', this.decodedToken.email)
          localStorage.setItem('WarehouseArray', JSON.stringify(this.decodedToken.warehouses))
          console.log("DECODED", this.decodedToken)
          Swal.fire({
            toast: true,
            position: 'center',
            text: 'Login Successfully',
            showConfirmButton: false,
            timer: 3000,
            type: 'success',
          })
          this.router.navigate(['dashboard'])
          // this.siginpage = false;
          // this.forgotPswrd = true
        }
        else {
          Swal.fire({
            toast: true,
            position: 'center',
            text: 'Login Failed Please Check Username and Password',
            showConfirmButton: false,
            timer: 3000,
            type: 'error',
          })
        }
      })
    this.LoginForm.reset();
  }









  ForgotPwdPage() {
    this.siginpage = false;
    this.forgotPswrd = true;
  }
  /*------------------------------FORGOT_PASSWORD_API_CALLING--------------------------*/
  ForGotPasswordAPI() {
    this.apiCall.getForgotPwdApi(this.RequiredOtpGenerate)
      .subscribe(
        res => {
          console.log("CHECKING STATUS", res.error)
          if (res.error === "There is no user with the provided information.") {
            Swal.fire({
              toast: true,
              title: "There is no user with the provided information",
              type: 'error',
              timer: 2000,
              showConfirmButton: false
            })
            this.forgotPswrd = true;
            this.otpVerify = false;
          } else {
            Swal.fire({
              toast: true,
              title: "OTP sent successfully to user Mobile Number",
              type: 'success',
              timer: 2000,
              showConfirmButton: false
            })
          }
          this.RequiredOtpVerify.mobileNo = this.RequiredOtpGenerate.mobileNo
        }
      )
    this.forgotPswrd = false;
    this.otpVerify = true;
  }
  /*------------------------------OTP_API_CALLING--------------------------*/
  OtpVerificationAPI() {
    this.apiCall.getOtpApi(this.RequiredOtpVerify)
      .subscribe(
        res => {
          if (this.RequiredOtpVerify.mobileNo === '' || this.RequiredOtpVerify.otp === '') {
            Swal.fire({
              toast: true,
              title: "Please Enter Your OTP",
              type: 'warning',
              timer: 2000,
              showConfirmButton: false
            })
          }
          else if (res.status === false) {
            Swal.fire({
              toast: true,
              title: "Please Check Your OTP",
              type: 'warning',
              timer: 2000,
              showConfirmButton: false
            })
          }
          else {
            localStorage.setItem('ID', res.result.userId)
            Swal.fire({
              toast: true,
              title: "OTP Verified",
              type: 'success',
              timer: 2000,
              showConfirmButton: false
            })
            this.setpwd = true;
            this.otpVerify = false;
          }
        }
      )
  }
  /*-------------------------------RESEND_OTP_API_CALLING---------------------*/
  ResendOtpAPI() {
    this.apiCall.getOtpResend(this.RequiredOtpGenerate)
      .subscribe(res => {
        Swal.fire({
          toast: true,
          title: "OTP resent Successfully",
          type: 'success',
          timer: 3000,
          showConfirmButton: false
        })
      })
  }
  /*-----------------------------SET_PASSWORD_API_CALLING----------------------*/
  SetPasswordAPi() {
    console.log("CHANGE PASSWORD")
    this.submitted = true;
    if (this.SetPwdForm.invalid) {
      return;
    }
    const dataToBeSent = this.RequiredNewPassword
    if (lodash.isEqual(dataToBeSent.updates.email, '')) delete dataToBeSent.updates.email
    if (lodash.isEqual(dataToBeSent.updates.mobileNo, '')) delete dataToBeSent.updates.mobileNo
    if (lodash.isEqual(dataToBeSent.updates.fullname, '')) delete dataToBeSent.updates.fullname
    this.apiCall.getUpdateUser(this.RequiredNewPassword)
      .subscribe(res => {
        // if(error === "There is no user with the provided information")
        localStorage.removeItem('ID')
        console.log("CHANGE", res)
        Swal.fire({
          toast: true,
          title: "Password Changed Successfully",
          type: 'success',
          timer: 2000,
          showConfirmButton: false
        })
      })
    this.siginpage = true;
    this.setpwd = false;
  }
}