import { Component, Injectable, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';
import { myVariables } from 'src/environments/environment';

@Component({
  selector: 'app-stockstatement',
  templateUrl: './stockstatement.component.html',
  styleUrls: ['./stockstatement.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StockstatementComponent implements OnInit {
  @ViewChild('pagin') paginator: Paginator;
  @ViewChild('myTable') private _table: Table;
  @ViewChild('calendar', undefined) private calendar: any;
  // disabled = true;
  RequiredStock = {
    checked: false,
    userId: localStorage.getItem('UserID'),
    pageNumber: 1,
    pageLimit: 10
  }

  downloadRequest = {
    // "userId": localStorage.getItem('UserID'),
    //   "warehouseId": localStorage.getItem('WarehouseID')
  }
  jsonData = { arrayValue: [] }
  cars: any[];
  cols: any[];
  firstCols: any[];
  addToCart = [];
  colors: SelectItem[];
  selectedRowData: any;
  displayBasic: boolean;
  displayInd: boolean;
  expandedRowIndex: any;
  rowData_proCode: any;
  rowData_Stock: any;
  checkBoxVal: any;
  dateTime = new Date();
  getQtyVal: any;
  checking: string;
  addRowData: any;
  selectedRowArr = [];
  addCartparams: any;
  pro_name: any;
  pro_code: any;
  entered_qty: any;
  SelectedDate: any;
  SendingValue: any;
  public dateFilters: Date = new Date();
  rowData_available: any;
  balance: any;
  DefaultDate: any;
  obj: { width: string; };
  primaryClosing: any;
  pageNumber: any;
  check = false;
  totalRecords: number;

  ProductSearch = {
    "searchval": "",
    "customerId": localStorage.getItem('ViewCustomer_id')
  }

  ProductMaterial = {
    "userId": localStorage.getItem('Cus_ID'),
    "date": localStorage.getItem('SDate') || localStorage.getItem('DefaultDate'),
    "materialcode": "",
    "warehouseId": localStorage.getItem('WarehouseID')
  }

  SupplierfilteredOption: Observable<string[]>;
  name: FormControl;
  AutoFillSuppliers = [];
  printingMaterialName: string;
  isEnabled = false;
  sohPostData: { customerId: string; warehouseId: string; date: string; };
  rows: any;
  row2: any;
  first: number;
  newDate: Date;
  // collectedData: { productId: string; productName: string; SkuUpcEan: string; quantity: string; };
  // colors = this.colorNames.map((name) => {
  //   return { label: name, value: name }
  // });
  constructor(private router: Router, private Apicall: ApiService, private datePipe: DatePipe) {
    this.name = new FormControl();
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );
  }
  ngOnInit() {
    myVariables.completedReceiptRowsPerPage = 1;
    myVariables.completedDeliveryRowsPerPage = 1;
    myVariables.stagingReceiptRowsPerPage = 1;
    myVariables.StaockStatementRowsPerPage = 1;
    myVariables.stagingReceiptPageNumber = 1;
    myVariables.completedReceiptPageNumber = 1;
    myVariables.completedDeliveryPageNumber = 1;
    myVariables.pendingDeliveriespageNumber = 1;
    myVariables.pendingDeliveriesRowsPerPage = 1;
    myVariables.pendingReceiptRowsPerPage = 1;
    myVariables.pendingReceiptPageNumber = 1;
    myVariables.grnPageNumber = 1;
    myVariables.grnRowsPerPage = 1;
    myVariables.returnGrnPageNumber = 1;
    myVariables.returnGrnRowsPerPage = 1;
    myVariables.dnRowsPerPage = 1;
    myVariables.dnPageNumber = 1;
    myVariables.shippingOrdersRowsPerPage = 1;
    myVariables.shippingOrdersPageNumber = 1;
    // myVariables.StockStatementPageNumber=1;

    sessionStorage.removeItem('compltdReceiptDate');
    sessionStorage.removeItem('StagingReceiptDate');
    sessionStorage.removeItem('PendingReceiptDate');
    sessionStorage.removeItem('PendingDeliDate');
    sessionStorage.removeItem('compltdDeliDates');
    sessionStorage.removeItem('shippingOrdersDate');
    sessionStorage.removeItem('grnReportsDates');
    sessionStorage.removeItem('ReturnGrnReportsDates');
    sessionStorage.removeItem('dnReportsDate');
    sessionStorage.removeItem('active')
    if (localStorage.getItem('SDate')) {
      this.DefaultDate = localStorage.getItem('SDate');

      this.newDate = new Date(this.DefaultDate);

      this.dateFilters = new Date(this.newDate)

      console.log("if", this.dateFilters);

    }

    else {
      console.log("else");

      this.DefaultDate = new Date();
      this.DefaultDate = this.datePipe.transform(this.DefaultDate, "yyyy-MM-dd")
      // this.sohdetails.date=this.DefaultDate
      localStorage.setItem('DefaultDate', this.DefaultDate)
      this.dateFilters = new Date(this.DefaultDate)
      console.log("else", this.dateFilters);

    }
    console.log("if", this.dateFilters);


    this.StockStmtApi()
    var _self = this;

    this.firstCols = [
      // { field: 'date', header: 'Date' },
      // { field: 'OpeningStock', open: 'OpeningStock', width: '40%' },
      // { field: 'Receipt', receipt: 'Receipt' },
      // { field: 'Dispatches', dispatch: 'Dispatches' },
      { field: 'ClosingStock', close: 'ClosingStock' }
    ]
    this.cols = [
      { field: 'date', header: 'Date' },
      { field: 'SkuUpcEan', header: 'Material Code' },
      { field: 'productName', header: 'Description' },
      // { field: 'mapa', header: 'Mapa' },
      // { field: 'barcode', header: 'Barcode' },
      // { field: 'category', header: 'Category' },
      // { field: 'subCategory', header: 'Subcategory' },
      { field: 'totalGoodQty', header: 'Good' },
      { field: 'totalDamagedQty', header: 'Damaged' },
      { field: 'actualQty', header: 'Actual' },
      { field: 'totalQty', header: 'Total' },
    ];
  }
  onDateSelect(value) {
    var _self = this;


    this.SelectedDate = this.formatDate(this.dateFilters)
    this.dateFilters = new Date(this.SelectedDate)
    console.log("SELECTED", this.SelectedDate)
    localStorage.setItem('SDate', this.SelectedDate)
    this.paginator.changePage(0)
    this.StockStmtApi()
  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
  toggleVisibility(ri, rowData, isChecked) {
    // console.log("BOOLEAN",rowData)
    this.selectedRowData = rowData
    this.checkBoxVal = isChecked;
    this.expandedRowIndex = ri;
    if (this.checkBoxVal == true) {
      rowData.checkVal = true
      this.selectedRowData = rowData
      this.addRowData = rowData
      this.displayBasic = true;
      this.checkBoxVal = false;
      this.checking = "expandedRowIndex"
      this.rowData_proCode = rowData.SkuUpcEan;
      this.rowData_Stock = rowData.closing
      this.rowData_available = rowData.actualQty
      console.log("CALCULATING HERE", this.balance)
    }
  }
  /*---------------------------CALCULATION-------------------------------*/
  checkLength1(e, input) {
    const keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';
    const newValue = input.value + (isNaN(keyValue) ? '' : keyValue.toString());
    const match = newValue.match(numberOnlyPattern);
    if (+newValue > this.rowData_available || !match || newValue === '') {
      e.preventDefault();
    }
  }
  /*------------------END CALCULATION------------------------------*/
  cancel() {
    this.displayBasic = false;
  }
  CreateCustomer() {
    console.log("GO TO DELIVERY ORDER")
    if (localStorage.getItem('Cus_MbNo') === null || localStorage.getItem('Cus_Name') === null) {
      this.router.navigate(['/customer/deliveryorder'])
    }
  }
  creatingStmt() {
    this.router.navigate(['/customer/deliveryorder'])
  }
  StockStmtApi() {
    this.Apicall.getStockStmt(this.RequiredStock)
      .subscribe(
        res => {
          console.log("CALCULATION DATA", res)
          this.cars = res.result;
          this.rows = this.cars.length
          this.totalRecords = res['noOfPages'] * this.rows
          console.log(this.rows);

          if (this.rows != 10 && this.rows != 25 && this.rows != 50 && this.rows != 75) {
            console.log(this.rows);
            console.log(this.row2);

            console.log("not");
            if (this.row2 == undefined) {
              this.row2 = this.cars.length
            }
            this.rows = this.row2
            this.totalRecords = res.noOfPages * this.row2
          }
          else {
            console.log("yes");
            this.rows = myVariables.StaockStatementRowsPerPage
            this.totalRecords = res.noOfPages * this.rows

          }
          this.first = (this.rows * this.RequiredStock.pageNumber) - 1
          if (this.cars.length === 0) {
            this.check = true
          } else {
            this.check = false
          }
          err => {
            console.log("GETTING ERROR STOCK STATEMENT", err)
          }
        }
      )
  }

  downloadStmt() {
    this.Apicall.downloadReportApi(this.downloadRequest)
      .subscribe({
        next: (response: any) => {

          let fileName = 'Stockstatement';
          const contentDisposition = response.headers.get('content-disposition');
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }

          const fileContent = response.body;

          saveAs(fileContent, fileName);

        },
        error: (error) => {

          console.log({ error });

        }
      });
  }
  binSoH() {
    const obj =
    {
      "customerId": localStorage.getItem('ViewCustomer_id'),
      "warehouseId": localStorage.getItem('WarehouseID')
    }
    this.Apicall.binSoh(obj)
      .subscribe({
        next: (response: any) => {
          let fileName = 'BinSoh';
          const contentDisposition = response.headers.get('content-disposition');
          console.log('content', response.headers)
          console.log('content', response.headers.get('content-disposition'))
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          const fileContent = response.body;
          saveAs(fileContent, fileName);
        },
        error: (error) => {
          console.log({ error });
        }
      })
  }

  SendSoH() {

    if (localStorage.getItem('SDate')) {
      this.sohPostData = {
        "customerId": localStorage.getItem('ViewCustomer_id'),
        "warehouseId": localStorage.getItem('WarehouseID'),
        "date": localStorage.getItem('SDate')
      }

    }
    else {
      this.sohPostData = {
        "customerId": localStorage.getItem('ViewCustomer_id'),
        "warehouseId": localStorage.getItem('WarehouseID'),
        "date": localStorage.getItem('DefaultDate')
      }
    }

    this.Apicall.sendSohApi(this.sohPostData)
      .subscribe(
        res => {
          if (res.status == true) {
            Swal.fire({
              toast: true,
              title: res.result,
              timer: 3000,
              type: "success",
              showConfirmButton: false
            })
          }
          else {
            Swal.fire({
              toast: true,
              title: res.result,
              timer: 3000,
              type: "warning",
              showConfirmButton: false
            })
          }

        }
      )
  }

  filters(val: string) {
    // console.log("CHECKING HERE", this.AutoFillSuppliers)
    if (this.AutoFillSuppliers === undefined || this.AutoFillSuppliers === []) {
      return
    }
    return this.AutoFillSuppliers.filter(option =>
      option.productName.toLowerCase().includes(val.toLowerCase()));
  }

  setInputField(data) {
    this.ProductSearch.searchval = data
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );

    if (data.length > 2) {
      this.ProductSearching()

    } else if (data.length < 1) {
      // console.log("please enter minimum 3 characters")
      this.AutoFillSuppliers = []
      this.StockStmtApi()
    } else {
      this.AutoFillSuppliers = []
    }
  }

  ProductSearching() {
    this.Apicall.getProductName(this.ProductSearch)
      .subscribe(
        res => {
          this.AutoFillSuppliers = res.result
          console.log("AUTO FILL", this.AutoFillSuppliers)
          this.SupplierfilteredOption = this.name.valueChanges
            .pipe(
              startWith(''),
              map(val => this.filters(val))
              // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
            );
          if (res.result === 'No data Found' || res.status === false) {
            this.AutoFillSuppliers = []
          }
        }
      )
  }

  clearfilter() {
    this.ProductSearch.searchval = '';
    this.isEnabled = false;
    this.StockStmtApi()
  }

  selectedSupplier(evt) {
    console.log("EVENT TRIGGERING", evt)
    for (var i = 0; i < this.AutoFillSuppliers.length; i++) {
      if (this.AutoFillSuppliers[i].productCode == evt.source.value) {
        console.log(this.AutoFillSuppliers[i].productCode)
        this.isEnabled = true;
        this.ProductMaterial.date = localStorage.getItem('SDate') || localStorage.getItem('DefaultDate')
        this.ProductMaterial.materialcode = this.AutoFillSuppliers[i].productCode
        this.printingMaterialName = this.AutoFillSuppliers[i].productCode + ' - ' + this.AutoFillSuppliers[i].productName
        this.Apicall.getAllMaterials(this.ProductMaterial)
          .subscribe(
            res => {
              this.cars = []
              // var newResult = res.result
              // console.log("step2",newResult)
              console.log("step3", this.cars.push(res.result))
              this.totalRecords = res.noOfPages
              this.SupplierfilteredOption = this.name.valueChanges
                .pipe(
                  startWith(''),
                  map(val => this.filters(val))
                );

            }
          )

      }
    }
  }


  // paginate(event) {
  //   this.pageNumber = event.page + 1;
  //   this.RequiredStock.pageNumber = this.pageNumber
  //   this.StockStmtApi();
  // }

  paginate(event) {
    // console.log(event)
    // this.RequiredDetails.pageLimit=event.rows
    // this.pageNumber = event.page + 1;
    // this.RequiredDetails.pageNumber = this.pageNumber
    // console.log(event.rows, this.RequiredStock.pageLimit)

    // if (this.RequiredStock.pageLimit == event.rows) {
    //   this.RequiredStock.pageNumber = event.page + 1


    // } else if (event.rows == 10) {
    //   this.RequiredStock.pageLimit = 10
    //   this.RequiredStock.pageNumber = event.page


    //   this.paginator.changePage(0)

    // } else if (event.rows == 25) {
    //   this.RequiredStock.pageLimit = 25
    //   this.RequiredStock.pageNumber = event.page



    //   this.paginator.changePage(0)

    // } else if (event.rows == 50) {
    //   this.RequiredStock.pageLimit = 50
    //   this.RequiredStock.pageNumber = event.page

    //   this.paginator.changePage(0)

    // }
    // else {

    //   // this.RequiredPending.pageLimit=event.rows 
    //   this.RequiredStock.pageNumber = event.page + 1
    //   // this.paginator.changePage(0)
    // }


    // console.log(this.RequiredStock)
    // this.StockStmtApi()
    this.row2 = event.rows
    // console.log(event.rows ,this.totalrows);



    // this.RequiredDetails.pageLimit=event.rows
    // this.pageNumber = event.page + 1;
    // this.RequiredDetails.pageNumber = this.pageNumber
    //  console.log(event.rows,this.RequiredStock.pageLimit)

    if (this.RequiredStock.pageLimit == event.rows) {
      this.RequiredStock.pageNumber = event.page + 1
      myVariables.StockStatementPageNumber = this.RequiredStock.pageNumber
      //  this.GetallPendingStatus()

    } else if (event.rows == 10) {
      this.RequiredStock.pageLimit = 10
      this.RequiredStock.pageNumber = event.page
      myVariables.StaockStatementRowsPerPage = this.RequiredStock.pageLimit


      this.paginator.changePage(0)
      // this.GetallPendingStatus()

    }
    else if (event.rows == 25) {
      this.RequiredStock.pageLimit = 25
      this.RequiredStock.pageNumber = event.page
      myVariables.StaockStatementRowsPerPage = this.RequiredStock.pageLimit



      this.paginator.changePage(0)
      // this.GetallPendingStatus()

    } else if (event.rows == 50) {
      this.RequiredStock.pageLimit = 50
      this.RequiredStock.pageNumber = event.page
      myVariables.StaockStatementRowsPerPage = this.RequiredStock.pageLimit

      this.paginator.changePage(0)
      //  this.GetallPendingStatus()

    }
    else if (event.rows == 75) {
      this.RequiredStock.pageLimit = 75
      this.RequiredStock.pageNumber = event.page
      myVariables.StaockStatementRowsPerPage = this.RequiredStock.pageLimit

      this.paginator.changePage(0)
      //  this.GetallPendingStatus()


    }
    else {

      // this.RequiredStock.pageLimit=event.rows 
      this.RequiredStock.pageNumber = event.page + 1
      myVariables.StockStatementPageNumber = this.RequiredStock.pageNumber
      // this.first=event.page
      // console.log(event);
      // console.log(this.first);


      // this.paginator.changePage(0)

    }


    console.log(this.RequiredStock)
    this.StockStmtApi()


  }
}