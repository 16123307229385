import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { LoaderService } from '../loader/loader.service';
import { FormControl } from '@angular/forms';
import { dashboardVarialbles } from 'src/environments/environment';


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavHeaderComponent implements OnInit {
  user_name: any;
  UserMblNo: any;
  warehouseArray: any;
  warehouseName = new FormControl('');
  selectedwarehouse: any;

  constructor(private router: Router, private ApiCall: ApiService, public loaderService: LoaderService) { }

  ngOnInit() {
    dashboardVarialbles.isStagingRoute = false
    dashboardVarialbles.isPendingRoute = false
    dashboardVarialbles.IsCompletedRoute = false
    window.scrollTo({ top: 0, behavior: 'smooth' });
    localStorage.setItem('PageNo', "1")
    this.user_name = localStorage.getItem('U_name')
    this.UserMblNo = localStorage.getItem('U_number')
    this.warehouseArray = JSON.parse(localStorage.getItem('WarehouseArray'))
    if (localStorage.getItem('WarehouseID') == '' || localStorage.getItem('WarehouseID') == undefined || localStorage.getItem('WarehouseID') == null) {
      // this.warehouseName=this.warehouseArray[0].name
      this.warehouseName = new FormControl(this.warehouseArray[0].id)
      console.log(this.warehouseName)

      localStorage.setItem('WarehouseID', this.warehouseArray[0].id)
    }
    else {



      this.selectedwarehouse = this.warehouseArray.find(c => c.id == localStorage.getItem('WarehouseID'))
      // this.warehouseName=this.selectedwarehouse.name
      this.warehouseName = new FormControl(this.selectedwarehouse.id);
      // console.log(this.warehouseName);

      // console.log(this.warehouseName);
    }
    console.log(this.warehouseArray)
    this.ConfigPhaseDetails();
  }
  ConfigPhaseDetails() {
    console.log(localStorage.getItem('WarehouseID'))
    this.ApiCall.getConfigDetails()
      .subscribe(res => {
        console.log(res)
        res.result.map(ass => {
          ass.hasOwnProperty('description')
          delete ass['description']

          ass.hasOwnProperty('module')
          delete ass['module']

          ass.hasOwnProperty('subModule')
          delete ass['subModule']

          ass.hasOwnProperty('title')
          delete ass['title']

          ass.hasOwnProperty('userRole')
          delete ass['userRole']
        })
        localStorage.setItem('ConfigDetails', JSON.stringify(res.result))
      })
  }
  async warehousechange(event) {
    console.log(event)
    localStorage.setItem('WarehouseID', event)
    await this.router.navigate(['/dashboard'])
    window.location.reload();

  }
  logOut() {
    this.ApiCall.getLogOutApi()
      .subscribe(res => {
        console.log("LOGOUT SUCESSFULLY", res)
        if (res.status == true) {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate([''])
          Swal.fire({
            toast: true,
            position: 'center',
            text: 'Logout Successfully',
            showConfirmButton: false,
            timer: 3000,
            type: 'success',
          })
        }
      })
  }

  Home() {
    this.router.navigate(['dashboard'])
  }


  ChangePassword() {
    this.router.navigate(['changepassword'])
  }
}
