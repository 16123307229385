import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import lodash from 'lodash';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
export interface Product {

}


@Component({
  selector: 'app-view-selected-receipt',
  templateUrl: './view-selected-receipt.component.html',
  styleUrls: ['./view-selected-receipt.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class ViewSelectedReceiptComponent implements OnInit {
  @ViewChild("fileUpload", {}) fileUpload: ElementRef; files = [];
  materialList: any;
  cus_name: any;
  invoiceNo: any;
  bookingId: any;
  invoiceDate: any;
  materialListValue: any;
  InfoPickupInvoice: any;
  pendingreceiptData: any;
  customerNameList: any;
  Date: any;
  ExpectedDate: any;
  ExpectedTime: any;
  SupplierName: any;
  SupplierMobile: any;
  fileName: any;
  documentView: any;
  notFound = false;
  deleteIndex: any;

  RequiredAddMaterial = {
    bookingId: "",
    invoiceDate: "",
    invoiceNumber: "",
    warehouseId: "",
    userId: "",
    materials: [],
    pickupMaterialId: localStorage.getItem('pending_receipt_id'),
    purchaseOrderNumber: "",
  }
  Add_material = [];
  receiptNumber: any;
  EmptyInvoice = false;
  InvoiceDetails: any;
  InvoiceForm: FormGroup;
  submitted = false;
  rowIndex: any;
  exisitngMaterial = [];
  grnDate: any;
  BusinessName: any;
  InvoiceNumber: any;
  InvoiceDate: any;
  PoNumber: any;
  displayProgressSpinner = false;
  Loader = false
  EmptyLocation: any;
  ImgThumbnail: any;
  imagePath = [];

  img_name: any;
  strLength: number;
  rowDeleteDoc: any;
  deleteSelectedIndex: any;
  pickupReqID: any;
  Uploading = false;
  findIndex: any;
  imgURL: any;
  responseOfmaterial: any;
  ResponseMaterials: any
  FilterMaterials = [];
  DocumentID: any;
  SubLoopImage = [];
  GrnNumber: any;
  GrnCount = 0;
  HideAddMaterialBtn = true;
  passStatusValue: any;
  PostBtnEnable = true;
  PutBtnEnable = false;
  disablePutBtn = true;

  RequiredToMoveComplete = {
    "bookingId": '',
    "pickupMaterialId": localStorage.getItem('pending_receipt_id'),
    "userId": ''
  }
  passConfigStatusValue: any;
  passConfigStatusValue1: any;
  apiresult: any;
  displayDialog: boolean;
  maximiseData: Product;
  displayDialog1: boolean;
  enableCompleteBtn: boolean = false;
  allowLocationPutaway: any;

  constructor(private messageService: MessageService, private router: Router, private formBuilder: FormBuilder, private apiCall: ApiService, private snackBar: MatSnackBar, private datePipe: DatePipe) { }
  ngOnInit() {
    localStorage.removeItem('Redirecting')
    this.passConfigStatusValue = JSON.parse(localStorage.getItem('ConfigDetails'))
    console.log(this.passConfigStatusValue)
    this.passConfigStatusValue1 = this.passConfigStatusValue.find(item => item.appConfigurationId == 1002)
    console.log(this.passConfigStatusValue1)
    this.filterById()
    this.InvoiceFormValidation()
    this.viewSelect()

    // this.initFileUploadEventListener();

    this.ResponseMaterials = [
      {
        "quantity": 200, "goodQuantity": 150, "damagedQuantity": 50, "shortageQuantity": 0, "excessQuantity": 0, "productId": 732, "remark": "", "invoiceQuantity": 200, "materialCode": "FR10000000279", "materialName": "Fridge", "brand": "", "unitOfMaterials": "Pcs", "description": "Fridge", "length": null, "lengthUnit": null,
        "width": null, "widthUnit": null, "height": null, "heightUnit": null, "weight": null, "weightUnit": "Kgs", "unitPerStack": null, "area": null, "areaUnit": null, "boxPcsQuantity": null, "unitOfBoxPices": "None", "isFifo": "false", "isPalletLevel": "false"
      },
      {
        "quantity": 100, "goodQuantity": 70, "damagedQuantity": 30, "shortageQuantity": 0, "excessQuantity": 0, "productId": 638, "remark": null, "invoiceQuantity": 100, "materialCode": "MO10000000199", "materialName": "MotoGPluse", "brand": "Onida", "unitOfMaterials": "Pcs", "description": "MotoGPluse", "length": 1, "lengthUnit": "Feet", "width": 2, "widthUnit": "Inches", "height": 8, "heightUnit": "Inches", "weight": 2, "weightUnit": "Kgs", "unitPerStack": 10, "area": null, "areaUnit": null, "boxPcsQuantity": null, "unitOfBoxPices": "None", "isFifo": "true", "isPalletLevel": "false"
      }
    ]

    if (localStorage.getItem('ViewReceiptPoNo') === "null") {
      this.RequiredAddMaterial.purchaseOrderNumber = ''
    } else {
      this.RequiredAddMaterial.purchaseOrderNumber = localStorage.getItem('ViewReceiptPoNo')
    }
    if (localStorage.getItem('ViewReceiptInvoiceNo') === "null") {
      this.RequiredAddMaterial.invoiceNumber = ''
    } else {
      this.RequiredAddMaterial.invoiceNumber = localStorage.getItem('ViewReceiptInvoiceNo')
    }
    if (localStorage.getItem('ViewReceiptInvoiceDate') === "null") {
      this.RequiredAddMaterial.invoiceDate = ''
    } else {
      this.RequiredAddMaterial.invoiceDate = localStorage.getItem('ViewReceiptInvoiceDate')
    }
  }

  @Output() event = new EventEmitter<string>()

  InvoiceFormValidation() {
    this.InvoiceForm = this.formBuilder.group({
      InvoiceNo: ['', Validators.required],
      InvoiceDate: ['', Validators.required],
      PoNo: ['']
    })
  }

  get f() {
    return this.InvoiceForm.controls;
  }
  filterById() {


    if (this.passConfigStatusValue1.status === false) {


      this.HideAddMaterialBtn = false
      this.PostBtnEnable = false;
      this.PutBtnEnable = true

    } else {
      this.HideAddMaterialBtn = true
      this.PostBtnEnable = true;
      this.PutBtnEnable = false

    }


  }
  RedirectToAddMaterial() {
    this.router.navigate(['/addmaterial'])
  }
  viewSelect() {
    this.apiCall.getViewSelectedReceipt()
      .subscribe(res => {
        console.log('apicall response', res)
        this.pendingreceiptData = res.result
        // console.log("INVOICE QTY", this.pendingreceiptData)
        localStorage.setItem('ADD_CusID', res.result[0].customerId)
        this.passStatusValue = this.pendingreceiptData[0].status
        this.event.emit(this.passStatusValue)

        if (this.pendingreceiptData[0].status === 'Staging') {
          this.HideAddMaterialBtn = false
          this.PostBtnEnable = false;
          this.PutBtnEnable = true
        }
        console.log(this.passStatusValue);

        this.RequiredAddMaterial.bookingId = res.result[0].bookingId
        this.RequiredToMoveComplete.bookingId = res.result[0].bookingId
        this.RequiredToMoveComplete.userId = res.result[0].userId
        this.RequiredAddMaterial.userId = res.result[0].userId
        this.RequiredAddMaterial.warehouseId = res.result[0].warehouseId
        this.BusinessName = res.result[0].businessName
        this.allowLocationPutaway = res.allowLocationPutaway

        // console.log("Pending Receipt Details", this.Add_material)
        this.Add_material = res.result[0].materials
        for (var j = 0; j < res.result[0].materials.length; j++) {
          if (this.Add_material[j].stagingGoodQuantity || this.Add_material[j].stagingDamagedQuantity) {

            this.Add_material[j]["stagingGoodQuantity"] = this.Add_material[j].stagingGoodQuantity - this.Add_material[j].goodQuantity
            this.Add_material[j]["stagingDamagedQuantity"] = this.Add_material[j].stagingDamagedQuantity - this.Add_material[j].damagedQuantity
            this.materialList = [
              { field: 'SkuUpcEan', header: 'SKU Code' },
              { field: 'description', header: 'Description' },
              { field: 'unitOfMaterials', header: 'UoM' },
              { field: 'quantity', header: 'Quantity' },
              // { field: 'invoiceQuantity', header: 'Invoice Quantity' },
              { field: 'stagingGoodQuantity', header: 'Good Quantity' },
              { field: 'stagingDamagedQuantity', header: 'Damaged Quantity' },
              { field: 'manufacturedDate', header: 'Manufacturing Date' },
              { field: 'cartonCode', header: 'Carton Code' },
              { field: 'batchNumber', header: 'Batch No.' },

              // { field: 'productimage', header: 'Product Image' },
              { field: 'location', header: 'Location' },
            ];
          } else {
            // this.Add_material[j]["goodQuantity"] = "0"
            // this.Add_material[j]["damagedQuantity"] = "0"
            this.materialList = [
              { field: 'SkuUpcEan', header: 'SKU Code' },
              { field: 'description', header: 'Description' },
              { field: 'unitOfMaterials', header: 'UoM' },
              { field: 'quantity', header: 'Quantity' },

              { field: 'invoiceQuantity', header: 'Invoice Quantity' },
              { field: 'goodQuantity', header: 'Good Quantity' },
              { field: 'damagedQuantity', header: 'Damaged Quantity' },
              { field: 'manufacturedDate', header: 'Manufacturing Date' },
              { field: 'cartonCode', header: 'Carton Code' },
              { field: 'batchNumber', header: 'Batch No.' },
              // { field: 'productimage', header: 'Product Image' },
              { field: 'location', header: 'Location' },

            ];
          }

          this.Add_material[j]["brand"] = ""
          this.Add_material[j]["dimension"] = ""
          this.Add_material[j]["media"] = []
          this.Add_material[j]["materialLocation"] = []
          // this.Add_material[j]["productImage"] = []
          this.Add_material[j]["productName"] = ""
          this.Add_material[j]["remark"] = ""
          this.Add_material[j]["weight"] = ""

          this.Add_material[j]["SkuUpcEan"] = res.result[0].materials[j].materialCode
          this.Add_material[j]["description"] = res.result[0].materials[j].materialName

          delete this.Add_material[j].materialCode
          delete this.Add_material[j].materialName

          localStorage.setItem("exisiting_material", JSON.stringify(this.Add_material))

        }

        if (localStorage.getItem('materialInfo') === '' || localStorage.getItem('materialInfo') === null || localStorage.getItem('materialInfo') === undefined) {
          localStorage.setItem("materialInfo", JSON.stringify(res.result[0].materials))
        } else {
          this.Add_material = JSON.parse(localStorage.getItem('materialInfo'))
          console.log("HELLO EVERYONE LOOK @ HERE -------- 1")
          // if(this.Add_material[0].materialLocation){
          //   this.PostBtnEnable = false
          //   this.PutBtnEnable = true
          // }
          // this.Add_material.map(ass => {
          //   ass["productImage"] = []
          // })

        }
        for (let i = 0; i < this.Add_material.length; i++) {


          console.log(this.Add_material[i])
          if (this.Add_material[i].stagingGoodQuantity != 0 || this.Add_material[i].stagingDamagedQuantity != 0) {
            if (this.Add_material[i].materialLocation.length != 0) {
              this.disablePutBtn = false

            }
            else {
              this.disablePutBtn = true
            }
          }

        }
        console.log(this.disablePutBtn);

        console.log("MATERIAL", this.Add_material)
        if (!this.pendingreceiptData[0].documents.length) {
          this.notFound = true
          // console.log('',this.file)
        }
        else {
          this.fileName = this.pendingreceiptData[0].documents
          this.documentView = this.fileName[0].fileUrl
        }
        // }
        this.receiptNumber = res.result[0].id
        this.cus_name = res.result[0].customerName
        this.bookingId = res.result[0].bookingId
        this.InvoiceDetails = res.result[0]

        if (!this.InvoiceDetails.pickupInvoice.length) {
          this.EmptyInvoice = true;
        }
        else {
          this.InfoPickupInvoice = res.result[0].pickupInvoice
          this.RequiredAddMaterial.invoiceDate = res.result[0].pickupInvoice[0].invoiceDate
          this.RequiredAddMaterial.invoiceNumber = res.result[0].pickupInvoice[0].invoiceNumber
          this.RequiredAddMaterial.purchaseOrderNumber = res.result[0].pickupInvoice[0].purchaseOrderNumber
          localStorage.setItem('PikcupInvoiceDetails', JSON.stringify(this.InfoPickupInvoice));
          // console.log("CHECKING INVOICE", this.InfoPickupInvoice)
        }

        this.Date = res.result[0].createdAt
        this.ExpectedDate = res.result[0].pickupDate
        this.ExpectedTime = res.result[0].pickupTime
        this.SupplierName = res.result[0].supplier.name
        this.SupplierMobile = res.result[0].supplier.mobileNo
      })

  }


  /*------------------PUT METHOD CALLING LOCATION PUT AWAY-------------------*/
  cancel() {
    this.displayDialog1 = false
  }
  Okay() {
    this.displayDialog1 = false
  }
  showdialog(rowData, i) {
    console.log(rowData, i)

    this.maximiseData = rowData
    this.displayDialog1 = true
    console.log(this.maximiseData)
  }
  submitPUTData() {
    this.displayProgressSpinner = true;
    this.Loader = true
    this.submitted = true;

    this.RequiredAddMaterial.invoiceDate = this.datePipe.transform(this.RequiredAddMaterial.invoiceDate, "yyyy-MM-dd")

    if (this.RequiredAddMaterial.invoiceDate === "" || this.RequiredAddMaterial.invoiceDate === null && this.RequiredAddMaterial.invoiceNumber === "" || this.RequiredAddMaterial.invoiceNumber === null) {
      if (this.InvoiceForm.invalid) {
        this.displayProgressSpinner = true;
        this.Loader = true
        return
      }
    }
    console.log(this.Add_material)
    for (let i = 0; i < this.Add_material.length; i++) {
      this.Add_material[i].goodQuantity = this.Add_material[i].stagingGoodQuantity
      this.Add_material[i].damagedQuantity = this.Add_material[i].stagingDamagedQuantity

    }
    this.RequiredAddMaterial.materials = this.Add_material
    var count = 0;
    for (var i = 0; i < this.Add_material.length; i++) {
      if (this.Add_material[i].invoiceQuantity === 0) {
        const snack = this.snackBar.open('Please fill the Invoice Quantity', 'X', {
          duration: 1500,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
        this.displayProgressSpinner = false;
        this.Loader = false
        return;
      }
      else {
        this.displayProgressSpinner = true;
        this.Loader = true
        count = 1;
      }
    }
    if (count == 1) {
      this.displayProgressSpinner = true;
      this.Loader = true
      for (var i = 0; i < this.RequiredAddMaterial.materials.length; i++) {
        this.EmptyLocation = this.RequiredAddMaterial.materials[i].materialLocation
        for (var j = 0; j < this.EmptyLocation.length; j++) {
          for (var k = 0; k < this.EmptyLocation[j].pallets.length; k++) {
            if (this.EmptyLocation[j].pallets[k].number === '' || this.EmptyLocation[j].pallets[k].palletQuantity === '') {
              this.EmptyLocation[j].pallets = []
            }
          }

        }
      }
      console.log("Full Parameters", this.RequiredAddMaterial)
      if (this.passConfigStatusValue1.status == false) {
        console.log('one phase is executed')
        this.apiCall.getPUTAddMaterial(this.RequiredAddMaterial)
          .subscribe(res => {
            if (res.status) {
              // this.grnDate = res.result.grnDate
              // this.grnDate = this.datePipe.transform(this.grnDate, "d MMM y")
              // this.GrnNumber = res.result.grnNumber
              // this.messageService.add({ key: 'S', sticky: true, severity: 'success', summary: 'GRN Raised', detail: this.GrnNumber })
              // Swal.fire({
              //   toast: true,
              //   title: res.message,
              //   showConfirmButton: false,
              //   type: 'success',
              //   timer: 3000
              // })
              // this.CompleteLocationDetails()
              this.enableCompleteBtn = true
              console.log('DATA SUBMITED', res)
              localStorage.removeItem('Add_material')
              localStorage.removeItem('bookingID')
              localStorage.removeItem('invoiceDate')
              localStorage.removeItem('InvoiceNo')
              localStorage.removeItem('PikcupInvoiceDetails')
              localStorage.removeItem('materialInfo')
              localStorage.removeItem('selectedProduct')
              localStorage.removeItem('NewAddMaterial')
              localStorage.removeItem('ViewReceiptInvoiceDate')
              localStorage.removeItem('ViewReceiptInvoiceNo')
              localStorage.removeItem('ViewReceiptPoNo')
              this.displayProgressSpinner = false;
              this.Loader = false
              this.router.navigate(['/completedreceipt'])
            }
            else {
              const snack = this.snackBar.open(res.error, 'X', {
                duration: 3000,
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
                panelClass: ['success-snackbar']
              });
              this.displayProgressSpinner = false;
              this.Loader = false
            }

          })
      }
      else {
        this.submitPOSTData()
      }

    }
  }

  CompleteLocationDetails() {
    this.apiCall.moveToComplete(this.RequiredToMoveComplete)
      .subscribe(res => {
        if (res.status) {
          Swal.fire({
            toast: true,
            title: "Receipt Completed Successfully",
            showConfirmButton: false,
            type: 'success',
            timer: 3000
          })
          this.router.navigate(['/completedreceipt'])
        }
        else (
          console.log(res)

        )

      })
  }

  /*----------------------------POST METHOD CALLING-----------------------------*/

  submitPOSTData() {
    this.displayProgressSpinner = true;
    this.Loader = true
    this.submitted = true;
    this.RequiredAddMaterial.invoiceDate = this.datePipe.transform(this.RequiredAddMaterial.invoiceDate, "yyyy-MM-dd")

    if (this.RequiredAddMaterial.invoiceDate === "" || this.RequiredAddMaterial.invoiceDate === null && this.RequiredAddMaterial.invoiceNumber === "" || this.RequiredAddMaterial.invoiceNumber === null) {
      if (this.InvoiceForm.invalid) {
        this.displayProgressSpinner = true;
        this.Loader = true
        return
      }
    }

    this.RequiredAddMaterial.materials = this.Add_material
    var count = 0;
    for (var i = 0; i < this.Add_material.length; i++) {

      // if (this.pendingreceiptData[0].status !== 'Staging') {
      //   if (lodash.isEqual(this.Add_material[i].stagingGoodQuantity, this.Add_material[i].stagingGoodQuantity)) delete this.Add_material[i].stagingGoodQuantity

      //   if (lodash.isEqual(this.Add_material[i].stagingDamagedQuantity, this.Add_material[i].stagingDamagedQuantity)) delete this.Add_material[i].stagingDamagedQuantity
      // }

      if (this.Add_material[i].invoiceQuantity === 0) {
        const snack = this.snackBar.open('Please fill the Invoice Quantity', 'X', {
          duration: 1500,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
        this.displayProgressSpinner = false;
        this.Loader = false
        return;
      }
      else {
        this.displayProgressSpinner = true;
        this.Loader = true
        count = 1;
      }
    }
    if (count == 1) {
      this.displayProgressSpinner = true;
      this.Loader = true
      for (var i = 0; i < this.RequiredAddMaterial.materials.length; i++) {
        this.EmptyLocation = this.RequiredAddMaterial.materials[i].materialLocation
        for (var j = 0; j < this.EmptyLocation.length; j++) {
          for (var k = 0; k < this.EmptyLocation[j].pallets.length; k++) {
            if (this.EmptyLocation[j].pallets[k].number === '' || this.EmptyLocation[j].pallets[k].palletQuantity === '') {
              this.EmptyLocation[j].pallets = []
            }
          }

        }
      }
      console.log("Full Parameters", this.RequiredAddMaterial)

      if (this.passConfigStatusValue1.status == true) {
        console.log('two phase is executed')
        if (this.pendingreceiptData[0].status !== 'Staging') {
          for (var i = 0; i < this.RequiredAddMaterial.materials.length; i++) {
            if (this.RequiredAddMaterial.materials[i].materialLocation.length != 0) {

            }
          }
          this.apiCall.getPOSTAddMaterial(this.RequiredAddMaterial)
            .subscribe(res => {
              if (res.status) {
                this.apiresult = res
                this.grnDate = res.result.grnDate
                this.grnDate = this.datePipe.transform(this.grnDate, "d MMM y")
                this.GrnNumber = res.result.grnNumber
                console.log("CURRENT ARRAY", this.Add_material)
                this.messageService.add({ key: 'S', sticky: true, severity: 'success', summary: 'GRN Raised', detail: this.GrnNumber })


              }
              else {
                const snack = this.snackBar.open(res.error, 'X', {
                  duration: 3000,
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ['success-snackbar']
                });
                this.displayProgressSpinner = false;
                this.Loader = false
              }
            })
        }
        console.log(this.RequiredAddMaterial)
        console.log(this.Add_material);

        if (this.pendingreceiptData[0].status === 'Staging') {
          for (var i = this.RequiredAddMaterial.materials.length - 1; i >= 0; i--) {
            if (this.RequiredAddMaterial.materials[i].stagingGoodQuantity == 0 && this.RequiredAddMaterial.materials[i].stagingDamagedQuantity == 0) {
              // delete this.RequiredAddMaterial.materials[i]
              this.RequiredAddMaterial.materials.splice(i, 1)


            }
          }

          // this.RequiredAddMaterial.materials.splice()


          this.apiCall.getPutAddMaterial1twoPhase(this.RequiredAddMaterial).subscribe(res => {
            console.log(res)
            if (res.status) {
              this.enableCompleteBtn = true
              //  this.CompleteLocationDetails()
              console.log('DATA SUBMITED')
              localStorage.removeItem('Add_material')
              localStorage.removeItem('bookingID')
              localStorage.removeItem('invoiceDate')
              localStorage.removeItem('InvoiceNo')
              localStorage.removeItem('PikcupInvoiceDetails')
              localStorage.removeItem('materialInfo')
              localStorage.removeItem('selectedProduct')
              localStorage.removeItem('NewAddMaterial')
              localStorage.removeItem('ViewReceiptInvoiceDate')
              localStorage.removeItem('ViewReceiptInvoiceNo')
              localStorage.removeItem('ViewReceiptPoNo')
              this.displayProgressSpinner = false;
              this.Loader = false

            }
            else {

              const snack = this.snackBar.open(res.error, 'X', {
                duration: 3000,
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
                panelClass: ['success-snackbar']
              });
            }
            console.log("this is also executed")
          })

        }

      }
      else (
        this.submitPUTData()
      )

    }
  }


  documentViewPage() {
    window.open(this.documentView, '_blank')
  }

  CreateMaterial() {
    this.router.navigate(['/addmaterial'])
  }
  viewReciept(rowData, ri) {
    console.log(ri)
    console.log(this.Add_material)

    if (this.RequiredAddMaterial.invoiceNumber === ""
      && this.RequiredAddMaterial.invoiceNumber === null
      && this.RequiredAddMaterial.invoiceDate === ""
      && this.RequiredAddMaterial.invoiceDate === null
      && this.RequiredAddMaterial.purchaseOrderNumber === ""
      && this.RequiredAddMaterial.purchaseOrderNumber === null) {
      console.log("No need to store")
    } else {
      this.InvoiceNumber = this.RequiredAddMaterial.invoiceNumber
      this.InvoiceDate = this.RequiredAddMaterial.invoiceDate
      this.InvoiceDate = this.datePipe.transform(this.InvoiceDate, "yyyy-MM-dd")
      this.PoNumber = this.RequiredAddMaterial.purchaseOrderNumber

      localStorage.setItem('ViewReceiptInvoiceNo', this.InvoiceNumber)
      localStorage.setItem('ViewReceiptInvoiceDate', this.InvoiceDate)
      localStorage.setItem('ViewReceiptPoNo', this.PoNumber)
    }
    localStorage.setItem('rowIndex', ri)
    localStorage.setItem('materialInfo', JSON.stringify(this.Add_material));
    localStorage.setItem('selectedProduct', JSON.stringify(rowData));
    this.router.navigate(['/inwardmaterial'])
  }

  ShowLocationScreen(rowData, ri) {

    localStorage.setItem('rowIndex', ri)
    localStorage.setItem('materialInfo', JSON.stringify(this.Add_material));
    localStorage.setItem('selectedProduct', JSON.stringify(rowData));
    this.router.navigate(['/inwardmaterials'])
  }
  ViewLocation(rowData) {

    var Location = "true";
    if (this.RequiredAddMaterial.invoiceNumber === "" && this.RequiredAddMaterial.invoiceNumber === null && this.RequiredAddMaterial.invoiceDate === "" && this.RequiredAddMaterial.invoiceDate === null && this.RequiredAddMaterial.purchaseOrderNumber === "" && this.RequiredAddMaterial.purchaseOrderNumber === null) {
      console.log("No need to store")
    } else {
      this.InvoiceNumber = this.RequiredAddMaterial.invoiceNumber
      this.InvoiceDate = this.RequiredAddMaterial.invoiceDate
      this.InvoiceDate = this.datePipe.transform(this.InvoiceDate, "yyyy-MM-dd")
      this.PoNumber = this.RequiredAddMaterial.purchaseOrderNumber

      localStorage.setItem('ViewReceiptInvoiceNo', this.InvoiceNumber)
      localStorage.setItem('ViewReceiptInvoiceDate', this.InvoiceDate)
      localStorage.setItem('ViewReceiptPoNo', this.PoNumber)
      localStorage.setItem('Redirecting', Location)
    }

    localStorage.setItem('materialInfo', JSON.stringify(this.Add_material));
    localStorage.setItem('selectedProduct', JSON.stringify(rowData));
    this.router.navigate(['/inwardmaterial'])
  }

  DisabledRow(rowData, ri) {
    this.deleteIndex = ri
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Do you want to delete' })

  }

  onMatReject() {
    this.messageService.clear('c');
  }

  onConfirmDelete() {
    this.Add_material.splice(this.deleteIndex, 1);
    localStorage.setItem('materialInfo', JSON.stringify(this.Add_material));
    this.messageService.clear('c');
  }

  grnCloseBtn() {
    this.router.navigate(['pendingreceipt'])
    this.messageService.clear('S');
  }




  /*----------------------UPLOAD DOUCMENT-------------------------*/
  uploadFile(file) {
    this.displayProgressSpinner = true
    this.Loader = true
    const formData = new FormData();
    formData.append('productId', this.DocumentID)
    formData.append('file', file.data);
    file.inProgress = true;
    this.Uploading = true;
    this.displayProgressSpinner = true
    this.Loader = true

    console.log("FORM DATA", formData)
    this.apiCall.upload(formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        this.displayProgressSpinner = false;
        this.Loader = false
        // this.Uploading = false
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          if (event.body.error) {
            this.Uploading = false;
            this.displayProgressSpinner = false;
            this.Loader = false
            var err = event.body.error.message
            Swal.fire({
              toast: true,
              title: err,
              showConfirmButton: false,
              type: 'error',
              timer: 3000
            })
          }
          //console.log("IMAGE SUCCESFULLY UPLOADED", event.body);
          if (event.body.status) {
            this.Uploading = false
            this.displayProgressSpinner = false
            this.Loader = false
          }
        }
      });

  }
  // onRowDeleteDocument(index, deleteDocument) {
  //   this.rowDeleteDoc = deleteDocument
  //   this.deleteSelectedIndex = index
  //   this.messageService.add({ key: 'd', sticky: true, severity: 'warn', detail: 'Are you sure you want to delete document ' + deleteDocument + ' ?' })
  // }
  // onReject() {
  //   this.messageService.clear('d');
  // }
  // onEnter(e) {
  // }
  // removeSelectedFile() {
  //   this.files.splice(this.rowDeleteDoc, 1);
  //   localStorage.setItem("Documents", JSON.stringify(this.files))
  //   this.messageService.clear('d');
  //   return Promise.resolve()
  // }

  ImgThumbnails(rowData, ri) {
    console.log("ROWDATA GETTING", rowData, ri)
    var reader = new FileReader();
    reader.readAsDataURL(rowData.productImage[0].data);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  FindIndex(ri) {
    console.log("FIND INDEX", ri)
    this.findIndex = ri

    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      var _validFileExtensions = [".jpg", ".jpeg", ".png"];
      var blnValid = false;
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.img_name = file.name
        this.strLength = 4;

        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (this.img_name.substr(this.img_name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
            this.Add_material[ri]['productImage'] = []
            console.log("PRODUCT IMAGE", this.Add_material, Array.isArray(this.Add_material))
            this.Add_material[ri].productImage.push({ data: file, inProgress: false, progress: 0, file_name: this.img_name });

            this.SubLoopImage = this.Add_material[ri].productImage
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          alert("Sorry, " + this.img_name + " is invalid, Only JPG / PNG / JPEG files are allowed");
          return false;
        }


        // if (this.img_name.substring(this.img_name.length - this.strLength) !== ".pdf") {
        //     this.Add_material[ri]['productImage'] = []
        //     console.log("PRODUCT IMAGE", this.Add_material, Array.isArray(this.Add_material))
        //   this.Add_material[ri].productImage.push({ data: file, inProgress: false, progress: 0, file_name: this.img_name });

        //   this.SubLoopImage = this.Add_material[ri].productImage
        //   console.log("SUB LOOP", this.Add_material)
        // }
        // else {
        //   alert("Only JPG / PNG / JPEG files are allowed")
        // }
      }
    };
    fileUpload.click();
  }

  initFileUploadEventListener() {

    const fileUpload = this.fileUpload.nativeElement;
    console.dir(fileUpload);
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.img_name = file.name
        this.strLength = 4;
        if (this.img_name.substring(this.img_name.length - this.strLength) == ".pdf") {
          this.files.push({ data: file, inProgress: false, progress: 0, file_name: this.img_name });
        }
        else {
          alert("Only pdf files are allowed")
        }
      }
      // this.uploadFiles();
    };
  }

  preview(files) {
    // console.log("files", files)
    // if (files.length === 0)
    //   return;

    // var mimeType = files[0].type;
    // // if (mimeType.match(/image\/*/) == null) {
    // //   this.message = "Only images are supported.";
    // //   return;
    // // }

    // var reader = new FileReader();
    // this.imagePath = files;
    // reader.readAsDataURL(files[0]);
    // reader.onload = (_event) => {
    //   this.ImgThumbnail = reader.result;
    //   this.Add_material[this.findIndex][this.findIndex] = files
    //   console.log("IMAG PATH", files[0])
    //   files = []
    // }
  }
}