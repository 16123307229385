import { Component, OnInit, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { EMPTY, Observable, Subject } from 'rxjs';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { map, startWith } from 'rxjs/operators';
@Component({
    selector: 'app-stockledger',
    templateUrl: './stockledger.component.html',
    styleUrls: ['./stockledger.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class StockledgerComponent implements OnInit {
  @ViewChild('myTable') private _table: Table;
  @ViewChild('calendar', undefined) private calendar: any;
  cars: any[];
  cols: any[];
  data: any[];
  multiSelectVal: string[] = [];
  colors: SelectItem[];
  selectedRowData: any[];
  displayBasic: boolean;
  displayInd: boolean;
  expandedRowIndex: any;
  rowData_proCode: any;
  rowData_Stock: any;
  checkBoxVal: any;
  control: FormControl;
  dateTime = new Date();
  previousVal: any;
  currentVal: any;
  selectedCity: any;
  // selectedProduct: any;
  sDate: any;
  eDate: any;
  RequiredMaterials = {
    productId: "",
    customerId: localStorage.getItem('ViewCustomer_id'),
    warehouseId: localStorage.getItem('WarehouseID'),
    startDate: "",
    endDate: ""
  }

  excelDownload = {
    "customerId": localStorage.getItem('ViewCustomer_id'),
    "warehouseId": localStorage.getItem('WarehouseID'),
    "productId": "",
    "startDate": "",
    "endDate": ""
  };

  PaginationReq = {
    "customerId": localStorage.getItem('ViewCustomer_id'),
    "pageNumber": "1"
  }

  ProductSearch = {
    "searchval": "",
    "customerId": localStorage.getItem('ViewCustomer_id')
  }

  ProductMaterial = {
    "userId": localStorage.getItem('ID'),
    "date": localStorage.getItem('SDate') || localStorage.getItem('DefaultDate'),
    "materialcode": ""
  }

  dropdownValues: any[];
  dropdownData = {};
  FixedDate: any;
  dateFilters: any;
  datas: any;
  Yesterday = new Date();
  Today = new Date();
  checkerNgmodal: string;
  rangeDates: Date[];
  DefaultProduct: any;
  showProduct: any;
  today: any;
  firstDate: any;
  rowlength: any;
  totalCount: any;
  check = false
  loading: boolean = true;
  pageNumber: any;
  totalRecords: any;

  SupplierfilteredOption: Observable<string[]>;
  name: FormControl;
  AutoFillSuppliers = [];
  printingMaterialName: string = '';

  isEnabled = false;

  constructor(private router: Router, private Apicall: ApiService, private datePipe: DatePipe) {
    this.name = new FormControl();
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );
  }
  ngOnInit() {
    // this.GetLedgerProducts()
    this.today = new Date();
    this.firstDate = new Date();
    this.firstDate.setMonth(this.today.getMonth() - 1);
    this.rangeDates = [this.firstDate, this.today];
    this.RequiredMaterials.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
    this.excelDownload.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
    this.RequiredMaterials.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
    this.excelDownload.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
    this.dateTime.setDate(this.dateTime.getDate() + 0);
    // //console.log("TABLE VALUES", this._table)
    this.selectedRowData = [];
    this.cols = [
      { field: 'date', header: 'Date' },
      { field: 'time', header: 'Time' },
      { field: 'documentNo', header: 'Document No' },
      { field: 'goodQuantity', header: 'Good' },
      { field: 'damagedQuantity', header: 'Damaged' },
      { field: 'goodInward', header: 'Good' },
      { field: 'damagedInward', header: 'Damaged' },
      { field: 'goodOutward', header: 'Good' },
      { field: 'damagedOutward', header: 'Damaged' },
      { field: 'goodBalance', header: 'Good' },
      { field: 'damagedBalance', header: 'Damaged' },
      { field: 'balance', header: 'Total' },
      // { field: 'Remark', header: 'Remark' },
      { field: 'purchaseOrderNumber', header: 'PO Number' },
      { field: 'grnNumber', header: 'GRN / DN Number' },
      { field: 'brand', header: 'Brand' },
    ];
    this.check=true
    this.dropdownValues = [];
    this.control = new FormControl();
  }

  filters(val: string) {
    // console.log("CHECKING HERE", this.AutoFillSuppliers)
    if (this.AutoFillSuppliers === undefined || this.AutoFillSuppliers === []) {
      return
    }
    return this.AutoFillSuppliers.filter(option =>
      option.productName.toLowerCase().includes(val.toLowerCase()));
  }

  setInputField(data) {
    console.log(data);
    
    this.ProductSearch.searchval = data
    if (data.length > 2) {
      this.ProductSearching()
    } 
    if(data.length==0){
      this.ProductSearch.searchval=''
      this.SupplierfilteredOption=EMPTY
      // this.AutoFillSuppliers=[]
      // this.cars=[]
      this.check=true
      this.RequiredMaterials.productId=''
      this.showProduct=''
      this.GetLedgerMaterial()
      // this.ProductSearching()
    }
  }

  ProductSearching() {
    this.SupplierfilteredOption =

      this.Apicall.getProductName(this.ProductSearch)
        .pipe(
          map(x => x.result))

    this.Apicall.getProductName(this.ProductSearch)
      .subscribe(
        res => {
          this.AutoFillSuppliers = res.result;
          // this.filteredOptions = this.materialName.valueChanges
          // .pipe(
          //   startWith(''),
          //   map(val => this.filter(val))
          // );
          // console.log("AUTO FILL", this.AutoFillSuppliers)
          if (!this.AutoFillSuppliers) {
            this.AutoFillSuppliers.map(function (entry) {
              entry.isdisabled = false;
              return entry;
            });
          }

          if (res.result === 'No data Found' || res.status == false) {
            this.AutoFillSuppliers = []
            this.SupplierfilteredOption = EMPTY
          }
        }
      )
  }

  clearfilter() {
    this.printingMaterialName = null
    this.isEnabled = false
    this.SupplierfilteredOption = EMPTY
    this.GetLedgerProducts()
  }

  selectedSupplier(evt) {
    // console.log("ARRAY", this.AutoFillSuppliers, evt)
    for (var i = 0; i < this.AutoFillSuppliers.length; i++) {
      if (this.AutoFillSuppliers[i].productCode === evt) {
        // console.log("PRODUCT ID",this.AutoFillSuppliers[i].productId)
        this.RequiredMaterials.productId = this.AutoFillSuppliers[i].productId
        this.excelDownload.productId = this.AutoFillSuppliers[i].productId
        this.printingMaterialName = this.AutoFillSuppliers[i].productCode + ' - ' + this.AutoFillSuppliers[i].productName
        this.isEnabled = true
        this.GetLedgerMaterial()
        this.showProduct = this.printingMaterialName
        // console.log("EVENT TRIGGERING", this.printingMaterialName)
      }
    }

  }

  handleData(ri, rowData, isChecked) {
    // //console.log("BOOLEAN",isChecked)
    this.checkBoxVal = isChecked
    if (this.checkBoxVal == true) {
      this.displayBasic = true;
      this.expandedRowIndex = ri;
      // //console.log(this.expandedRowIndex)
      this.rowData_proCode = rowData.ProductCode;
      this.rowData_Stock = rowData.ClosingStock
    }
    else {
      this.checkBoxVal = false;
    }
  }
  onDateSelect() {
    var _self = this;
    // //console.log("SELECTED", this.rangeDates)
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;
      this.RequiredMaterials.startDate = this.formatDate(_self.rangeDates[0])
      this.excelDownload.startDate = this.formatDate(_self.rangeDates[0])
      this.RequiredMaterials.endDate = this.formatDate(_self.rangeDates[1])
      this.excelDownload.endDate = this.formatDate(_self.rangeDates[1])
      // //console.log("CHANGES PARAMS", this.RequiredMaterials.startDate)
      this.GetLedgerMaterial();
    } else {
      e = s + 86400000;
    }
    if (this.rangeDates[1]) { // If second date is selected
      this.calendar.overlayVisible = false;
    }
    // compare it to the actual values
    //   return value.getTime() >= s && value.getTime() <= e;
    // this._table.filter(this.formatDate(value), 'date', 'equals')
  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
  selectProd(event) {
    // console.log("PRODUCT ID", event)
    this.RequiredMaterials.productId = event
    this.excelDownload.productId = event
    for (var i = 0; i < this.dropdownValues.length; i++) {
      if (this.dropdownValues[i].value == event) {
        this.showProduct = this.dropdownValues[i].label
        // productChange = this.showProduct;
        // //console.log("SELECTED PRODUCT", this.showProduct)
      }
    }
    // //console.log("EVENT", event)
    this.GetLedgerMaterial()
  }
  cartData = [];
  // getTotalOpeningQuantity() {
  //   return this.cars.map(t => t.Quantity).reduce((acc, value) => acc + value, 0);
  // }
  // getTotalInwardQuantity() {
  //   return this.cars.map(t => t.inward).reduce((acc, value) => acc + value, 0);
  // }
  // getTotalOutwardQuantity() {
  //   return this.cars.map(t => t.outward).reduce((acc, value) => acc + value, 0);
  // }
  // getTotalBalanceQuantity() {
  //   return this.cars.map(t => t.balance).reduce((acc, value) => acc + value, 0);
  // }
  /*----------------------------------------START_API CALL------------------------------------------*/
  GetLedgerProducts() {
    this.Apicall.getLedgerUserId(this.PaginationReq)
      .subscribe(
        res => {
          this.dropdownValues = []
          this.data = res.result;
          this.totalRecords = res.noOfPages

          // this.showProduct = (this.dropdownValues[0].iricode ? this.dropdownValues[0].iricode + ' - ' : '') + this.dropdownValues[0].productName

          // this.DefaultProduct = this.dropdownValues[0].productId
          // localStorage.setItem('DefaultProduct', this.DefaultProduct)
          // this.RequiredMaterials.productId = localStorage.getItem('DefaultProduct')
          // this.excelDownload.productId = localStorage.getItem('DefaultProduct')
          // this.dropdownValues.splice(0,2)
          // console.log(this.dropdownValues);

          for (var i = 0; i < this.data.length; i++) {
            let dropdownData = { label: "", value: "", code: "" };
            dropdownData.label = (this.data[i].productCode ? this.data[i].productCode + ' - ' : '') + this.data[i].productName
            dropdownData.value = this.data[i].productId
            this.showProduct = dropdownData.label
            this.dropdownValues.push(dropdownData)
            this.DefaultProduct = this.dropdownValues[0].value;
            localStorage.setItem('DefaultProduct', this.DefaultProduct)
            this.showProduct = this.dropdownValues[0].label
            this.RequiredMaterials.productId = localStorage.getItem('DefaultProduct')
            this.excelDownload.productId = localStorage.getItem('DefaultProduct')
          }
          this.GetLedgerMaterial()
          err => {
            console.log("ERR", err)
          }
        }
      )
  }

  paginate(event) {
    this.pageNumber = event.page + 1;
    this.PaginationReq.pageNumber = this.pageNumber
    this.GetLedgerProducts();
  }

  // compareObjects(o1: any, o2: any): boolean {
  //   return o1.name === o2.name && o1.id === o2.id;
  // }
  GetLedgerMaterial() {
    this.Apicall.getLedgerMaterial(this.RequiredMaterials)
      .subscribe(
        res => {
          this.cars = res.result;
          this.totalCount = res.current;
          this.rowlength = this.cars.length;

          if (this.cars.length === 0) {
            // console.log("SHOW PRODUCT", this.printingMaterialName)
            this.check = true
          } else {
            this.check = false
          }

          for (var i = 0; i < this.cars.length; i++) {
            if (this.cars.length) {
              this.cars[i].date = new Date(this.cars[i].date)
              if (this.cars[i].gcnNumber == null || this.cars[i].gcnNumber == "") {
                this.cars[i]['grnNumber'] = this.cars[i].grnNumber
              }
              else if (this.cars[i].grnNumber == null || this.cars[i].grnNumber == "") {
                this.cars[i]['grnNumber'] = this.cars[i].gcnNumber
              }
              // //console.log("GETTING MATEIALS", res)
            }
          }

          err => {
            //console.log("ERR", err)
          }
        }
      )
    this.loading = false;
  }
  onSelectType() {
    // //console.log("ROWDATA", rowData)
    // //console.log("SELECTED ID", this.dropdownData)
  }
  /*----------------------------------------END_API CALL------------------------------------------*/
  ledgerTable() {
    this.router.navigate(["/customer/deliveryorder"])
  }


  downloadStmtLedger() {
    this.Apicall.downloadReportLedgerApi(this.excelDownload)
      .subscribe({
        next: (response: any) => {

          let fileName = 'Stockledger';
          const contentDisposition = response.headers.get('content-disposition');
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }

          const fileContent = response.body;

          saveAs(fileContent, fileName);

        },
        error: (error) => {

          console.log({ error });

        }
      });
  }
}

