import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { myVariables } from 'src/environments/environment';

@Component({
  selector: 'app-view-customers',
  templateUrl: './view-customers.component.html',
  styleUrls: ['./view-customers.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewCustomersComponent implements OnInit {

  cols = [];
  completeData:any;
  viewCustomer_id: any;
  UserDetails=[];
  constructor(private Apicall: ApiService, private router:Router) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber=1;
    myVariables.grnRowsPerPage=1;
    myVariables.returnGrnPageNumber=1;
    myVariables.returnGrnRowsPerPage=1;
    myVariables.dnRowsPerPage=1;
    myVariables.dnPageNumber=1;
    myVariables.shippingOrdersRowsPerPage=1;
    myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  sessionStorage.removeItem('shippingOrdersDate');
  sessionStorage.removeItem('grnReportsDates');
  sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('dnReportsDate');
  sessionStorage.removeItem('active')

    this.GetallPendingStatus();
    this.cols = [
      { field: 'businessName', header: 'Business Name' },
      { field: 'fullname', header: 'Business Contact' },
      { field: 'mobileNo', header: 'Mobile Number' },
      { field: 'email', header: 'Email Id' }
    ];
  }
  GetallPendingStatus() {
    this.Apicall.getDeliveryStatus()
    .subscribe(
      res => {
        console.log("Response", res.result)
        this.completeData = res.result;
        // console.log("COMING BEFORE FOT LOOP DATA", this.completeData.length)
        for(var i=0; i<this.completeData.length; i++){
            // console.log("ORG_NAME", this.completeData[i]) //ethula oru data tha varutha
          // this.completeData[i].user['orgName'] = this.completeData[i].businessName

          // console.log("LOOPING",this.completeData[i])
          this.UserDetails.push(this.completeData[i])
        }
    
        console.log("Result", this.UserDetails)
      }
    )
  }
  inViewAcustomer(rowData) {
    this.viewCustomer_id = rowData
    localStorage.setItem('ViewCustomer_id', this.viewCustomer_id.id)
    console.log("ROW DATA PICKING", this.viewCustomer_id)
    this.router.navigate(['viewacustomer'])
  }

}
