import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-locations-pie',
  templateUrl: './locations-pie.component.html',
  styleUrls: ['./locations-pie.component.css']
})
export class LocationsPieComponent implements OnInit {

  data: any;
  report: any;

  options: any;
  filledLocation: any;
  emptyLocation: any;


  constructor(private apiCall: ApiService) { }

  ngOnInit() {


    this.apiCall.locationDetails().subscribe(res => {
      console.log(res)
      this.filledLocation = res['result'].filledCount
      this.emptyLocation = res['result'].emptyCount

      this.report = [this.emptyLocation, this.filledLocation]

      this.data = {
        labels: ['Empty', 'Filled'],

        datasets: [
          {

            data: [this.emptyLocation, this.filledLocation],
            backgroundColor: [

              '#E32322',
              "#42A191",




            ],

          }
        ]
      };

      this.options = {
        maintainAspectRatio: false,
        responsive: false,
        //  aspectRatio: 1 ,
        cutoutPercentage: 60,
        legend: {

          onClick: (e) => e.stopPropagation(),
          position: 'right',
          labels: {
            boxWidth: 12

          },

        },

        rotation: 1 * Math.PI,
        circumference: 2 * Math.PI,

      }
    })


  }
  data1() {

  }


}