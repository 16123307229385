import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-tract-order',
  templateUrl: './tract-order.component.html',
  styleUrls: ['./tract-order.component.css']
})
export class TractOrderComponent implements OnInit {
  trackform = new FormGroup({
    orderNo: new FormControl('')
  }
  )
  orderval: any;
  result: any;
  scandetails: []
  statusarray: any = [];
  item: any[] = [];
  scandetails1: never[];


  currentstatus: any;
  item1: any[] = [];
  value: any;
  valuestatus: number;

  showtracker: boolean = true;
  selectedrow: any;
  awb_no: any;
  logistic: any='';
  current_status: any='';
  order_no: any;
  pick_up_date: any='';
  delivery_date: any='';
  lastactive: any;
  loader: boolean=false;
  loading: boolean;
  item11: any[] = [];
  items: any[] = [];
  orderDetails: any;
  constructor(private apiCall: ApiService) { }

  ngOnInit() {



  }

  // ----------------------------------------method to get form value

  trackOrder() {
    this.loading=true
    
    this.orderval = this.trackform.value.orderNo;
    console.log(this.orderval)
    
    // this.getTrackDetails()
    this.getTrackDetails1()

    const obj = {

      "orderNumber": this.orderval

    }
    
  }

    // ---------------------------------------------------back button to go back to form
  back() {
    this.showtracker = true;
    this.item = [];
    this.items = [];

    this.valuestatus = 0
    
     
  this.awb_no=''
  this.logistic=''

  this.current_status=''
 
  this.order_no=''
  this.pick_up_date=''
  this.delivery_date=''

  }

  get order() {
    return this.trackform.get('orderNo')
  }

  // ---------------------------------------------------------- method to call api and get the details of order----------------------
   
  // getTrackDetails() {

    
 
  //   const obj = {

  //     "orderNumber": this.orderval

  //   }

  //   this.apiCall.geTrackingDetails(obj).subscribe(data => {
    
  //     if(data.status==true){
  //     this.loading=false
  //     this.showtracker = false;
      
  //     this.result = data.trackOrder
  //     this.awb_no = this.result.awb_no
  //     this.logistic = this.result.logistic


  //     this.order_no = this.result.order_details.order_number
  //     this.pick_up_date = this.result.order_date_time.pickup_date

  //     this.delivery_date = this.result.order_date_time.delivery_date

  //     this.current_status = this.result.current_status
  //     console.log(this.currentstatus)


  //     console.log("success data", this.result)


  //     this.scandetails = this.result.scan_details
  //     console.log(this.scandetails)

  //     this.scandetails.forEach((element: any) => {
  //       console.log("checking element : ", element["status"])
  //       this.item1.push(element["status"]);

  //       this.item.push(element["status"]);

  //      if(element.status==this.current_status){
  //       this.selectedrow=element
  //       this.valuestatus=1
  //      }

  //     })
  //     console.log(this.item)
  //     console.log("check item1", this.item1)

  //     if (this.item[this.item.length - 1]["status"] !== "delivered") {
  //       this.item.push("...")
  //       this.item.push("Delivered")
  //     }
  //     console.log(this.item)

  //   }
  
  // else{
  //   this.loading=false
  //   Swal.fire({
  //     toast: true,
  //     // icon:'error',
  //     position: 'center',
  //     text: 'Please enter valid Order Number',
  //     showConfirmButton: false,
  //     timer: 3000,
  //     type: 'error',
  //   })
  // }}
  
  
     
  //   )
 
  // }

// method to get the value on click of different status of stepper

  getValue(items: any) {
    console.log(items)
    this.value = items

    this.scandetails.forEach((element: any) => {
      console.log(element)
      if ((element.status == this.value) )   {
        this.selectedrow = element
      }
     
      console.log(this.selectedrow)
      this.valuestatus = 1
    })

  }
  
  
 
 getTrackDetails1() {

    
 
    const obj = {

      "orderNumber": this.orderval

    }

    this.apiCall.geTrackingDetails(obj).subscribe(data => {
    
      if(data['status']==true){
      this.loading=false
      this.showtracker = false;
      
      this.result = data['trackOrder']
      this.orderDetails=data['orderDetails']
console.log(this.result);
console.log(data['orderDetails'])
 
      this.awb_no = this.orderDetails.wayBill
  this.logistic=this.orderDetails.logistics
      this.order_no = this.orderDetails.orderNumber
      this.delivery_date = this.orderDetails.expectedDeliveryDate
      // this.pick_up_date = this.result.map(obj => {
      //   if(obj.status == "picked up"){
      //     return obj.statusDateTime
      //   }
      // })
      this.pick_up_date=''
      if(this.result.length!=0){

        for(let i=0;i<this.result.length;i++){
          if(this.result[i].status=="picked up"){
            this.pick_up_date=this.result[i].statusDateTime
            break;
          }
  
        }
        
  
        this.current_status = this.result[this.result.length-1].status
        console.log(this.currentstatus)
  
  
        console.log("success data", this.result)
   
        this.result.forEach((element: any) => {
          console.log("checking element : ", element["status"])
          this.item11.push(element["status"]);
  
          // this.items.push(element["status"]);
          // this.item11.push({label:element["status"], value:element["statusCode"]});
  
          this.items.push({label:element["status"], value:element["statusCode"]});
          
          
          if ((element.status == this.items[this.items.length - 1].label) )   {
            this.selectedrow=element
            this.valuestatus=1
           }
        
        })
        
        
        console.log(this.selectedrow);
        console.log(this.items)
        console.log("check item1", this.item11)
  
        if (this.items[this.items.length - 1].label!== "delivered") {
          
  
          this.items.push({label:"...", value:"..."});
          this.items.push({label:"Delivered", value:"Delivered"});
  
  
        }
        console.log(this.items)
  
      }
      else{
        Swal.fire({
          toast: true,
          // icon:'error',
          position: 'center',
          text: 'No tracking details were found',
          showConfirmButton: false,
          timer: 3000,
          type: 'error',
        })
      }
      
    }
  
  else{
    this.loading=false
    Swal.fire({
      toast: true,
      // icon:'error',
      position: 'center',
      text: 'Please enter valid Order Number',
      showConfirmButton: false,
      timer: 3000,
      type: 'error',
    })
  }}
  
  
     
    )
 
  }

  getValue1(itemss){
    console.log(itemss)
    this.value = itemss.value

    this.result.forEach((element: any) => {
      console.log(element)
      if ((element.statusCode == this.value) )   {
        this.selectedrow = element
      }
     
      console.log(this.selectedrow)
      this.valuestatus = 1
    })

  }

}