import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Paginator } from 'primeng/paginator';
import { saveAs } from 'file-saver';
import { ApiService } from '../api.service';
import { myVariables } from 'src/environments/environment';

@Component({
  selector: 'app-grn-details',
  templateUrl: './grn-details.component.html',
  styleUrls: ['./grn-details.component.css']
})
export class GrnDetailsComponent implements OnInit {
  @ViewChild('pagin') paginator: Paginator; 
  @ViewChild('pagin2') paginator2: Paginator;
  @ViewChild('calendar', undefined) private calendar: any;
  @ViewChild('calendar2',undefined) private calendar2:any;

  today: any;
  firstDate: any;
  rangeDates: any[];
  rangeDates1:any[];
  cols: { field: string; header: string; }[];
  deliveryData: any;
  deliveryData2: any;
  SelectedDate: any;
   public dateFilters = new Date();
  grndetails: any;
  totalnoofpages: any;
  totalrecords: any;
  rows: any;
  pageNumber: any;
  RequiredPending: any;
  rows2: any;
  totalrecords2:any;
  cols2: { field: string; header: string; }[];
  first: any;
  first1: any;
  customerList: any;
  row2: any;
  row3: any;
  savedDates: any;
  savedDates1: any;
  today1: any;
  firstDate1: any;
  check: boolean;
  check1: boolean;
  activeTab: any;
  date: Date;
  todayDate: Date;
  date1: Date;
  todayDate1: Date;


  constructor(private Apicall: ApiService, private router: Router,private datePipe: DatePipe) { }
  sales: any[];
 
  RequiredDetails = {
   
    // customerId: localStorage.getItem('CusID'),
    // warehouseID:localStorage.getItem('WarehouseID'),
    startDate: '',
    endDate: '',
    pageNumber: 1,
    pageLimit:10
  }
  RequiredDetails2 = {
   
    // customerId: localStorage.getItem('CusID'),
    // warehouseID:localStorage.getItem('WarehouseID'),
    startDate: "",
    endDate: "",
    pageNumber: 1,
    pageLimit:10
  }
  selectedCustomer='Please select a customer'
  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    // myVariables.grnPageNumber=1;
    // myVariables.grnRowsPerPage=1;
    // myVariables.returnGrnPageNumber=1;
    // myVariables.returnGrnRowsPerPage=1;
    myVariables.dnRowsPerPage=1;
    myVariables.dnPageNumber=1;
    myVariables.shippingOrdersRowsPerPage=1;
    myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  sessionStorage.removeItem('shippingOrdersDate');
  // sessionStorage.removeItem('grnReportsDates');
  // sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('dnReportsDate');
    if (sessionStorage.getItem('active')) {
      this.activeTab = sessionStorage.getItem('active');
    }
    else{
      this.activeTab='grn'
    }
    if(sessionStorage.getItem('selectedCust')){
      this.selectedCustomer=sessionStorage.getItem('selectedCust')
     }
    // thistoday = new Date();
    //  console.log(this.DefaultDate)
    //  this.DefaultDate = this.datePipe.transform(this.DefaultDate, "yyyy-MM-dd")
    //  localStorage.setItem('DefaultDate', this.DefaultDate)
    //  console.log(this.DefaultDate)
    //  this.firstDate.setMonth(this.DefaultDate.getMonth() - 1);
    // var _self = this;
    //  this.today = new Date();
    //  this.firstDate = new Date();
    //  this.firstDate.setMonth(this.today.getMonth() - 1);
    //  this.rangeDates = [this.firstDate, this.today];
    //  console.log(this.today,this.firstDate)
    //  this.RequiredDetails.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
    //  this.RequiredDetails.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
    //  this.RequiredDetails2.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
    //  this.RequiredDetails2.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
    if(myVariables.grnPageNumber!=1){
      this.RequiredDetails.pageNumber=myVariables.grnPageNumber
    }
    if(myVariables.grnRowsPerPage!=1){
      this.RequiredDetails.pageLimit=myVariables.grnRowsPerPage
    }
    if(myVariables.returnGrnPageNumber!=1){
      this.RequiredDetails2.pageNumber=myVariables.returnGrnPageNumber
    }
    if(myVariables.returnGrnRowsPerPage!=1){
      this.RequiredDetails2.pageLimit=myVariables.returnGrnRowsPerPage
    }
    if (sessionStorage.getItem('grnReportsDates')) {
      this.savedDates=JSON.parse(sessionStorage.getItem('grnReportsDates'))
      console.log(this.savedDates);
      
       this.date = new Date(this.savedDates.startDate)

      this.todayDate = new Date(this.savedDates.endDate)

      this.rangeDates = [this.date, this.todayDate];

      // console.log(date)
      // console.log(typeof (date))

      this.RequiredDetails.startDate = this.datePipe.transform(this.date, 'yyyy-MM-dd')
      this.RequiredDetails.endDate = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd')
      // this.RequiredDetails2.startDate = this.datePipe.transform(date, 'yyyy-MM-dd')
      // this.RequiredDetails2.endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd')
      console.log(this.RequiredDetails);
      this.getGrnDetail()
    }
    else {
      var _self = this;
      this.today = new Date();
      this.firstDate = new Date();
      // this.firstDate.setMonth(this.today.getMonth() - 1);
     this.firstDate.setDate(this.firstDate.getDate() - 7);
      // console.log('today date', this.today)
      // console.log('first date', this.firstDate)
      // console.log(typeof (this.today))
      this.rangeDates = [this.firstDate, this.today];
      this.RequiredDetails.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
      this.RequiredDetails.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
      console.log(this.RequiredDetails);
      
      // this.RequiredDetails2.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
      // this.RequiredDetails2.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
      this.getGrnDetail()
    }

    if (sessionStorage.getItem('ReturnGrnReportsDates')) {
      this.savedDates1=JSON.parse(sessionStorage.getItem('ReturnGrnReportsDates'))
      this.date1 = new Date(this.savedDates1.startDate)

      this.todayDate1 = new Date(this.savedDates1.endDate)

      this.rangeDates1 = [this.date1, this.todayDate1];

      // console.log(date)
      // console.log(typeof (date))
      // this.RequiredDetails.startDate = this.datePipe.transform(date, 'yyyy-MM-dd')
      // this.RequiredDetails.endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd')
      this.RequiredDetails2.startDate = this.datePipe.transform(this.date1, 'yyyy-MM-dd')
      this.RequiredDetails2.endDate = this.datePipe.transform(this.todayDate1, 'yyyy-MM-dd')
      console.log(this.RequiredDetails2)
      this.getReturnGrnDetail()
    } 
    else {
      var _self = this;
      this.today1 = new Date();
      this.firstDate1 = new Date();
      // this.firstDate.setMonth(this.today.getMonth() - 1);
      this.firstDate1.setDate(this.firstDate1.getDate() - 7);
      // console.log('today date', this.today)
      // console.log('first date', this.firstDate)
      // console.log(typeof (this.today))
      this.rangeDates1 = [this.firstDate1,this.today1];

      this.RequiredDetails2.startDate = this.datePipe.transform(this.firstDate1, 'yyyy-MM-dd')
      this.RequiredDetails2.endDate = this.datePipe.transform(this.today1, 'yyyy-MM-dd')
      // this.RequiredDetails2.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
      // this.RequiredDetails2.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
      console.log(this.RequiredDetails2)
      this.getReturnGrnDetail()
    }
    

  
 
 
  // this.getGrnDetail()
// this.getReturnGrnDetail()

  this.cols = [
    { field: 'grn_no', header: 'GRN no' },
    { field: 'grn_date', header: 'GRN Date' },
    { field: 'supplier_name', header: 'Supplier Name' },
   
    { field: 'po_number', header: 'PO Number' },  
     {field: 'Status',header:'Status'}
    
  ];

  this.cols2 = [
    { field: 'grn_no', header: 'GRN no' },
    { field: 'grn_date', header: 'GRN Date' },
    { field: 'return_supplier_name', header: 'Supplier Name' },
   
    { field: 'document_refference_number', header: 'Reference Number' },  
     {field: 'Status',header:'Status'}
    
  ];
  this.Apicall.getDeliveryStatus().subscribe(res=>{
    // console.log(res.result)
    this.customerList=res.result
    })
 
  }
  onDateSelect(event) {
    // console.log(event)
    var _self = this;
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;

      // console.log(_self.rangeDates[0], _self.rangeDates[1])
      // console.log('if part is hitting')
      // console.log(_self.rangeDates[0])
      // console.log(typeof (_self.rangeDates[0]));

      //  sessionStorage.setItem("firstDate", JSON.stringify(_self.rangeDates[0]));
      // sessionStorage.setItem("lastDate", JSON.stringify(_self.rangeDates[1]));
      const obj={
        "startDate":_self.rangeDates[0],
        "endDate":_self.rangeDates[1]
      }
     sessionStorage.setItem('grnReportsDates',JSON.stringify(obj))
      this.RequiredDetails.startDate = this.formatDate(_self.rangeDates[0])
       this.RequiredDetails.endDate = this.formatDate(_self.rangeDates[1])
      
      //  this.RequiredDetails2.startDate = this.formatDate(JSON.parse((sessionStorage.getItem("firstDate"))))
      //  this.RequiredDetails2.endDate = this.formatDate(JSON.parse((sessionStorage.getItem("lastDate"))))
       this.RequiredDetails.pageNumber=1
      //  this.RequiredDetails2.pageNumber=1
       this.paginator.changePage(0)
      //  this.paginator2.changePage(0)
      this.getGrnDetail();
      // this.getReturnGrnDetail()
     } else {
      // console.log('else part is hitting')
      e = s + 86400000;
    }
    if (this.rangeDates[1]) { // If second date is selected
      console.log('i am bein run',this.rangeDates[1]);
      
      this.calendar.overlayVisible = false;
    }
  }
  defaultDate(){
    console.log('grndefaultdate');
    if(this.rangeDates[1]==null){
      if(sessionStorage.getItem('grnReportsDates')){
        this.savedDates=(JSON.parse(sessionStorage.getItem('grnReportsDates')))
        this.rangeDates=[new Date(this.savedDates.startDate), new Date(this.savedDates.endDate)]
      }
      else{
        this.rangeDates = [this.firstDate, this.today]
      }
    }
    
  }
  onDateSelect1(event) {
    // console.log(event)
    var _self = this;
    var s = _self.rangeDates1[0].getTime();
    var e;
    if (_self.rangeDates1[0] && _self.rangeDates1[1]) {
      e = _self.rangeDates1[1].getTime() + 86400000;

      // console.log(_self.rangeDates[0], _self.rangeDates[1])
      // console.log('if part is hitting')
      // console.log(_self.rangeDates[0])
      // console.log(typeof (_self.rangeDates[0]));

      //  sessionStorage.setItem("firstDate", JSON.stringify(_self.rangeDates1[0]));
      // sessionStorage.setItem("lastDate", JSON.stringify(_self.rangeDates1[1]));
      const obj={
        "startDate":_self.rangeDates1[0],
        "endDate":_self.rangeDates1[1]
      }
     sessionStorage.setItem('ReturnGrnReportsDates',JSON.stringify(obj))
      this.RequiredDetails2.startDate = this.formatDate(_self.rangeDates1[0])
       this.RequiredDetails2.endDate = this.formatDate(_self.rangeDates1[1])
      
      //  this.RequiredDetails2.startDate = this.formatDate(JSON.parse((sessionStorage.getItem("firstDate"))))
      //  this.RequiredDetails2.endDate = this.formatDate(JSON.parse((sessionStorage.getItem("lastDate"))))
      //  this.RequiredDetails.pageNumber=1
       this.RequiredDetails2.pageNumber=1
      //  this.paginator.changePage(0)
       this.paginator2.changePage(0)
      // this.getGrnDetail();
      this.getReturnGrnDetail()
     } else {
      // console.log('else part is hitting')
      e = s + 86400000;
    }
    if (this.rangeDates1[1]) { // If second date is selected
      console.log('i am being run',this.rangeDates1[1]);
      
      this.calendar2.overlayVisible = false;
    }
  }
  defaultDate1(){
    console.log('returngrndefaultdate');
    if(this.rangeDates1[1]==null){
      if(sessionStorage.getItem('ReturnGrnReportsDates')){
        this.savedDates1=(JSON.parse(sessionStorage.getItem('ReturnGrnReportsDates')))
        this.rangeDates1=[new Date(this.savedDates1.startDate), new Date(this.savedDates1.endDate)]
      }
      else{
        this.rangeDates1 = [this.firstDate1, this.today1]
    }
    }
    
  }
  formatDate(date) {


    // console.log(typeof (date))
    // console.log('hi')
    // console.log(date)
    let swap = new Date(date)
    // let aaa=JSON.parse(date)
    // console.log(typeof (swap))
    // console.log(swap)
    let month = swap.getMonth() + 1;
    // console.log(month)
    let day = swap.getDate();
    // console.log(day)
    // console.log('full year', swap.getFullYear)
    let newMonth: String
    let newDay: String
    if (month < 10) {
      newMonth = '0' + month;
    } else {
      newMonth = month.toString()
    }
    if (day < 10) {
      newDay = '0' + day;
    }
    else {
      newDay = day.toString();
    }
    return swap.getFullYear() + '-' + newMonth + '-' + newDay;
  }


  // api call for GRN Details
  dropdown(event){

    // console.log(this.selectedCustomer)
    sessionStorage.setItem('selectedCust',this.selectedCustomer)
    this.getGrnDetail()
    this.getReturnGrnDetail()
    }
    searchGrn(data){
      if(data.length>=3){
        const obj={
        
          "warehouseId":localStorage.getItem('WarehouseID'),      //(not mandatory)
          "status":"StagingAndCompleted",   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
          "searchval":data, 	//(mandatory and at least 3 characters)
          "customerId" : this.selectedCustomer, 	//(not mandatory)
          "isReturnGrn": false
          
      }
        this.Apicall.searchReceiptRequest(obj).subscribe(res=>{
          console.log(res)
          this.deliveryData=res['result']
          for(let i=0;i<this.deliveryData.length;i++){
            this.deliveryData[i]['grn_no']=this.deliveryData[i]['grnNumber']
            this.deliveryData[i]['grn_date']=this.deliveryData[i]['createdAt']
            this.deliveryData[i]['supplier_name']=this.deliveryData[i]['supplierName']
            this.deliveryData[i]['po_number']=this.deliveryData[i]['purchaseOrderNumber']
            this.deliveryData[i]['Status']='Completed'
            console.log((this.deliveryData[i]['grnNumber']));
            

           }

           if(this.rows!=10 && this.rows!=25 && this.rows!=50 && this.rows!=75 && (this.row2)){
            console.log(this.rows);
  
            console.log("not");
            this.rows=this.row2
            this.totalrecords = data['totalPages'] * this.row2
          }
          else{
            console.log("yes");
  this.rows=myVariables.grnRowsPerPage
            this.totalrecords = data['totalPages'] * this.rows
  
          }
          this.first=(this.rows*this.RequiredDetails.pageNumber)-1
           if(this.deliveryData.length == 0){
            this.check=true
          }else{
            this.check=false
          }
        })
       console.log(this.check)
      }
      if(data.length==0){
        this.getGrnDetail()
      }
    }
    searchReturnGrn(data){
      if(data.length>=3){
        const obj={
        
          "warehouseId":localStorage.getItem('WarehouseID'),      //(not mandatory)
          "status":"StagingAndCompleted",   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
          "searchval":data, 	//(mandatory and at least 3 characters)
          "customerId" : this.selectedCustomer, 	//(not mandatory)
          "isReturnGrn": true
          
      }
        this.Apicall.searchReceiptRequest(obj).subscribe(res=>{
          console.log(res)
          this.deliveryData2=res['result']
          for(let i=0;i<this.deliveryData2.length;i++){
            this.deliveryData2[i]['grn_no']=this.deliveryData2[i]['grnNumber']
            this.deliveryData2[i]['grn_date']=this.deliveryData2[i]['createdAt']
            this.deliveryData2[i]['supplier_name']=this.deliveryData2[i]['supplierName']
            this.deliveryData2[i]['po_number']=this.deliveryData2[i]['purchaseOrderNumber']
            this.deliveryData2[i]['Status']='Completed'
            this.deliveryData2[i]['return_supplier_name']=this.deliveryData2[i]['returnSupplierName']
            this.deliveryData2[i]['document_refference_number']=this.deliveryData2[i]['documentReffNumber']

            
           }
           if(this.rows2!=10 && this.rows2!=25 && this.rows2!=50 && this.rows2!=75 && (this.row3)){
            console.log(this.rows2);
  
            console.log("not");
            this.rows2=this.row3
            this.totalrecords2 = data['totalPages'] * this.row3
          }
          else{
            console.log("yes");
            this.rows2=myVariables.returnGrnRowsPerPage
            this.totalrecords2 = data['totalPages'] * this.rows2
  
          }
          this.first1=(this.rows2*this.RequiredDetails2.pageNumber)-1
  
           if(this.deliveryData2.length==0){
            this.check1=true
          }else{
            this.check1=false
          }
        })
       
      }
      if(data.length==0){
        this.getReturnGrnDetail()
      }
    }
  getGrnDetail()
    {
      console.log(this.RequiredDetails)
      this.Apicall.getGrnDetails(this.RequiredDetails,this.selectedCustomer).subscribe(data=>{
        console.log(data)
        
        this.deliveryData=data['result']
        this.deliveryData.forEach(element => {
          element.Status="Completed"
        });
        console.log(this.deliveryData)
        this.rows=this.deliveryData.length
        this.totalrecords=data['totalPages'] * this.rows
        this.rows= this.deliveryData.length

        console.log(this.rows);
        
        if(this.rows!=10 && this.rows!=25 && this.rows!=50 && this.rows!=75 && (this.row2)){
          console.log(this.rows);

          console.log("not");
          this.rows=this.row2
          this.totalrecords = data['totalPages'] * this.row2
        }
        else{
          console.log("yes");
this.rows=myVariables.grnRowsPerPage
          this.totalrecords = data['totalPages'] * this.rows

        }
        this.first=(this.rows*this.RequiredDetails.pageNumber)-1
        
        if(this.deliveryData.length==0){
          this.check=true
        }else{
          this.check=false
        }
      
      })

    }

    // api call for ReturnGRN Details

    getReturnGrnDetail()
    {
      this.Apicall.getreturnGrnDetails(this.RequiredDetails2,this.selectedCustomer).subscribe(data=>{
        console.log(data)

        this.deliveryData2=data['result']
        // console.log(this.deliveryData2)
        this.deliveryData2.forEach(element => {
          element.Status="Completed"
        });
        this.rows2=this.deliveryData2.length
        // console.log(this.rows2)
        this.totalrecords2=data['totalPages'] * this.rows2
        // console.log(this.totalrecords2)
        this.rows2= this.deliveryData2.length

        console.log(this.rows2);
        
        if(this.rows2!=10 && this.rows2!=25 && this.rows2!=50 && this.rows2!=75 && (this.row3)){
          console.log(this.rows2);

          console.log("not");
          this.rows2=this.row3
          this.totalrecords2 = data['totalPages'] * this.row3
        }
        else{
          console.log("yes");
          this.rows2=myVariables.returnGrnRowsPerPage
          this.totalrecords2 = data['totalPages'] * this.rows2

        }
        this.first1=(this.rows2*this.RequiredDetails2.pageNumber)-1

        if(this.deliveryData2.length==0){
          this.check1=true
        }else{
          this.check1=false
        }
      })

    }


    inrowInformationgrn(rowData){
      console.log(rowData)
      this.grndetails=rowData
      localStorage.setItem('grn_id', this.grndetails.grn_no)
      // //console.log("ROW DATA PICKING", this.pending_deliveries)
      this.router.navigate(['/view-grn-details'])


    }
    inrowInformationgrnreturn(rowData){
      console.log(rowData)
      this.grndetails=rowData
      localStorage.setItem('grn_id', this.grndetails.grn_no)
      // //console.log("ROW DATA PICKING", this.pending_deliveries)
      this.router.navigate(['/view-returngrn-details'])


    }
    grndownload(){
     
        this.Apicall.DownloadReportGRNdet(this.RequiredDetails,this.selectedCustomer)
        .subscribe({
          next: (response: any) => {
    
            let fileName = 'ConsolidateGRN';
            const contentDisposition = response.headers.get('content-disposition');
           
            if (contentDisposition) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }
    
            const fileContent = response.body;
    
            saveAs(fileContent, fileName);
    
          },
          error: (error) => {
    
            console.log({error});
    
          }
        });
      }
      returngrndownload(){
     
        this.Apicall.DownloadReportGRNdet(this.RequiredDetails,this.selectedCustomer)
        .subscribe({
          next: (response: any) => {
    
            let fileName = 'ConsolidateDN';
            const contentDisposition = response.headers.get('content-disposition');
            if (contentDisposition) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }
    
            const fileContent = response.body;
    
            saveAs(fileContent, fileName);
    
          },
          error: (error) => {
    
            console.log({error});
    
          }
        });
      }

      paginate(event) {
        //  console.log(event)
        // // this.RequiredDetails.pageLimit=event.rows
        // // this.pageNumber = event.page + 1;
        // // this.RequiredDetails.pageNumber = this.pageNumber
        // if(this.RequiredDetails.pageLimit!=event.rows){
        //   this.RequiredDetails.pageLimit=event.rows
        //   this.RequiredDetails.pageNumber=1
        //   this.first=event.first
        //   this.paginator.changePage(0)
        
        // }
        
        // else{this.RequiredDetails.pageNumber=event.page+1}
        // console.log(this.RequiredDetails)
        // this.getGrnDetail()
        this.row2=event.rows
        // console.log(event.rows ,this.totalrows);
        
         
          
       // this.RequiredstagingDelivery.pageLimit=event.rows
       // this.pageNumber = event.page + 1;
       // this.RequiredstagingDelivery.pageNumber = this.pageNumber
      //  console.log(event.rows,this.RequiredstagingDelivery.pageLimit)
      
       if(this.RequiredDetails.pageLimit==event.rows){
        this.RequiredDetails.pageNumber=event.page+1
        myVariables.grnPageNumber=this.RequiredDetails.pageNumber
         
      }else if( event.rows==10){
        this.RequiredDetails.pageLimit = 10
        this.RequiredDetails.pageNumber=event.page
        myVariables.grnRowsPerPage=this.RequiredDetails.pageLimit

      
      
        this.paginator.changePage(0)
      
      }
      else if( event.rows==25){
        this.RequiredDetails.pageLimit = 25
        this.RequiredDetails.pageNumber=event.page
        myVariables.grnRowsPerPage=this.RequiredDetails.pageLimit
      
      
      
        this.paginator.changePage(0)
      
      }else if( event.rows==50){
        this.RequiredDetails.pageLimit = 50
        this.RequiredDetails.pageNumber=event.page
        myVariables.grnRowsPerPage=this.RequiredDetails.pageLimit
      
        this.paginator.changePage(0)
        
      }
      else if( event.rows==75){
        this.RequiredDetails.pageLimit = 75
        this.RequiredDetails.pageNumber=event.page
        myVariables.grnRowsPerPage=this.RequiredDetails.pageLimit
      
        this.paginator.changePage(0)
        
      }
       else {
          
        // this.RequiredstagingDelivery.pageLimit=event.rows 
        this.RequiredDetails.pageNumber=event.page + 1
        // this.first=event.page
        // console.log(event);
        // console.log(this.first);
      myVariables.grnPageNumber=this.RequiredDetails.pageNumber
        
      // this.paginator.changePage(0)
      
       }
      
       
       console.log(this.RequiredDetails)
       this.getGrnDetail()
       
    
      }

      paginate2(event) {

         console.log(event)
        // // this.RequiredDetails.pageLimit=event.rows
        // // this.pageNumber = event.page + 1;
        // // this.RequiredDetails.pageNumber = this.pageNumber
        // if(this.RequiredDetails2.pageLimit!=event.rows){
        //   console.log(event)
        //   this.RequiredDetails2.pageLimit=event.rows
        //   this.RequiredDetails2.pageNumber=1
        //   this.first1=event.first
        //   this.paginator2.changePage(0)
        
        // }
        
        // else{this.RequiredDetails2.pageNumber=event.page+1}
        // console.log(this.RequiredDetails2)
        this.row3=event.rows
        // console.log(event.rows ,this.totalrows);
        
         
          
       // this.RequiredstagingDelivery.pageLimit=event.rows
       // this.pageNumber = event.page + 1;
       // this.RequiredstagingDelivery.pageNumber = this.pageNumber
      //  console.log(event.rows,this.RequiredstagingDelivery.pageLimit)
      
       if(this.RequiredDetails2.pageLimit==event.rows){
        this.RequiredDetails2.pageNumber=event.page+1
      myVariables.returnGrnPageNumber=this.RequiredDetails2.pageNumber
         
      }else if( event.rows==10){
        this.RequiredDetails2.pageLimit = 10
        this.RequiredDetails2.pageNumber=event.page
      myVariables.returnGrnRowsPerPage=this.RequiredDetails2.pageLimit
      
        this.paginator2.changePage(0)
      
      }
      else if( event.rows==25){
        this.RequiredDetails2.pageLimit = 25
        this.RequiredDetails2.pageNumber=event.page
        myVariables.returnGrnRowsPerPage=this.RequiredDetails2.pageLimit
      
      
      
        this.paginator2.changePage(0)
      
      }else if( event.rows==50){
        this.RequiredDetails2.pageLimit = 50
        this.RequiredDetails2.pageNumber=event.page
        myVariables.returnGrnRowsPerPage=this.RequiredDetails2.pageLimit
      
        this.paginator2.changePage(0)
        
      }
      else if( event.rows==75){
        this.RequiredDetails2.pageLimit = 75
        this.RequiredDetails2.pageNumber=event.page
        myVariables.returnGrnRowsPerPage=this.RequiredDetails2.pageLimit
      
        this.paginator2.changePage(0)
        
      }
       else {
          
        // this.RequiredstagingDelivery.pageLimit=event.rows 
        this.RequiredDetails2.pageNumber=event.page + 1
        // this.first=event.page
        // console.log(event);
        // console.log(this.first);
      myVariables.returnGrnPageNumber=this.RequiredDetails2.pageNumber
        
      // this.paginator.changePage(0)
      
       }
      
       
       console.log(this.RequiredDetails2)
      
       
    
        this.getReturnGrnDetail()
    
      }
      returngrntab1(activeTab) {
        console.log("clear")
        console.log(activeTab);
    
        this.activeTab = activeTab;
        sessionStorage.setItem('active', this.activeTab)
        this.ngOnInit()
        this.getGrnDetail()
      }
      returngrntab(activeTab) {
        console.log("clear")
        console.log(activeTab);
        this.activeTab = activeTab;
        sessionStorage.setItem('active', this.activeTab)
    
        this.ngOnInit()
    
        this.getReturnGrnDetail()
    
      }

       getColor(status) {
        switch (status) {
          case 'Completed':
            return '#3CA724';
          case 'Cancelled':
            return 'red';
        }
      }
}
 
 