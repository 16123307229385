import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { ApiService } from '../api.service';
import { DatePipe } from '@angular/common';
import { Paginator } from 'primeng/paginator';
import { myVariables } from 'src/environments/environment';
@Component({
  selector: 'app-shipping-orders',
  templateUrl: './shipping-orders.component.html',
  styleUrls: ['./shipping-orders.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShippingOrdersComponent implements OnInit {
  @ViewChild('Opendel') private _table: Table;
  @ViewChild('calendar', undefined) private calendar: any;
  @ViewChild('pagin') paginator: Paginator;
  

  cols = [];
  rangeDates: Date[];
  completeData: any;
  shippingPostData={
    
      startDate : "",
      endDate  : "",
      awbNumber  : "",
      deliveryRequestId : "",
      deliveryOrderNumber : "",
      pageNumber : 1,
      pageLimit : 10
  };
  pageNumber: any;
  rowCount: any;
  pending_deliveries: any;
  savedDates: any;
  today: any;
  firstDate: any;
  row2: any;
  check: boolean;
  rows: any;
  totalRecords: number;
  first: number;
  constructor(private Apicall: ApiService,private router:Router,private datePipe:DatePipe) { }

  ngOnInit() {
    myVariables.completedReceiptRowsPerPage=1;
    myVariables.completedDeliveryRowsPerPage=1;
    myVariables.stagingReceiptRowsPerPage=1;
    myVariables.StaockStatementRowsPerPage=1;
    myVariables.stagingReceiptPageNumber=1;
    myVariables.completedReceiptPageNumber=1;
    myVariables.completedDeliveryPageNumber=1;
    myVariables.pendingDeliveriespageNumber=1;
    myVariables.pendingDeliveriesRowsPerPage=1;
    myVariables.pendingReceiptRowsPerPage=1;
    myVariables.pendingReceiptPageNumber=1;
    myVariables.grnPageNumber=1;
    myVariables.grnRowsPerPage=1;
    myVariables.returnGrnPageNumber=1;
    myVariables.returnGrnRowsPerPage=1;
    myVariables.dnRowsPerPage=1;
    myVariables.dnPageNumber=1;
    // myVariables.shippingOrdersRowsPerPage=1;
    // myVariables.shippingOrdersPageNumber=1;
    myVariables.StockStatementPageNumber=1;

  sessionStorage.removeItem('compltdReceiptDate');
  sessionStorage.removeItem('StagingReceiptDate');
  sessionStorage.removeItem('PendingReceiptDate');
  sessionStorage.removeItem('PendingDeliDate');
  sessionStorage.removeItem('compltdDeliDates');
  // sessionStorage.removeItem('shippingOrdersDate');
  sessionStorage.removeItem('grnReportsDates');
  sessionStorage.removeItem('ReturnGrnReportsDates');
  sessionStorage.removeItem('dnReportsDate');
  sessionStorage.removeItem('active')

    this.cols = [
      { field: 'deliveryRequestId', header: 'Delivery Request Id' },
      { field: 'wayBill', header: 'AWB Number' },
      { field: 'orderNumber', header: 'Order Number' },
      {field:'logistics',header:'Logistics Name'},
      { field: 'shippingCharges', header: 'Shipping Charges' },
      { field: 'pickupAddressId', header: 'Pickup Address' },
      { field: 'latestCourierStatus', header: 'Courier Status' }
    ];
    if(myVariables.shippingOrdersPageNumber!=1){
      this.shippingPostData.pageNumber=myVariables.shippingOrdersPageNumber
    }
console.log(myVariables.shippingOrdersPageNumber,myVariables.shippingOrdersRowsPerPage);

    if(myVariables.shippingOrdersRowsPerPage!=1){
      this.shippingPostData.pageLimit=myVariables.shippingOrdersRowsPerPage
    }
    if (sessionStorage.getItem('shippingOrdersDate')) {
      this.savedDates=JSON.parse(sessionStorage.getItem('shippingOrdersDate'))
      let date = new Date(this.savedDates.startDate)
      console.log(date);
      let todayDate = new Date(this.savedDates.endDate)

      this.rangeDates = [date, todayDate];

        // console.log(date)
        // console.log(typeof (date))

        this.shippingPostData.startDate = this.datePipe.transform(date, 'yyyy-MM-dd')
        console.log(this.shippingPostData.startDate);
        
        this.shippingPostData.endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd')
        console.log(this.shippingPostData.endDate);
        this.getshippingorders();

      }

      else {
        var _self = this;
        this.today = new Date();
        this.firstDate = new Date();
        // this.firstDate.setMonth(this.today.getMonth());
        this.firstDate.setDate(this.firstDate.getDate() - 7);

        // console.log('today date', this.today)
        // console.log('first date', this.firstDate)
        // console.log(typeof (this.today))
        this.rangeDates = [this.firstDate, this.today];

        this.shippingPostData.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
        this.shippingPostData.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
        this.getshippingorders();



      } 
    // this.getshippingorders();
  }

getshippingorders(){
  
  this.Apicall.getAllShippingOrders(this.shippingPostData).subscribe(res => {
    console.log(res['result'])
    this.completeData=res['result']
    this.rows=this.completeData.length 
          this.totalRecords=res['noOfPages'] * this.rows
          console.log("PENDING STATUS", this.totalRecords)
          this.rows= this.completeData.length

          console.log(this.rows);
          
          if(this.rows!=10 && this.rows!=25 && this.rows!=50 && this.rows!=75 && (this.row2)){
            console.log(this.rows);
            console.log(this.row2);
            
            console.log("not");
            // if(this.row2==undefined){
            //   this.row2=this.completeData.length
            // }
            this.rows=this.row2
            this.totalRecords = res['noOfPages'] * this.row2
          }
          else{
            console.log("yes");
            this.rows=myVariables.shippingOrdersRowsPerPage
            this.totalRecords = res['noOfPages'] * this.rows
            console.log(this.totalRecords,this.rows)
          }
          this.first=(this.rows*this.shippingPostData.pageNumber)-1

    if(this.completeData.length==0){
      this.check=true
    }
    else{
      this.check=false
    }
  })
}
searchShippingOrders(data){

  if(data.length >= 3){
    const obj={
      
        "warehouseId":localStorage.getItem('WarehouseID') ,    //(not mandatory)
        "status":"Completed",   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
        "searchval":data, 	//(mandatory and at least 3 characters)
        "customerId" :null 	//(not mandatory)
        
    }
    this.Apicall.searchDeliveryRequest(obj).subscribe(res=>{
      this.completeData = res['result'];
      for(let i=0;i<this.completeData.length;i++){
        this.completeData[i]['deliveryRequestId']=this.completeData[i]['id']
        this.completeData[i]['wayBill']=this.completeData[i]['wayBill']
        this.completeData[i]['orderNumber']=this.completeData[i]['orderDocumentNumber']


      }
})
}
if(data.length==0){
  this.getshippingorders();
}
}

defaultDate(){
  console.log('defaultdate');
  if(this.rangeDates[1]==null){
    if(sessionStorage.getItem('shippingOrdersDate')){
      this.savedDates=(JSON.parse(sessionStorage.getItem('shippingOrdersDate')))
      this.rangeDates=[new Date(this.savedDates.startDate), new Date(this.savedDates.endDate)]
    }
    else{
      this.rangeDates = [this.firstDate, this.today]
    }
  }
  
}
inPendingDeliveries(rowData) {
  this.pending_deliveries = rowData
  console.log(this.pending_deliveries.deliveryRequestId)
  localStorage.setItem('completed_delivery_id', this.pending_deliveries.deliveryRequestId)
  // localStorage.setItem('Dly_CusId', this.pending_deliveries.customerId)
  // console.log("ROW DATA PICKING", this.pending_deliveries.customerId)
  this.router.navigate(['/Viewcompleteddelivery'])
}

onDateSelect() {
  var _self = this;
  var s = _self.rangeDates[0].getTime();
  var e;
  if (_self.rangeDates[0] && _self.rangeDates[1]) {
    e = _self.rangeDates[1].getTime() + 86400000;
    this.shippingPostData.startDate = this.formatDate(_self.rangeDates[0])
    this.shippingPostData.endDate = this.formatDate(_self.rangeDates[1])
    const obj={
      "startDate":_self.rangeDates[0],
      "endDate": _self.rangeDates[1]
    }
    sessionStorage.setItem('shippingOrdersDate',JSON.stringify(obj))
    this.getshippingorders();
  } else {
    e = s + 86400000;
  }
  if (this.rangeDates[1]) { // If second date is selected
    this.calendar.overlayVisible = false;
  }
}
formatDate(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return date.getFullYear() + '-' + month + '-' + day;
}
paginate(event) {
  console.log(event);
  
  // console.log(event)
  // this.rowCount = event['rows']
  // this.shippingPostData.pageLimit = this.rowCount
  // this.pageNumber = event.page + 1;
  // this.shippingPostData.pageNumber = this.pageNumber
  // this.getshippingorders();
  this.row2=event.rows
  // console.log(event.rows ,this.totalrows);
  
   
    
 // this.RequiredDetails.pageLimit=event.rows
 // this.pageNumber = event.page + 1;
 // this.RequiredDetails.pageNumber = this.pageNumber
//  console.log(event.rows,this.shippingPostData.pageLimit)

 if(this.shippingPostData.pageLimit==event.rows){
  this.shippingPostData.pageNumber=event.page+1
myVariables.shippingOrdersPageNumber=this.shippingPostData.pageNumber
   
}else if( event.rows==10){
  this.shippingPostData.pageLimit = 10
  this.shippingPostData.pageNumber=event.page
myVariables.shippingOrdersRowsPerPage=this.shippingPostData.pageLimit


  this.paginator.changePage(0)

}
else if( event.rows==25){
  this.shippingPostData.pageLimit = 25
  this.shippingPostData.pageNumber=event.page
  myVariables.shippingOrdersRowsPerPage=this.shippingPostData.pageLimit



  this.paginator.changePage(0)

}else if( event.rows==50){
  this.shippingPostData.pageLimit = 50
  this.shippingPostData.pageNumber=event.page
  myVariables.shippingOrdersRowsPerPage=this.shippingPostData.pageLimit

  this.paginator.changePage(0)
  
}
else if( event.rows==75){
  this.shippingPostData.pageLimit = 75
  this.shippingPostData.pageNumber=event.page
  myVariables.shippingOrdersRowsPerPage=this.shippingPostData.pageLimit

  this.paginator.changePage(0)
  
}
 else {
    
  // this.shippingPostData.pageLimit=event.rows 
  this.shippingPostData.pageNumber=event.page + 1
  myVariables.shippingOrdersPageNumber=this.shippingPostData.pageNumber
  // this.first=event.page
  // console.log(event);
  // console.log(this.first);

  
// this.paginator.changePage(0)

 }

 
 console.log(this.shippingPostData)
 this.getshippingorders()
 
}
}
