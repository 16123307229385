import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Moment } from 'moment'
import * as moment from 'moment';

import { dashboardVarialbles } from 'src/environments/environment';

@Component({
  selector: 'app-delivery-history',
  templateUrl: './delivery-history.component.html',
  styleUrls: ['./delivery-history.component.css']
})
export class DeliveryHistoryComponent implements OnInit {
  today: any;
  startDate: any;
  data: any[];
  basicDataLabels: any[];
  pendingData: any[];
  stagingData: any[];
  completedData: any[];
  selectedDateRange: string;
  apiPostData: any;
  dropdownArray: any[];
  todaysDate: any;
  firstDateOfThisMonth: any;
  lastDateOfThisMonth: any;
  firstDateOfLastMonth: any;
  lastDateOfLastMonth: any;
  firstDateOfSecondLastMonth: any;
  lastDateOfSecondLastMonth: any;
  firstDateOfThirdLastMonth: any;
  lastDateOfThirdLastMonth: any;
  daysInMonth: any;
  lastfifteenDays: any;
  lastFourtyFiveDays: any;
  secondLstMonth: any;
  ThirdLstMonth: any;
  totalDaysInLastMonth: any;
  totalDaysInLastSecondMonth: any;
  totalDaysInLastThirdMonth: any;

  constructor(private datePipe: DatePipe, private apiCall: ApiService) { }
  title: any;
  basicData: any;
  StackedOptions
  ngOnInit() {
    this.basicDataLabels = [];
    this.pendingData = [];
    this.stagingData = [];
    this.completedData = [];
    this.dropdownArray = [];
    const now = moment();
    let todaysDates = new Date();

    // 1st
    this.todaysDate = moment(todaysDates).format('YYYY-MM-DD')
    this.today = moment(todaysDates).format('DD')
    this.firstDateOfThisMonth = moment(this.todaysDate).startOf('month').format('YYYY-MM-DD')
    // 1st end

    this.lastDateOfThisMonth = moment(this.todaysDate).endOf('month').format('YYYY-MM-DD')
    // 2nd 
    this.firstDateOfLastMonth = moment(this.todaysDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfLastMonth = moment(this.todaysDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    // 2nd end



    // 3rd
    this.firstDateOfSecondLastMonth = moment(this.todaysDate).subtract(2, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfSecondLastMonth = moment(this.todaysDate).subtract(2, 'months').endOf('month').format('YYYY-MM-DD')

    // 4th
    this.firstDateOfThirdLastMonth = moment(this.todaysDate).subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
    this.lastDateOfThirdLastMonth = moment(this.todaysDate).subtract(3, 'months').endOf('month').format('YYYY-MM-DD')
    // 3rd end



    // Last 30 Days
    // this.lastfifteenDays = moment(todaysDates).format('YYYY-MM-DD').subtract(14, 'd');
    this.daysInMonth = 15
    this.lastfifteenDays = moment(this.todaysDate).subtract(15, 'days').format('YYYY-MM-DD')


    this.lastFourtyFiveDays = moment(this.lastfifteenDays).subtract(15, 'days').format('YYYY-MM-DD')



    console.log("days are", this.lastfifteenDays, this.lastFourtyFiveDays);


    // Last !5 Days end

    this.firstDateOfThisMonth = moment(this.todaysDate).startOf('month').format('YYYY-MM-DD')
    // 1st end


    let thisMonth = moment(this.firstDateOfThisMonth).format('MMM')
    let lastMonth = moment(this.firstDateOfLastMonth).format('MMM')
    this.secondLstMonth = moment(this.firstDateOfSecondLastMonth).format('MMM')
    this.ThirdLstMonth = moment(this.firstDateOfThirdLastMonth).format('MMM')
    let lastFourMonthArr = ['Last 15 Days']
    let daysObj = {
      'Last 15 Days': {
        startDate: this.lastfifteenDays,
        endDate: this.todaysDate

      }
    }
    if (this.today > 15) {

      lastFourMonthArr.push('01 ' + thisMonth + "-" + thisMonth + 15)
      daysObj['01 ' + thisMonth + "-" + thisMonth + 15] = {
        startDate: moment(this.firstDateOfThisMonth).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(this.firstDateOfThisMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
      }
    }

    console.log("==============================================>", lastMonth, this.secondLstMonth, this.ThirdLstMonth)

    this.totalDaysInLastMonth = moment(this.firstDateOfLastMonth, "YYYY-MM-DD").daysInMonth()

    lastFourMonthArr.push(16 + " " + lastMonth + "-" + this.totalDaysInLastMonth + " " + lastMonth)
    daysObj[16 + " " + lastMonth + "-" + this.totalDaysInLastMonth + " " + lastMonth] = {
      startDate: moment(this.firstDateOfLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfLastMonth).endOf('month').format('YYYY-MM-DD')
    }

    lastFourMonthArr.push('01 ' + lastMonth + "-" + 15 + " " + lastMonth)
    daysObj['01 ' + lastMonth + "-" + 15 + " " + lastMonth] = {
      startDate: moment(this.firstDateOfLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    this.totalDaysInLastSecondMonth = moment(this.firstDateOfSecondLastMonth, "YYYY-MM-DD").daysInMonth()
    lastFourMonthArr.push(16 + " " + this.secondLstMonth + "-" + this.totalDaysInLastSecondMonth + " " + this.secondLstMonth)
    daysObj[16 + " " + this.secondLstMonth + "-" + this.totalDaysInLastSecondMonth + " " + this.secondLstMonth] = {
      startDate: moment(this.firstDateOfSecondLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfSecondLastMonth).endOf('month').format('YYYY-MM-DD')
    }
    lastFourMonthArr.push('01 ' + this.secondLstMonth + "-" + 15 + " " + this.secondLstMonth)
    daysObj['01 ' + this.secondLstMonth + "-" + 15 + " " + this.secondLstMonth] = {
      startDate: moment(this.firstDateOfSecondLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfSecondLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    this.totalDaysInLastThirdMonth = moment(this.firstDateOfThirdLastMonth, "YYYY-MM-DD").daysInMonth()
    lastFourMonthArr.push(16 + " " + this.ThirdLstMonth + "-" + this.totalDaysInLastThirdMonth + " " + this.ThirdLstMonth)
    daysObj[16 + " " + this.ThirdLstMonth + "-" + this.totalDaysInLastThirdMonth + " " + this.ThirdLstMonth] = {
      startDate: moment(this.firstDateOfThirdLastMonth).startOf('month').add(15, 'days').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfThirdLastMonth).endOf('month').format('YYYY-MM-DD')
    }
    lastFourMonthArr.push('01 ' + this.ThirdLstMonth + "-" + 15 + " " + this.ThirdLstMonth)
    daysObj['01 ' + this.ThirdLstMonth + "-" + 15 + " " + this.ThirdLstMonth] = {
      startDate: moment(this.firstDateOfThirdLastMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(this.firstDateOfThirdLastMonth).startOf('month').add(14, 'days').format('YYYY-MM-DD'),
    }

    // this.categories[2].value = this.secondLstMonth



    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", lastFourMonthArr,
      daysObj)
    // push into dropdown
    lastFourMonthArr.forEach(element => {
      this.dropdownArray.push({ "id": lastFourMonthArr.indexOf(element), "value": element })
    })

    console.log("array dropdown issssssssssssssss    ", this.dropdownArray);

    // console.log(dashboardVarialbles.receiptHistoryDateRange);

    if (dashboardVarialbles.deliveryHistoryDateRange == 'last 15 Days') {

      this.selectedDateRange = 'last 15 Days'
      this.apiPostData = {
        "startDate": daysObj['last 15 Days']['startDate'],
        "endDate": daysObj['last 15 Days'].endDate,
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
    }
    else {

      this.selectedDateRange = dashboardVarialbles.deliveryHistoryDateRange
      console.log(this.selectedDateRange);

      this.apiPostData = {
        "startDate": daysObj[dashboardVarialbles.deliveryHistoryDateRange].startDate,
        "endDate": daysObj[dashboardVarialbles.deliveryHistoryDateRange].endDate,
        "warehouseId": localStorage.getItem('WarehouseID'),
        "customerId": null
      }
      console.log(this.apiPostData);

    }
    this.apiCall.deliveryHistory(this.apiPostData).subscribe(res => {
      this.data = res['result']
      console.log(this.data)
      for (let i = 0; i < this.data.length; i++) {

        this.basicDataLabels.push(this.datePipe.transform(this.data[i].date, 'MMM dd'))
        this.pendingData.push(this.data[i].Pending)
        this.stagingData.push(this.data[i].Staging)
        this.completedData.push(this.data[i].Completed)

        // console.log((this.basicDataLabels));
        // const myArray = Object.entries(this.basicDataLabels);
        // console.log(myArray);


      }
      console.log(this.pendingData, this.completedData, this.stagingData);

      this.title = "GFG";

      // this.StackedOptions.global.elements.rectangle.borderWidth=2

      this.basicData = {
        labels: this.basicDataLabels,
        datasets: [
          {
            label: "Pending",
            backgroundColor: "#6CA6C1",
            // barPercentage: 0.5,
            barThickness: 8,
            maxBarThickness: 8,
            data: this.pendingData
          },
          {
            label: "Staging",
            backgroundColor: "#F9DF62",
            // barPercentage: 0.5,
            barThickness: 8,
            maxBarThickness: 8,
            data: this.stagingData
          },
          {
            label: "Completed",
            backgroundColor: "#72C238",
            barThickness: 8,
            maxBarThickness: 8,

            data: this.completedData
          }
        ]
      };

      // for (let i = 0; i < this.basicDataLabels.length; i++) {
      //   this.basicData.labels.push(this.basicDataLabels[i])
      // }
      // console.log(this.basicData.labels)





      this.StackedOptions = {
        legend: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          },
          barWidth: {
            ratio: 0.5 // Adjust the bar width
          }
        },
        layout: {
          padding: {
            left: 10,
            right: 10
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false,

            },
            ticks: {
              color: "#black",
              fontSize: 7


            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
              color: '#F6F6F6',
              borderDash: [12, 5]

            },
            ticks: {
              color: "#black"
            },
            grid: {
              color: "rgba(255,255,255,0.2)"
            }
          }]
        },

      };
      // this.data.forEach(element => {
      //   this.basicDataLabels.push(element)
      // });

    })

  }
  dateRangeChange(event) {
    // console.log(event.target.value)
    dashboardVarialbles.deliveryHistoryDateRange = event.target.value
    this.ngOnInit();
  }

  ondataclick(data) {
    // console.log(data);

  }

}
