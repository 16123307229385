import { Component, Injectable, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-stock-transaction',
  templateUrl: './stock-transaction.component.html',
  styleUrls: ['./stock-transaction.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StockTransactionComponent implements OnInit {
  @ViewChild('pagin') paginator: Paginator;
  @ViewChild('myTable') private _table: Table;
  @ViewChild('calendar', undefined) private calendar: any;
  // disabled = true;
  RequiredStock = { checked: false, userId: localStorage.getItem('UserID'),  pageNumber: 1 }
  jsonData = { arrayValue: [] }
  cars: any[];
  cols: any[];
  firstCols: any[];
  addToCart = [];
  colors: SelectItem[];
  selectedRowData: any;
  displayBasic: boolean;
  displayInd: boolean;
  expandedRowIndex: any;
  rowData_proCode: any;
  rowData_Stock: any;
  checkBoxVal: any;
  dateTime = new Date();
  getQtyVal: any;
  checking: string;
  addRowData: any;
  selectedRowArr = [];
  addCartparams: any;
  pro_name: any;
  pro_code: any;
  entered_qty: any;
  SelectedDate: any;
  SendingValue: any;
  public dateFilters: Date = new Date();
  rowData_available: any;
  balance: any;
  DefaultDate: any;
  obj: { width: string; };
  primaryClosing: any;
  check = false;
  pageNumber: any;
  totalRecords: number;

  downloadRequest = {
    "userId":localStorage.getItem('Cus_ID'),
    "warehouseId":localStorage.getItem('WarehouseID'),
    "date":""
  }

  ProductSearch = {
    "searchval": "",
    "customerId": localStorage.getItem('ViewCustomer_id')
  }

  ProductMaterial = {
    "userId": localStorage.getItem('Cus_ID'),
    "date": (localStorage.getItem('SDate') || localStorage.getItem('DefaultDate')),
    "materialcode": ""
  }

  SupplierfilteredOption: Observable<string[]>;
  name: FormControl;
  AutoFillSuppliers = [];
  printingMaterialName: string;
  isEnabled = false;
  
  constructor(private router: Router, private Apicall: ApiService, private datePipe: DatePipe) { 
    this.name = new FormControl();
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );
  }
  ngOnInit() {
    this.DefaultDate = new Date();
    this.DefaultDate = this.datePipe.transform(this.DefaultDate, "yyyy-MM-dd")
    this.downloadRequest.date = this.DefaultDate
    localStorage.setItem('DefaultDate', this.DefaultDate)
    console.log(this.DefaultDate)
    this.dateTime.setDate(this.dateTime.getDate() + 0);
    this.StockStmtApi()
    var _self = this;

    this.firstCols = [
      { field: 'OpeningStock', open: 'OpeningStock', width: '40%' },
      { field: 'Receipt', receipt: 'Receipt' },
      { field: 'Dispatches', dispatch: 'Dispatches' },
      { field: 'ClosingStock', close: 'ClosingStock' }
    ]
    this.cols = [
      { field: 'date', header: 'Date' },
      { field: 'SkuUpcEan', header: 'Material Code' },
      { field: 'productName', header: 'Description' },
      { field: 'goodOpeningStock', header: 'Good' },
      { field: 'damagedOpeningStock', header: 'Damaged' },
      { field: 'goodInward', header: 'Good' },
      { field: 'damagedInward', header: 'Damaged' },
      { field: 'goodOutward', header: 'Good' },
      { field: 'damagedOutward', header: 'Damaged' },
      { field: 'goodClosing', header: 'Good' },
      { field: 'damagedClosing', header: 'Damaged' },
      { field: 'totalQty', header: 'Total' },
    ];
  }

  filters(val: string) {
    // console.log("CHECKING HERE", this.AutoFillSuppliers)
    if (this.AutoFillSuppliers === undefined || this.AutoFillSuppliers === []) {
      return
    }
    return this.AutoFillSuppliers.filter(option =>
      option.productName.toLowerCase().includes(val.toLowerCase()));
  }

  setInputField(data) {
    this.ProductSearch.searchval = data
    this.SupplierfilteredOption = this.name.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filters(val))
        // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
      );

    if (data.length > 2) {
      this.ProductSearching()

    } else if(data.length < 1){
      // console.log("please enter minimum 3 characters")
      this.AutoFillSuppliers = []
      this.StockStmtApi()
    }else{
      this.AutoFillSuppliers = []
    }
  }

  ProductSearching() {
    this.Apicall.getProductName(this.ProductSearch)
      .subscribe(
        res => {
          this.AutoFillSuppliers = res.result
          this.SupplierfilteredOption = this.name.valueChanges
            .pipe(
              startWith(''),
              map(val => this.filters(val))
              // map(val => (val ? this.filters(val) : this.AutoFillSuppliers.slice()))
            );
          if (res.result === 'No data Found' || res.status === false) {
            this.AutoFillSuppliers = []
          }
        }
      )
  }

  clearfilter(){
    this.ProductSearch.searchval = '';
    this.isEnabled = false;
    this.StockStmtApi()
  }


  selectedSupplier(evt) {
    for (var i = 0; i < this.AutoFillSuppliers.length; i++) {
      if (this.AutoFillSuppliers[i].productCode == evt.source.value) {
        console.log(this.AutoFillSuppliers[i].productCode)
        this.isEnabled = true;
        this.ProductMaterial.date = localStorage.getItem('SDate') || localStorage.getItem('DefaultDate')
        this.ProductMaterial.materialcode = this.AutoFillSuppliers[i].productCode
        this.printingMaterialName = this.AutoFillSuppliers[i].productCode + ' - ' + this.AutoFillSuppliers[i].productName
        this.Apicall.getAllMaterials(this.ProductMaterial)
          .subscribe(
            res => {
              this.cars = []
              // var newResult = res.result
              // console.log("step2",newResult)
              console.log("step3",this.cars.push(res.result))
              this.totalRecords = 0
              this.SupplierfilteredOption = this.name.valueChanges
              .pipe(
                startWith(''),
                map(val => this.filters(val))
              );

            }
          )

      }
    }
  }

  onDateSelect(value) {
    var _self = this;
    this.SelectedDate = this.formatDate(this.dateFilters)
    this.downloadRequest.date = this.SelectedDate
    console.log("SELECTED", this.SelectedDate)

    localStorage.setItem('SDate', this.SelectedDate)
    this.RequiredStock.pageNumber=1
    this.paginator.changePage(0)
    this.StockStmtApi()
  }
  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return date.getFullYear() + '-' + month + '-' + day;
  }
  toggleVisibility(ri, rowData, isChecked) {
    // console.log("BOOLEAN",rowData)
    this.selectedRowData = rowData
    this.checkBoxVal = isChecked;
    this.expandedRowIndex = ri;
    if (this.checkBoxVal == true) {
      rowData.checkVal = true
      this.selectedRowData = rowData
      this.addRowData = rowData
      this.displayBasic = true;
      this.checkBoxVal = false;
      this.checking = "expandedRowIndex"
      this.rowData_proCode = rowData.SkuUpcEan;
      this.rowData_Stock = rowData.closing
      this.rowData_available = rowData.actualQty
      console.log("CALCULATING HERE", this.balance)
    }
  }
  /*---------------------------CALCULATION-------------------------------*/
  checkLength1(e, input) {
    const keyValue = +e.key;
    const numberOnlyPattern = '[0-9]+';
    const newValue = input.value + (isNaN(keyValue) ? '' : keyValue.toString());
    const match = newValue.match(numberOnlyPattern);
    if (+newValue > this.rowData_available || !match || newValue === '') {
      e.preventDefault();
    }
  }
  /*------------------END CALCULATION------------------------------*/
  cancel() {
    this.displayBasic = false;
  }
  CreateCustomer() {
    console.log("GO TO DELIVERY ORDER")
    if (localStorage.getItem('Cus_MbNo') === null || localStorage.getItem('Cus_Name') === null) {
      this.router.navigate(['/customer/deliveryorder'])
    }
  }
  creatingStmt() {
    this.router.navigate(['/customer/deliveryorder'])
  }
  StockStmtApi() {
    this.Apicall.getStockStmt(this.RequiredStock)
      .subscribe(
        res => {
          console.log("CALCULATION DATA", res)
          this.cars = res.result;
          this.totalRecords = res.noOfPages
          if(this.cars.length === 0){
            this.check = true
          }else{
            this.check = false
          }
          err => {
            console.log("GETTING ERROR STOCK STATEMENT", err)
          }
        }
      )
  }

  DownloadTransaction() {
    this.Apicall.downloadTransactionReportApi(this.downloadRequest)
    .subscribe({
      next: (response: any) => {

        let fileName = 'Stocktransaction';
        const contentDisposition = response.headers.get('content-disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        const fileContent = response.body;

        saveAs(fileContent, fileName);

      },
      error: (error) => {

        console.log({error});

      }
    });
  }

  paginate(event) {
    this.pageNumber = event.page + 1;
    this.RequiredStock.pageNumber = this.pageNumber
    this.StockStmtApi();
  }
}
