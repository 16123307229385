import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginformComponent } from './loginform/loginform.component';
import { ViewCustomersComponent } from './view-customers/view-customers.component';
import { ViewSelectedCustomerComponent } from './view-selected-customer/view-selected-customer.component';
import { StockstatementComponent } from './stockstatement/stockstatement.component';
import { StockledgerComponent } from './stockledger/stockledger.component';
import { PendingreceiptComponent } from './pendingreceipt/pendingreceipt.component';
import { PendingdeliveriesComponent } from './pendingdeliveries/pendingdeliveries.component';
import { CompletedreceiptComponent } from './completedreceipt/completedreceipt.component';
import { CompleteddeliveriesComponent } from './completeddeliveries/completeddeliveries.component';
import { CreateReceiptComponent } from './create-receipt/create-receipt.component';
import { CompleteDeliveryComponent } from './complete-delivery/complete-delivery.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewSelectedReceiptComponent } from './view-selected-receipt/view-selected-receipt.component';
import { ViewSelectedDeliveryComponent } from './view-selected-delivery/view-selected-delivery.component';
import { AddMaterialComponent } from './add-material/add-material.component';

import { LoginguardGuard } from '../app/loginguard.guard';
import { RedirectGuard } from '../app/redirect.guard';
import { ViewCompletedReceiptComponent } from './view-completed-receipt/view-completed-receipt.component';
import { ViewCompletedDeliveryComponent } from './view-completed-delivery/view-completed-delivery.component';
import { MaterialMasterComponent } from './material-master/material-master.component';
import { NewAddMaterialComponent } from './new-add-material/new-add-material.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { StockTransactionComponent } from './stock-transaction/stock-transaction.component';
import { SearchByProductComponent } from './search-by-product/search-by-product.component';
import { SearchByLocationComponent } from './search-by-location/search-by-location.component';
import { LocationTransferComponent } from './location-transfer/location-transfer.component';
import { ReturnGrnComponent } from './return-grn/return-grn.component';
import { LocationPutawayComponent } from './location-putaway/location-putaway.component';
import { AddMatLocationComponent } from './add-mat-location/add-mat-location.component';
import { LocationTypeComponent } from './location-type/location-type.component';
import { LocationMasterComponent } from './location-master/location-master.component';
import { ShippingOrdersComponent } from './shipping-orders/shipping-orders.component';
import { CheckCourierServiceComponent } from './check-courier-service/check-courier-service.component';
import { TractOrderComponent } from './tract-order/tract-order.component';
import { CreateShipmentComponent } from './create-shipment/create-shipment.component';
import { GrnDetailsComponent } from './grn-details/grn-details.component';
import { ViewGrnDetailsComponent } from './view-grn-details/view-grn-details.component';
import { ViewReturnGrnDetailsComponent } from './view-return-grn-details/view-return-grn-details.component';
import { DnDetailsComponent } from './dn-details/dn-details.component';
import { ViewDnDetailsComponent } from './view-dn-details/view-dn-details.component';

const routes: Routes = [
  {
    path : '',
    redirectTo : 'login',
    pathMatch : 'full'
  },
  {
    path : 'login',
    component : LoginformComponent,
    canActivate : [RedirectGuard]
  },
  {
    path : 'changepassword',
    component : ChangePasswordComponent,
    canActivate : [LoginguardGuard]
  },
  {
    path : 'dashboard',
    component : DashboardComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'viewcustomers',
    component : ViewCustomersComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'viewacustomer',
    component : ViewSelectedCustomerComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'materialmaster',
    component : MaterialMasterComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'stockstatement',
    component : StockstatementComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'stockledger',
    component : StockledgerComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'stocktransaction',
    component : StockTransactionComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'completdelivery',
    component : CompleteDeliveryComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'pendingreceipt',
    component : PendingreceiptComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'createreceipt',
    component : CreateReceiptComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'createreturngrn',
    component : ReturnGrnComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'viewselectedreceipt',
    component : ViewSelectedReceiptComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'pendingdeliveries',
    component : PendingdeliveriesComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'viewselecteddeliveries',
    component : ViewSelectedDeliveryComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'inwardmaterial',
    component : AddMaterialComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'inwardmaterials',
    component : AddMatLocationComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'addmaterial',
    component : NewAddMaterialComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'completedreceipt',
    component : CompletedreceiptComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'Viewcompletedreceipt',
    component : ViewCompletedReceiptComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'Viewcompleteddelivery',
    component : ViewCompletedDeliveryComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'completeddeliveries',
    component : CompleteddeliveriesComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'searchbyproduct',
    component : SearchByProductComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'searchbylocation',
    component : SearchByLocationComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'locationtransfer',
    component : LocationTransferComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'locationputaway',
    component : LocationPutawayComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path : 'location-type',
    component : LocationTypeComponent,
    canActivate : [LoginguardGuard]
  },
  {
    path : 'location-master',
    component : LocationMasterComponent,
    canActivate : [LoginguardGuard]
  },
  {
    path : 'shipping-Orders',
    component: ShippingOrdersComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path:'checkCourierService',
    component: CheckCourierServiceComponent,
    canActivate:[LoginguardGuard]
  },
  {
    path:'track-order',
    component:TractOrderComponent,
    canActivate:[LoginguardGuard]
  },
  {
    path:'create-shipment',
    component:CreateShipmentComponent,
    canActivate:[LoginguardGuard]
  },
  {
    path: 'grn-details',
    component: GrnDetailsComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path: 'view-grn-details',
    component: ViewGrnDetailsComponent,
    canActivate: [LoginguardGuard]
  },
  {
    path:'view-returngrn-details',
    component: ViewReturnGrnDetailsComponent,
    canActivate:[LoginguardGuard]
  },
  {
    path:'dn-details',
    component:DnDetailsComponent,
    canActivate:[LoginguardGuard]
  },
  {
    path:'view-dn-details',
    component: ViewDnDetailsComponent,
    canActivate:[LoginguardGuard]
  }



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
