import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-return-grn',
  templateUrl: './return-grn.component.html',
  styleUrls: ['./return-grn.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class ReturnGrnComponent implements OnInit, AfterViewInit {

  formGroup: FormGroup;
  customerList: any;
  BookingList: any;
  EndCustomerList: any;

  materialForm: FormGroup;
  minDate = new Date();
  orderItems = new FormArray([]);
  row = [];
  DocumentType = [];
  cars: any[];
  cols: any[];
  filteredOptions: Observable<string[]>;
  materialName: FormControl;
  AutoFillSuppliers = [];
  DeliveryNumberAutoSuggestFill: string[] = [];
  deliveryOrderNo = new FormControl();

  SupplierfilteredOption: Observable<string[]>;

  isEnabled = false;
  InputDataValue: any;
  HideClearBtn: boolean;

  rowPoNumber = [];
  deleteMatIndex: any;

  ProductSearch = {
    "searchval": "",
    "customerId": localStorage.getItem('CusID')
  }

  matCode: any;
  DeliveryNumberArray = []

  ReturnGRNRequired = {
    "bookingId": "", 
    "userId": localStorage.getItem('ID'),
    "customerId": "",
    "warehouseId": localStorage.getItem('WarehouseID'),
    "documentReffNumber": "",
    "documentDate": "",
    "remarks": "",
    "documentType": "",
    "endCustomerId": "",
    "deliveryOrderId": "",
    "deliveryRequestId": "",
    "receivedDate":"",
    "materials": []
  }
  submitted: boolean;
  selectedDeliveryRequestDetails: any;

  constructor(private ApiCall: ApiService, private cdRef: ChangeDetectorRef, private messageService: MessageService, private formBuilder: FormBuilder,  private datePipe: DatePipe, private router : Router) {
    this.materialName = new FormControl();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.ReceiptForm();
    this.customerDetails();
    this.materialForm = new FormGroup({
      'orderItems': this.orderItems
    });

    this.row = [
      {
        productId: "",
        materialCode: "",
        materialName: "",
        quantity: ""
      }
    ];

    this.cols = [
      { field: 'materialName', header: 'Material Name' },
      { field: 'materialCode', header: 'Material Code' },
      { field: 'quantity', header: 'Quantity' },
    ];

    this.DocumentType = [
      { field: 'gate_pass', value: 'Gate Pass' },
      { field: 'awb_number', value: 'AWB Number' },
      { field: 'others', value: 'Others' },
    ];

    this.cars = [
      { materialName: null, materialCode: null, quantity: null, editable: true }
    ]
    this.addTable()
  }


  ReceiptForm() {
    this.formGroup = this.formBuilder.group({
      customerName: ['', Validators.required],
      bookingId: ['', Validators.required],
      deliveryOrderNo: ['', Validators.required],
      DocRefNo: ['', Validators.required],
      DocType: ['', Validators.required],
      DocDate: ['', Validators.required],
      receivedDate:['',Validators.required],
      EndCustomer: ['', Validators.required],
      remarks: ['', Validators.required],
    })
  }
  public ReceiptcheckError = (controlName: any, errorName: any) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  }
  get f() { return this.formGroup.controls; }


  /*-----------------------------START - CUSTOMER DETAILS-------------------------------*/
  customerDetails() {
    this.ApiCall.getCustomerList()
      .subscribe(
        res => {
          this.customerList = res.result;
          console.log(this.customerList);
          
        }
      )
  }

  selectedValue(id, index) {
    localStorage.setItem("CusID", this.customerList[id].id)
    this.ProductSearch.customerId = this.customerList[id].id
    this.ReturnGRNRequired.customerId = this.customerList[id].id
    // localStorage.setItem("Custom_User_Id", this.customerList[id].userId)
    this.BookingDetails();
    this.DeliveryOrderNumber();
    this.EndCustomerDetails();
    // (<FormArray>this.materialForm.get('orderItems')).removeAt(index);

    this.SupplierfilteredOption = this.deliveryOrderNo.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filters(value))
      );
      console.log(this.SupplierfilteredOption)
  }
  /*-----------------------------END - CUSTOMER DETAILS-------------------------------*/


  /*-----------------------------START - BOOKING DETAILS-------------------------------*/
  BookingDetails() {
    this.ApiCall.getBookingList()
      .subscribe(
        res => {
          console.log("Booking List", res.result)
          this.BookingList = res.result;
        }
      )
  }

  selectedID(id) {
    console.log("BOOKING", id)
    this.BookingDetails();
    this.ReturnGRNRequired.bookingId = this.BookingList[id].id
  }

  /*-----------------------------END - BOOKING DETAILS-------------------------------*/


  /*-----------------------------START - DELIVERY ORDER NUMBER------------------------*/
  DeliveryOrderNumber() {
    this.ApiCall.getDeliveryOrderNumbers()
      .subscribe(
        res => {

          // let DeliveryNumberAutoSuggestFill = res.result;
          // let values = DeliveryNumberAutoSuggestFill.map((item) => item.deliveryRequestId);
          this.DeliveryNumberArray = res.result
          console.log("FILTERED VALUES", this.DeliveryNumberArray)
        }
      )
  }

  filters(value) {
    return this.DeliveryNumberArray.filter(option =>
      option.orderNumber.toLowerCase().includes(value.toLowerCase()));
  }

  SelectedDeliveryOrder(data, index) {
    console.log("Coming", data, index)
    for (var i = 0; i < this.DeliveryNumberArray.length; i++) {
      if (this.DeliveryNumberArray[i].orderNumber === data) {
        this.ReturnGRNRequired.deliveryRequestId = this.DeliveryNumberArray[i].deliveryRequestId
        this.ReturnGRNRequired.deliveryOrderId = this.DeliveryNumberArray[i].orderNumber
      }
    }
    console.log(this.ReturnGRNRequired.deliveryRequestId)
    localStorage.setItem('completed_delivery_id',this.ReturnGRNRequired.deliveryRequestId)
    this.ApiCall.getViewCompletedDelivery().subscribe(res=>{
      this.selectedDeliveryRequestDetails=res.result
      console.log(res)
    })
    
  }

  /*----------------------------END - DELIVERY ORDER NUMBER---------------------------*/

  /*----------------------------START -ENDCUSTOMER DETAILS------------------------------*/

  EndCustomerDetails() {
    this.ApiCall.getEndCustomerDetails()
      .subscribe(
        res => {
          console.log("Customer List", res.result)
          this.EndCustomerList = res.result;
        }
      )
  }

  selectedCustomerid(id) {
    console.log("ID", id)
    this.ReturnGRNRequired.endCustomerId = id
  }

  /*----------------------------END - ENDCUSTOMER DETAILS------------------------------*/

  addTable() {

    var ContainerLength = this.materialForm.value.orderItems.length

    if (ContainerLength !== 0) {
      if (this.materialForm.value.orderItems[ContainerLength - 1].materialName === null || this.materialForm.value.orderItems[ContainerLength - 1].quantity === null) {
        Swal.fire({
          toast: true,
          title: 'Please fill existing fields before creating new one !',
          timer: 3000,
          type: "warning",
          showConfirmButton: false
        })
        return
      } else {

        if (this.materialForm.value.orderItems[ContainerLength - 1].editable !== true) {
          this.materialForm.value.orderItems[ContainerLength - 1].editable = !this.materialForm.value.orderItems[ContainerLength - 1].editable
        }
        console.log("CONTAINER LENGTH", this.materialForm.value.orderItems)
        this.materialForm.value.orderItems[ContainerLength - 1].editable = !this.materialForm.value.orderItems[ContainerLength - 1].editable
      }
    }
    this.HideClearBtn = true
    this.InputDataValue = '';
    (<FormArray>this.materialForm.get('orderItems')).push(
      new FormGroup({
        'materialName': new FormControl(null, Validators.required),
        'materialCode': new FormControl(null, Validators.required),
        'quantity': new FormControl(null, Validators.required),
        'editable': new FormControl(true)
      })
    );
    this.initForm((<FormArray>this.materialForm.get('orderItems')).length - 1);
    console.log("CHECKING MATERIAL FORM", this.materialForm.value.orderItems)
    this.filteredOptions = EMPTY;
  }


  isDisabled(value) {
    return this.rowPoNumber.includes(value);
  }


  getControls() {
    return (<FormArray>this.materialForm.get('orderItems')).controls;
  }

  initForm(index?: number) {
    var arrayControl = this.materialForm.get('orderItems') as FormArray;
    this.filteredOptions = arrayControl.at(index).get('materialName').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value.toString()))
      );
  }

  filter(val: string) {
    return this.AutoFillSuppliers.filter(option => option.productName.toLowerCase());
    // }
  }


  clearfilter(index) {
    console.log("INDEX", index)
    this.isEnabled = false;
    this.InputDataValue = ''
  }

  PencilDisable(orderItem: any, i) {
    orderItem.editable = !orderItem.editable;
  }

  CheckDisable(orderItem: any, i) {
    if (orderItem.materialName === null || orderItem.quantity === null) {
      Swal.fire({
        toast: true,
        title: 'Please fill required fields before submit!',
        timer: 3000,
        type: "warning",
        showConfirmButton: false
      })
    } else {
      orderItem.editable = !orderItem.editable;
    }
  }

  onDeleteIngredient(index: number, item: any) {
    this.deleteMatIndex = index
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Do you want to delete' })
  }

  onConfirmDelete() {
    this.rowPoNumber.splice(this.deleteMatIndex, 1);
    (<FormArray>this.materialForm.get('orderItems')).removeAt(this.deleteMatIndex);
    this.messageService.clear('c');
  }
  onMatReject() {
    this.messageService.clear('c');
  }


  setInputField(data) {
    this.InputDataValue = data
    this.ProductSearch.searchval = data
    this.ProductSearch.customerId = localStorage.getItem('CusID')
    if (data.length > 2) {
      this.ProductSearching()
    } else {
      this.ProductSearch.searchval = ''
      this.filteredOptions = EMPTY
      this.AutoFillSuppliers = []
    }
  }

  ProductSearching() {

    this.filteredOptions =

      this.ApiCall.getProductName(this.ProductSearch).pipe(
        map(x => x.result))

    this.ApiCall.getProductName(this.ProductSearch)
      .subscribe(
        res => {
          this.AutoFillSuppliers = res.result;
          this.filteredOptions = this.orderItems.valueChanges
            .pipe(
              startWith(''),
              map(val => this.filter(val))
            );
          console.log("AUTO FILL", this.AutoFillSuppliers)
          if (!this.AutoFillSuppliers) {
            this.AutoFillSuppliers.map(function (entry) {
              entry.isdisabled = false;
              return entry;
            });
          }

          if (res.result === 'No data Found' || res.status === false) {
            this.AutoFillSuppliers = []
            this.filteredOptions = EMPTY
          }

        }
      )
  }

  clearMatCode(event: any, matIndex) {
    console.log("VALUE DATA WANT TO SEE", event.target.value)
    this.rowPoNumber = []
    this.orderItems.value[matIndex].materialCode = ''
  };

  selectedMaterialName(data, index) {
    console.log("Coming", data, index)
    for (var i = 0; i < this.AutoFillSuppliers.length; i++) {
      if (this.AutoFillSuppliers[i].productName === data) {
        this.matCode = this.AutoFillSuppliers[i]
        this.rowPoNumber.push(this.matCode.productName)
        console.log("ROW CHECKING", this.rowPoNumber)
        console.log("Printing value", this.matCode)
        this.orderItems.value[index].materialCode = this.AutoFillSuppliers[i].productCode
      }
    }
    this.HideClearBtn = false
    this.isEnabled = true;
  }

  submitData(){
    this.ReturnGRNRequired.documentDate = this.datePipe.transform(this.ReturnGRNRequired.documentDate, "yyyy-MM-dd")
    this.ReturnGRNRequired.receivedDate = this.datePipe.transform(this.ReturnGRNRequired.receivedDate,"yyy-MM-dd")
    this.matCode = (<FormArray>this.materialForm.get('orderItems')).value;

    this.ReturnGRNRequired.materials = this.matCode

    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    var ContainerLength = this.materialForm.value.orderItems.length
      console.log("SUBMITION DATA", this.ReturnGRNRequired)


      if (ContainerLength !== 0) {
        if (this.materialForm.value.orderItems[ContainerLength - 1].materialName === null || this.materialForm.value.orderItems[ContainerLength - 1].quantity === null) {
          Swal.fire({
            toast: true,
            title: 'Please fill Material fields before Submit !',
            timer: 3000,
            type: "warning",
            showConfirmButton: false
          })
          return
        }
      }
      console.log(this.ReturnGRNRequired.materials);
      console.log(this.selectedDeliveryRequestDetails.materials);
      
      
  for(let i=0;i<this.ReturnGRNRequired.materials.length;i++){
    for(let j=0;j<this.selectedDeliveryRequestDetails.materials.length;j++){      
      if(this.selectedDeliveryRequestDetails.materials[i].productCode!=this.ReturnGRNRequired.materials[i]['materialCode']){
        console.log(this.selectedDeliveryRequestDetails.materials[j].productCode!=this.ReturnGRNRequired.materials[i].materialCode);
        Swal.fire({
          toast: true,
          title: 'Order does not contain the seletcted product',
          timer: 3000,
          type: "error",
          showConfirmButton: false
        })
        return
      }
    }
  }
      if (this.ReturnGRNRequired.materials[0].materialName == '' || this.ReturnGRNRequired.materials[0].materialName == null) {
        Swal.fire({
          toast: true,
          title: 'Please choose at least one material while creating receipt alert',
          timer: 3000,
          type: "error",
          showConfirmButton: false
        })
      }
      else {
        console.log('PARAMS', this.ReturnGRNRequired)
        this.ApiCall.returnGrnCreation(this.ReturnGRNRequired)
          .subscribe(
            res => {
              if (res.error == "notNull Violation: receiptMaterial.quantity cannot be null") {
                Swal.fire({
                  toast: true,
                  title: 'Please choose at least one material while creating receipt alert',
                  timer: 3000,
                  type: "error",
                  showConfirmButton: false
                })
              }
              if (res.error == "Out of range value for column 'pincode' at row 1") {
                Swal.fire({
                  toast: true,
                  title: 'Please Enter Valid Pin code',
                  timer: 2000,
                  type: "error",
                  showConfirmButton: false
                })
              }
              Swal.fire({
                toast: true,
                title: 'Return GRN Created Successfully',
                timer: 2000,
                type: "success",
                showConfirmButton: false
              })
              this.router.navigate(['/dashboard'])
            }
  
          )
  
      }
    }

}
