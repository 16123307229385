// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
export const myVariables = {
  completedReceiptRowsPerPage: 1,
  completedDeliveryRowsPerPage: 1,
  stagingReceiptRowsPerPage: 1,
  stagingReceiptPageNumber: 1,
  completedReceiptPageNumber: 1,
  completedDeliveryPageNumber: 1,
  pendingDeliveriespageNumber: 1,
  pendingDeliveriesRowsPerPage: 1,
  pendingReceiptRowsPerPage: 1,
  pendingReceiptPageNumber: 1,
  grnPageNumber: 1,
  grnRowsPerPage: 1,
  returnGrnPageNumber: 1,
  returnGrnRowsPerPage: 1,
  dnRowsPerPage: 1,
  dnPageNumber: 1,
  shippingOrdersRowsPerPage: 1,
  shippingOrdersPageNumber: 1,
  StockStatementPageNumber: 1,
  StaockStatementRowsPerPage: 1,
  directChannelOrder: null,



};
export const dashboardVarialbles = {
  receiptHistoryDateRange: 'Last 15 Days',
  deliveryHistoryDateRange: 'Last 15 Days',
  HistoryDateRange: 'Last 15 Days',
  isStagingRoute: false,
  isPendingRoute: false,
  IsCompletedRoute: false
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
