import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-check-courier-service',
  templateUrl: './check-courier-service.component.html',
  styleUrls: ['./check-courier-service.component.css']
})
export class CheckCourierServiceComponent implements OnInit {
  pincode:number;
  completeData: any;
  showTable=false;
  validation: string;
  constructor(private Apicall: ApiService) { }
cols=[];
  ngOnInit() {
    this.cols=[
      {field:'services',header:'SERVICE NAME'},
      {field: 'cod',header:'COD'},
      {field:'district',header:'DISTRICT'},
      {field:'pickup',header:'PICKUP'},
      {field:'prepaid',header:'PREPAID'},
      {field:'sort_code',header:'SORT CODE'},
      {field:'state_code',header:'STATE CODE'}
    ]
  }
  getColor(data){
    
    if(data.cod=='Y' && data.prepaid=='Y' ){
      return '#C8EEB5'
    }
    else if(data.cod=='y'&&data.prepaid=='N' || data.cod=='N' && data.prepaid=='Y'){
      return '#FEE9C1'
    }
    else if(data.cod=='N' && data.prepaid=='N'){
      return '#FCD8D8'
    }
    else{
      return 'white'
    }
  }
  getColor1(data){
    if(data.cod=='Y' && data.prepaid=='Y' ){
      return '#32BC08'
    }
    else if(data.cod=='y'&&data.prepaid=='N' && data.pickup=='Y' 
    || data.cod=='N' && data.prepaid=='Y' && data.pickup=='N' 
    || data.cod=='N' && data.prepaid=='Y' && data.pickup=='Y' 
    || data.cod=='y'&&data.prepaid=='N' && data.pickup=='N'){
      return '#eb9d01'
    }
    else if(data.cod=='N' && data.prepaid=='N'){
      return '#F10707'
    }
    else{
      return 'black'
    }
  }
  checkCourier(){
    console.log(this.pincode)
    this.validation=this.isValidPinCode(this.pincode)
    console.log(this.pincode.toString().length)
    if( this.pincode==undefined||this.pincode==null ||this.pincode.toString().length!=6){
      Swal.fire({
        toast: true,
        position: 'center',
        text: 'Please enter a valid pincode',
        showConfirmButton: false,
        timer: 3000,
        type: 'error',
      })
    }
    else{
      this.Apicall.getCourierServiceDetails(this.pincode).subscribe(res=>{
        console.log(res['detailOfServices'])
        if(res['status']==true){
          this.showTable=true
          this.completeData=res['detailOfServices']
          this.completeData.forEach(x=>x.cod=='Y')
        }
        
      })

    }
    
  }
   isValidPinCode(str) {
    // Regex to check valid
    // Pincode of India
    let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
 
    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }
 
    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return "true";
    }
    else {
        return "false";
    }
}
}
