import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/api.service'
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Paginator } from 'primeng/paginator';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { dashboardVarialbles, myVariables } from 'src/environments/environment'



@Component({
  selector: 'app-completedreceipt',
  templateUrl: './completedreceipt.component.html',
  styleUrls: ['./completedreceipt.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CompletedreceiptComponent implements OnInit {
  @ViewChild('pagin') paginator: Paginator;
  @ViewChild('calendar', undefined) private calendar: any;
  RequiredCompletedReceipt = {
    status: "Confirmed",
    warehouseId: localStorage.getItem('WarehouseID'),
    startDate: 'firstDate',
    endDate: 'today',
    pageNumber: 1,
    pageLimit: 10
  }
  cols = [];
  deliveryData: any;
  completed_receipt: any;
  Loader = true;
  pageNumber: any;

  check = false;

  public dateFilters: Date = new Date();
  SelectedDate: any;

  newCar: boolean;
  displayDialog: boolean;
  customerList = [];
  customerData: any;
  selectedCustomers: any;
  totalRecords: number;
  today: Date;
  firstDate: any;
  dateTime = new Date();
  rangeDates: any[];
  first: any;
  pagelinks: any;
  rows: any;
  row2: any;
  savedDates: any;
  date: any;
  constructor(private Apicall: ApiService, private router: Router, private datePipe: DatePipe) { }
  ngOnInit() {
    // myVariables.completedReceiptRowsPerPage=1,
    myVariables.completedDeliveryRowsPerPage = 1,
      myVariables.stagingReceiptRowsPerPage = 1,
      myVariables.StaockStatementRowsPerPage = 1
    myVariables.stagingReceiptPageNumber = 1,
      // myVariables.completedReceiptPageNumber=1,
      myVariables.completedDeliveryPageNumber = 1,
      myVariables.pendingDeliveriespageNumber = 1,
      myVariables.pendingDeliveriesRowsPerPage = 1,
      myVariables.pendingReceiptRowsPerPage = 1,
      myVariables.pendingReceiptPageNumber = 1,
      myVariables.grnPageNumber = 1,
      myVariables.grnRowsPerPage = 1,
      myVariables.returnGrnPageNumber = 1,
      myVariables.returnGrnRowsPerPage = 1,
      myVariables.dnRowsPerPage = 1,
      myVariables.dnPageNumber = 1,
      myVariables.shippingOrdersRowsPerPage = 1,
      myVariables.shippingOrdersPageNumber = 1,
      myVariables.StockStatementPageNumber = 1,
      myVariables.directChannelOrder = null,

      // sessionStorage.removeItem('compltdReceiptDate'),
      sessionStorage.removeItem('StagingReceiptDate'),
      sessionStorage.removeItem('PendingReceiptDate'),
      sessionStorage.removeItem('PendingDeliDate'),
      sessionStorage.removeItem('compltdDeliDates'),
      sessionStorage.removeItem('shippingOrdersDate'),
      sessionStorage.removeItem('grnReportsDates'),
      sessionStorage.removeItem('ReturnGrnReportsDates'),
      sessionStorage.removeItem('dnReportsDate'),
      sessionStorage.removeItem('active')

    this.dateTime.setDate(this.dateTime.getDate() + 0);
    if (myVariables.completedReceiptPageNumber != 1) {
      this.RequiredCompletedReceipt.pageNumber = myVariables.completedReceiptPageNumber

    }
    if (myVariables.completedReceiptRowsPerPage != 1) {
      this.RequiredCompletedReceipt.pageLimit = myVariables.completedReceiptRowsPerPage
    }
    if (dashboardVarialbles.IsCompletedRoute) {
      this.date = new Date()
      this.rangeDates = [this.date, this.date]
      this.GetallPendingStatus()
    } else {
      if (sessionStorage.getItem('compltdReceiptDate')) {
        this.savedDates = JSON.parse(sessionStorage.getItem('compltdReceiptDate'))
        let date = new Date(this.savedDates.startDate)
        console.log(date);
        let todayDate = new Date(this.savedDates.endDate)

        this.rangeDates = [date, todayDate];

        // console.log(date)
        // console.log(typeof (date))

        this.RequiredCompletedReceipt.startDate = this.datePipe.transform(date, 'yyyy-MM-dd')
        console.log(this.RequiredCompletedReceipt.startDate);

        this.RequiredCompletedReceipt.endDate = this.datePipe.transform(todayDate, 'yyyy-MM-dd')
        console.log(this.RequiredCompletedReceipt.endDate);
        this.GetallPendingStatus();

      }

      else {
        var _self = this;
        // console.log(this)
        this.today = new Date();
        this.firstDate = new Date();
        // this.firstDate.setMonth(this.today.getMonth());
        this.firstDate.setDate(this.firstDate.getDate() - 7);
        // console.log('today date', this.today)
        // console.log('first date', this.firstDate)
        // console.log(typeof (this.today))
        this.rangeDates = [this.firstDate, this.today];

        this.RequiredCompletedReceipt.startDate = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd')
        this.RequiredCompletedReceipt.endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd')
        // console.log(this.RequiredCompletedReceipt);

        this.GetallPendingStatus();



      }
    }

    localStorage.setItem('SelectedCustomerID', '')
    this.GetAllCustomer();
    this.cols = [
      { field: 'id', header: 'Receipt Request Id' },
      { field: 'businessName', header: 'Business Name' },
      { field: 'createdAt', header: 'Date' },
      { field: 'pickupDate', header: 'Expected Receipt Date' },
      { field: 'pickupTime', header: 'Expected Receipt Time' },
      { field: 'poNo', header: 'PO Number' },
      { field: 'grnNumber', header: 'GRN Number' },
      { field: 'status', header: 'Status' }
    ];
  }

  getColor(status) {
    switch (status) {
      case 'Completed':
        return '#58a846';
      case 'Cancelled':
        return 'red';
    }
  }
  GetallPendingStatus() {
    this.Loader = true;
    if (dashboardVarialbles.IsCompletedRoute) {
      this.RequiredCompletedReceipt.endDate = this.datePipe.transform(this.date, 'yyyy-MM-dd')
      this.RequiredCompletedReceipt.startDate = this.datePipe.transform(this.date, 'yyyy-MM-dd')
    }
    this.Apicall.getPendingReceipt(this.RequiredCompletedReceipt)
      .subscribe(
        res => {
          this.deliveryData = res.result;

          this.rows = this.deliveryData.length
          this.totalRecords = res['noOfPages'] * this.rows
          console.log("Completed receipt", this.totalRecords)
          this.rows = this.deliveryData.length

          console.log(this.rows);

          if (this.rows != 10 && this.rows != 25 && this.rows != 50 && this.rows != 75 && (this.row2)) {
            console.log(this.rows);

            console.log("not");
            this.rows = this.row2
            this.totalRecords = res.noOfPages * this.row2
          }
          else {
            console.log("yes");
            this.rows = myVariables.completedReceiptRowsPerPage

            this.totalRecords = res.noOfPages * this.rows

          }
          for (var i = 0; i < this.deliveryData.length; i++) {
            if (!this.deliveryData[i].pickupInvoice.length) {
              this.deliveryData[i]['invoiceNo'] = ''
              this.deliveryData[i]['invoiceDate'] = ''
              this.deliveryData[i]['poNo'] = ''
            }
            else {
              this.deliveryData[i]['invoiceNo'] = this.deliveryData[i].pickupInvoice[0].invoiceNumber
              this.deliveryData[i]['invoiceDate'] = this.deliveryData[i].pickupInvoice[0].invoiceDate
              this.deliveryData[i]['poNo'] = this.deliveryData[i].pickupInvoice[0].purchaseOrderNumber
            }
          }
          this.first = (this.rows * this.RequiredCompletedReceipt.pageNumber) - 1
          if (this.deliveryData.length === 0) {
            this.check = true
          } else {
            this.check = false
          }
          this.Loader = false;
        }
      )
  }
  search(data) {
    if (data.length >= 3) {
      const obj = {

        "warehouseId": this.RequiredCompletedReceipt.warehouseId,      //(not mandatory)
        "status": "Completed",   //(not mandatory, status can be “Completed’ or 					“Pending” by default it is “Pending” )
        "searchval": data, 	//(mandatory and at least 3 characters)
        "customerId": null 	//(not mandatory)

      }
      this.Apicall.searchReceiptRequest(obj).subscribe(res => {
        this.deliveryData = res['result'];
        this.rows = res['result'].length
        this.totalRecords = 1 * this.rows
        console.log(res)
        console.log("Pending Delivery ", this.deliveryData)
        if (this.rows != 10 && this.rows != 25 && this.rows != 50 && this.rows != 75 && (this.row2)) {
          console.log(this.rows);

          console.log("not");
          this.rows = this.row2
          // this.totalRecords = 1 * this.row2
          this.totalRecords = data['totalPages'] * this.row2
        }
        else {
          console.log("yes");
          this.rows = myVariables.completedReceiptRowsPerPage

          // this.totalRecords = 1 * this.rows
          this.totalRecords = data['totalPages'] * this.rows


        }
        if (this.deliveryData.length === 0) {
          this.check = true
          // console.log(this.check)
        } else {
          for (var i = 0; i < this.deliveryData.length; i++) {
            // console.log('i am running');
            // console.log(this.pendingData[i].purchaseOrderNumber)
            this.deliveryData[i]['poNo'] = this.deliveryData[i].purchaseOrderNumber
          }
          this.check = false
        }
      })
    }
    if (data.length == 0) {
      this.GetallPendingStatus();
    }

  }


  onDateSelect(event) {
    console.log(event)
    var _self = this;
    var s = _self.rangeDates[0].getTime();
    var e;
    if (_self.rangeDates[0] && _self.rangeDates[1]) {
      e = _self.rangeDates[1].getTime() + 86400000;

      // console.log(_self.rangeDates[0], _self.rangeDates[1])
      // console.log('if part is hitting')
      // console.log(_self.rangeDates[0])
      // console.log(typeof (_self.rangeDates[0]));

      // sessionStorage.setItem("firstDate", JSON.stringify(_self.rangeDates[0]));
      // sessionStorage.setItem("lastDate", JSON.stringify(_self.rangeDates[1]));
      const obj = {
        "startDate": _self.rangeDates[0],
        "endDate": _self.rangeDates[1]
      }
      sessionStorage.setItem("compltdReceiptDate", JSON.stringify(obj))


      this.RequiredCompletedReceipt.startDate = this.formatDate(_self.rangeDates[0])
      this.RequiredCompletedReceipt.endDate = this.formatDate(_self.rangeDates[1])
      this.RequiredCompletedReceipt.pageNumber = 1
      myVariables.completedReceiptPageNumber = 1
      this.paginator.changePage(0)

      this.GetallPendingStatus();
    } else {
      // console.log('else part is hitting')
      e = s + 86400000;
    }
    if (this.rangeDates[1]) { // If second date is selected
      console.log(this.calendar);

      this.calendar.overlayVisible = false;
    }
  }
  defaultDate() {
    console.log('defaultdate');
    if (this.rangeDates[1] == null) {
      if (sessionStorage.getItem('compltdReceiptDate')) {
        this.savedDates = (JSON.parse(sessionStorage.getItem('compltdReceiptDate')))
        this.rangeDates = [new Date(this.savedDates.startDate), new Date(this.savedDates.endDate)]
      }
      else {
        this.rangeDates = [this.firstDate, this.today]
      }
    }

  }
  formatDate(date) {


    // console.log(typeof (date))
    // console.log('hi')
    // console.log(date)
    let swap = new Date(date)
    //  console.log(typeof (swap))
    // console.log(swap)
    let month = swap.getMonth() + 1;
    // console.log(month)
    let day = swap.getDate();
    // console.log(day)
    // console.log('full year', swap.getFullYear)
    let newMonth: String
    let newDay: String
    if (month < 10) {
      newMonth = '0' + month;
    } else {
      newMonth = month.toString()
    }
    if (day < 10) {
      newDay = '0' + day;
    }
    else {
      newDay = day.toString();
    }
    return swap.getFullYear() + '-' + newMonth + '-' + newDay;
  }

  downloadStmtGRN() {
    this.Apicall.downloadReportGrn(this.RequiredCompletedReceipt.startDate, this.RequiredCompletedReceipt.endDate)
      .subscribe({

        next: (response: any) => {
          console.log(response)
          if (response.status == 200) {
            let fileName = 'ConsolidateGRN';
            const contentDisposition = response.headers.get('content-disposition');
            if (contentDisposition) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }

            const fileContent = response.body;

            saveAs(fileContent, fileName);
          }
        },
        error: (error) => {

          console.log({ error });
          Swal.fire({
            toast: true,
            // title: "Error In Downloading The Report",
            title: error,
            type: 'warning',
            showConfirmButton: true
          })


        }
      });
  }

  onRowSelect(event) {
    this.newCar = false;
    this.displayDialog = true;
  }

  GetAllCustomer() {
    this.Apicall.getDeliveryStatus()
      .subscribe(
        res => {
          console.log("Response", res.result)
          this.customerData = res.result;
          for (var i = 0; i < this.customerData.length; i++) {
            this.customerList.push(this.customerData[i])
          }
          // this.customerList = [{ fullname: "Sudhar", id: 1},
          // { fullname: "Kanna", id: 2},
          // { fullname: "Aswath", id: 3},
          // { fullname: "Anish", id: 4},
          // { fullname: "Selva", id: 5},
          // { fullname: "Gowtham", id: 6},]
          console.log("Result", this.customerList)
        }
      )
  }

  selectedCustomer(event) {
    localStorage.setItem('SelectedCustomerID', this.selectedCustomers.id)

    if (localStorage.getItem('SelectedCustomerID') !== '') {
      this.downloadStmtGRN()
      this.displayDialog = false;
    } else {
      this.displayDialog = true;
    }

    console.log("SELECTED CUSTOMERS", this.selectedCustomers)
  }


  paginate(event) {
    // console.log(event)
    // // this.RequiredDetails.pageLimit=event.rows
    // // this.pageNumber = event.page + 1;
    // // this.RequiredDetails.pageNumber = this.pageNumber
    // console.log(event.rows, this.RequiredCompletedReceipt.pageLimit)

    // if (this.RequiredCompletedReceipt.pageLimit == event.rows) {
    //   this.RequiredCompletedReceipt.pageNumber = event.page + 1


    // } else if (event.rows == 10) {
    //   this.RequiredCompletedReceipt.pageLimit = 10
    //   this.RequiredCompletedReceipt.pageNumber = event.page


    //   this.paginator.changePage(0)

    // } else if (event.rows == 25) {
    //   this.RequiredCompletedReceipt.pageLimit = 25
    //   this.RequiredCompletedReceipt.pageNumber = event.page



    //   this.paginator.changePage(0)

    // } else if (event.rows == 50) {
    //   this.RequiredCompletedReceipt.pageLimit = 50
    //   this.RequiredCompletedReceipt.pageNumber = event.page

    //   this.paginator.changePage(0)

    // }
    // else {

    //   // this.RequiredPending.pageLimit=event.rows 
    //   this.RequiredCompletedReceipt.pageNumber = event.page + 1
    //   // this.paginator.changePage(0)
    // }


    // console.log(this.RequiredCompletedReceipt)
    // this.GetallPendingStatus()

    this.row2 = event.rows
    console.log(event.rows, this.rows);



    // this.RequiredDetails.pageLimit=event.rows
    // this.pageNumber = event.page + 1;
    // this.RequiredDetails.pageNumber = this.pageNumber
    console.log(event.rows, this.RequiredCompletedReceipt.pageLimit)

    if (this.RequiredCompletedReceipt.pageLimit == event.rows) {
      this.RequiredCompletedReceipt.pageNumber = event.page + 1
      // sessionStorage.setItem('completedReceieptPageNo.',JSON.stringify(this.RequiredCompletedReceipt.pageNumber))
      myVariables.completedReceiptPageNumber = this.RequiredCompletedReceipt.pageNumber

    } else if (event.rows == 10) {
      this.RequiredCompletedReceipt.pageLimit = 10
      this.RequiredCompletedReceipt.pageNumber = event.page
      // sessionStorage.setItem('compleDeliRowsPerPage',JSON.stringify(this.RequiredCompletedReceipt.pageLimit))
      myVariables.completedReceiptRowsPerPage = this.RequiredCompletedReceipt.pageLimit


      this.paginator.changePage(0)

    }
    else if (event.rows == 25) {
      this.RequiredCompletedReceipt.pageLimit = 25
      this.RequiredCompletedReceipt.pageNumber = event.page
      // sessionStorage.setItem('compleDeliRowsPerPage',JSON.stringify(this.RequiredCompletedReceipt.pageLimit))
      myVariables.completedReceiptRowsPerPage = this.RequiredCompletedReceipt.pageLimit



      this.paginator.changePage(0)

    } else if (event.rows == 50) {
      this.RequiredCompletedReceipt.pageLimit = 50
      this.RequiredCompletedReceipt.pageNumber = event.page
      // sessionStorage.setItem('compleDeliRowsPerPage',JSON.stringify(this.RequiredCompletedReceipt.pageLimit))
      myVariables.completedReceiptRowsPerPage = this.RequiredCompletedReceipt.pageLimit

      this.paginator.changePage(0)

    }
    else if (event.rows == 75) {
      this.RequiredCompletedReceipt.pageLimit = 75
      this.RequiredCompletedReceipt.pageNumber = event.page
      // sessionStorage.setItem('compleDeliRowsPerPage',JSON.stringify(this.RequiredCompletedReceipt.pageLimit))
      myVariables.completedReceiptRowsPerPage = this.RequiredCompletedReceipt.pageLimit

      this.paginator.changePage(0)

    }
    else {

      // this.RequiredCompletedReceipt.pageLimit=event.rows 
      this.RequiredCompletedReceipt.pageNumber = event.page + 1
      myVariables.completedReceiptPageNumber = this.RequiredCompletedReceipt.pageNumber

      // sessionStorage.setItem('completedDeliveryPageNo.',JSON.stringify(this.RequiredCompletedReceipt.pageNumber))

      // this.first=event.page
      // console.log(event);
      // console.log(this.first);


      // this.paginator.changePage(0)

    }


    console.log(this.RequiredCompletedReceipt)
    this.GetallPendingStatus()

  }

  CompletedReceipt(rowData) {
    this.completed_receipt = rowData
    localStorage.setItem('completed_receipt_id', this.completed_receipt.id)
    // console.log("ROW DATA PICKING", this.pending_receipt)
    this.router.navigate(['/Viewcompletedreceipt'])
  }
}
