import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError, Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public liveServer = 'https://business.city-link.co.in/storage'
  // public testServer = 'http://52.170.134.229/storage';
  public testServer = 'http://52.140.121.136/storage';
  public NewtestServer = 'http://52.170.134.229/storageRbac';
  public testTestserver = 'http://52.140.121.136/storageledger';
  public testServer2 = 'http://52.140.121.136/storagereports';
  public localServer = 'http://localhost:4000'

  public rootPath = this.liveServer


  private loginUrl = this.rootPath + '/auth/signin';  /* Completed */
  private refreshTokenUrl = this.rootPath + '/auth/validate'; /* Completed */
  private forgotPwdUrl = this.rootPath + '/otp/generate';  /* Completed */
  private OTPUrl = this.rootPath + '/otp/verify';  /* Completed */
  private resendOTPUrl = this.rootPath + '/otp/resend';  /* Completed */
  private updateUrl = this.rootPath + '/user';  /* Completed */
  private logOutUrl = this.rootPath + '/auth/signout'; /* Complete */

  private warehouseDetailsUrl = this.rootPath + '/warehouse/user'; /* Completed */

  private configDetailsUrl = this.rootPath + '/configuration/warehouse';

  private dashboardUrl = this.rootPath + '/dashboard/warehouse'; /* Completed */

  private ReceiptHistoryUrl = this.rootPath + '/pickupRequest/status';
  private deliveryHistoryUrl = this.rootPath + '/delivery/request/status';
  private getInfoReceiptUrl = this.rootPath + '/dashboard/warehouse/receipt/count'
  private getInfoDeliveryUrl = this.rootPath + '/dashboard/warehouse/deliveries'
  private locationDetailsUrl = this.rootPath + '/locations/fullfilled/status/warehouseId/'
  private goodAndDamagedSkuUrl = this.rootPath + '/product/avilable/quantity/warehouseId/'
  private warehouseUtillizationUrl = this.rootPath + '/transactions/bin/utilization'
  private mostOrderPlacedUrl = this.rootPath + '/shipment/most/order'
  private unfilledOrdersUrl = this.rootPath + '/dashboard/unfulfillable'
  private createReceiptUrl = this.rootPath + '/pickupRequest';  /* Completed */

  // private returnGRNUrl = 'http://52.170.134.229/storage_return_grn/pickupRequest/return';
  private returnGRNUrl = this.rootPath + '/pickupRequest/return';

  private autoComAddMaterialUrl = this.rootPath + '/material/search/by/sku';  /* Completed */
  private customerListUrl = this.rootPath + '/customer/warehouse'; /* Completed */
  private bookingListUrl = this.rootPath + '/booking/warehouse';   /* Completed */

  // private deliveryOrderNumberUrl = 'http://52.170.134.229/storage_return_grn/deliveryorder/customer';
  private deliveryOrderNumberUrl = this.rootPath + '/deliveryorder/customer';


  private endCustomerDetailsUrl = this.rootPath + '/end/customer';

  private addMaterialUrlOnePhase = this.rootPath + '/goodsReciept';  /* Completed */

  private addMaterialUrlTwoPhase = this.rootPath + '/goodsReciept/twophase';

  private movingCompleteReceiptUrl = this.rootPath + '/goodsReciept/twophase/complete';

  private viewCustomersUrl = this.rootPath + '/customer/warehouse';   /* Completed */
  private viewSelectedCustomerUrl = this.rootPath + '/customer';   /* Completed */

  private blockedInventoryReporturl = this.rootPath + '/deliveryitem/block/inventory/report'
  private inventorySyncUrl = 'https://business.city-link.co.in/storage/materialstodeliver/ecommerce/catalog/inventory';
  private stockStmtUrl = this.rootPath + '/stock/statement/date';  /* Completed */
  private sendingSohUrl = this.rootPath + '/stock/statement/sendemail'; /* Completed */
  private binSohUrl = this.rootPath + '/transactions/bin/soh/download'

  private productbasedMaterialApi = this.rootPath + '/stock/statement/materials'; /* Completed */

  private ledgerDataUrl = this.rootPath + '/search/product/all';  /* Completed */
  private ledgerMaterialUrl = this.rootPath + '/stock/ledger/warehouse';  /* Completed */

  private getReceiptUrl = this.rootPath + '/pickupRequest/by/warehouse'; /* Completed */
  // private pendingDeliveriesUrl = this.rootPath + '/pickupRequest/by/warehouse';  /* Completed */

  // private completeReceiptUrl = this.rootPath + '/delivery/request/by/warehouse'; /* Completed */
  private getDeliveryUrl = this.rootPath + '/delivery/request/by/warehouse'; /* Completed */

  private viewSelectedReceiptUrl = this.rootPath + '/pickupRequest';   /* Completed */

  private viewSelectedDeliverytUrl = this.rootPath + '/delivery/request';  /* Completed */
  private moveToStageUrl = this.rootPath + '/materialstodeliver';  /* Completed */

  private gcnUrl = this.rootPath + '/materialstodeliver';  /* Completed */

  private skuMasterUrl = this.rootPath + '/material/sku/master';   /* Completed */

  private materialMasterSearchApi = this.rootPath + '/material/sku/master';

  private generateMaterialCodeUrl = this.rootPath + '/master/sku';   /* Completed */

  private countryCodeUrl = this.rootPath + '/Country'; /* Completed */

  private currenciesUrl = this.rootPath + '/currency'; /* Completed */

  private supplierInfoApi = this.rootPath + '/end/customer'; /* Completed */

  private uploadDocUrl = this.rootPath + '/material/add/image'; /* Completed */

  private searchByLocation = this.rootPath + '/material/getmaterialbylocation'; /* Completed */

  private searchByProduct = this.rootPath + '/material/location'; /* Completed */

  private getAllLocation = this.rootPath + '/material/getallmateriallocation'; /* Completed */

  private cartonCodeValidateUrl = this.rootPath + '/goodsReciept/cartoncode/validate';

  /*----------------grn details--------------*/

  private grndetails = this.rootPath + '/transactions/grn/simple'
  private returngrndetails = this.rootPath + '/transactions/grn/returns/simple'

  /*----------------dn details--------------*/

  private dnDetails = this.rootPath + '/transactions/dn/simple'
  //  private returnDnDetails=this.rootPath + '/transactions/dn/returns/simple'
  private getAllchannelUrl = this.rootPath + '/transactions/dn/channel'



  /*----------------------Location transfer----------------------------*/
  // private transferLocationUrl = this.rootPath + '/material/location/transfer';
  private transferLocationUrl = this.rootPath + '/material/location/transfer/onetomany';
  private sourceLocationUrl = this.rootPath + '/locationledger/product/location/name/'
  private existingLocationUrl = this.rootPath + '/locationledger/location/product/details'
  private locationSearchUrl = this.rootPath + '/locations/search/warehouseid'
  private locationTransferSummaryUrl = this.rootPath + '/material/location/transfer/summaryid/'

  /*------------------------deliver request------------------------------*/
  private generateInvoiceUrl = this.rootPath + '/materialstodeliver/ecommerce/generate/invoice';
  private completeMenifestUrl = this.rootPath + '/materialstodeliver/ecommerce/manifest'
  private bulkGenerateInvoiceUrl = this.rootPath + '/materialstodeliver/ecommerce/generate/invoice/bulk'
  private bulkCompleteManifestUrl = this.rootPath + '/materialstodeliver/ecommerce/manifest/bulk'
  private shippingLableUrl = this.rootPath + '/shipment/print/label'
  private manifestLableUrl = this.rootPath + '/shipment/print/manifest'
  private cancelShipmentUrl = this.rootPath + '/shipment/cancel'
  private allocateShipUrl = this.rootPath + '/materialstodeliver/ecommerce/allocate/shipping'
  private createLabelUrl = this.rootPath + '/materialstodeliver/ecommerce/create/invoice'
  private getWarehouseByCustId = this.rootPath + '/warehouse/customerid/'
  private orderTransferUrl = this.rootPath + '/materialstodeliver/order/transfer'


  /*--------------------------------search implementaion----------------------*/

  private searchProductUrl = this.rootPath + '/search/product'
  private searchDeliveryRequestUrl = this.rootPath + '/search/deliveryorder'
  private searchReceiptUrl = this.rootPath + '/search/pickup/material'



  /*-------------------------------Create shipment ------------------------------------*/

  // private getRateUrl=this.rootPath + '/shipment/delivery/quote'
  private getRateUrl = this.rootPath + '/shipment/delivery/quote/filter'
  private completeShipmenturl = this.rootPath + '/shipment'


  /*-------------------------------location type ------------------------------------*/

  private getAllLocationType = this.rootPath + '/locations/type'
  private getLocationTypeById = this.rootPath + '/locations/type'
  private addLocationType = this.rootPath + '/locations/type';
  private updateLocationType = this.rootPath + '/locations/type';
  private deletelocaType = this.rootPath + '/locations/type';

  /*---------------------------Location Master-----------------------------------*/

  private getAllLocationMaster = this.rootPath + '/locations/warehouseid'
  private getlocationMasterById = this.rootPath + '/locations';
  private addLocationMaster = this.rootPath + '/locations';
  private updateLocationMaster = this.rootPath + '/locations';
  private deleteLocaMaster = this.rootPath + '/locations';
  private sohBinDataUrl = this.rootPath + '/transactions/bin/soh'


  /*------------------------shipping Order-----------------------------------*/

  private getShippingOrdersurl = this.rootPath + '/shipment/orders'

  /*-------------------check Courier Services-------------------*/
  private getCourierServiceUrl = this.rootPath + `/shipment/pincode?pincode`


  /*--------------------------tract order----------------------------------------*/

  public orderTrackingUrl = this.rootPath + '/shipment/tracking/order';

  /*-----------------------EXCEL DOWNLOAD APIs--------------------------------*/


  private downloadStmt = this.rootPath + '/stock/statement/download/date'; /* Completed */
  private downloadStmtLedger = this.rootPath + '/stock/ledger/download/warehouseAndCustomerAndDate'; /* Completed */
  private downloadStockTransaction = this.rootPath + '/stock/transaction/download'; /* Completed */
  private downloadStmtGRN = this.rootPath + '/goodsReciept/download/customer'; /* Completed */
  private downloadStmtDN = this.rootPath + '/delivery/request/download/delivery/customer';/* Completed */


  /*-------------------------END EXCEL------------------------------------------*/

  private getOrderdetailsApi = this.rootPath + '/delivery/request/ordernumber'; /* Completed */
  private orderDocumentNumberAPI = this.rootPath + '/deliverymaterials/orders/partial'; /* Completed */
  private partialOrderApi = this.rootPath + '/deliverymaterials/orders/partial/date/start'; /* Pending */

  private productInfoApi = this.rootPath + '/search/product';

  decodedToken: any;
  helper = new JwtHelperService();

  constructor(private http: HttpClient) { }

  /*--------------------------------------LOGIN_FORM-----------------------------------*/

  getToken() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token');
    return helper.isTokenExpired(token);
    // This condition check Isexpired (true or false)
    // console.log("TOKEN GETTING", token)
    // return token;
  }

  refreshTokenExpired() {
    const helper = new JwtHelperService();
    const refreshToken = localStorage.getItem('Refresh_token')
    return helper.isTokenExpired(refreshToken);
    // return token;
  }

  getLoginApi(User) {
    return this.http.post<any>(this.loginUrl, User)
  }

  getForgotPwdApi(User) {
    return this.http.post<any>(this.forgotPwdUrl, User)
  }

  getOtpApi(User) {
    return this.http.post<any>(this.OTPUrl, User)
  }

  getOtpResend(User) {
    return this.http.post<any>(this.resendOTPUrl, User)
  }

  getUpdateUser(User) {
    return this.http.put(`${this.updateUrl + "/" + localStorage.getItem('UserID')}`, User);
  }

  getRefreshToken(User) {
    return this.http.post<any>(this.refreshTokenUrl, User)
  }


  //----------------------------------------WAREHOUSE_DETAILS------------------------------------//

  getWarehouseDetails() {
    return this.http.get<any>(this.warehouseDetailsUrl + "/" + localStorage.getItem('UserID'))
  }

  getConfigDetails() {
    var t = localStorage.getItem('token');

    var headers_object = new HttpHeaders();
    headers_object.append('Content-Type', 'application/json');
    headers_object.append("Authorization", "Bearer " + t);

    const httpOptions = {
      headers: headers_object
    };
    return this.http.get<any>(this.configDetailsUrl + '/' + localStorage.getItem('WarehouseID'), httpOptions)
  }
  //---------------------------------------DASHBOARD---------------------------------------//

  getDashboardApi(User) {
    return this.http.post<any>(this.dashboardUrl, User)
  }
  receiptHistory(data) {
    return this.http.post(this.ReceiptHistoryUrl, data)
  }
  deliveryHistory(data) {
    return this.http.post(this.deliveryHistoryUrl, data)
  }
  getInfoReceptChart(data) {
    return this.http.post(this.getInfoReceiptUrl, data)
  }
  getInfoDeliveryChart(data) {
    return this.http.post(this.getInfoDeliveryUrl, data)
  }
  locationDetails() {
    return this.http.get(this.locationDetailsUrl + localStorage.getItem('WarehouseID'))
  }
  goodAndDamagedSku() {
    return this.http.get(this.goodAndDamagedSkuUrl + localStorage.getItem('WarehouseID'))
  }
  warehouseUtillization(data) {
    return this.http.post(this.warehouseUtillizationUrl, data)
  }
  mostOrdePlaced(data) {
    return this.http.post(this.mostOrderPlacedUrl, data)
  }
  UnfilledOrders(data) {
    return this.http.post(this.unfilledOrdersUrl, data)
  }

  //-----------------------------------------CREATE_RECEIPT----------------------------------//

  getCreateReceipt(User) {
    return this.http.post<any>(this.createReceiptUrl, User)
  }

  returnGrnCreation(User) {
    return this.http.post<any>(this.returnGRNUrl, User)
  }

  getSkuMaterial(User) {
    return this.http.post<any>(this.autoComAddMaterialUrl, User)
  }

  getCustomerList() {
    return this.http.get<any>(this.customerListUrl + "/" + localStorage.getItem('WarehouseID'))
  }

  getBookingList() {
    return this.http.get<any>(this.bookingListUrl + "/" + localStorage.getItem('WarehouseID') + "/" + "customer" + "/" + localStorage.getItem('CusID'))
  }

  getDeliveryOrderNumbers() {
    return this.http.get<any>(this.deliveryOrderNumberUrl + "/" + localStorage.getItem('CusID'))
  }

  getEndCustomerDetails() {
    return this.http.get<any>(this.endCustomerDetailsUrl + "/" + localStorage.getItem('CusID'))
  }

  //----------------------------------------ADD_MATERIAL--------------------------------------//

  getPOSTAddMaterial(User) {
    return this.http.post<any>(this.addMaterialUrlTwoPhase, User)
  }

  getPUTAddMaterial(User) {
    return this.http.post<any>(this.addMaterialUrlOnePhase, User)
  }
  getPutAddMaterial1twoPhase(user) {
    return this.http.put<any>(this.addMaterialUrlTwoPhase, user)
  }

  moveToComplete(User) {
    return this.http.post<any>(this.movingCompleteReceiptUrl, User)
  }

  // CancelMaterial(User){
  //   return this.
  // }

  //-------------------------------------------VIEW_ALL_CUSTOMERS---------------------------//
  getDeliveryStatus() {
    return this.http.get<any>(this.viewCustomersUrl + "/" + localStorage.getItem('WarehouseID'))
  }
  getPendingDelivery() {
    return this.http.get<any>(this.viewSelectedCustomerUrl + "/" + localStorage.getItem('ViewCustomer_id'))
  }
  inventorySync(data) {
    return this.http.post<any>(this.inventorySyncUrl, data)
  }
  blockedInventoryReport(data) {
    // return this.http.post<any>(this.blockedInventoryReporturl, data)
    return this.http.post<HttpResponse<any>>(this.blockedInventoryReporturl, data, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });
  }

  //------------------------------------------STOCK_STATEMENT---------------------------------//

  getStockStmt(User) {
    // return this.http.get<any>(this.stockStmtUrl+"?token="+User.token)
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('token')
      })
    }
    return this.http.post<any>(this.stockStmtUrl + "/" +
      (localStorage.getItem('SDate') || localStorage.getItem('DefaultDate')) +
      "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" +
      "customer" + "/" + localStorage.getItem('ViewCustomer_id'), User, httpOptions)
  }

  getAllMaterials(User) {
    return this.http.post<any>(this.productbasedMaterialApi, User)
  }

  sendSohApi(User) {
    return this.http.post<any>(this.sendingSohUrl, User)
  }
  binSoh(data): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.binSohUrl, data, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    })
  }
  // downloadReportDn(startDate, endDate): Observable<HttpResponse<any>> {
  //   var url = this.downloadStmtDN + "/" + localStorage.getItem("SelectedCustomerID") + "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" + startDate + "/" + endDate
  //   return this.http.get<HttpResponse<any>>(url, {
  //     responseType: "blob" as 'json',
  //     observe: 'response' as 'body'
  //   });
  // }

  /*------------------------------------STOCK LEDGER---------------------------------------*/
  getLedgerUserId(User) {
    return this.http.post<any>(this.ledgerDataUrl, User)
    // return this.http.get(this.ledgerDataUrl, User)
  }
  getLedgerMaterial(User) {
    return this.http.post<any>(this.ledgerMaterialUrl, User)
  }

  //---------------------------------------LOCATION TYPE-------------------------------//
  getLocationType() {
    return this.http.get<any>(this.getAllLocationType)
  }
  getLocaTypeById(id) {
    return this.http.get<any>(this.getLocationTypeById + "/" + id)
  }
  addLocaType(data) {
    console.log('api post data', data)
    return this.http.post<any>(this.addLocationType, data)
  }
  updatelocaType(data) {
    console.log('api put data', data)
    return this.http.put(this.updateLocationType, data)
  }
  deleteLocationType(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('token')
      })
    }
    // console.log( 'api delete data',id)
    return this.http.delete(this.deletelocaType + "/" + id)
  }

  //--------------------------------------Location Master---------------------------------//

  getLocationMaster(data) {
    return this.http.get(this.getAllLocationMaster + "/" + localStorage.getItem('WarehouseID') + '?' + 'pagenumber' + "=" + data.pageNumber + '&' + 'pagelimit' + '=' + data.pageLimit)
  }
  getExistingLocation() {
    return this.http.get(this.getAllLocationMaster + "/" + localStorage.getItem('WarehouseID'))
  }
  addLocaMaster(data) {
    console.log('api post data', data)
    return this.http.post<any>(this.addLocationMaster, data)
  }
  updatelocaMaster(data) {
    console.log('api put data', data)
    return this.http.put(this.updateLocationMaster, data)
  }
  deleteLocationMaster(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('token')
      })
    }
    // console.log( 'api delete data',id)
    return this.http.delete(this.deleteLocaMaster + "/" + id)
  }
  SOHBinData(data) {
    return this.http.post(this.sohBinDataUrl, data)
  }

  //-------------------------------------------Courier Service availability------------//

  getCourierServiceDetails(id) {
    return this.http.get(this.getCourierServiceUrl + "=" + `${id}`)
  }

  //---------------------------------Tract order--------------------//

  geTrackingDetails(data) {
    return this.http.post(this.orderTrackingUrl, data)
  }

  //--------------------------------------------PENDING STATUS---------------------------//
  getPendingReceipt(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('token')
      })
    }
    return this.http.post<any>(this.getReceiptUrl, User, httpOptions)
  }
  getWarehouseBycustId(data) {
    return this.http.get(this.getWarehouseByCustId + data)
  }
  orderTransfer(data) {
    return this.http.post(this.orderTransferUrl, data)
  }
  // getPendingDeliveries(User) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "x-access-token": localStorage.getItem('token')
  //     })
  //   }
  //   return this.http.post<any>(this.pendingDeliveriesUrl, User, httpOptions)
  // }

  //---------------------------------------------COMPLETED_ STATUS--------------------------//
  // getCompletedReceipt(User) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "x-access-token": localStorage.getItem('token')
  //     })
  //   }
  //   return this.http.post<any>(this.completeReceiptUrl, User, httpOptions)
  // }

  getCOmpletedDeliveries(User) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('token')
      })
    }
    return this.http.post<any>(this.getDeliveryUrl, User, httpOptions)
  }


  getPartialDetails() {
    return this.http.get<any>(this.partialOrderApi + "/" + localStorage.getItem('StartDateExcel') + "/" + "end" + "/" + localStorage.getItem('EndDateExcel') + "/" + "customer" + "/" + localStorage.getItem("Cus_ID"))
  }

  getViewSelectedReceipt(): Observable<any> {
    return this.http.get<any>(this.viewSelectedReceiptUrl + "/" + localStorage.getItem('pending_receipt_id'))
  }
  CartonCodeValidate(data) {
    return this.http.post(this.cartonCodeValidateUrl, data)
  }

  getViewCompletedReceipt(): Observable<any> {
    return this.http.get<any>(this.viewSelectedReceiptUrl + "/" + localStorage.getItem('completed_receipt_id'))
  }


  //--------------------------------------------COMPLETE DELIVERY-------------------------// 

  getViewCompletedDelivery(): Observable<any> {
    return this.http.get<any>(this.viewSelectedDeliverytUrl + "/" + localStorage.getItem('completed_delivery_id'))
  }

  getAlldeliverydetails() {
    return this.http.get<any>(this.getOrderdetailsApi + "/" + localStorage.getItem('OrderNumber'))
  }

  getorderNumberDetails() {
    return this.http.get<any>(this.orderDocumentNumberAPI + "/" + localStorage.getItem('OrderDocNumber'))
  }

  getViewSelectedDelivery(): Observable<any> {
    return this.http.get<any>(this.viewSelectedDeliverytUrl + "/" + localStorage.getItem('pending_delivery_id'))
  }

  getMoveToStage(User) {
    return this.http.post<any>(this.moveToStageUrl, User)
  }
  generateInvoiceAndLabel(data) {
    console.log(data)
    console.log(data.customerId)
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });
    return this.http.post(this.generateInvoiceUrl, data, { headers: headers })


  }
  BulkGenerateInvoiceAndLabel(data) {
    console.log(data)
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });
    return this.http.post(this.bulkGenerateInvoiceUrl, data, { headers: headers })

  }
  completeShipment(data) {

    return this.http.post(this.completeShipmenturl, data)
  }
  allocateShipping(data) {
    return this.http.post(this.allocateShipUrl, data)
  }
  createLabel(data) {
    return this.http.post(this.createLabelUrl, data)
  }

  shippingLabelLink(data) {
    return this.http.put(this.shippingLableUrl, data)
  }
  manifestLbelLink(data) {
    return this.http.put(this.manifestLableUrl, data)
  }
  BulkcompleteManifest(data) {
    console.log(data)
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json'
      });
    return this.http.post(this.bulkCompleteManifestUrl, data, { headers: headers })

  }
  completeMinifest(data) {
    console.log(data)
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem("token")

      }
    );
    return this.http.post(this.completeMenifestUrl, data, { headers: headers })
  }
  cancelShipment(data) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem("token")

      }
    );
    return this.http.post(this.cancelShipmentUrl, data, { headers: headers })
  }

  //----------------------------------grn details --------------------------

  getGrnDetails(data, customerId) {
    return this.http.get(this.grndetails + '?' + 'customerId=' + customerId + '&warehouseId=' + localStorage.getItem('WarehouseID') + '&endDate=' + data.endDate + '&startDate=' + data.startDate
      + '&pageLimit=' + data.pageLimit + '&pageNumber=' + data.pageNumber)
  }

  getreturnGrnDetails(data, customerId) {
    return this.http.get(this.returngrndetails + '?' + 'customerId=' + customerId + '&warehouseId=' + localStorage.getItem('WarehouseID')
      + '&endDate=' + data.endDate + '&startDate=' + data.startDate + '&pageLimit=' + data.pageLimit + '&pageNumber=' + data.pageNumber)
  }

  DownloadReportGRNdet(data, id): Observable<HttpResponse<any>> {
    var url1 = this.rootPath + '/transactions/grn/download' + "?" + '&customerId=' + id + '&warehouseId='
      + localStorage.getItem('WarehouseID') + '&startDate=' + data.startDate + '&endDate=' + data.endDate
    return this.http.get<HttpResponse<any>>(url1, {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    })
  }

  grnNumberInformation() {
    return this.http.get<any>(this.rootPath + '/transactions/grn' + "/" + localStorage.getItem('grn_id'))
  }


  //-------------------------------------------Dn details----------------------

  getDnDetails(data, customerId) {
    return this.http.get(this.dnDetails + '?' + 'customerId=' + customerId + '&warehouseId=' + localStorage.getItem('WarehouseID') + '&endDate=' + data.endDate + '&startDate=' + data.startDate
      + '&pageLimit=' + data.pageLimit + '&pageNumber=' + data.pageNumber + '&channel=' + data.channel)
  }

  // getreturnDnDetails(data,customerId){
  //  return this.http.get(this.returnDnDetails + '?'+'customerId=' + customerId + '&warehouseId=' + localStorage.getItem('WarehouseID') 
  //  + '&endDate=' + data.endDate  + '&startDate=' + data.startDate    + '&pageLimit=' +data.pageLimit  + '&pageNumber=' +data.pageNumber)
  // }

  DownloadReportDndet(data, id): Observable<HttpResponse<any>> {
    var url1 = this.rootPath + '/transactions/dn/download' + "?" + '&customerId=' + id + '&warehouseId='
      + localStorage.getItem('WarehouseID') + '&startDate=' + data.startDate + '&endDate=' + data.endDate
    return this.http.get<HttpResponse<any>>(url1, {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body'
    })
  }
  getAllChannel(data) {
    return this.http.get(this.getAllchannelUrl + '/' + data)
  }

  DnNumberInformation() {
    return this.http.get<any>(this.rootPath + '/transactions/dn' + "/" + localStorage.getItem('dn_id'))
  }

  getChannellist(id) {
    return this.http.get(this.rootPath + '/transactions/dn/channel' + "/" + id)
  }
  //-----------------------------------------create shipment-------------------------

  getRate(data) {
    return this.http.post(this.getRateUrl, data)
  }
  createShipment(data) {
    return this.http.post(this.completeShipmenturl, data)
  }

  //----------------------------------------Shipping Orders-------------------------------

  getAllShippingOrders(data) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem("token")

      }
    );
    return this.http.post(this.getShippingOrdersurl, data, { headers: headers })
  }

  //----------------------------------------GENRATE GCN-------------------------------
  getGcnNumber(User) {
    return this.http.put<any>(`${this.gcnUrl}`, User)
  }
  getLogOutApi() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token")
      })
    }
    return this.http.post<any>(this.logOutUrl, {}, httpOptions)
  }

  //---------------------------------MATERIAL_MASTER-------------------------------------//

  getSKUMaster() {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-access-token": localStorage.getItem('token')
      })
    }
    return this.http.get<any>(this.skuMasterUrl + "/" + localStorage.getItem('ViewCustomer_id') + "/" + localStorage.getItem('PageNo'), httpOptions)
  }

  getMaterialMasterProduct(User) {
    return this.http.post<any>(this.materialMasterSearchApi + "/" + localStorage.getItem('ViewCustomer_id') + "/" + localStorage.getItem('Selected_Product'), User)
  }

  //--------------------------------GENERATING MATERIAL CODE-----------------------------//
  getMaterialCode(User) {
    return this.http.post<any>(this.generateMaterialCodeUrl, User)
  }

  //------------------------------COUNTRY CODE------------------------------//

  countryCode() {
    return this.http.get<any>(this.countryCodeUrl)
  }

  getCurrencies() {
    return this.http.get<any>(this.currenciesUrl)
  }

  getSupplierInfo() {
    return this.http.get<any>(this.supplierInfoApi + "/" + localStorage.getItem('CusID'))
  }

  //----------------------------------search implementation----------------------------//
  searchDeliveryRequest(data) {
    return this.http.post(this.searchDeliveryRequestUrl, data)
  }
  searchReceiptRequest(data) {
    return this.http.post(this.searchReceiptUrl, data)
  }

  //--------------------------------SEARCH BY PRODUCT & LOCATION -------------------------------//

  postSearchByLocation(User) {
    return this.http.post<any>(this.searchByLocation, User)
  }

  getSearchByMaterial(User) {
    return this.http.post<any>(this.searchByProduct, User)
  }

  getSearchByLocation(User): Observable<any> {
    return this.http.post<any>(this.getAllLocation, User)
  }

  // -----------------------------location transfer-----------------------------
  transferLocation(User) {
    console.log('api post data', User)
    return this.http.post<any>(this.transferLocationUrl, User)
  }

  sourceLocation(name) {
    return this.http.get(this.sourceLocationUrl + name + '/warehouse/' + localStorage.getItem('WarehouseID'))
  }
  existingLocation(data) {
    return this.http.post(this.existingLocationUrl, data)
  }
  locationSearch(data) {
    return this.http.get(this.locationSearchUrl + '/' + localStorage.getItem('WarehouseID') + '/' + data)
  }
  locationTransferSummary(id) {
    return this.http.get(this.locationTransferSummaryUrl + id)
  }



  //-------------------------------UPLOAD DOCUMENT--------------------------------//

  public upload(formData): Observable<any> {
    return this.http.post<any>(this.uploadDocUrl, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getProductName(User) {
    return this.http.post<any>(this.productInfoApi, User)
  }

  //------------------------------EXCEL DOWNLOAD--------------------------------------//

  downloadReportApi(User): Observable<HttpResponse<any>> {
    let url = this.downloadStmt + "/" + (localStorage.getItem('SDate') || localStorage.getItem('DefaultDate')) + "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" + "customer" + "/" + localStorage.getItem("ViewCustomer_id")

    var body = {
      "userId": localStorage.getItem('ViewCustomer_id'),
      "warehouseId": localStorage.getItem('WarehouseID')
    };

    return this.http.post<HttpResponse<any>>(url, body, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });

  }

  downloadTransactionReportApi(User): Observable<HttpResponse<any>> {
    let url = this.downloadStockTransaction

    var body = {
      "userId": User.userId,
      "warehouseId": User.warehouseId,
      "date": User.date
    };

    return this.http.post<HttpResponse<any>>(url, body, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });

  }

  downloadReportDn(startDate, endDate): Observable<HttpResponse<any>> {
    var url = this.downloadStmtDN + "/" + localStorage.getItem("SelectedCustomerID") + "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" + startDate + "/" + endDate
    return this.http.get<HttpResponse<any>>(url, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });
  }

  downloadReportGrn(startDate, endDate): Observable<HttpResponse<any>> {
    var url = this.downloadStmtGRN + "/" + localStorage.getItem("SelectedCustomerID") + "/" + "warehouse" + "/" + localStorage.getItem('WarehouseID') + "/" + startDate + "/" + endDate
    return this.http.get<HttpResponse<any>>(url, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });
  }

  downloadReportLedgerApi(User): Observable<HttpResponse<any>> {
    var url = this.downloadStmtLedger
    var body = {
      "customerId": User.customerId,
      "warehouseId": User.warehouseId,
      "productId": User.productId,
      "startDate": User.startDate,
      "endDate": User.endDate
    };
    return this.http.post<HttpResponse<any>>(url, body, {
      responseType: "blob" as 'json',
      observe: 'response' as 'body'
    });
  }


  /*--------------------------------TOKEN IMPLEMENTATION------------------------------------*/

  private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
  getNewRefreshToken(): Observable<any> {
    let token = localStorage.getItem('Refresh_token');

    return this.http.post<any>(this.refreshTokenUrl, { token }).pipe(
      map(result => {
        if (result && result.token) {
          this.loginStatus.next(true);
          localStorage.setItem('loginStatus', '1');
          localStorage.setItem("token", result.token)
          this.decodedToken = this.helper.decodeToken(localStorage.getItem('token'));
          localStorage.setItem('UserID', this.decodedToken.id)
          localStorage.setItem('U_name', this.decodedToken.name)
          localStorage.setItem('U_number', this.decodedToken.mobileNo)
          localStorage.setItem('U_email', this.decodedToken.email)
        }

        return <any>result;

      })
    );

  }

  checkLoginStatus(): boolean {

    var loginCookie = localStorage.getItem("loginStatus");

    if (loginCookie == "1") {
      if (localStorage.getItem('session_access_token') != null || localStorage.getItem('session_access_token') != undefined) {
        return true;
      }
    }
    return false;
  }

  logout() {
    // Set Loginstatus to false and delete saved jwt cookie
    this.loginStatus.next(false);
    localStorage.clear()
    console.log("Logged Out Successfully");

  }
}


