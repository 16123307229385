import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-ware-house-details',
  templateUrl: './ware-house-details.component.html',
  styleUrls: ['./ware-house-details.component.css']
})
export class WareHouseDetailsComponent implements OnInit {
  totalCustomers: any;
  areaOccupied: any;
  completedReceipt: any;
  completedDeliveries: any;

  constructor(private apiCall: ApiService, private router: Router) { }

  ngOnInit() {
    const obj = {
      "warehouseId": localStorage.getItem('WarehouseID')
    }
    this.apiCall.getDashboardApi(obj).subscribe(res => {
      // console.log(res)
      this.totalCustomers = res.result.Served['Total number of Customers']
      this.areaOccupied = res.result.Served['Total Area Occupied']
      this.completedReceipt = res.result['Completed Tasks']['Receipts Count']
      this.completedDeliveries = res.result['Completed Tasks']['Deliveries Count']
      // console.log(this.totalCustomers, this.areaOccupied)

    })
  }
  Customer() {
    this.router.navigate(['viewcustomers'])
  }

}
